import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromParcelsEntity from '@app/overview/shared/parcels/parcels-entity.reducer';
import { ParcelsEffects } from '@app/overview/shared/parcels/parcels.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('parcels', fromParcelsEntity.parcelsReducer),
    EffectsModule.forFeature([ParcelsEffects]),
  ],
})
export class ParcelsModule {}
