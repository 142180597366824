import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CompanionComponent } from '@davinkevin/companion-component';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { Parcel } from '@app/overview/shared/farm-star/parcel.model';
import { Observable } from 'rxjs';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import { FilteredParcel } from '@app/overview/shared/parcels-map.models';
import * as fromOverview from '@app/overview/overview.reducer';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarIconComponent } from '@app/overview/shared/snackbar-icon/snackbar-icon.component';
import * as fromRoot from '@app/reducer';
import { fadeInOutWithTransition } from '@app/shared/animations/animations';
import { FilteredFarm } from '@app/overview/overview.models';
import { map } from 'rxjs/operators';
import selectAllFields = fromParcelsViewer.selectAllFields;
import FilterFeatures = ParcelsViewerActions.FilterFeatures;
import { FilterFarm, PrepareFarmSelection } from '@app/overview/overview.actions';

@Component({
  selector: 'fstar-map-viewer',
  templateUrl: './parcels-viewer.component.html',
  styleUrls: ['./parcels-viewer.component.scss'],
  animations: [fadeInOutWithTransition('0s', '0.3s')],
})
export class ParcelsViewerComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: false })
  viewContainerRef: ViewContainerRef;

  public parcels$: Observable<Parcel[]>;
  public filteredParcels$: Observable<FilteredParcel[]>;
  public openingToolbarStatus: boolean;
  public isLoadingParcelsAdvices$: Observable<boolean>;
  public selectedParcel$: Observable<FilteredParcel>;
  areParcelsFeaturesActivated: boolean;
  snackBarRef: MatSnackBarRef<SnackbarIconComponent>;
  isAdviceModeContainerOpened$: Observable<boolean>;
  isLaiModeActivated$: Observable<boolean>;
  public filteredFarms$: Observable<FilteredFarm[]>;
  public selectedFarm$: Observable<FilteredFarm>;

  constructor(
    private store: Store<fromParcelsViewer.State | fromOverview.State | fromRoot.State>,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const untilDestroy = this.companion.untilDestroy();

    this.isAdviceModeContainerOpened$ = this.store.pipe(select(fromParcelsViewer.selectIsAdviceModeContainerOpened));

    this.isLaiModeActivated$ = this.store.pipe(
      select(fromParcelsViewer.selectIsLaiMode),
      map(isLaiModeSelected => isLaiModeSelected)
    );

    this.filteredFarms$ = this.store.pipe(select(fromOverview.selectFilteredFarms));

    this.selectedFarm$ = this.store.pipe(select(fromOverview.selectSelectedFilteredFarm));

    this.selectedParcel$ = this.store.pipe(select(fromParcelsViewer.selectSelectedParcelFiltered));

    this.isLoadingParcelsAdvices$ = this.store.pipe(select(fromParcelsViewer.selectIsLoadingParcelsOrAdvices));

    this.store
      .pipe(untilDestroy(), select(fromParcelsViewer.selectAreParcelsFeaturesActivated))
      .subscribe(areParcelsFeaturesActivated => {
        this.areParcelsFeaturesActivated = areParcelsFeaturesActivated;
        if (!areParcelsFeaturesActivated) {
          this.snackBarRef = this.snackBar.openFromComponent(SnackbarIconComponent, {
            panelClass: ['white-snackbar'],
            verticalPosition: 'bottom',
            viewContainerRef: this.viewContainerRef,
            data: {
              message: 'Aucune parcelle enregistrée.',
              hasWhiteFillColor: false,
              hasCloseButton: false,
            },
          });
        } else {
          this.closeSnackBar();
        }
      });

    this.parcels$ = this.store.pipe(select(selectAllFields));

    this.filteredParcels$ = this.store.pipe(select(fromParcelsViewer.selectFilteredFields));

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectCurrentOpeningToolbarStatus))
      .subscribe(status => (this.openingToolbarStatus = status));
  }

  closeSnackBar() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  ngOnDestroy() {
    this.closeSnackBar();
    this.companion.destroy();
  }

  filterTermChanged(term: string) {
    this.store.dispatch(new FilterFeatures(term));
  }

  parcelToSelect(parcelId: string) {
    this.store.dispatch(new ParcelsViewerActions.SelectFeature(parcelId));
  }

  filterFarmTermChanged(term: string) {
    this.store.dispatch(FilterFarm({ filter: term }));
  }

  onParcelHovered(parcelId: string) {
    this.store.dispatch(new ParcelsViewerActions.HoverFeature(parcelId));
  }

  farmToSelect(farmId: string) {
    this.store.dispatch(PrepareFarmSelection({ farmId }));
  }
}
