import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';
import { Extent } from 'ol/extent';
import { ObservationService } from '@app/overview/shared/observation/observation.service';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  LayerGroupComponent,
  MapComponent as OlMapComponent,
  SelectInteractionComponent,
  SourceVectorComponent,
  ViewComponent,
} from 'ngx-openlayers';
import { Feature as OlFeature, MapBrowserEvent } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import { createBox } from 'ol/interaction/Draw';
import { GeoJSON } from 'ol/format';
import { singleClick } from 'ol/events/condition';
import {
  getCenterFromFeature,
  getIntersectFeature,
  isPointIntersectFeature,
  proj3857,
  proj4326,
} from '@app/overview/parcels-viewer/shared/utils';
import { forkJoin, fromEvent, Observable, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CompanionComponent } from '@davinkevin/companion-component';
import { Feature, Point, Polygon } from 'geojson';
import * as fromParcelDetails from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.reducer';
import { select, Store } from '@ngrx/store';
import {
  DrawMode,
  FeatureArea,
  FeatureBiophy,
  PointWithBiophyValue,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import {
  DeleteFeature,
  DrawEnd,
  EndDrawMode,
  FindObservationOfPoint,
  FindObservationOfPolygon,
  ResetBiophyOfPoint,
  SelectFeature,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { IdService } from '@app/shared/id/id.service';
import { DOCUMENT } from '@angular/common';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '@app/shared/image/image.service';
import { point as turfPoint } from '@turf/helpers';
import { Layer } from 'ol/layer';
import { ParcelRecommendationApiService } from '@app/shared/api/parcel/recommendation/parcel-recommendation-api.service';
import { ParcelWithAdvices } from '@app/overview/shared/farm-star/parcel.model';
import { ParcelApiService } from '@app/shared/api/parcel/parcel-api.service';
import { transformToMapExtent } from '@app/shared/utils/ol-utils';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as fromOverview from '@app/overview/overview.reducer';

interface ParcelImage {
  link: string;
  extent: Extent;
}

@Component({
  selector: 'fstar-parcel-details-map',
  templateUrl: './parcel-details-map.component.html',
  styleUrls: ['./parcel-details-map.component.scss'],
})
export class ParcelDetailsMapComponent implements OnInit, AfterViewInit, OnDestroy {
  private companion = new CompanionComponent();
  observationImageUrl: string;
  observationImageExtent: Extent;
  @Input() parcel: Observable<ParcelWithAdvices>;

  @ViewChild('map', { static: true }) map: OlMapComponent;
  @ViewChild('view', { static: true }) view: ViewComponent;
  @ViewChild('aoiSource', { static: true }) aoiSource: SourceVectorComponent;
  @ViewChild('markersSource', { static: true }) markersSource: SourceVectorComponent;
  @ViewChild('featuresLayerGroup', { static: false }) featuresLayerGroup: LayerGroupComponent;
  @ViewChild('featureSelect', { static: true }) featureSelect: SelectInteractionComponent;

  public proj3857 = proj3857;
  public center = [217073.00704688346, 5909489.863677091];
  public geoJsonFormat = new GeoJSON();
  public showMarkers = false;
  isDrawModeActivated = false;
  drawMode: DrawMode;
  drawModeEnum = DrawMode;
  drawBoxGeometryFunction = createBox();
  selectedStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.5)',
    }),
    stroke: new Stroke({
      color: '#042237',
      width: 2,
    }),
  });
  public features$: Observable<FeatureArea[]> = of([]);
  public selectedFeature$: Observable<FeatureArea> = null;
  public pointWithBiophyValue$: Observable<PointWithBiophyValue>;
  public selectedObservation$: Observable<farmStarApiModel.ObservationBackend>;
  public selectedFeatureBiophy$: Observable<FeatureBiophy>;
  selectedFeatureId: string = null;
  parcelToOlFeature: OlFeature;
  selectionPointDisabled: boolean;
  public isPanelDoseOpen = false;
  public biophyImage: ParcelImage = { link: null, extent: null };
  public totalNitrogenImage: ParcelImage = { link: null, extent: null };
  showTotalNitrogenDose = false;
  parcelValue: ParcelWithAdvices;
  constructor(
    private store: Store<fromParcelDetails.State | fromParcelsViewer.ParcelsViewerGlobalState>,
    private idService: IdService,
    @Inject(DOCUMENT) private document: any,
    private httpClient: HttpClient,
    private imageService: ImageService,
    private observationService: ObservationService,
    private farmStarService: FarmStarService,
    private parcelRecommendationApi: ParcelRecommendationApiService,
    private parcelApi: ParcelApiService
  ) {}

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    timer(1).subscribe(_ => {
      this.map.instance.updateSize();
    });

    fromEvent<KeyboardEvent>(this.document, 'keydown')
      .pipe(
        filter(() => !!this.selectedFeatureId),
        filter((event: KeyboardEvent) => event.key === 'Delete')
      )
      .subscribe(() => {
        this.store.dispatch(DeleteFeature({ featureId: this.selectedFeatureId }));
      });

    fromEvent<KeyboardEvent>(this.document, 'keydown')
      .pipe(
        filter(() => this.isDrawModeActivated),
        filter((event: KeyboardEvent) => event.key === 'Delete')
      )
      .subscribe(() => {
        this.store.dispatch(EndDrawMode());
        this.featureSelect.instance.setActive(true);
      });

    fromEvent<KeyboardEvent>(this.document, 'keydown')
      .pipe(
        filter(() => this.isDrawModeActivated),
        filter(event => event.key === 'Escape')
      )
      .subscribe(() => {
        this.store.dispatch(EndDrawMode());
        this.featureSelect.instance.setActive(true);
      });

    this.selectedFeature$ = this.store.pipe(select(fromParcelDetails.selectSelectedFeature));

    this.selectedFeatureBiophy$ = this.store.pipe(select(fromParcelDetails.selectSelectedFeatureBiophy));

    this.selectedObservation$ = this.store.pipe(select(fromParcelDetails.selectSelectedObservation));
    this.store
      .pipe(
        untilDestroy(),
        select(fromParcelDetails.selectSelectedObservation),
        filter(observation => !!observation)
      )
      .subscribe((observation: farmStarApiModel.ObservationBackend) => {
        this.observationImageUrl = this.observationService.getLaiLayerLargePngUrl(observation);
        this.observationImageExtent = this.farmStarService.transformImageExtent(
          this.observationService.getExtent(this.observationService.getA3iDisplayFullCycleIllustration(observation))
        );
      });

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectSelectedFeatureId))
      .subscribe(selectedFeatureId => (this.selectedFeatureId = selectedFeatureId));

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectIsDrawModeActivated))
      .subscribe(isDrawModeActivated => (this.isDrawModeActivated = isDrawModeActivated));

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectDrawMode))
      .subscribe(drawMode => (this.drawMode = drawMode));

    this.features$ = this.store.pipe(select(fromParcelDetails.selectAllFeatures));

    this.pointWithBiophyValue$ = this.store.pipe(
      select(fromParcelDetails.selectPointWithBiophyValue),
      map(biophyValue => {
        this.map.instance.updateSize();
        return biophyValue;
      })
    );

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectIsPanelDoseOpened))
      .subscribe(isPanelDoseOpen => (this.isPanelDoseOpen = isPanelDoseOpen));

    this.parcel.subscribe(parcelValue => (this.parcelValue = parcelValue));
    this.store
      .pipe(untilDestroyed(this), select(fromOverview.selectHideDataForCampaigns))
      .subscribe(hideForCampaigns => {
        this.showTotalNitrogenDose = false;
        if (this.parcelValue && hideForCampaigns.has(this.parcelValue.campaignId)) {
          const hiddenForCampaign = hideForCampaigns.get(this.parcelValue.campaignId).hideNitrogenDose;
          this.showTotalNitrogenDose = !hiddenForCampaign && this.parcelValue.isTotalNDelivered;
        }
      });
  }

  ngAfterViewInit(): void {
    const untilDestroy = this.companion.untilDestroy();

    this.store
      .pipe(
        untilDestroy(),
        select(fromParcelDetails.selectAllFeatures),
        filter(() => this.featureSelect && !!this.featureSelect.instance)
      )
      .subscribe(() => this.featureSelect.instance.getFeatures().clear());

    this.parcel
      .pipe(
        untilDestroy(),
        filter(field => field != null),
        tap(() => this.aoiSource.instance.clear()),
        tap(() => this.markersSource.instance.clear()),
        tap(field => {
          this.totalNitrogenImage.link = this.parcelRecommendationApi.getTotalNRecommendationIllustrationUrl(
            field.totalNRecommendationId
          );
          this.biophyImage = { link: field.biophyImageLink, extent: field.biophyImageExtent };
        }),
        map(
          field =>
            ({
              ...field.aoi.polygon,
              id: field.id,
              properties: { name: field.name, numSaisi: field.code },
            } as Feature<any>)
        ),
        map(feature =>
          this.geoJsonFormat.readFeature(feature, {
            dataProjection: proj4326,
            featureProjection: proj3857,
          })
        ),
        switchMap(feature =>
          forkJoin({
            olFeature: of(feature),
            extent: this.parcelApi.getIllustrationExtent(feature.getId() as string),
          })
        )
      )
      .subscribe(({ olFeature, extent }) => {
        this.parcelToOlFeature = olFeature;
        this.aoiSource.instance.addFeature(olFeature);
        this.markersSource.instance.addFeature(getCenterFromFeature(olFeature));
        this.totalNitrogenImage.extent = transformToMapExtent(extent);
        this.view.instance.fit(this.aoiSource.instance.getExtent());
      });
  }

  ngOnDestroy() {
    this.companion.destroy();
  }

  selectCondition() {
    return (event: MapBrowserEvent) => singleClick(event) && !this.isDrawModeActivated;
  }

  trackByFeatureId(_: any, feature: Feature) {
    return feature.id;
  }

  onFeatureSelect(feature: OlFeature) {
    this.store.dispatch(SelectFeature({ featureId: feature && feature.getId() ? feature.getId().toString() : null }));
  }

  startDraw() {
    this.store.dispatch(SelectFeature(null));
    this.featureSelect.instance.getFeatures().clear();
    this.featureSelect.instance.setActive(false);
  }

  endDraw(feature: OlFeature) {
    const featureDrawedInParcel = getIntersectFeature(this.parcelToOlFeature, feature);
    if (!!featureDrawedInParcel && featureDrawedInParcel.geometry.type === 'Polygon') {
      this.selectionPointDisabled = true;
      const olFeatureDrawed: OlFeature = this.geoJsonFormat.readFeature(featureDrawedInParcel);
      const id = this.idService.create();
      olFeatureDrawed.setId(id);
      this.store.dispatch(SelectFeature({ featureId: id }));
      this.store.dispatch(EndDrawMode());
      this.store.dispatch(DrawEnd({ feature: featureDrawedInParcel, featureId: id }));

      const reprojFeature: Feature = this.convertToGeoJsonFeatureFrom3857To4326(olFeatureDrawed);
      const polygon: Polygon = reprojFeature.geometry as Polygon;
      const featureId = reprojFeature.id as string;
      this.store.dispatch(FindObservationOfPolygon({ polygon, featureId }));

      this.featureSelect.instance.getFeatures().push(olFeatureDrawed);
      timer(300)
        .pipe()
        .subscribe(() => {
          this.featureSelect.instance.setActive(true);
          this.selectionPointDisabled = false;
        });
    }
  }

  updateZoomLevel() {
    this.showMarkers = this.view.instance.getZoom() < 15;
  }

  isFeaturesLayer = (layer: Layer) => {
    return this.featuresLayerGroup.instance
      .getLayers()
      .getArray()
      .some(l => l === layer);
    // tslint:disable-next-line
  };

  convertToGeoJsonFeatureFrom3857To4326(olFeature: OlFeature): Feature {
    const geoJsonFeature4326 = this.geoJsonFormat.writeFeature(olFeature, {
      dataProjection: proj4326,
      featureProjection: proj3857,
    });
    return JSON.parse(geoJsonFeature4326);
  }

  clickInteraction(mapBrowserEvent: MapBrowserEvent) {
    if (
      isPointIntersectFeature(this.parcelToOlFeature, mapBrowserEvent.coordinate as [number, number]) &&
      !this.isDrawModeActivated &&
      !this.selectionPointDisabled
    ) {
      const featurePoint = turfPoint(mapBrowserEvent.coordinate);
      const olFeatureDrawed: OlFeature = this.geoJsonFormat.readFeature(featurePoint);
      const reprojFeature: Feature = this.convertToGeoJsonFeatureFrom3857To4326(olFeatureDrawed);
      const point: Point = reprojFeature.geometry as Point;
      this.store.dispatch(
        FindObservationOfPoint({ point, coordinates: mapBrowserEvent.coordinate as [number, number] })
      );
    }
  }

  resetPointBiophy() {
    this.store.dispatch(ResetBiophyOfPoint());
  }

  imageLoadFunction = (image: any, src: string) => {
    this.loadImage(src).subscribe((imageSrc: string) => {
      (image.getImage() as HTMLImageElement).src = imageSrc;
    });
  };

  loadImage(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      switchMap(this.imageService.toBase64),
      catchError(() => {
        return of(this.imageService.emptyPng());
      })
    );
  }
}
