import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NitrogenStatus,
  Parcel,
  ParcelWithTotalDoseComputatioMethods,
} from '@app/overview/shared/farm-star/parcel.model';
import * as Highcharts from 'highcharts';
import {
  emptyDetailsChartWhenCauEqual0,
  emptyGlobalChartWhenCauEqual0,
  generateOptions,
  NitrogenLabels,
  parcelHasAtLeastOneNeededNitrogenData,
} from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/total-dose-details';

@Component({
  selector: 'fstar-fwt-ra-dialog',
  templateUrl: './fwt-ra-dialog.component.html',
  styleUrls: ['../total-dose-details-dialog.component.scss', './fwt-ra-dialog.component.scss'],
})
export class FwtRaDialogComponent {
  Highcharts = Highcharts;
  chartInstance: Highcharts.Chart = null;
  isRemainingMeasuredUpdated: boolean;
  subTotal: number;
  nitrogenLabelsEnum = NitrogenLabels;

  globalChartColors = ['#052338', '#9EBA11', '#9013FE'];
  globalChartOptions: Highcharts.Options = null;

  detailsChartColors = ['#cfc32e', '#e56c00', '#87241a', '#6B9243', '#CCCCCC', '#76CFFF', '#000000'];
  detailsChartOptions: Highcharts.Options = null;

  noDecimalFormat = '1.0-0';
  oneDecimalFormat = '1.1-1';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParcelWithTotalDoseComputatioMethods,
    private dialogRef: MatDialogRef<FwtRaDialogComponent>
  ) {
    this.isRemainingMeasuredUpdated =
      !!data.agroData.nitrogenStatus.actualMeasuredNitrogenCredit ||
      data.agroData.nitrogenStatus.actualMeasuredNitrogenCredit === 0;

    const displayNotAvailableChart =
      !parcelHasAtLeastOneNeededNitrogenData(
        [
          'humusMineralizationEffect',
          'previousCropMinEffect',
          'grasslandEffect',
          'catchCropEffect',
          'irrigationEffect',
          'nitrogenCreditBalanceBeginning',
          'actualMeasuredNitrogenCredit',
          'historicOrganicWasteEffect',
        ],
        this.data
      ) || this.data.agroData.nitrogenStatus.workingCoefficient === 0;

    this.subTotal = this.getSubTotal(this.data);

    const globalChartData = this.generateGlobalData(data);
    const detailsChartData = this.generateDetailsData(data);

    this.globalChartOptions = generateOptions(this.globalChartColors, globalChartData, '60%', displayNotAvailableChart);
    this.detailsChartOptions = generateOptions(
      this.detailsChartColors,
      detailsChartData,
      '80%',
      displayNotAvailableChart
    );
  }

  generateGlobalData(data: Parcel): Array<Highcharts.SeriesPieDataOptions> {
    const nitrogenStatus = data.agroData.nitrogenStatus;

    if (nitrogenStatus.workingCoefficient === 0) {
      return emptyGlobalChartWhenCauEqual0([
        NitrogenLabels.MINERALIZATION,
        NitrogenLabels.OTHER_SUPPLIES,
        NitrogenLabels.ORGANIC_WASTE_PRODUCTS,
      ]);
    }

    const mineralizationTotal = this.sumOfAllMineralizationElements(nitrogenStatus);

    const otherSuppliesTotal = this.sumOfAllOtherSuppliesElements(nitrogenStatus);

    const organicWastEffectTotal = Math.abs(nitrogenStatus.organicWasteEffect);

    const total = mineralizationTotal + otherSuppliesTotal + organicWastEffectTotal;

    return [
      {
        name: NitrogenLabels.MINERALIZATION,
        y: (100 * mineralizationTotal) / total,
      },
      {
        name: NitrogenLabels.OTHER_SUPPLIES,
        y: (100 * otherSuppliesTotal) / total,
      },
      {
        name: NitrogenLabels.ORGANIC_WASTE_PRODUCTS,
        y: (100 * organicWastEffectTotal) / total,
      },
    ];
  }

  sumOfAllMineralizationElements(nitrogenStatus: NitrogenStatus) {
    let mineralizationTotal =
      Math.abs(nitrogenStatus.humusMineralizationEffect) +
      Math.abs(nitrogenStatus.previousCropMinEffect) +
      Math.abs(nitrogenStatus.grasslandEffect) +
      Math.abs(nitrogenStatus.catchCropEffect);
    mineralizationTotal = Math.abs(mineralizationTotal / nitrogenStatus.workingCoefficient);

    return mineralizationTotal;
  }

  sumOfAllOtherSuppliesElements(nitrogenStatus: NitrogenStatus) {
    const actualMeasuredNitrogenCredit = this.isRemainingMeasuredUpdated
      ? nitrogenStatus.actualMeasuredNitrogenCredit
      : nitrogenStatus.nitrogenCreditBalanceBeginning;

    const otherSuppliesTotal = Math.abs(nitrogenStatus.irrigationEffect) + Math.abs(actualMeasuredNitrogenCredit);

    return Math.abs(otherSuppliesTotal / nitrogenStatus.workingCoefficient);
  }

  generateDetailsData(data: Parcel): Array<Highcharts.SeriesPieDataOptions> {
    const nitrogenStatus = data.agroData.nitrogenStatus;

    if (nitrogenStatus.workingCoefficient === 0) {
      return emptyDetailsChartWhenCauEqual0([
        NitrogenLabels.HUMUS_MINERALIZATION_EFFECT,
        NitrogenLabels.PREVIOUS_CROP_MIN_EFFECT,
        NitrogenLabels.GRASSLAND_EFFECT,
        NitrogenLabels.CATCH_CROP_EFFECT,
        NitrogenLabels.IRRIGATION_EFFECT,
        NitrogenLabels.N_CREDIT_BALANCE_BEGINNING,
        NitrogenLabels.ORGANIC_WASTE_EFFECT,
      ]);
    }

    let humus = Math.abs(nitrogenStatus.humusMineralizationEffect);
    humus = Math.abs(humus / nitrogenStatus.workingCoefficient);
    let previousCrop = Math.abs(nitrogenStatus.previousCropMinEffect);
    previousCrop = Math.abs(previousCrop / nitrogenStatus.workingCoefficient);
    let grasslandEffect = Math.abs(nitrogenStatus.grasslandEffect);
    grasslandEffect = Math.abs(grasslandEffect / nitrogenStatus.workingCoefficient);
    let catchCropEffect = Math.abs(nitrogenStatus.catchCropEffect);
    catchCropEffect = Math.abs(catchCropEffect / nitrogenStatus.workingCoefficient);
    let irrigationEffect = Math.abs(nitrogenStatus.irrigationEffect);
    irrigationEffect = Math.abs(irrigationEffect / nitrogenStatus.workingCoefficient);

    let actualMeasuredNitrogenCredit = this.isRemainingMeasuredUpdated
      ? Math.abs(nitrogenStatus.actualMeasuredNitrogenCredit)
      : Math.abs(nitrogenStatus.nitrogenCreditBalanceBeginning);
    actualMeasuredNitrogenCredit = Math.abs(actualMeasuredNitrogenCredit / nitrogenStatus.workingCoefficient);

    const organicWasteEffect = Math.abs(nitrogenStatus.organicWasteEffect);
    const total =
      humus +
      previousCrop +
      grasslandEffect +
      catchCropEffect +
      irrigationEffect +
      actualMeasuredNitrogenCredit +
      organicWasteEffect;

    return [
      {
        name: NitrogenLabels.HUMUS_MINERALIZATION_EFFECT,
        y: (100 * humus) / total,
      },
      {
        name: NitrogenLabels.PREVIOUS_CROP_MIN_EFFECT,
        y: (100 * previousCrop) / total,
      },
      {
        name: NitrogenLabels.GRASSLAND_EFFECT,
        y: (100 * grasslandEffect) / total,
      },
      {
        name: NitrogenLabels.CATCH_CROP_EFFECT,
        y: (100 * catchCropEffect) / total,
      },
      {
        name: NitrogenLabels.IRRIGATION_EFFECT,
        y: (100 * irrigationEffect) / total,
      },
      {
        name: NitrogenLabels.N_CREDIT_BALANCE_BEGINNING,
        y: (100 * actualMeasuredNitrogenCredit) / total,
      },
      {
        name: NitrogenLabels.ORGANIC_WASTE_EFFECT,
        y: (100 * organicWasteEffect) / total,
      },
    ];
  }

  getSubTotal(data: Parcel): number {
    const nitrogenStatus = data.agroData.nitrogenStatus;

    const actualMeasuredNitrogenCredit = this.isRemainingMeasuredUpdated
      ? nitrogenStatus.actualMeasuredNitrogenCredit
      : nitrogenStatus.nitrogenCreditBalanceBeginning;

    return Math.abs(
      nitrogenStatus.totalNeed +
        nitrogenStatus.nitrogenCreditYield -
        (nitrogenStatus.humusMineralizationEffect +
          nitrogenStatus.previousCropMinEffect +
          nitrogenStatus.grasslandEffect +
          nitrogenStatus.catchCropEffect +
          nitrogenStatus.irrigationEffect +
          actualMeasuredNitrogenCredit)
    );
  }

  onChartInit(chartInstance: Highcharts.Chart) {
    this.chartInstance = chartInstance;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.chartInstance.reflow();
  }

  onClose() {
    this.dialogRef.close();
  }
}
