import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import { polygon, point as turfPoint } from '@turf/helpers';
import { default as centerOfMass } from '@turf/center-of-mass';
import { booleanContains, intersect } from '@turf/turf';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import { Feature as GeoJsonFeature } from 'geojson';
import IconOrigin from 'ol/style/IconOrigin';
import { DailyStatus } from '@app/overview/shared/farm-star/parcel.model';
import { CropCode } from '@app/overview/shared/farm-star/agro-datum.model';

export const proj3857 = 'EPSG:3857';
export const proj4326 = 'EPSG:4326';
const hydroStressColor = 'rgba(208, 2, 27, 0.35)';
const hydroNoStressColor = 'rgba(6, 31, 92, 0.40)';

export const getIntersectFeature = (parentFeature: Feature, childFeature: Feature): GeoJsonFeature => {
  const parentFeatureCoordinates = polygon((parentFeature.getGeometry() as Polygon).getCoordinates());
  const childFeatureCoordinates = polygon((childFeature.getGeometry() as Polygon).getCoordinates());
  return intersect(parentFeatureCoordinates, childFeatureCoordinates);
};

export const isPointIntersectFeature = (parentFeature: Feature, pointCoordinates: [number, number]): boolean => {
  const parentFeatureCoordinates = polygon((parentFeature.getGeometry() as Polygon).getCoordinates());
  return booleanContains(parentFeatureCoordinates, turfPoint(pointCoordinates));
};

export const getCenterFromFeature = (feature: Feature): Feature => {
  const featureCoordinates = polygon((feature.getGeometry() as Polygon).getCoordinates());
  const centeredPoint = centerOfMass(featureCoordinates);
  const point = new Feature(new Point([centeredPoint.geometry.coordinates[0], centeredPoint.geometry.coordinates[1]]));
  point.setId(feature.getId());
  return point;
};

export const getCenterFromFeatureWithStyle = (featureStyle: Style | Style[] | StyleFunction) => (
  feature: Feature
): Feature => {
  const featureCoordinates = polygon((feature.getGeometry() as Polygon).getCoordinates());
  const centeredPoint = centerOfMass(featureCoordinates);
  const point = new Feature(new Point([centeredPoint.geometry.coordinates[0], centeredPoint.geometry.coordinates[1]]));
  point.setId(feature.getId());
  const properties = feature.getProperties();
  delete properties.geometry;
  point.setProperties(properties);
  point.setStyle(featureStyle);
  return point;
};

export const hiddenFeatureStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0)',
  }),
  stroke: new Stroke({
    color: 'rgba(255, 255, 255, 0)',
  }),
});

export const hoverDefaultStyle = new Style({
  fill: new Fill({ color: 'rgba(255, 255, 255, 0.25)' }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const hoverHighlightStyle = new Style({
  fill: new Fill({ color: 'rgba(229, 108, 0, 0.25)' }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const hoverHighlightStressStyle = new Style({
  fill: new Fill({ color: 'rgba(208, 2, 27, 0.15)' }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const hoverHighlightNoStressStyle = new Style({
  fill: new Fill({ color: 'rgba(6, 31, 92, 0.25)' }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const defaultStyle = new Style({
  fill: new Fill({ color: 'rgba(255, 255, 255, 0)' }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const highlightStyle = new Style({
  fill: new Fill({ color: 'rgba(229, 108, 0, 0.5)' }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const highlightStressStyle = new Style({
  fill: new Fill({ color: hydroStressColor }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const highlightNoStressStyle = new Style({
  fill: new Fill({ color: hydroNoStressColor }),
  stroke: new Stroke({ color: 'rgba(255, 255, 0)', width: 2 }),
});

export const medianBiophyStyle = (medianBiophy: number | null) =>
  new Style({
    image: new Circle({
      radius: 25,
      fill: new Fill({ color: '#9EBA11' }),
    }),
    text: new Text({
      font: '400 11px Lato',
      fill: new Fill({ color: '#fff' }),
      offsetY: 1,
      offsetX: -1,
      text: medianBiophy ? medianBiophy.toString() : 'ND',
    }),
  });

export const medianBiophyMarkerStyle = (medianBiophy: number | null) =>
  new Style({
    image: new Icon({
      src: 'assets/images/marker-background.svg',
      anchor: [0.5, 1],
      scale: 0.5,
      anchorOrigin: IconOrigin.TOP_LEFT,
    }),
    text: new Text({
      font: '400 11px Lato',
      fill: new Fill({ color: '#fff' }),
      offsetY: -35,
      text: medianBiophy ? medianBiophy.toString() : 'ND',
    }),
  });

export const totalDoseStyle = (totalDose: number | null) =>
  new Style({
    image: new Circle({
      radius: 25,
      fill: new Fill({ color: 'rgba(216, 216, 216, 0.5)' }),
    }),
    text: new Text({
      font: '400 11px Lato',
      fill: new Fill({ color: '#fff' }),
      offsetY: 1,
      offsetX: -1,
      text: totalDose != null ? totalDose + ' U' : 'ND',
    }),
  });

export const totalDoseMarkerStyle = (totalDose: number | null) =>
  new Style({
    image: new Icon({
      src: 'assets/images/marker-background.svg',
      anchor: [0.5, 1],
      scale: 0.5,
      anchorOrigin: IconOrigin.TOP_LEFT,
    }),
    text: new Text({
      font: '400 11px Lato',
      fill: new Fill({ color: '#fff' }),
      offsetY: -35,
      text: totalDose != null ? totalDose + ' U' : 'ND',
    }),
  });

export const styleInteractionSelected = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.25)',
  }),
  stroke: new Stroke({
    color: 'rgba(255, 255, 0)',
    width: 2,
  }),
});

export const toHydroMarkerStyleTemplate = (color: string) => {
  const markerBackgroundStyle = new Style({
    image: new Icon({
      src: 'assets/images/marker-background.svg',
      anchor: [0.5, 1],
      scale: 0.5,
      anchorOrigin: IconOrigin.TOP_LEFT,
    }),
  });

  const imageStyle = new Style({
    image: new Icon({
      src: 'assets/images/drop.svg',
      anchor: [0.5, 1.8],
      scale: 0.9,
      anchorOrigin: IconOrigin.TOP_LEFT,
      color,
    }),
  });
  return [markerBackgroundStyle, imageStyle];
};

export const generateHydroMarkerStyle = (dailyStatus: DailyStatus) => {
  if (dailyStatus) {
    const color = dailyStatus.easilyUsableReserve < dailyStatus.waterDeficit ? hydroStressColor : hydroNoStressColor;
    return toHydroMarkerStyleTemplate(color);
  }
  return toHydroMarkerStyleTemplate('grey');
};
const toHydroStyleTemplate = (color: string, value?: number) => {
  const imageCircleStyle = new Style({
    image: new Circle({
      radius: 28,
      fill: new Fill({ color: '#FFF' }),
    }),
  });

  const svgIconStyle = new Style({
    image: new Icon({
      src: 'assets/images/drop-full.svg',
      anchor: [0.52, 0.73],
      scale: 2,
      anchorOrigin: IconOrigin.TOP_LEFT,
      color,
    }),
  });

  const textStyle = new Style({
    text: new Text({
      font: 'bold 16px Lato',
      fill: new Fill({ color: '#FFF' }),
      offsetX: 0,
      offsetY: -3,
      text: value ? Math.round(value).toString() : 'ND',
    }),
  });

  if (value) {
    const textUnityStyle = new Style({
      text: new Text({
        font: '12px Lato',
        fill: new Fill({ color }),
        offsetX: 0,
        offsetY: 21,
        text: 'mm',
      }),
    });
    return [imageCircleStyle, textStyle, svgIconStyle, textUnityStyle];
  }
  return [imageCircleStyle, textStyle, svgIconStyle];
};

export const generateHydroStyle = (dailyStatus: DailyStatus) => {
  if (dailyStatus) {
    const color = dailyStatus.easilyUsableReserve < dailyStatus.waterDeficit ? hydroStressColor : hydroNoStressColor;
    return toHydroStyleTemplate(color, dailyStatus.waterDeficit);
  }
  return toHydroStyleTemplate('grey');
};

const toStageStyleTemplate = (
  circleRadius: number,
  textOffsetX: number,
  textOffsetY: number,
  svgUrl: string,
  svgAnchorX: number,
  svgAnchorY: number,
  value: string,
  color: any,
  svgScale?: number
) => {
  const imageCircleStyle = new Style({
    image: new Circle({
      radius: circleRadius,
      fill: new Fill({ color }),
    }),
  });

  let textStyle;
  if (value) {
    textStyle = new Style({
      text: new Text({
        font: '400 11px Lato',
        fill: new Fill({ color: '#fff' }),
        offsetX: textOffsetX,
        offsetY: textOffsetY,
        text: value,
      }),
    });
  }

  let svgIconStyle;
  if (svgUrl) {
    svgIconStyle = new Style({
      image: new Icon({
        src: svgUrl,
        anchor: [svgAnchorX, svgAnchorY],
        scale: svgScale,
        anchorOrigin: IconOrigin.TOP_LEFT,
      }),
    });
  }
  return [imageCircleStyle, textStyle, svgIconStyle].filter(item => item != null);
};

const toStageMarkerStyleTemplate = (
  svgUrl: string,
  svgAnchorX: number,
  svgAnchorY: number,
  svgScale?: number,
  numberOfLeaves?: number
) => {
  const markerBackgroundStyle = new Style({
    image: new Icon({
      src: 'assets/images/marker-background.svg',
      anchor: [0.5, 1],
      scale: 0.5,
      anchorOrigin: IconOrigin.TOP_LEFT,
    }),
  });

  let imageOrTextStyle;
  if (svgUrl) {
    imageOrTextStyle = new Style({
      image: new Icon({
        src: svgUrl,
        anchor: [svgAnchorX, svgAnchorY],
        scale: svgScale,
        anchorOrigin: IconOrigin.TOP_LEFT,
      }),
    });
  } else {
    imageOrTextStyle = new Style({
      text: new Text({
        font: '400 11px Lato',
        fill: new Fill({ color: '#fff' }),
        offsetX: 0.5,
        offsetY: -35,
        text: 'ND',
      }),
    });
  }
  let textStyle;
  if (numberOfLeaves) {
    textStyle = new Style({
      text: new Text({
        font: '400 11px Lato',
        fill: new Fill({ color: '#fff' }),
        offsetX: -10,
        offsetY: -35,
        text: numberOfLeaves.toString(),
      }),
    });
  }
  return [markerBackgroundStyle, imageOrTextStyle, textStyle].filter(item => item != null);
};

export const generateStageMarkerStyle = (type: PhenologicalStageCode) => {
  switch (type) {
    case PhenologicalStageCode.seedling:
      return toStageMarkerStyleTemplate('assets/images/stages/seedling.svg', 0.5, 2.75, 0.5);
    case PhenologicalStageCode.emergence:
      return toStageMarkerStyleTemplate('assets/images/stages/leaf.svg', 0.5, 2.75, 0.5);
    case PhenologicalStageCode.fourLeaves:
      return toStageMarkerStyleTemplate('assets/images/stages/leaf.svg', 0.2, 1.8, 0.8, 4);
    case PhenologicalStageCode.sixLeaves:
      return toStageMarkerStyleTemplate('assets/images/stages/leaf.svg', 0.2, 1.8, 0.8, 6);
    case PhenologicalStageCode.eightLeaves:
      return toStageMarkerStyleTemplate('assets/images/stages/leaf.svg', 0.2, 1.8, 0.8, 8);
    case PhenologicalStageCode.tenLeaves:
      return toStageMarkerStyleTemplate('assets/images/stages/leaf.svg', 0.1, 1.8, 0.8, 10);
    case PhenologicalStageCode.bloom:
      return toStageMarkerStyleTemplate('assets/images/stages/bloom.svg', 0.5, 2, 0.8);
    case PhenologicalStageCode.humidity50:
      return toStageMarkerStyleTemplate('assets/images/stages/corn.svg', 0.5, 1.9, 0.8);
    case PhenologicalStageCode.humidity45:
      return toStageMarkerStyleTemplate('assets/images/stages/corn.svg', 0.5, 1.9, 0.8);
    case PhenologicalStageCode.humidity35:
      return toStageMarkerStyleTemplate('assets/images/stages/corn.svg', 0.5, 1.9, 0.8);
    case PhenologicalStageCode.humidity32:
      return toStageMarkerStyleTemplate('assets/images/stages/corn.svg', 0.5, 1.9, 0.8);
    case PhenologicalStageCode.dryMatter35:
      return toStageMarkerStyleTemplate('assets/images/stages/straw.svg', 0.5, 1.9, 0.8);
    case PhenologicalStageCode.dryMatter32:
      return toStageMarkerStyleTemplate('assets/images/stages/straw.svg', 0.5, 1.9, 0.8);
    default:
      return toStageMarkerStyleTemplate(null, 0.5, 1.9, 0.8);
  }
};

export const generateStageStyle = (type: PhenologicalStageCode) => {
  switch (type) {
    case PhenologicalStageCode.seedling:
      return toStageStyleTemplate(
        20,
        0,
        0,
        'assets/images/stages/seedling.svg',
        0.5,
        0.5,
        null,
        'rgba(125,140,31, 1)',
        0.5
      );
    case PhenologicalStageCode.emergence:
      return toStageStyleTemplate(
        20,
        0,
        0,
        'assets/images/stages/leaf.svg',
        0.4,
        0.5,
        null,
        'rgba(125,140,31, 1)',
        0.8
      );
    case PhenologicalStageCode.fourLeaves:
      return toStageStyleTemplate(20, -7, 0, 'assets/images/stages/leaf.svg', 0, 0.5, '4', 'rgba(125,140,31, 1)', 0.8);
    case PhenologicalStageCode.sixLeaves:
      return toStageStyleTemplate(20, -7, 0, 'assets/images/stages/leaf.svg', 0, 0.5, '6', 'rgba(125,140,31, 1)', 0.8);
    case PhenologicalStageCode.eightLeaves:
      return toStageStyleTemplate(20, -7, 0, 'assets/images/stages/leaf.svg', 0, 0.5, '8', 'rgba(125,140,31, 1)', 0.8);
    case PhenologicalStageCode.tenLeaves:
      return toStageStyleTemplate(20, -9, 0, 'assets/images/stages/leaf.svg', 0, 0.5, '10', 'rgba(125,140,31, 1)', 0.8);
    case PhenologicalStageCode.bloom:
      return toStageStyleTemplate(20, 0, 0, 'assets/images/stages/bloom.svg', 0.5, 0.5, null, 'rgba(125,140,31, 1)');
    case PhenologicalStageCode.humidity50:
      return toStageStyleTemplate(25, 0, 10, 'assets/images/stages/corn.svg', 0.5, 1, '50%', 'rgba(229,108,0, 1)');
    case PhenologicalStageCode.humidity45:
      return toStageStyleTemplate(25, 0, 10, 'assets/images/stages/corn.svg', 0.5, 1, '45%', 'rgba(229,108,0, 1)');
    case PhenologicalStageCode.humidity35:
      return toStageStyleTemplate(25, 0, 10, 'assets/images/stages/corn.svg', 0.5, 1, '35% H', 'rgba(229,108,0, 1)');
    case PhenologicalStageCode.humidity32:
      return toStageStyleTemplate(25, 0, 10, 'assets/images/stages/corn.svg', 0.5, 1, '32% H', 'rgba(229,108,0, 1)');
    case PhenologicalStageCode.dryMatter35:
      return toStageStyleTemplate(25, 0, 10, 'assets/images/stages/straw.svg', 0.5, 1, '35% MS', 'rgba(229,108,0, 1)');
    case PhenologicalStageCode.dryMatter32:
      return toStageStyleTemplate(25, 0, 10, 'assets/images/stages/straw.svg', 0.5, 1, '32% MS', 'rgba(229,108,0, 1)');
    default:
      return toStageStyleTemplate(25, -1, 1, null, 0.5, 1, 'ND', 'rgba(229,108,0, 1)');
  }
};

export const styleFunction = (_: any, resolution: number) => {
  return resolution < 20 ? defaultStyle : hiddenFeatureStyle;
};

export const styleFunctionStageHarvest = (feature: Feature, resolution: number) => {
  const showFeatureStyle = feature.get('isHarvestStage') === true ? highlightStyle : defaultStyle;
  return resolution < 20 ? showFeatureStyle : hiddenFeatureStyle;
};

export const styleFunctionHydroHarvest = (feature: Feature, resolution: number) => {
  let showFeatureStyle = defaultStyle;
  const lastDailyStatus = feature.get('lastDailyStatus');
  if (feature.get('lastDailyStatus')) {
    showFeatureStyle =
      lastDailyStatus.easilyUsableReserve < lastDailyStatus.waterDeficit
        ? highlightStressStyle
        : highlightNoStressStyle;
  }
  return resolution < 20 ? showFeatureStyle : hiddenFeatureStyle;
};

export const styleHoverStageFunction = (feature: Feature, resolution: number, isStageMode: boolean) => {
  const showFeatureStyle =
    feature.get('isHarvestStage') === true && isStageMode ? hoverHighlightStyle : hoverDefaultStyle;
  return resolution < 20 ? showFeatureStyle : hiddenFeatureStyle;
};

export const styleHoverHydroFunction = (feature: Feature, resolution: number, isHydroMode: boolean) => {
  let showFeatureStyle = hoverDefaultStyle;
  const lastDailyStatus = feature.get('lastDailyStatus');
  if (feature.get('lastDailyStatus') && isHydroMode) {
    showFeatureStyle =
      lastDailyStatus.easilyUsableReserve < lastDailyStatus.waterDeficit
        ? hoverHighlightStressStyle
        : hoverHighlightNoStressStyle;
  }
  return resolution < 20 ? showFeatureStyle : hiddenFeatureStyle;
};

export const generateCropMarkerStyle = (cropCode: string) => {
  const code = CropCode.fromCode(cropCode);
  const crop = code ? code.key.toLowerCase() : null;
  const markerStyle = new Style({
    image: new Icon({
      src: `assets/images/crop-markers/marker-${crop}.svg`,
      anchor: [0.5, 1],
      scale: 0.5,
      anchorOrigin: IconOrigin.TOP_LEFT,
    }),
  });
  return markerStyle;
};
