import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NitrogenStatus,
  Parcel,
  ParcelWithTotalDoseComputatioMethods,
} from '@app/overview/shared/farm-star/parcel.model';
import * as Highcharts from 'highcharts';
import {
  generateOptions,
  NitrogenLabels,
  parcelHasAtLeastOneNeededNitrogenData,
} from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/total-dose-details';

@Component({
  selector: 'fstar-default-fertiweb-without-cau-dialog',
  templateUrl: './default-fertiweb-without-cau-dialog.component.html',
  styleUrls: ['../total-dose-details-dialog.component.scss', './default-fertiweb-without-cau-dialog.component.scss'],
})
export class DefaultFertiwebWithoutCauDialogComponent {
  Highcharts = Highcharts;
  chartInstance: Highcharts.Chart = null;
  isRemainingMeasuredUpdated: boolean;
  nitrogenLabelsEnum = NitrogenLabels;

  globalChartColors = ['#052338', '#9EBA11', '#9013FE'];
  globalChartOptions: Highcharts.Options = null;

  detailsChartColors = ['#cfc32e', '#e56c00', '#87241a', '#6B9243', '#CCCCCC', '#76CFFF', '#035AA6'];
  detailsChartOptions: Highcharts.Options = null;

  noDecimalFormat = '1.0-0';
  oneDecimalFormat = '1.1-1';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParcelWithTotalDoseComputatioMethods,
    private dialogRef: MatDialogRef<DefaultFertiwebWithoutCauDialogComponent>
  ) {
    this.isRemainingMeasuredUpdated =
      !!data.agroData.nitrogenStatus.actualMeasuredNitrogenCredit ||
      data.agroData.nitrogenStatus.actualMeasuredNitrogenCredit === 0;

    const displayNotAvailableChart = !parcelHasAtLeastOneNeededNitrogenData(
      [
        'humusMineralizationEffect',
        'previousCropMinEffect',
        'grasslandEffect',
        'catchCropEffect',
        'irrigationEffect',
        'nitrogenCreditBalanceBeginning',
        'actualMeasuredNitrogenCredit',
        'currentYearOrganicWasteEffect',
        'historicOrganicWasteEffect',
      ],
      this.data
    );

    const globalChartData = this.generateGlobalData(data);
    const detailsChartData = this.generateDetailsData(data);

    this.globalChartOptions = generateOptions(this.globalChartColors, globalChartData, '60%', displayNotAvailableChart);
    this.detailsChartOptions = generateOptions(
      this.detailsChartColors,
      detailsChartData,
      '80%',
      displayNotAvailableChart
    );
  }

  generateGlobalData(data: Parcel): Array<Highcharts.SeriesPieDataOptions> {
    const nitrogenStatus = data.agroData.nitrogenStatus;

    const mineralizationTotal = this.sumOfAllMineralizationElements(nitrogenStatus);

    const otherSuppliesTotal = this.sumOfAllOtherSuppliesElements(nitrogenStatus);

    const total = mineralizationTotal + otherSuppliesTotal;

    return [
      {
        name: NitrogenLabels.MINERALIZATION,
        y: (100 * mineralizationTotal) / total,
      },
      {
        name: NitrogenLabels.OTHER_SUPPLIES,
        y: (100 * otherSuppliesTotal) / total,
      },
    ];
  }

  sumOfAllMineralizationElements(nitrogenStatus: NitrogenStatus) {
    return (
      Math.abs(nitrogenStatus.humusMineralizationEffect) +
      Math.abs(nitrogenStatus.previousCropMinEffect) +
      Math.abs(nitrogenStatus.grasslandEffect) +
      Math.abs(nitrogenStatus.catchCropEffect)
    );
  }

  sumOfAllOtherSuppliesElements(nitrogenStatus: NitrogenStatus) {
    const actualMeasuredNitrogenCredit = this.isRemainingMeasuredUpdated
      ? nitrogenStatus.actualMeasuredNitrogenCredit
      : nitrogenStatus.nitrogenCreditBalanceBeginning;

    const currentYearOrganicWasteEffect = nitrogenStatus.currentYearOrganicWasteEffect;

    return (
      Math.abs(nitrogenStatus.irrigationEffect) +
      Math.abs(actualMeasuredNitrogenCredit) +
      Math.abs(currentYearOrganicWasteEffect)
    );
  }

  generateDetailsData(data: Parcel): Array<Highcharts.SeriesPieDataOptions> {
    const nitrogenStatus = data.agroData.nitrogenStatus;

    const humus = Math.abs(nitrogenStatus.humusMineralizationEffect);
    const previousCrop = Math.abs(nitrogenStatus.previousCropMinEffect);
    const grasslandEffect = Math.abs(nitrogenStatus.grasslandEffect);
    const catchCropEffect = Math.abs(nitrogenStatus.catchCropEffect);

    const irrigationEffect = Math.abs(nitrogenStatus.irrigationEffect);

    const actualMeasuredNitrogenCredit = this.isRemainingMeasuredUpdated
      ? Math.abs(nitrogenStatus.actualMeasuredNitrogenCredit)
      : Math.abs(nitrogenStatus.nitrogenCreditBalanceBeginning);

    const currentYearOrganicWasteEffect = Math.abs(nitrogenStatus.currentYearOrganicWasteEffect);

    const total =
      humus +
      previousCrop +
      grasslandEffect +
      catchCropEffect +
      irrigationEffect +
      actualMeasuredNitrogenCredit +
      currentYearOrganicWasteEffect;

    return [
      {
        name: NitrogenLabels.HUMUS_MINERALIZATION_EFFECT,
        y: (100 * humus) / total,
      },
      {
        name: NitrogenLabels.PREVIOUS_CROP_MIN_EFFECT,
        y: (100 * previousCrop) / total,
      },
      {
        name: NitrogenLabels.GRASSLAND_EFFECT,
        y: (100 * grasslandEffect) / total,
      },
      {
        name: NitrogenLabels.CATCH_CROP_EFFECT,
        y: (100 * catchCropEffect) / total,
      },
      {
        name: NitrogenLabels.IRRIGATION_EFFECT,
        y: (100 * irrigationEffect) / total,
      },
      {
        name: NitrogenLabels.N_CREDIT_BALANCE_BEGINNING,
        y: (100 * actualMeasuredNitrogenCredit) / total,
      },
      {
        name: NitrogenLabels.CURRENT_YEAR_ORGANIC_WASTE_EFFECT,
        y: (100 * currentYearOrganicWasteEffect) / total,
      },
    ];
  }

  onChartInit(chartInstance: Highcharts.Chart) {
    this.chartInstance = chartInstance;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.chartInstance.reflow();
  }

  onClose() {
    this.dialogRef.close();
  }
}
