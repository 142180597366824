import {
  MaxDailyStatus,
  PhenologicalStageByEstimateDate,
  TimelineRainAndIrrigation,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { DailyStatus } from '@app/overview/shared/farm-star/parcel.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import { DatePipe } from '@angular/common';
import { PlotArearangeZonesOptions, SeriesLineDataOptions } from 'highcharts';

export function transformToMaxDailyStatus(dailyStatus: DailyStatus[]): MaxDailyStatus {
  return dailyStatus.reduce(
    (previousValue: MaxDailyStatus, currentValue: DailyStatus) => {
      return {
        easilyUsableReserve:
          currentValue.easilyUsableReserve > previousValue.easilyUsableReserve
            ? currentValue.easilyUsableReserve
            : previousValue.easilyUsableReserve,
        usableReserve:
          currentValue.usableReserve > previousValue.usableReserve
            ? currentValue.usableReserve
            : previousValue.usableReserve,
      };
    },
    { easilyUsableReserve: 0, usableReserve: 0 }
  );
}

export function transformToTimelineRainAndIrrigation(dailyStatus: DailyStatus[]): TimelineRainAndIrrigation[] {
  return dailyStatus.reduce((previousValue: TimelineRainAndIrrigation[], currentValue: DailyStatus) => {
    const currentDate = new Date(currentValue.date);
    const getDateForTimeline = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toDateString();
    const index = previousValue.findIndex(i => i.date === getDateForTimeline);
    if (index < 0) {
      previousValue = [
        ...previousValue,
        {
          date: getDateForTimeline,
          rain: currentValue.rain,
          irrigation: currentValue.irrigation,
        },
      ];
    } else {
      previousValue[index] = {
        date: getDateForTimeline,
        rain: previousValue[index].rain + currentValue.rain,
        irrigation: previousValue[index].irrigation + currentValue.irrigation,
      };
    }
    return previousValue;
  }, []);
}

export function buildWaterDeficitAreaLineDataOptions(
  rfu: Array<SeriesLineDataOptions>,
  waterDeficit: Array<SeriesLineDataOptions>,
  currentDate: number
): { areaLineDataOptions: Array<SeriesLineDataOptions>; zones: Array<PlotArearangeZonesOptions> } {
  let isStressZones = true;
  if (!waterDeficit || waterDeficit.length < 2) {
    return {
      areaLineDataOptions: [],
      zones: [],
    };
  }

  return waterDeficit.reduce(
    (
      series: { areaLineDataOptions: Array<SeriesLineDataOptions>; zones: Array<PlotArearangeZonesOptions> },
      w: SeriesLineDataOptions,
      i: number
    ) => {
      series.areaLineDataOptions.push([w.x, w.y, rfu[i].y]);
      if (w.x >= currentDate) {
        series.zones = displayNewZones(currentDate, series.zones, w.y >= rfu[i].y);
      }

      if (
        waterDeficit[i + 1] &&
        isCurrentDateAndNextDateBetweenTwoZones(currentDate, w, waterDeficit[i + 1], rfu[i].y, rfu[i + 1].y)
      ) {
        series.zones = displayNewZones(currentDate, series.zones, waterDeficit[i + 1].y >= rfu[i + 1].y);
      }

      if (i > 0) {
        const intersectZone = intersect(w.x, waterDeficit[i - 1].x, w.y, waterDeficit[i - 1].y, rfu[i].y, rfu[i - 1].y);
        if (w.y < rfu[i].y && isStressZones) {
          series.zones = displayNewZones(intersectZone, series.zones, false);
          isStressZones = false;
        } else if (w.y >= rfu[i].y && !isStressZones) {
          series.zones = displayNewZones(intersectZone, series.zones, true);
          isStressZones = true;
        }
      }
      return series;
    },
    { areaLineDataOptions: [], zones: [{ value: waterDeficit[0].x, fillColor: 'rgba(255,255,255,0)' }] }
  );
}

function isCurrentDateAndNextDateBetweenTwoZones(
  currentDate: number,
  waterDeficit: SeriesLineDataOptions,
  nextWaterDeficit: SeriesLineDataOptions,
  rfuValue: number,
  nextRfuValue: number
): boolean {
  return (
    waterDeficit.x >= currentDate &&
    nextWaterDeficit.x < currentDate &&
    intersect(waterDeficit.x, nextWaterDeficit.x, waterDeficit.y, nextWaterDeficit.y, rfuValue, nextRfuValue) >=
      currentDate
  );
}

function displayNewZones(
  intersectZone: number,
  zones: Array<PlotArearangeZonesOptions>,
  isStressZone: boolean
): Array<PlotArearangeZonesOptions> {
  return [
    {
      value: intersectZone,
      fillColor: isStressZone ? 'rgba(208, 2, 27, 0.4)' : 'rgba(255,255,255,0)',
    },
    ...zones,
  ];
}

export function createPlotLines(
  phenologicalStageByEstimateDate: PhenologicalStageByEstimateDate[],
  beginDate: number,
  endDate: number
): Highcharts.XAxisPlotLinesOptions[] {
  if (phenologicalStageByEstimateDate) {
    const today = new Date();
    const shouldHideHarvestStage = () => {
      const bloomStage = phenologicalStageByEstimateDate.find(stage => stage.codePs === PhenologicalStageCode.bloom);
      return bloomStage && today.getTime() < new Date(bloomStage.date).getTime();
    };
    const filteredStageByDate: PhenologicalStageByEstimateDate[] = phenologicalStageByEstimateDate.filter(
      stage =>
        (new Date(stage.date).getTime() >= beginDate &&
          new Date(stage.date).getTime() <= endDate &&
          stage.codePs !== PhenologicalStageCode.humidity32 &&
          stage.codePs !== PhenologicalStageCode.humidity35 &&
          stage.codePs !== PhenologicalStageCode.humidity45 &&
          stage.codePs !== PhenologicalStageCode.humidity50) ||
        !shouldHideHarvestStage()
    );
    return filteredStageByDate.map(stage =>
      generatePlotLine(stage.codePs, stage.date)
    ) as Highcharts.XAxisPlotLinesOptions[];
  } else {
    return [];
  }
}

export const disableAllOthersPlotlines = (plotLinesAndBands: any, plotline: any) => {
  for (const otherPlotline of plotLinesAndBands.filter(
    (item: { id: string; label: string }) => item.id !== plotline.id && item.label
  )) {
    otherPlotline.label.element.classList.add('disable');
    otherPlotline.svgElem.element.classList.add('disable');
  }
};

export const removeAllOthersPlotlines = (plotLinesAndBands: any, plotline: any) => {
  for (const otherPlotline of plotLinesAndBands.filter(
    (item: { id: string; label: string }) => item.id !== plotline.id && item.label
  )) {
    otherPlotline.label.element.classList.remove('disable');
    otherPlotline.svgElem.element.classList.remove('disable');
  }
};

export function resetMouseEventWhenChartRendered(
  chartInstance: Highcharts.Chart,
  phenologicalStageByEstimateDate: PhenologicalStageByEstimateDate[]
) {
  if (chartInstance && phenologicalStageByEstimateDate && phenologicalStageByEstimateDate.length > 0) {
    const plotLinesAndBands = (chartInstance.xAxis[0] as any).plotLinesAndBands;
    for (const plotline of plotLinesAndBands) {
      if (plotline.label) {
        plotline.label.element.addEventListener('mouseenter', () =>
          disableAllOthersPlotlines(plotLinesAndBands, plotline)
        );
        plotline.label.element.addEventListener('mouseleave', () =>
          removeAllOthersPlotlines(plotLinesAndBands, plotline)
        );
      }
    }
  }
}

function toStageTemplate(
  x: number,
  y: number,
  type: PhenologicalStageCode,
  width: number,
  height: number,
  sprite: string,
  label: string,
  estimateDate: string,
  isLastStage: boolean,
  value?: string,
  color?: any,
  hasFilterClass?: boolean
) {
  const template = {
    rotation: 0,
    useHTML: true,
    x,
    y,
    text: `<div id='${type}' class='plotline-stage'>
              <div class='stage-value${isLastStage ? ' last-value' : ''}'>
              ${value ? '<p>' + value + '</p>' : ''}
                <svg ${hasFilterClass ? 'class="filter"' : ''} width="${width}" height="${height}" aria-hidden="true">
                  <use href="assets/images/sprites-stages.svg#${sprite}"></use>
                </svg>
              </div>
              <div class="stage-label">
                <div class="stage-label-state">Stade</div>
                <div class="stage-label-status">${label}</div>
                <div class="stage-label-date">${estimateDate}</div>
                </div>
            </div>`,
  };
  return color ? { ...template, style: { fill: color } } : template;
}

function getStageTemplate(type: PhenologicalStageCode, estimateDate: string) {
  switch (type) {
    case PhenologicalStageCode.seedling:
      return toStageTemplate(-18, -20, type, 10, 10, 'seedling', 'Semis', estimateDate, false);
    case PhenologicalStageCode.emergence:
      return toStageTemplate(-19, -22, type, 20, 20, 'leaf', 'Levée', estimateDate, false, null, '#9EBA11');
    case PhenologicalStageCode.fourLeaves:
      return toStageTemplate(-19, -22, type, 20, 20, 'leaf', '4 feuilles', estimateDate, false, '4', '#9EBA11');
    case PhenologicalStageCode.sixLeaves:
      return toStageTemplate(-19, -22, type, 20, 20, 'leaf', '6 feuilles', estimateDate, false, '6', '#9EBA11');
    case PhenologicalStageCode.eightLeaves:
      return toStageTemplate(-19, -22, type, 20, 20, 'leaf', '8 feuilles', estimateDate, false, '8', '#9EBA11');
    case PhenologicalStageCode.tenLeaves:
      return toStageTemplate(-20, -22, type, 20, 20, 'leaf', '10 feuilles', estimateDate, true, '10', '#9EBA11');
    case PhenologicalStageCode.bloom:
      return toStageTemplate(-20, -22, type, 20, 20, 'bloom', 'Floraison', estimateDate, false, null, '#9EBA11');
    case PhenologicalStageCode.humidity50:
      return toStageTemplate(-20, -22, type, 10, 20, 'corn', '50 % H', estimateDate, false, '50', null, true);
    case PhenologicalStageCode.humidity45:
      return toStageTemplate(-20, -22, type, 10, 20, 'corn', '45 % H', estimateDate, false, '45', null, true);
    case PhenologicalStageCode.humidity35:
      return toStageTemplate(-20, -22, type, 10, 20, 'corn', '35 % H', estimateDate, false, '35', null, true);
    case PhenologicalStageCode.humidity32:
      return toStageTemplate(-20, -22, type, 10, 20, 'corn', '32 % H', estimateDate, false, '32', null, true);
    case PhenologicalStageCode.dryMatter32:
      return toStageTemplate(-20, -22, type, 10, 20, 'straw', '32 % MS', estimateDate, false, '32', null, true);
    case PhenologicalStageCode.dryMatter35:
      return toStageTemplate(-20, -22, type, 10, 20, 'straw', '35 % MS', estimateDate, false, '35', null, true);
  }
}

function generatePlotLine(type: PhenologicalStageCode, estimateDate: string): Highcharts.XAxisPlotLinesOptions {
  const date = new Date(estimateDate);
  const estimateDateformated = formatDateForChart(estimateDate);
  const color = type === PhenologicalStageCode.seedling ? '#E56C00' : '#ccd6eb';
  return {
    id: type,
    color,
    dashStyle: 'Dash',
    value: date.getTime(),
    width: 1,
    label: getStageTemplate(type, estimateDateformated),
  };
}

export function formatDateForChart(date: string | number) {
  const datePipe = new DatePipe('fr-FR');
  return datePipe.transform(new Date(date), 'dd/MM/yyyy');
}

function intersect(x1: number, x2: number, y1: number, y2: number, y3: number, y4: number): number {
  return (x2 * y1 - x1 * y2 - (x2 * y3 - x1 * y4)) / (y4 - y3 - (y2 - y1));
}
