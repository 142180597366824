import { ParcelWithAdvices } from '@app/overview/shared/farm-star/parcel.model';
import { polygonToFeature, transformToMapExtent } from '@app/shared/utils/ol-utils';
import { ParcelApiModel } from './parcel-api.model';

export function parcelDtoToParcel(dto: ParcelApiModel.ClientPortalParcelDto): ParcelWithAdvices {
  const currentStageEstimate = dto.stageEstimates.currentStageRefId
    ? {
        phenologicalStageRefId: dto.stageEstimates.currentStageRefId,
        isCurrentStage: true,
        isNextStage: false,
      }
    : null;
  const nextStageEstimate = dto.stageEstimates.nextStageDate
    ? {
        stageDate: dto.stageEstimates.nextStageDate,
        isCurrentStage: false,
        isNextStage: true,
      }
    : null;
  return {
    active: true,
    id: dto.id,
    code: dto.code,
    name: dto.name,
    pacBlockNumber: dto.pacBlockNumber,
    measuredSurface: dto.measuredSurface,
    campaignId: dto.campaignId,
    aoi: {
      originCrsName: dto.aoi.originCrsName,
      centroid: dto.aoi.mongoCentroid,
      polygon: polygonToFeature(dto.aoi.polygon),
    },
    agroData: {
      crop: {
        refData: { crop: { code: dto.crop.cropData.code } },
        linkedVarietyUsed: dto.crop.linkedVarietyUsed,
        linkedVarietyName: dto.crop.linkedVarietyName,
        varietyRefId: dto.crop.varietyRefId,
        kindRefId: dto.crop.kindRefId,
        cropRefId: dto.crop.cropRefId,
      },
      nitrogenStatus: { projectedDose: dto.nitrogenStatus.projectedDose },
      activities: {
        seedingActivity: {
          seedingDate: dto.seedingDate,
          actualSeedingDate: dto.actualSeedingDate,
          density: dto.seedingDensity,
        },
        previous: { ...dto.previousCropActivity },
        irrigation: { irrigated: dto.irrigation.irrigated },
      },
      ndoseComputationMethodRefId: dto.ndoseComputationMethodRefId,
      soilRefId: dto.soilRefId,
      medianYieldTarget: dto.medianYieldTarget,
    },
    lastDailyStatus: {
      waterDeficit: dto.irrigation.waterDeficit,
      easilyUsableReserve: dto.irrigation.easilyUsableReserve,
    },
    currentStageEstimate,
    nextStageEstimate,
    medianBiophy: dto.lai.median,
    biophyImageExtent: transformToMapExtent(dto.lai.imageExtent),
    biophyImageLink: dto.lai.imageLink,
    isTotalNDelivered: dto.nitrogenStatus.deliveryStatus === 'NOMINAL',
    totalNRecommendationId: dto.nitrogenStatus.parcelRecommendationId,
  };
}
