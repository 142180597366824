import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { UserRole } from '@app/overview/overview.models';

export const USER_INFO_DIALOG_WIDTH = '650px';

@Component({
  selector: 'fstar-user-information',
  templateUrl: './user-information.dialog.component.html',
  styleUrls: ['./user-information.dialog.component.scss'],
})
export class UserInformationDialogComponent {
  cguLink = environment.staticAssets.cgu;
  rgpdLink = environment.staticAssets.rgpd;

  userRole = UserRole;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: farmStarApiModel.UserDataAndUserRole,
    private dialogRef: MatDialogRef<UserInformationDialogComponent>
  ) {}

  onClose() {
    this.dialogRef.close();
  }

  isFarmer(data: farmStarApiModel.UserDataAndUserRole): data is farmStarApiModel.FarmerDataAndUserRole {
    return data.userRole === UserRole.farmer;
  }
}
