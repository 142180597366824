import { Parcel } from './parcel.model';

export interface Crop {
  id?: string;
  label?: string;
  code?: string;
}

export class CropCode {
  static readonly BARLEY = new CropCode('BARLEY', 'ORGE', 'Orge');
  static readonly CORN = new CropCode('CORN', 'MAIS', 'Maïs');
  static readonly RAPESEED = new CropCode('RAPESEED', 'COLZA', 'Colza');
  static readonly TRITICALE = new CropCode('TRITICALE', 'TRITICALE', 'Triticale');
  static readonly WHEAT = new CropCode('WHEAT', 'BLE', 'Blé');

  private constructor(readonly key: string, readonly code: string, readonly label: string) {}

  static all(): CropCode[] {
    return [CropCode.BARLEY, CropCode.CORN, CropCode.RAPESEED, CropCode.TRITICALE, CropCode.WHEAT];
  }

  static fromCode(code: string): CropCode {
    return CropCode.all().find(cropCode => cropCode.code === code);
  }

  static fromParcel(parcel: Parcel): CropCode {
    return CropCode.fromCode(parcel.agroData.crop.refData.crop.code);
  }

  static isCrop(parcel: Parcel, cropCode: CropCode): boolean {
    return parcel.agroData.crop.refData.crop.code === cropCode.code;
  }

  static toCrop(cropCode: CropCode): Crop {
    return { label: cropCode.label, code: cropCode.code };
  }

  static getCropFromCode(code: string): Crop {
    return CropCode.toCrop(CropCode.fromCode(code));
  }
}
