import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RainIrrigationEditionComponent } from './rain-irrigation-edition.component';
import { MatButtonModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RainIrrigationEditionComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [RainIrrigationEditionComponent],
})
export class RainIrrigationEditionModule {}
