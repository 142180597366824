import {
  DrawMode,
  HydroDataInformation,
  PhenologicalStageByEstimateDate,
  PointWithBiophyValue,
  TechnicianDataInformation,
} from './parcel-details.models';
import { Action, ActionReducerMap, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromFeaturesEntity from '@app/overview/parcels-viewer/core/parcel-details/entities/features-entity.reducers';
import * as fromStagesEstimateEntity from '@app/overview/parcels-viewer/core/parcel-details/entities/stages-estimate-entity.reducers';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { Dictionary } from '@ngrx/entity';
import * as fromOverview from '@app/overview/overview.reducer';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import * as fromParcelsEntity from '@app/overview/shared/parcels/parcels-entity.reducer';
import * as fromBiophyEntity from '@app/overview/parcels-viewer/core/parcel-details/entities/biophy-entity.reducers';
import {
  aprilIndex,
  firstDayOfMonthBeginDate,
  lastDayOfMonthEndDate,
  novemberIndex,
} from '@app/shared/utils/date-utils';
import * as fromFeaturesBiophyEntity from '@app/overview/parcels-viewer/core/parcel-details/entities/features-biophy-entity.reducers';
import PhenologicalStageAction = farmStarApiModel.PhenologicalStageAction;
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import selectIsLoadingParcels = fromParcelsViewer.selectIsLoadingParcels;
import selectIsLoadingFarms = fromOverview.selectIsLoadingFarms;
import { DailyStatus } from '@app/overview/shared/farm-star/parcel.model';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { SeriesLineDataOptions } from 'highcharts';
import * as fromStageNotificationsEntity from '@app/overview/parcels-viewer/core/parcel-details/entities/stage-notifications-entity.reducers';
import { selectIsAFarmer } from '@app/overview/overview.reducer';
import { selectUserData } from '@app/overview/overview.reducer';

export interface ParcelDetailsGlobalState {
  features: fromFeaturesEntity.State;
  featuresBiophy: fromFeaturesBiophyEntity.State;
  stageEstimates: fromStagesEstimateEntity.State;
  biophy: fromBiophyEntity.State;
  stageNotifications: fromStageNotificationsEntity.State;
  parcelDetails: State;
}

export interface State {
  technicianDataInformation: TechnicianDataInformation;
  hydroDataInformation: HydroDataInformation;
  updateRequestInProgress: boolean;
  drawMode: DrawMode;
  isDrawModeActivated: boolean;
  selectedFeatureId: string;
  year: number;
  beginMonthIndex: number;
  endMonthIndex: number;
  pointWithBiophyValue: PointWithBiophyValue;
  isPanelHydroOpened: boolean;
  isPanelDoseOpened: boolean;
  dailyStatus: DailyStatus[];
  recommendation: farmStarApiModel.RecommendationBackend;
}

const initialState: State = {
  technicianDataInformation: { isLoading: false, technicianData: null },
  hydroDataInformation: {
    isLoading: false,
    irrigationStatus: null,
    maxDailyStatus: null,
    timelineRainAndIrrigation: null,
  },
  updateRequestInProgress: false,
  drawMode: DrawMode.none,
  isDrawModeActivated: false,
  selectedFeatureId: null,
  year: new Date().getFullYear(),
  beginMonthIndex: aprilIndex,
  endMonthIndex: novemberIndex,
  pointWithBiophyValue: null,
  isPanelHydroOpened: false,
  isPanelDoseOpened: false,
  dailyStatus: [],
  recommendation: null,
};

const reducer = createReducer(
  initialState,
  on(parcelDetailsActions.ResetParcelData, state => ({
    ...initialState,
    recommendation: state.recommendation,
  })),
  on(parcelDetailsActions.ResetBiophyOfPoint, state => ({
    ...state,
    pointWithBiophyValue: null,
  })),
  on(parcelDetailsActions.FindObservationOfPointSuccess, (state, { medianBiophy, coordinates }) => ({
    ...state,
    pointWithBiophyValue: {
      coordinates,
      medianBiophy,
    },
  })),
  on(parcelDetailsActions.SaveFilterDate, (state, { year, beginMonthIndex, endMonthIndex }) => ({
    ...state,
    year,
    beginMonthIndex,
    endMonthIndex,
  })),
  on(parcelDetailsActions.UpdateParcelSeedlingDate, parcelDetailsActions.UpdateParcelTotalDose, state => ({
    ...state,
    updateRequestInProgress: true,
  })),
  on(
    parcelDetailsActions.UpdateParcelTotalDoseError,
    parcelDetailsActions.UpdateParcelSeedlingDateError,
    parcelDetailsActions.UpdateParcelTotalDoseSuccess,
    parcelDetailsActions.UpdateParcelSeedlingDateSuccess,
    state => ({
      ...state,
      updateRequestInProgress: false,
    })
  ),
  on(parcelDetailsActions.FindTechnicianData, state => ({
    ...state,
    technicianDataInformation: {
      ...state.technicianDataInformation,
      isLoading: true,
    },
  })),
  on(parcelDetailsActions.FindTechnicianDataSuccess, (state, { technicianData }) => ({
    ...state,
    technicianDataInformation: {
      isLoading: false,
      technicianData,
    },
  })),
  on(parcelDetailsActions.FindTechnicianDataError, state => ({
    ...state,
    technicianDataInformation: {
      isLoading: false,
      technicianData: null,
    },
  })),
  on(parcelDetailsActions.FindHydro, state => ({
    ...state,
    dailyStatus: state.dailyStatus,
    hydroDataInformation: {
      ...state.hydroDataInformation,
      isLoading: true,
    },
  })),
  on(parcelDetailsActions.FindHydroSuccess, (state, { dailyStatus, hydroDataInformation }) => ({
    ...state,
    dailyStatus,
    hydroDataInformation,
  })),
  on(parcelDetailsActions.FindHydroError, state => ({
    ...state,
    dailyStatus: [],
    hydroDataInformation: {
      isLoading: false,
      irrigationStatus: null,
      maxDailyStatus: null,
      timelineRainAndIrrigation: null,
    },
  })),
  on(parcelDetailsActions.ActivateDrawMode, (state, { drawMode }) => ({
    ...state,
    isDrawModeActivated: true,
    drawMode,
  })),
  on(parcelDetailsActions.EndDrawMode, state => ({
    ...state,
    isDrawModeActivated: false,
    drawMode: DrawMode.none,
  })),
  on(parcelDetailsActions.DeleteFeature, state => ({
    ...state,
    selectedFeatureId: null,
  })),
  on(parcelDetailsActions.SelectFeature, (state, { featureId }) => ({
    ...state,
    selectedFeatureId: featureId,
  })),
  on(parcelDetailsActions.IsPanelHydroOpened, (state, { isPanelHydroOpened }) => ({
    ...state,
    isPanelHydroOpened,
  })),
  on(parcelDetailsActions.IsPanelDoseOpened, (state, { isOpen }) => ({
    ...state,
    isPanelDoseOpened: isOpen,
  })),
  on(parcelDetailsActions.FindRecommendationSuccess, (state, { recommendation }) => ({
    ...state,
    recommendation,
  })),
  on(parcelDetailsActions.FindRecommendationError, state => ({
    ...state,
    recommendation: null,
  }))
);

export function parcelDetailsReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const reducers: ActionReducerMap<ParcelDetailsGlobalState> = {
  features: fromFeaturesEntity.featuresEntityReducer,
  featuresBiophy: fromFeaturesBiophyEntity.featuresBiophyEntityReducer,
  stageEstimates: fromStagesEstimateEntity.stagesEstimateEntityReducer,
  biophy: fromBiophyEntity.biophyEntityReducer,
  stageNotifications: fromStageNotificationsEntity.stagesNotificationsEntityReducer,
  parcelDetails: parcelDetailsReducer,
};

const getParcelDetailsGlobalState = createFeatureSelector<ParcelDetailsGlobalState>('parcel-details');

const getFeaturesBiophyEntitiesState = createSelector(getParcelDetailsGlobalState, state => state.featuresBiophy);

const { selectEntities: selectAllFeaturesBiophyEntities } = fromFeaturesBiophyEntity.adapter.getSelectors(
  getFeaturesBiophyEntitiesState
);

const getFeaturesEntitiesState = createSelector(getParcelDetailsGlobalState, state => state.features);

export const {
  selectAll: selectAllFeatures,
  selectEntities: selectAllFeaturesEntities,
} = fromFeaturesEntity.adapter.getSelectors(getFeaturesEntitiesState);

const getStagesEstimateEntitiesState = createSelector(getParcelDetailsGlobalState, state => state.stageEstimates);

export const {
  selectAll: selectAllStagesEstimate,
  selectEntities: selectAllStagesEstimateEntities,
} = fromStagesEstimateEntity.adapter.getSelectors(getStagesEstimateEntitiesState);

const getBiophyEntitiesState = createSelector(getParcelDetailsGlobalState, state => state.biophy);

export const {
  selectAll: selectAllBiophy,
  selectEntities: selectAllObservationEntities,
} = fromBiophyEntity.adapter.getSelectors(getBiophyEntitiesState);

const getStageNotificationsEntitiesState = createSelector(
  getParcelDetailsGlobalState,
  state => state.stageNotifications
);

export const {
  selectEntities: selectStageNotificationsEntities,
  selectTotal: selectTotalStageNotificationsEntities,
} = fromStageNotificationsEntity.adapter.getSelectors(getStageNotificationsEntitiesState);

export const selectSelectedObservationId = createSelector(getBiophyEntitiesState, state => state.selectedBiophyId);

export const selectAllBiophySeriesLineDataOptions = createSelector(
  selectAllBiophy,
  selectSelectedObservationId,
  (allBiophy: farmStarApiModel.ObservationBackend[], selectedBiophyId: string) => {
    return allBiophy
      .map(observation => {
        let median = 0;
        const laiLayer = observation.layers.find(l => l.productDefinitionCode === 'LAI');
        if (laiLayer) {
          median = laiLayer.metadata.stats.median;
          return {
            id: observation.id,
            x: new Date(observation.acquisition.sensingDateTime).getTime(),
            y: median,
            selected: observation.id === selectedBiophyId,
          };
        } else {
          return undefined;
        }
      })
      .filter(value => value);
  }
);

export const selectSelectedObservation = createSelector(
  selectAllObservationEntities,
  selectSelectedObservationId,
  (allBiophyEntities: Dictionary<farmStarApiModel.ObservationBackend>, selectedBiophyId: string) =>
    allBiophyEntities[selectedBiophyId]
);

const getParcelDetailsState = createSelector(getParcelDetailsGlobalState, state => state.parcelDetails);

export const selectPointWithBiophyValue = createSelector(getParcelDetailsState, state => state.pointWithBiophyValue);

const selectYear = createSelector(getParcelDetailsState, state => state.year);

const selectBeginMonthIndex = createSelector(getParcelDetailsState, state => state.beginMonthIndex);

const selectEndMonthIndex = createSelector(getParcelDetailsState, state => state.endMonthIndex);

export const selectDayOfMonthBeforeBeginDate = createSelector(
  selectYear,
  selectBeginMonthIndex,
  (year: number, beginMonthIndex: number) => firstDayOfMonthBeginDate(year, beginMonthIndex)
);

export const selectLastDayOfMonthEndDate = createSelector(
  selectYear,
  selectEndMonthIndex,
  (year: number, endMonthIndex: number) => lastDayOfMonthEndDate(year, endMonthIndex)
);

export const selectIsDrawModeActivated = createSelector(getParcelDetailsState, state => state.isDrawModeActivated);
const selectTechnicianDataInformation = createSelector(getParcelDetailsState, state => state.technicianDataInformation);
export const selectHydroDataInformation = createSelector(getParcelDetailsState, state => state.hydroDataInformation);

export const selectTimelineRainAndIrrigation = createSelector(
  selectHydroDataInformation,
  hydroDataInformation => hydroDataInformation && hydroDataInformation.timelineRainAndIrrigation
);

export const selectBeginAndEndDatesOfTimelineRainAndIrrigation = createSelector(
  selectTimelineRainAndIrrigation,
  timelineRainAndIrrigation =>
    timelineRainAndIrrigation
      ? {
          beginDate: timelineRainAndIrrigation[timelineRainAndIrrigation.length - 1].date,
          endDate: timelineRainAndIrrigation[0].date,
        }
      : { beginDate: null, endDate: null }
);

const selectUpdateRequestInProgress = createSelector(getParcelDetailsState, state => state.updateRequestInProgress);
export const selectTechnicianData = createSelector(
  selectTechnicianDataInformation,
  technicianDataInformation => technicianDataInformation && technicianDataInformation.technicianData
);

export const selectDrawMode = createSelector(getParcelDetailsState, state => state.drawMode);
export const selectSelectedFeatureId = createSelector(getParcelDetailsState, state => state.selectedFeatureId);
export const selectSelectedFeature = createSelector(
  selectAllFeaturesEntities,
  selectSelectedFeatureId,
  (features, id) => (id ? features[id] : null)
);
export const selectSelectedFeatureBiophy = createSelector(
  selectAllFeaturesBiophyEntities,
  selectSelectedFeatureId,
  (features, id) => features[id]
);

const selectIsLoadingStageEstimate = createSelector(
  getStagesEstimateEntitiesState,
  state => state.isLoadingStageEstimate
);

const selectIsLoadingStageNotifications = createSelector(
  getStageNotificationsEntitiesState,
  state => state.isLoadingStageNotifications
);

const selectIsLoadingBiophy = createSelector(getBiophyEntitiesState, state => state.isLoadingBiophy);

const selectIsLoadingForStageTab = createSelector(
  selectIsLoadingStageEstimate,
  selectIsLoadingStageNotifications,
  (isLoadingStageEstimate, isLoadingStageNotifications) => isLoadingStageEstimate || isLoadingStageNotifications
);

export const selectAllParcelInfoLoading = createSelector(
  selectIsLoadingFarms,
  selectTechnicianDataInformation,
  selectUpdateRequestInProgress,
  selectIsLoadingForStageTab,
  selectIsLoadingBiophy,
  selectHydroDataInformation,
  selectIsLoadingParcels,
  fromParcelsEntity.selectIsSelectedParcelDetailDataLoading,
  (
    isLoadingFarms,
    technicianDataInformation,
    updateRequestInProgress,
    isLoadingForStageTab,
    isLoadingBiophy,
    hydroDataInformation,
    isParcelLoading,
    isParcelDetailDataLoading
  ) =>
    isLoadingFarms ||
    (technicianDataInformation && technicianDataInformation.isLoading) ||
    updateRequestInProgress ||
    isLoadingForStageTab ||
    isLoadingBiophy ||
    (hydroDataInformation && hydroDataInformation.isLoading) ||
    isParcelLoading ||
    isParcelDetailDataLoading
);

export const selectHarvestSectionIsDisabled = createSelector(
  selectAllStagesEstimate,
  fromOverview.selectAllPhenologicalStagesEntities,
  (
    stagesEstimates: farmStarApiModel.StageEstimate[],
    phenologicalStagesEntities: Dictionary<farmStarApiModel.PhenologicalStageBackend>
  ) => {
    return (
      !stagesEstimates.some(s => {
        const phenologicalStage = phenologicalStagesEntities[s.phenologicalStageRefId];
        return phenologicalStage && phenologicalStage.action === PhenologicalStageAction.harvest;
      }) ||
      !stagesEstimates.some(s => {
        const phenologicalStage = phenologicalStagesEntities[s.phenologicalStageRefId];
        return (
          s.isCurrentStage &&
          (phenologicalStage.code === PhenologicalStageCode.humidity50 ||
            phenologicalStage.code === PhenologicalStageCode.humidity45 ||
            phenologicalStage.code === PhenologicalStageCode.humidity35 ||
            phenologicalStage.code === PhenologicalStageCode.humidity32 ||
            phenologicalStage.code === PhenologicalStageCode.dryMatter32 ||
            phenologicalStage.code === PhenologicalStageCode.dryMatter35 ||
            phenologicalStage.code === PhenologicalStageCode.bloom)
        );
      })
    );
  }
);

export const selectStageEstimateByPhenologicalCode = createSelector(
  selectAllStagesEstimateEntities,
  fromOverview.selectAllPhenologicalStages,
  (
    stagesEstimateEntities: Dictionary<farmStarApiModel.StageEstimate>,
    phenologicalStages: farmStarApiModel.PhenologicalStageBackend[]
  ) => (code: farmStarApiModel.PhenologicalStageCode) => {
    const stage = phenologicalStages.find(p => p.code === code);
    return stage ? stagesEstimateEntities[stage.id] : null;
  }
);

export const selectStageEstimateDateBoundWithPhenologicalCode = createSelector(
  selectAllStagesEstimate,
  fromOverview.selectAllPhenologicalStages,
  (
    stagesEstimate: farmStarApiModel.StageEstimate[],
    phenologicalStages: farmStarApiModel.PhenologicalStageBackend[]
  ): PhenologicalStageByEstimateDate[] => {
    if (phenologicalStages.length === 0) {
      return [];
    }
    return stagesEstimate
      .map(s => {
        const stageFound = phenologicalStages.find(code => code.id === s.phenologicalStageRefId);
        return { date: s.stageDate, codePs: stageFound.code };
      })
      .filter(s => s !== null);
  }
);

export const selectIsPanelHydroOpened = createSelector(getParcelDetailsState, state => state.isPanelHydroOpened);

export const selectIsPanelDoseOpened = createSelector(getParcelDetailsState, state => state.isPanelDoseOpened);

const selectDailyStatus = createSelector(getParcelDetailsState, state => state.dailyStatus);

export const selectIsDisplayMessageForSelectedNotificationsPreferences$ = createSelector(
  selectIsAFarmer,
  selectUserData,
  selectTotalStageNotificationsEntities,
  (isAFarmer, userData, totalStageNotifications) => {
    if (!isAFarmer) {
      return false;
    }
    return userData.emailNotification || userData.smsNotification ? false : totalStageNotifications > 0;
  }
);

export const selectAllMultiSeriesLineDataOptions = createSelector(selectDailyStatus, (dailyStatus: DailyStatus[]) => {
  return dailyStatus.length > 0
    ? dailyStatus.reduce(
        (previousValue: Array<SeriesLineDataOptions>[], currentValue: DailyStatus) => {
          const id = currentValue.id;
          const x = new Date(`${currentValue.date}+0000`).getTime();
          previousValue[0] = [...previousValue[0], { id, x, y: currentValue.usableReserve }];
          previousValue[1] = [...previousValue[1], { id, x, y: currentValue.easilyUsableReserve }];
          previousValue[2] = [...previousValue[2], { id, x, y: currentValue.waterDeficit }];
          previousValue[3] = [...previousValue[3], { id, x, y: currentValue.rain + currentValue.irrigation }];
          previousValue[4] = [...previousValue[4], { id, x, y: currentValue.rootDrainage * -1 }];
          return previousValue;
        },
        [[], [], [], [], []]
      )
    : [];
});

export const selectRecommendation = createSelector(getParcelDetailsState, state => state.recommendation);
