import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ImageService {
  emptyPng(): string {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  }

  toBase64(image: Blob): Observable<any> {
    const reader: FileReader = new FileReader();
    const load$ = fromEvent(reader, 'loadend').pipe(
      map((e: ProgressEvent) => (e.target as FileReader).result),
      take(1)
    );
    reader.readAsDataURL(image);
    return load$;
  }
}
