import { proj4326, proj3857 } from '@app/overview/parcels-viewer/shared/utils';
import { Extent } from '@hanreev/types-ol/ol/extent';
import { transformExtent } from 'ol/proj';
import { Feature, Polygon } from 'geojson';

export function polygonToFeature(polygon: Polygon): Feature<any> {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      ...polygon,
    },
  };
}

export function transformToMapExtent(extent: Extent) {
  return extent ? transformExtent(extent, proj4326, proj3857) : null;
}
