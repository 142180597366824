import { Injectable } from '@angular/core';
import { SpreadingEquipmentApiService } from '@app/shared/api/core/spreading-equipment/spreading-equipment-api.service';
import { VraMapApiService } from '@app/shared/api/vra-map/vra-map-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromOverview from '@app/overview/overview.reducer';
import {
  changeMainSpreadingEquipment,
  createSpreadingEquipment,
  deleteSpreadingEquipment,
  FindSpreadingEquipments,
  FindSpreadingEquipmentsSuccess,
  FindTerminals,
  FindTerminalsSuccess,
  patchSpreadingEquipment,
} from './spreading-equipments.action';
import { SpreadingEquipment } from '@app/overview/shared/farm-star/spreading-equipment.model';

@Injectable()
export class SpreadingEquipmentsEffects {
  constructor(
    private actions$: Actions,
    private vraMapApiService: VraMapApiService,
    private spreadingEquipmentApiService: SpreadingEquipmentApiService,
    private store: Store<fromOverview.State>
  ) {}

  getTerminals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindTerminals),
      concatMap(_ => this.vraMapApiService.getTerminals()),
      switchMap(terminals => [FindTerminalsSuccess({ terminals }), FindSpreadingEquipments()])
    )
  );

  getSpreadingEquipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindSpreadingEquipments),
      withLatestFrom(this.store.pipe(select(fromOverview.selectUserData))),
      concatMap(([_, userData]) =>
        this.spreadingEquipmentApiService
          .getSpreadingEquipmentForUser(userData.id)
          .pipe(map(spreadingEquipments => FindSpreadingEquipmentsSuccess({ spreadingEquipments })))
      )
    )
  );

  createSpreadingEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSpreadingEquipment),
      withLatestFrom(this.store.pipe(select(fromOverview.selectUserData))),
      concatMap(([action, userData]) => {
        const equipment: SpreadingEquipment = {
          id: undefined,
          name: action.spreadingEquipment.name,
          formapTerminalId: action.spreadingEquipment.formapTerminalId,
          format: action.spreadingEquipment.format,
          mainEquipment: action.spreadingEquipment.mainEquipment,
          user: 'http://localhost:13000/fs-core/api/user/' + userData.id,
        };
        return this.spreadingEquipmentApiService.postSpreadingEquipment(equipment);
      }),
      map(_ => FindSpreadingEquipments())
    )
  );

  patchSpreadingEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchSpreadingEquipment),
      concatMap(action => {
        return this.spreadingEquipmentApiService.patchSpreadingEquipment(action.spreadingEquipment);
      }),
      map(_ => FindSpreadingEquipments())
    )
  );

  deleteSpreadingEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSpreadingEquipment),
      concatMap(action => {
        return this.spreadingEquipmentApiService.deleteSpreadingEquipment(action.equipmentId);
      }),
      map(_ => FindSpreadingEquipments())
    )
  );

  changeMainSpreadingEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeMainSpreadingEquipment),
      concatMap(action => {
        return this.spreadingEquipmentApiService.changeMainSpreadingEquipment(action.equipmentId);
      }),
      map(_ => FindSpreadingEquipments())
    )
  );
}
