import * as Highcharts from 'highcharts';
import { ParcelWithTotalDoseComputatioMethods } from '@app/overview/shared/farm-star/parcel.model';

export const TOTAL_DOSE_DIALOG_WIDTH = '960px';
export const TOTAL_DOSE_DIALOG_MIN_WIDTH = '500px';

export enum CalculationMethodsCode {
  FWT_SO = 'FWT_SO',
  FWT_PC = 'FWT_PC',
  FWT_LORN = 'FWT_LORN',
  FWT_CHAMP = 'FWT_CHAMP',
  FWT_RA = 'FWT_RA',
  FWT_BZ = 'FWT_BZ',
  FWT_NF = 'FWT_NF',
  AZ = 'AZ',
  WIUZ = 'WIUZ',
}

export const nDoseComputationEngineFW = 'FW';

export const listCAUComputationMethods = [
  CalculationMethodsCode.FWT_SO,
  CalculationMethodsCode.FWT_PC,
  CalculationMethodsCode.FWT_LORN,
  CalculationMethodsCode.FWT_CHAMP,
  CalculationMethodsCode.FWT_RA,
];

export enum NitrogenLabels {
  DIALOG_TITLE = 'Calcul de la dose totale',
  SUPPLIES_TITLE = 'Fournitures',
  NEEDS_TITLE = 'Besoins',
  TOTAL_NEED = 'Besoin de la culture',
  MEDIAN_YIELD_TARGET = 'Objectif rendement :',
  N_CREDIT_YIELD = 'Reliquat après récolte',
  MINERALIZATION = 'Minéralisation',
  HUMUS_MINERALIZATION_EFFECT = 'Effet humus',
  PREVIOUS_CROP_MIN_EFFECT = 'Effet précédent',
  GRASSLAND_EFFECT = 'Arrière effet prairie',
  CATCH_CROP_EFFECT = 'Effet CIPAN',
  OTHER_SUPPLIES = 'Autres fournitures',
  IRRIGATION_EFFECT = 'Apport de l’eau d’irrigation',
  N_CREDIT_BALANCE_BEGINNING = 'Reliquat à l’entrée du bilan',
  CURRENT_YEAR_ORGANIC_WASTE_EFFECT = "Effet Matière organique de l'année",
  WORKING_COEFFICIENT = "Coefficient apparent d'utilisation",
  WORKING_COEFFICIENT_ACRONYM = '(CAU)',
  ORGANIC_WASTE_PRODUCTS = 'Produits résiduaires organiques',
  ORGANIC_WASTE_EFFECT = 'Effet des apports organiques',
  HISTORIC_ORGANIC_WASTE_EFFECT = 'Arrière effet des apports MO',
  SOIL_EFFECT = 'Fournitures du sol',
  SYMBIOTIC_FIXATION_EFFECT = 'Fixation symbiotique',
  FLOODING_EFFECT = 'Lessivage de l’azote du sol',
  FERTILIZER_MICROBIAL_EFFECT = 'Organisation microbienne du N de l’engrais',
  FERTILIZER_VOLATILIZATION_EFFECT = 'Volatilisation',
}

export function generateOptions(
  colors: string[],
  data: Array<Highcharts.SeriesPieDataOptions>,
  chartHeight: string,
  displayDefaultTitle?: boolean
): Highcharts.Options {
  return {
    chart: {
      height: chartHeight,
      backgroundColor: '#f7f7f7',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    credits: { enabled: false },
    legend: {
      width: 600,
      itemWidth: 300,
      itemMarginBottom: 14,
      symbolPadding: 8,
      symbolWidth: 24,
      squareSymbol: false,
      symbolRadius: 0,
      itemStyle: {
        width: 220,
        color: '#052338',
        fontSize: '18px',
        fontFamily: 'Lato, Roboto, Arial, sans-serif',
        fontWeight: '400',
        letterSpacing: '0.3px',
      },
    },
    title: {
      text: displayDefaultTitle ? 'Graphique Non applicable' : '',
    },
    tooltip: {
      borderWidth: 2,
      headerFormat: `<div style="font: 400 18px Lato, Roboto, Arial, sans-serif; letter-spacing: 0.3px">{point.key}</div><br>`,
      pointFormat: '<b>{point.percentage:.1f}%</b>',
      style: {
        color: '#052338',
        fontSize: '18px',
        fontFamily: 'Lato, Roboto, Arial, sans-serif',
        fontWeight: '900',
        letterSpacing: '0.3px',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        colors,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f} %',
          distance: -50,
          style: {
            color: '#FFFFFF',
            fontSize: '18px',
            fontFamily: 'Lato, Roboto, Arial, sans-serif',
            fontWeight: '900',
            letterSpacing: '0.3px',
          },
          filter: {
            property: 'percentage',
            operator: '>',
            value: 0,
          },
        },
        point: {
          events: {
            legendItemClick() {
              return false;
            },
          },
        },
        size: '100%',
        showInLegend: true,
      },
    },
    series: [
      {
        type: 'pie',
        data,
      },
    ],
  } as Highcharts.Options;
}

export function parcelHasAtLeastOneNeededNitrogenData(
  neededDataProp: string[],
  data: ParcelWithTotalDoseComputatioMethods
): boolean {
  return neededDataProp.some((prop: string) => {
    const propValue = data.agroData.nitrogenStatus[prop];
    return !!propValue && propValue !== 0;
  });
}

export function emptyGlobalChartWhenCauEqual0(gloabalChartLabels: NitrogenLabels[]) {
  return gloabalChartLabels.map(name => ({ name, y: 0 }));
}

export function emptyDetailsChartWhenCauEqual0(detailsChartsLabels: NitrogenLabels[]) {
  return detailsChartsLabels.map(name => ({ name, y: 0 }));
}
