import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import LoadingState = fromImportDatabase.LoadingState;

export interface TestAndImportState {
  testReportState: LoadingState;
  importReportState: LoadingState;
  isValidData: boolean;
}

export const getValidatedDataCount = (testItem: farmStarApiModel.TestReportItem): number => {
  return (
    testItem.farmer.total -
    testItem.farmer.rejected +
    (testItem.parcel.total - testItem.parcel.rejected) +
    (testItem.technician.total - testItem.technician.rejected)
  );
};

export const isExistingValidatedData = (testItem: farmStarApiModel.TestReportItem) => {
  return getValidatedDataCount(testItem) > 0;
};

export const getAddedAndImportedDataCount = (importItem: farmStarApiModel.ImportReportItem) => {
  return (
    importItem.farmer.added +
    importItem.farmer.updated +
    importItem.technician.added +
    importItem.technician.updated +
    importItem.parcel.added +
    importItem.parcel.updated
  );
};

export const isExistingRejectedData = (testItem: farmStarApiModel.TestReportItem) => {
  return testItem.farmer.rejected + testItem.parcel.rejected + testItem.technician.rejected > 0;
};

export const isValidatedAndRejectedTestedData = (testItem: farmStarApiModel.TestReportItem): boolean => {
  return isExistingValidatedData(testItem) && isExistingRejectedData(testItem);
};
