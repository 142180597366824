import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, TokenInterceptor } from '@fret-ngx/aaa';
import { environment } from '@env/environment';

@Injectable()
export class CustomTokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private interceptor: TokenInterceptor) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isSecuredUrl(request.url)) {
      return this.interceptor.intercept(request, next);
    }
    return next.handle(request);
  }
}

function isSecuredUrl(url: string) {
  return environment.securedUrls.find(u => url.search(u) > -1);
}
