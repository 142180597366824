import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditionMode } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/rain-irrigation-edition/rain-irrigation-edition.model';

@Component({
  selector: 'fstar-rain-irrigation-edition',
  templateUrl: './rain-irrigation-edition.component.html',
  styleUrls: ['./rain-irrigation-edition.component.scss'],
})
export class RainIrrigationEditionComponent {
  constructor() {}

  @Output() closeComponent = new EventEmitter();
  @Input() editionMode: EditionMode;

  readonly EditionMode = EditionMode;

  closeEditionLayer() {
    this.closeComponent.emit();
  }
}
