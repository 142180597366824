import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { routesNames } from '@app/shared/utils/routes.helper';
import { Observable } from 'rxjs';
import * as fromOverview from '@app/overview/overview.reducer';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { Dictionary } from '@ngrx/entity';
import { ParcelWithAdvices } from '@app/overview/shared/farm-star/parcel.model';

@Injectable()
export class FarmParcelDetailsGuard implements CanActivate {
  constructor(private store: Store<fromOverview.OverviewGlobalState>, private router: Router) {}

  findFarmParcels(): Observable<Dictionary<ParcelWithAdvices>> {
    return this.store.pipe(select(fromParcelsViewer.selectAllFieldsEntities), take(1));
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const parcelId = route.params.parcelId;
    return this.findFarmParcels().pipe(
      map((parcels: Dictionary<ParcelWithAdvices>) =>
        parcels[parcelId]
          ? true
          : this.router.createUrlTree([`/${routesNames.PARCELS}`], { queryParamsHandling: 'preserve' })
      )
    );
  }
}
