import { Feature } from 'geojson';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import { IrrigationStatus } from '@app/overview/shared/farm-star/parcel.model';

export enum DrawMode {
  polygon = 'POLYGON',
  box = 'BOX',
  none = 'NONE',
}

export interface FeatureArea extends Feature {
  area: string;
}

export interface FeatureBiophy {
  featureId: string;
  isLoading: boolean;
  isLoaded: boolean;
  medianBiophy?: number;
}

export interface PhenologicalStageByEstimateDate {
  date: string;
  codePs: PhenologicalStageCode;
}

export interface TechnicianDataInformation {
  isLoading: boolean;
  technicianData: farmStarApiModel.BasicUserData;
}

export interface HydroDataInformation {
  isLoading: boolean;
  irrigationStatus: IrrigationStatus;
  maxDailyStatus: MaxDailyStatus;
  timelineRainAndIrrigation: TimelineRainAndIrrigation[];
}

export interface TimelineRainAndIrrigation {
  date: string;
  rain: number;
  irrigation: number;
}

export interface MaxDailyStatus {
  easilyUsableReserve: number;
  usableReserve: number;
}

export interface PointWithBiophyValue {
  coordinates: [number, number];
  medianBiophy: number;
}
