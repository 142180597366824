<div class="parcel-and-owner-info">
  <div class="parcel-info" *ngIf="parcel">
    <div>
      <div class="parcel-info-label">Parcelle</div>
      <div class="parcel-info-name">
        {{ parcel.name }}<span>{{ parcel.code }}</span>
      </div>
    </div>
    <mat-icon [svgIcon]="cropIcon"></mat-icon>
  </div>
  <div class="owner-info" *ngIf="farm">
    <div class="owner-info-label">
      <mat-icon
        *ngIf="!(userRole === userRoleEnum.technician) && technician"
        [matTooltip]="
          'Technicien : ' + (!!technician.firstName ? technician.firstName : '') + ' ' + technician.lastName
        "
        matTooltipPosition="above"
        matTooltipClass="technician-tooltip"
        >info</mat-icon
      >
      <span>Exploitation</span>
    </div>
    <div class="owner-info-name">{{ farm.corporateName }}</div>
  </div>
</div>
