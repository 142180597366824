import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  LayerVectorComponent,
  MapComponent as OlMapComponent,
  SelectInteractionComponent,
  SourceVectorComponent,
  ViewComponent,
} from 'ngx-openlayers';
import {
  generateHydroMarkerStyle,
  generateHydroStyle,
  generateStageMarkerStyle,
  generateStageStyle,
  generateCropMarkerStyle,
  getCenterFromFeatureWithStyle,
  medianBiophyMarkerStyle,
  medianBiophyStyle,
  proj3857,
  proj4326,
  styleFunction,
  styleFunctionHydroHarvest,
  styleFunctionStageHarvest,
  styleHoverHydroFunction,
  styleHoverStageFunction,
  styleInteractionSelected,
  totalDoseMarkerStyle,
  totalDoseStyle,
  hiddenFeatureStyle,
} from '@app/overview/parcels-viewer/shared/utils';
import { Observable, of, timer } from 'rxjs';
import { ParcelTooltip } from '@app/overview/shared/parcels-map.models';
import { select, Store } from '@ngrx/store';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { pointerMove, singleClick } from 'ol/events/condition';
import { Feature, ImageTile } from 'ol';
import { SelectEvent } from 'ol/interaction/Select';
import { GeoJSON } from 'ol/format';
import { FeatureCollection } from 'geojson';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import * as fromOverview from '@app/overview/overview.reducer';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { DailyStatus, ParcelWithAdvices } from '@app/overview/shared/farm-star/parcel.model';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '@app/shared/image/image.service';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import { Extent } from 'ol/extent';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Dictionary } from '@ngrx/entity';
import { Crop } from '@app/overview/shared/farm-star/agro-datum.model';

@Component({
  selector: 'fstar-parcels-map',
  templateUrl: './parcels-map.component.html',
  styleUrls: ['./parcels-map.component.scss'],
})
export class ParcelsMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() parcels: Observable<ParcelWithAdvices[]> = of([]);

  @ViewChild('map', { static: true }) map: OlMapComponent;
  @ViewChild('view', { static: true }) view: ViewComponent;
  @ViewChild('aoiSource', { static: true }) aoiSource: SourceVectorComponent;
  @ViewChild('aoiLayer', { static: true }) aoiLayer: LayerVectorComponent;
  @ViewChild('doseSource', { static: true }) doseSource: SourceVectorComponent;
  @ViewChild('stageSource', { static: true }) stageSource: SourceVectorComponent;
  @ViewChild('hydroSource', { static: true }) hydroSource: SourceVectorComponent;
  @ViewChild('laiSource', { static: true }) laiSource: SourceVectorComponent;
  @ViewChild('doseMarkersSource', { static: true }) doseMarkersSource: SourceVectorComponent;
  @ViewChild('stageMarkersSource', { static: true }) stageMarkersSource: SourceVectorComponent;
  @ViewChild('hydroMarkersSource', { static: true }) hydroMarkersSource: SourceVectorComponent;
  @ViewChild('markersSource', { static: true }) markersSource: SourceVectorComponent;
  @ViewChild('laiMarkersSource', { static: true }) laiMarkersSource: SourceVectorComponent;
  @ViewChild('parcelSelect', { static: true }) parcelSelect: SelectInteractionComponent;

  public proj3857 = proj3857;
  public center = [217073.00704688346, 5909489.863677091];
  public selectOnHoverCondition = pointerMove;
  public selectOnClickCondition = singleClick;
  public hoveredFeature$: Observable<ParcelTooltip>;
  public allParcelsLastBiophy: ParcelWithAdvices[] = [];
  public geoJsonFormat = new GeoJSON();
  public showMarkers = true;
  public isDoseModeSelected: boolean;
  public isHydroModeSelected: boolean;
  public isStageModeSelected: boolean;
  public isLaiModeSelected: boolean;
  public styleInteractionSelected = styleInteractionSelected;
  public showParcels = true;
  public toggledLayers = {
    layers: { crop: false, dose: false, stage: false, lai: false, hydro: false },
    markers: { crop: true, dose: false, stage: false, lai: false, hydro: false },
  };
  public parcelFeatures: Feature[] = [];

  private shouldTriggerFit = true;
  private readonly DEFAULT_LAYER = 'crop';
  private currentLayer = this.DEFAULT_LAYER;

  constructor(
    private store: Store<fromParcelsViewer.State | fromOverview.State>,
    private httpClient: HttpClient,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    timer(1).subscribe(_ => {
      this.map.instance.updateSize();
    });

    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectIsStageMode))
      .subscribe(isStageModeSelected => {
        this.isStageModeSelected = isStageModeSelected;
        this.updateLayerVisibility('stage', isStageModeSelected);
      });

    this.store.pipe(untilDestroyed(this), select(fromParcelsViewer.selectIsLaiMode)).subscribe(isLaiModeSelected => {
      this.isLaiModeSelected = isLaiModeSelected;
      this.updateLayerVisibility('lai', isLaiModeSelected);
    });

    this.store.pipe(untilDestroyed(this), select(fromParcelsViewer.selectIsDoseMode)).subscribe(isDoseModeSelected => {
      this.isDoseModeSelected = isDoseModeSelected;
      this.updateLayerVisibility('dose', isDoseModeSelected);
    });

    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectIsHydroMode))
      .subscribe(isHydroModeSelected => {
        this.isHydroModeSelected = isHydroModeSelected;
        this.updateLayerVisibility('hydro', isHydroModeSelected);
      });

    this.hoveredFeature$ = this.store.pipe(
      untilDestroyed(this),
      select(fromParcelsViewer.selectHoveredFeature),
      map(hoveredFeature => {
        this.map.instance.render();
        this.parcelSelect.instance.getFeatures().clear();

        const allParcelsFeatures = this.aoiSource.instance.getFeatures();
        const featureSelected = allParcelsFeatures.find(
          p => hoveredFeature && p.getId().toString() === hoveredFeature.id
        );
        if (featureSelected) {
          this.parcelSelect.instance.getFeatures().push(featureSelected);
        }
        return hoveredFeature;
      })
    );

    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectAreParcelsFeaturesActivated))
      .subscribe(areParcelsFeaturesActivated => {
        this.showParcels = areParcelsFeaturesActivated;
        this.updateLayerVisibility();
      });

    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectCropFilter))
      .subscribe(cropFilter => this.filterFeaturesWithCrop(cropFilter));
  }

  ngAfterViewInit(): void {
    this.parcels.pipe(untilDestroyed(this)).subscribe((fields: ParcelWithAdvices[]) => {
      this.allParcelsLastBiophy = fields.filter(f => !!f.biophyImageLink && !!f.biophyImageExtent);
    });

    this.parcels
      .pipe(
        untilDestroyed(this),
        map((fields: ParcelWithAdvices[]) => {
          this.aoiSource.instance.clear();
          this.markersSource.instance.clear();
          this.doseSource.instance.clear();
          this.doseMarkersSource.instance.clear();
          this.stageSource.instance.clear();
          this.stageMarkersSource.instance.clear();
          this.laiSource.instance.clear();
          this.laiMarkersSource.instance.clear();
          this.hydroSource.instance.clear();
          this.hydroMarkersSource.instance.clear();
          this.parcelFeatures = [];
          return fields;
        }),
        filter((fields: ParcelWithAdvices[]) => fields && fields.length > 0),
        withLatestFrom(
          this.store.pipe(select(fromOverview.selectHideDataForCampaigns)),
          this.store.pipe(select(fromOverview.selectAllCropEntities))
        ),
        map(
          ([fields, hideDataForCampaigns, cropEntities]: [
            ParcelWithAdvices[],
            Map<string, fromOverview.HideCampaignData>,
            Dictionary<Crop>
          ]) =>
            ({
              type: 'FeatureCollection',
              features: fields.map((f: ParcelWithAdvices) => {
                const hideForCampaign = hideDataForCampaigns.get(f.campaignId);
                return {
                  ...f.aoi.polygon,
                  id: f.id,
                  properties: {
                    name: f.name,
                    numSaisi: f.code,
                    currentStageCode: f.currentStageCode,
                    isHarvestStage: f.isHarvestStage,
                    lastDailyStatus:
                      !hideForCampaign.hideIrrigationAmount && f.lastDailyStatus ? f.lastDailyStatus : null,
                    totalDose:
                      !hideForCampaign.hideNitrogenDose &&
                      f.isTotalNDelivered &&
                      f.agroData &&
                      f.agroData.nitrogenStatus &&
                      (!!f.agroData.nitrogenStatus.projectedDose || f.agroData.nitrogenStatus.projectedDose === 0)
                        ? Math.round(f.agroData.nitrogenStatus.projectedDose)
                        : null,
                    medianBiophy: f.medianBiophy ? f.medianBiophy : null,
                    cropCode: cropEntities[f.agroData.crop.cropRefId].code,
                  },
                };
              }),
            } as FeatureCollection<any>)
        ),
        map(features =>
          this.geoJsonFormat.readFeatures(features, {
            dataProjection: proj4326,
            featureProjection: proj3857,
          })
        )
      )
      .subscribe((olFeatures: Feature[]) => {
        this.laiSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const medianBiophyFeatureStyle = medianBiophyStyle(feature.get('medianBiophy'));
            return getCenterFromFeatureWithStyle(medianBiophyFeatureStyle)(feature);
          })
        );
        this.laiMarkersSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const medianBiophyMarkerFeatureStyle = medianBiophyMarkerStyle(feature.get('medianBiophy'));
            return getCenterFromFeatureWithStyle(medianBiophyMarkerFeatureStyle)(feature);
          })
        );
        this.doseSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const totalDoseFeatureStyle = totalDoseStyle(feature.get('totalDose'));
            const centeredFeature = getCenterFromFeatureWithStyle(totalDoseFeatureStyle)(feature);
            return centeredFeature;
          })
        );
        this.doseMarkersSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const totalDoseMarkerFeatureStyle = totalDoseMarkerStyle(feature.get('totalDose'));
            const centeredFeature = getCenterFromFeatureWithStyle(totalDoseMarkerFeatureStyle)(feature);
            return centeredFeature;
          })
        );
        this.stageSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const currentStage = feature.get('currentStageCode') as PhenologicalStageCode;
            const stageStyle = generateStageStyle(currentStage);
            const centeredFeature = getCenterFromFeatureWithStyle(stageStyle)(feature);
            return centeredFeature;
          })
        );
        this.stageMarkersSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const currentStage = feature.get('currentStageCode') as PhenologicalStageCode;
            const markerStyle = generateStageMarkerStyle(currentStage);
            const centeredFeature = getCenterFromFeatureWithStyle(markerStyle)(feature);
            return centeredFeature;
          })
        );
        this.hydroSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const dailyStatus = feature.get('lastDailyStatus') as DailyStatus;
            const hydroStyle = generateHydroStyle(dailyStatus);
            const centeredFeature = getCenterFromFeatureWithStyle(hydroStyle)(feature);
            return centeredFeature;
          })
        );
        this.hydroMarkersSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const dailyStatus = feature.get('lastDailyStatus') as DailyStatus;
            const markerStyle = generateHydroMarkerStyle(dailyStatus);
            const centeredFeature = getCenterFromFeatureWithStyle(markerStyle)(feature);
            return centeredFeature;
          })
        );
        this.aoiSource.instance.addFeatures(olFeatures);
        if (this.isStageModeSelected) {
          this.aoiLayer.instance.setStyle(styleFunctionStageHarvest);
        } else if (this.isHydroModeSelected) {
          this.aoiLayer.instance.setStyle(styleFunctionHydroHarvest);
        } else {
          this.aoiLayer.instance.setStyle(styleFunction);
        }
        this.markersSource.instance.addFeatures(
          olFeatures.map((feature: Feature) => {
            const markerStyle = generateCropMarkerStyle(feature.get('cropCode'));
            const centeredFeature = getCenterFromFeatureWithStyle(markerStyle)(feature);
            return centeredFeature;
          })
        );
        this.triggerFit(this.aoiSource.instance.getExtent());

        this.parcelFeatures.push(...this.aoiSource.instance.getFeatures());
        this.parcelFeatures.push(...this.markersSource.instance.getFeatures());
        this.parcelFeatures.push(...this.doseSource.instance.getFeatures());
        this.parcelFeatures.push(...this.doseMarkersSource.instance.getFeatures());
        this.parcelFeatures.push(...this.stageSource.instance.getFeatures());
        this.parcelFeatures.push(...this.stageMarkersSource.instance.getFeatures());
        this.parcelFeatures.push(...this.laiSource.instance.getFeatures());
        this.parcelFeatures.push(...this.laiMarkersSource.instance.getFeatures());
        this.parcelFeatures.push(...this.hydroSource.instance.getFeatures());
        this.parcelFeatures.push(...this.hydroMarkersSource.instance.getFeatures());
      });

    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectIsStageMode))
      .subscribe(isStageModeSelected => {
        if (isStageModeSelected) {
          this.aoiLayer.instance.setStyle(styleFunctionStageHarvest);
        } else {
          this.aoiLayer.instance.setStyle(styleFunction);
        }
      });

    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectIsHydroMode))
      .subscribe(isHydroModeSelected => {
        if (isHydroModeSelected) {
          this.aoiLayer.instance.setStyle(styleFunctionHydroHarvest);
        } else {
          this.aoiLayer.instance.setStyle(styleFunction);
        }
      });
  }

  ngOnDestroy() {
    // Do not remove
    // untilDestroyed requires the component to implement OnDestroy
  }

  triggerFit(extent: Extent) {
    if (this.shouldTriggerFit) {
      this.view.instance.fit(extent, { padding: [60, 60, 60, 60] });
      this.shouldTriggerFit = false;
    }
  }

  styleHoverAoiFunction = (feature: Feature, resolution: number) => {
    if (this.isStageModeSelected) {
      return styleHoverStageFunction(feature, resolution, this.isStageModeSelected);
    }
    return styleHoverHydroFunction(feature, resolution, this.isHydroModeSelected);
  };

  updateZoomLevel() {
    this.showMarkers = this.view.instance.getZoom() < 13;
    this.updateLayerVisibility();
  }

  onClick(event: SelectEvent) {
    this.store.dispatch(
      new ParcelsViewerActions.SelectFeature(event.selected[0] ? event.selected[0].getId().toString() : null)
    );
  }

  displayTooltip(event: SelectEvent) {
    this.store.dispatch(
      new ParcelsViewerActions.HoverFeature(event.selected[0] ? event.selected[0].getId().toString() : null)
    );
  }

  imageLoadFunction(): (image: ImageTile, src: string) => void {
    return (image, src) => {
      this.httpClient
        .get(src, { responseType: 'blob' })
        .pipe(
          switchMap(this.imageService.toBase64),
          catchError(() => {
            return of(this.imageService.emptyPng());
          })
        )
        .subscribe((imageSrc: string) => {
          (image.getImage() as HTMLImageElement).src = imageSrc;
        });
    };
  }

  private updateLayerVisibility(layer?: string, visible?: boolean) {
    if (layer === this.currentLayer || visible) {
      this.currentLayer = visible ? layer : this.DEFAULT_LAYER;
    }

    Object.keys(this.toggledLayers.layers).forEach(key => (this.toggledLayers.layers[key] = false));
    Object.keys(this.toggledLayers.markers).forEach(key => (this.toggledLayers.markers[key] = false));
    if (this.showParcels) {
      if (this.showMarkers) {
        this.toggledLayers.markers[this.currentLayer] = true;
      } else {
        this.toggledLayers.layers[this.currentLayer] = true;
        this.toggledLayers.layers.crop = true;
      }
    }
  }

  private filterFeaturesWithCrop(crop?: string) {
    if (crop) {
      this.parcelFeatures.forEach(parcel => {
        if (parcel.get('cropCode') === crop) {
          this.restoreFeatureStyle(parcel);
        } else {
          if (!parcel.get('hidden')) {
            parcel.set('hidden', true);
            parcel.set('originalStyle', parcel.getStyle());
            parcel.setStyle(hiddenFeatureStyle);
          }
        }
      });
    } else {
      this.parcelFeatures.forEach(parcel => {
        this.restoreFeatureStyle(parcel);
      });
    }
  }

  private restoreFeatureStyle(feat: Feature) {
    if (feat.get('hidden')) {
      feat.unset('hidden');
      feat.setStyle(feat.get('originalStyle'));
    }
  }
}
