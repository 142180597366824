import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromParcelDetails from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.reducer';
import { select, Store } from '@ngrx/store';
import { CompanionComponent } from '@davinkevin/companion-component';
import { DrawMode } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';

@Component({
  selector: 'fstar-draw-menu',
  templateUrl: './draw-menu.component.html',
  styleUrls: ['./draw-menu.component.scss'],
})
export class DrawMenuComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();
  isDrawModeActivated = false;
  drawMode: DrawMode;
  isMenuOpened = false;
  drawModeEnum = DrawMode;
  selectedFeatureId: string = null;

  constructor(private store: Store<fromParcelDetails.State>) {}

  ngOnDestroy() {
    this.companion.destroy();
  }

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectIsDrawModeActivated))
      .subscribe(isDrawModeActivated => (this.isDrawModeActivated = isDrawModeActivated));

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectDrawMode))
      .subscribe(drawMode => (this.drawMode = drawMode));

    this.store
      .pipe(untilDestroy(), select(fromParcelDetails.selectSelectedFeatureId))
      .subscribe(selectedFeatureId => (this.selectedFeatureId = selectedFeatureId));
  }

  clickOnMenu() {
    this.isMenuOpened = !this.isMenuOpened;
    if (this.isDrawModeActivated) {
      this.store.dispatch(parcelDetailsActions.EndDrawMode());
    }
  }

  clickOnDrawMode(drawMode: DrawMode) {
    this.store.dispatch(parcelDetailsActions.ActivateDrawMode({ drawMode }));
  }

  clickOnDelete() {
    this.store.dispatch(parcelDetailsActions.DeleteFeature({ featureId: this.selectedFeatureId }));
  }
}
