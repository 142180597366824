import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import { CustomerDataCampaign, TestErrorReportViewElement } from '@app/overview/import-database/import-database.model';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { Observable } from 'rxjs';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  SEND_TO_PRODUCTION_SUCCESS_DIALOG_WIDTH,
  SendToProductionSuccessDialogComponent,
} from '@app/overview/import-database/core/import-database-details/core/send-to-production-success-dialog/send-to-production-success-dialog.component';
import { ofType } from '@ngrx/effects';
import { CompanionComponent } from '@davinkevin/companion-component';
import { TestAndImportState } from '@app/overview/import-database/core/reports-checks';
import {
  OLD_TESTED_DATAFILE_DIALOG_WIDTH,
  OldTestedDatafileDialogComponent,
} from '@app/overview/import-database/core/import-database-details/core/old-tested-datafile-dialog/old-tested-datafile-dialog.component';
import selectTestAndImportReports = fromImportDatabase.selectTestAndImportStates;

@Component({
  selector: 'fstar-import-database-details',
  templateUrl: './import-database-details.component.html',
  styleUrls: ['./import-database-details.component.scss'],
})
export class ImportDatabaseDetailsComponent implements OnInit, OnDestroy {
  public customerData: CustomerDataCampaign;
  public testReport$: Observable<farmStarApiModel.TestReportItem>;
  public testErrorReport$: Observable<TestErrorReportViewElement[]>;
  public importReport$: Observable<farmStarApiModel.ImportReportItem>;
  public testAndImportStates: TestAndImportState;
  public hasMostRecentLoadedDatafile$: Observable<boolean>;

  private companion = new CompanionComponent();

  selectedTabIndex = 0;
  datafileStatus = farmStarApiModel.DatafileStatus;
  LoadingState = fromImportDatabase.LoadingState;

  constructor(
    private store: Store<fromImportDatabase.GlobalState>,
    public dialog: MatDialog,
    private action: ActionsSubject
  ) {}

  ngOnInit(): void {
    const untilDestroy = this.companion.untilDestroy();

    this.store
      .pipe(untilDestroy(), select(fromImportDatabase.selectSelectedCustomerDatafile))
      .subscribe((data: CustomerDataCampaign) => {
        this.customerData = data;
      });

    this.hasMostRecentLoadedDatafile$ = this.store.pipe(select(fromImportDatabase.selectHasMostRecentLoadedDatafile));

    this.testReport$ = this.store.pipe(
      untilDestroy(),
      select(fromImportDatabase.selectTestReport),
      tap(() => (this.selectedTabIndex = 0))
    );

    this.testErrorReport$ = this.store.pipe(select(fromImportDatabase.selectTestErrorReport));
    this.importReport$ = this.store.pipe(select(fromImportDatabase.selectImportReport));

    this.action.pipe(untilDestroy(), ofType(ImportDatabaseActions.SendDataFileToProductionSuccess)).subscribe(() => {
      this.dialog.open(SendToProductionSuccessDialogComponent, {
        width: SEND_TO_PRODUCTION_SUCCESS_DIALOG_WIDTH,
        backdropClass: 'custom-opacity-backdrop',
        autoFocus: false,
      });
      this.dialog.afterAllClosed.pipe(untilDestroy()).subscribe(() => {
        this.store.dispatch(ImportDatabaseActions.ReloadDataFile({ datafileId: this.customerData.id }));
      });
    });

    this.store
      .pipe(untilDestroy(), select(selectTestAndImportReports))
      .subscribe((testAndImportReports: TestAndImportState) => {
        this.testAndImportStates = testAndImportReports;
      });
  }

  ngOnDestroy(): void {
    this.companion.destroy();
  }

  public downloadFile() {
    this.store.dispatch(ImportDatabaseActions.DownloadUploadedDatafile());
  }

  public openImportDialog(data: CustomerDataCampaign) {
    this.store.dispatch(ImportDatabaseActions.SendDataFileToProduction({ datafileId: data.id }));
  }

  public tryToLoadOldDatafile() {
    this.dialog.open(OldTestedDatafileDialogComponent, {
      width: OLD_TESTED_DATAFILE_DIALOG_WIDTH,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
    });
  }
}
