import { Inject, Injectable } from '@angular/core';
import { nextToken } from '@app/shared/next/next';

@Injectable()
export class NextService {
  constructor(@Inject(nextToken) private next: boolean) {}

  isNextActivated(): boolean {
    return this.next;
  }
}
