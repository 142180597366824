import { Action } from '@ngrx/store';
import { CurrentStageByParcelId, Parcel } from '@app/overview/shared/farm-star/parcel.model';
import { AdviceMode } from '@app/overview/parcels-viewer/core/switch-advice-mode/advice-mode';

export namespace ParcelsViewerActions {
  export enum Types {
    ShowParcelsFeatures = '[Fields] Show parcels features',
    HideParcelsFeatures = '[Fields] Hide parcels features',
    UpdateParcelAgroData = '[Fields] Update parcel agro data',
    FindFieldsWithLoader = '[Fields] Find fields with loader',
    FindFieldsWithLoaderSuccess = '[Fields] Find fields with loader success',
    FindFieldsWithLoaderError = '[Fields] Find fields with loader error',
    HoverFeature = '[MapFeature] Hover feature',
    FilterFeatures = '[Fields] Filter features',
    SelectFeature = '[MapFeature] Select feature',
    ChangeAdviceMode = '[Advice Mode] Change advice mode',
    ToggleAdviceModeContainer = '[Advice Mode] Toggle advice mode container',
    FilterFeaturesByCrop = '[Fields] Filter features by crop',
    BindParcelStageEstimate = '[Fields] Bind stage estimate to parcel',
    BindParcelStageEstimateSuccess = '[Fields] Bind stage estimate to parcel success',
  }

  export class HideParcelsFeatures implements Action {
    readonly type = Types.HideParcelsFeatures;
  }

  export class ShowParcelsFeatures implements Action {
    readonly type = Types.ShowParcelsFeatures;
  }

  export class UpdateParcelAgroData implements Action {
    readonly type = Types.UpdateParcelAgroData;
    constructor(public parcel: Parcel) {}
  }

  export class FindFieldsWithLoader implements Action {
    readonly type = Types.FindFieldsWithLoader;
  }

  export class FindFieldsWithLoaderSuccess implements Action {
    readonly type = Types.FindFieldsWithLoaderSuccess;
    constructor(public fields: Parcel[]) {}
  }

  export class FindFieldsWithLoaderError implements Action {
    readonly type = Types.FindFieldsWithLoaderError;
  }

  export class HoverFeature implements Action {
    readonly type = Types.HoverFeature;
    constructor(public id: string) {}
  }

  export class SelectFeature implements Action {
    readonly type = Types.SelectFeature;
    constructor(public id: string) {}
  }

  export class ChangeAdviceMode implements Action {
    readonly type = Types.ChangeAdviceMode;
    constructor(public mode: AdviceMode) {}
  }

  export class ToggleAdviceModeContainer implements Action {
    readonly type = Types.ToggleAdviceModeContainer;
  }

  export class FilterFeatures implements Action {
    readonly type = Types.FilterFeatures;
    constructor(public filter: string) {}
  }

  export class FilterFeaturesByCrop implements Action {
    readonly type = Types.FilterFeaturesByCrop;
    constructor(public crop?: string) {}
  }

  export class BindParcelStageEstimate implements Action {
    readonly type = Types.BindParcelStageEstimate;
  }

  export class BindParcelStageEstimateSuccess implements Action {
    readonly type = Types.BindParcelStageEstimateSuccess;
    constructor(public currentStageByParcelsIds: CurrentStageByParcelId[]) {}
  }

  export type All =
    | ShowParcelsFeatures
    | HideParcelsFeatures
    | UpdateParcelAgroData
    | FindFieldsWithLoader
    | FindFieldsWithLoaderSuccess
    | FindFieldsWithLoaderError
    | HoverFeature
    | SelectFeature
    | FilterFeatures
    | FilterFeaturesByCrop
    | ChangeAdviceMode
    | ToggleAdviceModeContainer
    | BindParcelStageEstimate
    | BindParcelStageEstimateSuccess;
}
