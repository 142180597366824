import { Inject, Injectable } from '@angular/core';
import { Configuration, CONFIGURATION } from '../farm-star/configuration/configuration';
import { farmStarApiModel } from '../farm-star/farm-star.model';

@Injectable()
export class ObservationService {
  constructor(@Inject(CONFIGURATION) private config: Configuration) {}

  findLaiLayer(observation: farmStarApiModel.ObservationBackend) {
    return this.findLayer('LAI', observation);
  }

  findLayer(productDefinitionCode: string, observation: farmStarApiModel.ObservationBackend): farmStarApiModel.Layer {
    return observation.layers.find(layer => layer.productDefinitionCode === productDefinitionCode);
  }

  findIllustration(type: string, layer: farmStarApiModel.Layer): farmStarApiModel.Illustration {
    return layer.illustrations.find(i => i.type === type);
  }

  getImageUrl(imageBucketPath: string) {
    return `${this.config.url}/${this.config.api}/fs-core/api/layers/image?filePath=` + imageBucketPath;
  }

  getLaiLayerLargePngUrl(observation: farmStarApiModel.ObservationBackend): string {
    return this.getImageUrl(this.getA3iDisplayFullCycleIllustration(observation).filePath);
  }

  getExtent(illustration: farmStarApiModel.Illustration): [number, number, number, number] {
    return [illustration.bbox.minX, illustration.bbox.minY, illustration.bbox.maxX, illustration.bbox.maxY];
  }

  getA3iDisplayFullCycleIllustration(observation: farmStarApiModel.ObservationBackend): farmStarApiModel.Illustration {
    return this.findIllustration('A3I_DISPLAY_FULLCYCLE', this.findLaiLayer(observation));
  }
}
