import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { MatDialog } from '@angular/material/dialog';
import {
  LARGE_HELP_DIALOG_MAX_HEIGHT,
  LARGE_HELP_DIALOG_MIN_WIDTH,
  LARGE_HELP_DIALOG_WIDTH,
  LargeHelpPopupComponent,
} from '@app/overview/shared/large-help-popup/large-help-popup.component';
import { HideParcelDetailsHelpStatus } from '@app/shared/local-storage';
import { HideParcelDetailsHelp } from '@app/overview/overview.actions';

@Component({
  selector: 'fstar-small-help-popup',
  templateUrl: './small-help-popup.component.html',
  styleUrls: ['./small-help-popup.component.scss'],
})
export class SmallHelpPopupComponent {
  neverSeeAgain = false;

  constructor(private store: Store<fromOverview.OverviewGlobalState>, public dialog: MatDialog) {}

  hideHelp() {
    this.store.dispatch(
      HideParcelDetailsHelp({
        status: this.neverSeeAgain ? HideParcelDetailsHelpStatus.ALWAYS : HideParcelDetailsHelpStatus.CURRENT_SESSION,
      })
    );
  }

  showLargePopup() {
    this.dialog.open(LargeHelpPopupComponent, {
      width: LARGE_HELP_DIALOG_WIDTH,
      minWidth: LARGE_HELP_DIALOG_MIN_WIDTH,
      maxHeight: LARGE_HELP_DIALOG_MAX_HEIGHT,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
      panelClass: 'large-help-dialog',
    });
  }
}
