import { HttpParams } from '@angular/common/http';

export function toHttpParams(f: object): HttpParams {
  return Object.entries(f).reduce((params, [key, value]) => {
    if (value !== null) {
      if (Array.isArray(value)) {
        value.forEach(v => (params = params.append(key, String(v))));
      } else {
        params = params.set(key, String(value));
      }
    }
    return params;
  }, new HttpParams());
}
