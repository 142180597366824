import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CampaignStore } from '@app/overview/import-database/import-database.model';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { createReducer, on } from '@ngrx/store';

export type State = EntityState<CampaignStore>;

export const adapter: EntityAdapter<CampaignStore> = createEntityAdapter<CampaignStore>({
  selectId: campaign => campaign.code,
});

const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(ImportDatabaseActions.FindCampaignsWithCropsIdsSuccess, (state, { campaigns }) =>
    adapter.addAll(campaigns, state)
  ),
  on(ImportDatabaseActions.SaveCropCampaign, (state, { campaign, cropName }) => {
    const cropsNames =
      state.entities[campaign.code] && state.entities[campaign.code].cropsNames
        ? state.entities[campaign.code].cropsNames
        : [];
    return adapter.updateOne(
      {
        id: campaign.code,
        changes: {
          ...campaign,
          cropsNames: [...cropsNames, cropName],
        },
      },
      state
    );
  })
);
