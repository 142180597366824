import { Component, Input, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { ImageService } from '@app/shared/image/image.service';

@Component({
  selector: 'fstar-image-wrapper',
  templateUrl: './image-wrapper.component.html',
})
export class ImageWrapperComponent implements OnChanges {
  @Input() url: string;
  dataUrl$: Observable<any>;

  showImage = true;
  emptyPNG = this.imageService.emptyPng();

  constructor(private httpClient: HttpClient, private imageService: ImageService) {}

  ngOnChanges() {
    this.showImage = true;
    this.dataUrl$ = this.loadImage(this.url);
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      switchMap(this.imageService.toBase64),
      catchError(() => {
        this.showImage = false;
        return of(null);
      })
    );
  }

  imgError() {
    this.showImage = false;
  }
}
