import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { ParcelDetailsMapComponent } from './parcel-details-map.component';
import { MatIconModule } from '@angular/material/icon';
import { DrawInfoTooltipComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/draw-info-tooltip/draw-info-tooltip.component';
import { FromGeoJsonModule } from '@app/shared/directives/from-geojson.module';
import { OverlayLoaderModule } from '@app/overview/shared/overlay-loader/overlay-loader.module';
import { LaiLegendModule } from '@app/overview/shared/lai-legend/lai-legend.module';

@NgModule({
  declarations: [ParcelDetailsMapComponent, DrawInfoTooltipComponent],
  imports: [
    CommonModule,
    /* OL Modules */ AngularOpenlayersModule,
    MatIconModule,
    FromGeoJsonModule,
    LaiLegendModule,
    OverlayLoaderModule,
  ],
  exports: [ParcelDetailsMapComponent],
})
export class ParcelDetailsMapModule {}
