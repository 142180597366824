import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { catchError, concatMap, filter, flatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as fromParcelDetails from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.reducer';
import { select, Store } from '@ngrx/store';
import { Feature as OlFeature, Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { getArea } from 'ol/sphere';
import { FeatureArea } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { defer, iif, of } from 'rxjs';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';
import { ofRoute, routesRegex } from '@app/shared/utils/routes.helper';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import {
  DailyStatus,
  IrrigationStatus,
  Parcel,
  ParcelResponse,
  StageNotifications,
} from '@app/overview/shared/farm-star/parcel.model';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import * as fromOverview from '@app/overview/overview.reducer';
import { UserRole } from '@app/overview/overview.models';
import { FindParcelDetail, TriggerFindParcelInfo } from '@app/overview/shared/parcels/parcels.actions';
import { HIDE_PARCEL_DETAILS_HELP } from '@app/shared/local-storage';
import { FileSaverService } from '@app/shared/file-saver/file-saver.service';
import { GeoJSON } from 'ol/format';
import {
  transformToMaxDailyStatus,
  transformToTimelineRainAndIrrigation,
} from '@app/overview/parcels-viewer/core/parcel-details/utils';
import { ShowParcelDetailsHelp } from '@app/overview/overview.actions';
import { DisplaySnackbarError, DisplaySnackbarSuccess } from '@app/app.action';
import { ParcelRecommendationApiService } from '@app/shared/api/parcel/recommendation/parcel-recommendation-api.service';
import { CropCode } from '@app/overview/shared/farm-star/agro-datum.model';
import { CoreApiService } from '@app/shared/api/core/core-api.service';
import { RegisteredUser } from '@app/overview/shared/farm-star/farmer.model';

@Injectable()
export class ParcelDetailsEffects {
  constructor(
    private actions$: Actions<ParcelsViewerActions.All>,
    private store: Store<fromParcelDetails.State | fromOverview.OverviewGlobalState>,
    private farmStarService: FarmStarService,
    private fileSaverService: FileSaverService,
    private parcelRecommendationApi: ParcelRecommendationApiService,
    private coreApiService: CoreApiService
  ) {}

  downloadNDosePdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(parcelDetailsActions.DownloadNDosePdf),
        withLatestFrom(this.store.pipe(select(fromParcelDetails.selectRecommendation))),
        concatMap(([{ parcelCode }, recommendation]) =>
          this.farmStarService.downloadRecommendationFile(parcelCode, recommendation.name).pipe(
            tap((downloadedFileData: Blob) => {
              this.fileSaverService.saveAs(downloadedFileData, `dose_totale_${parcelCode}.pdf`);
            }),
            catchError(() => [])
          )
        )
      ),
    { dispatch: false }
  );

  downloadBiomassPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(parcelDetailsActions.DownloadParcelRecommendationPdf),
        switchMap(({ parcelCode, recommendationName, titre }) =>
          this.farmStarService.downloadRecommendationFile(parcelCode, recommendationName).pipe(
            tap((downloadedFileData: Blob) => {
              this.fileSaverService.saveAs(downloadedFileData, titre);
            }),
            catchError(() => [])
          )
        )
      ),
    { dispatch: false }
  );

  showParcelDetailsHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParcelsViewerActions.Types.SelectFeature),
      filter(() => !localStorage.getItem(HIDE_PARCEL_DETAILS_HELP)),
      map(() => ShowParcelDetailsHelp())
    )
  );

  updateParcelSeedlingDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.UpdateParcelSeedlingDate),
      concatMap(({ parcelRequest }) =>
        this.farmStarService.updateParcelSeedlingDate(parcelRequest).pipe(
          flatMap((updatedParcel: Parcel) => [
            parcelDetailsActions.UpdateParcelSeedlingDateSuccess({
              successMessage:
                'Cette date de semis sera prise en compte dans la prochaine mise à jour des stades (sous 2 à 3 jours).',
            }),
            new ParcelsViewerActions.UpdateParcelAgroData(updatedParcel),
            parcelDetailsActions.TriggerUpdateParcelStages({ parcelUpdated: updatedParcel }),
          ]),
          catchError(() =>
            of(
              parcelDetailsActions.UpdateParcelSeedlingDateError({
                errorMessage:
                  'Un problème est survenu. Merci de renouveler votre demande de mise à jour ultérieurement.',
              })
            )
          )
        )
      )
    )
  );

  updateParcelTotalDose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.UpdateParcelTotalDose),
      concatMap(({ parcelRequest }) =>
        this.farmStarService.updateParcelMeasuredNitrogenCredit(parcelRequest).pipe(
          concatMap((_: Parcel) =>
            iif(
              () => parcelRequest.crop === CropCode.CORN,
              this.farmStarService.triggerTotalNCalcul(parcelRequest.parcelId).pipe(
                concatMap(() => this.farmStarService.findParcelsWithMultipleIds([parcelRequest.parcelId])),
                map((parcelResponse: ParcelResponse) => parcelResponse._embedded.parcels[0]),
                flatMap((parcel: Parcel) => [
                  parcelDetailsActions.UpdateParcelTotalDoseSuccess(),
                  new ParcelsViewerActions.UpdateParcelAgroData(parcel),
                ]),
                catchError(() => [
                  parcelDetailsActions.UpdateParcelTotalDoseError({
                    errorMessage:
                      "Le service de calcul de la dose n'est pas disponible pour le moment." +
                      ' Le calcul sera relancé automatiquement dès que le service sera à nouveau disponible.',
                  }),
                  FindParcelDetail({ parcelId: parcelRequest.parcelId }),
                ])
              ),
              of(
                parcelDetailsActions.UpdateParcelTotalDoseSuccess(),
                FindParcelDetail({ parcelId: parcelRequest.parcelId })
              )
            )
          ),
          catchError(() =>
            of(
              parcelDetailsActions.UpdateParcelTotalDoseError({
                errorMessage:
                  'Un problème est survenu. Merci de renouveler votre demande de mise à jour ultérieurement.',
              })
            )
          )
        )
      )
    )
  );

  updateStagesWhenSeedlingDateIsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.TriggerUpdateParcelStages),
      map(({ parcelUpdated }) => parcelDetailsActions.FindStageEstimate({ parcel: parcelUpdated }))
    )
  );

  resetAllParcelDetailsData$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute(routesRegex.PARCELS_DETAILS),
      map(() => parcelDetailsActions.ResetParcelData())
    )
  );

  triggerFindTechnician$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParcelsViewerActions.Types.SelectFeature),
      withLatestFrom(this.store.pipe(select(fromOverview.selectUserRole))),
      filter(
        ([, userRole]: [ParcelsViewerActions.SelectFeature, UserRole]) =>
          !(userRole === UserRole.farmer || userRole === UserRole.technician)
      ),
      map(() => parcelDetailsActions.FindTechnicianData())
    )
  );

  findTechnicianNameOfSelectedFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindTechnicianData),
      withLatestFrom(this.store.pipe(select(fromOverview.selectSelectedFarm))),
      concatMap(([, farm]) =>
        this.coreApiService
          .getRegisteredTechnicians({ 'farm.id': farm.id, projection: 'registeredTechnicianUser' })
          .pipe(
            map((registeredTechnicians: RegisteredUser[]) => {
              const technicianData = registeredTechnicians.length ? registeredTechnicians[0].basicUser : null;
              return parcelDetailsActions.FindTechnicianDataSuccess({ technicianData });
            }),
            catchError(() => of(parcelDetailsActions.FindTechnicianDataError()))
          )
      )
    )
  );

  getIrrigationStatusOfSelectedParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindHydro),
      withLatestFrom(this.store.pipe(select(fromOverview.selectHideDataForCampaigns))),
      concatMap(([{ parcel }, hideDataForCampaigns]) =>
        iif(
          () => hideDataForCampaigns.get(parcel.campaignId).hideIrrigationAmount,
          of(
            parcelDetailsActions.FindHydroSuccess({
              dailyStatus: [],
              hydroDataInformation: {
                isLoading: false,
                irrigationStatus: null,
                maxDailyStatus: null,
                timelineRainAndIrrigation: null,
              },
            })
          ),
          this.farmStarService.getIrrigationStatus([parcel.id]).pipe(
            concatMap((irrigationStatus: IrrigationStatus[]) =>
              iif(
                () => irrigationStatus.length === 0,
                of(
                  parcelDetailsActions.FindHydroSuccess({
                    dailyStatus: [],
                    hydroDataInformation: {
                      isLoading: false,
                      irrigationStatus: null,
                      maxDailyStatus: null,
                      timelineRainAndIrrigation: null,
                    },
                  })
                ),
                defer(() =>
                  this.farmStarService.getDailyStatus(irrigationStatus[0].id).pipe(
                    map((dailyStatus: DailyStatus[]) =>
                      parcelDetailsActions.FindHydroSuccess({
                        dailyStatus,
                        hydroDataInformation: {
                          isLoading: false,
                          irrigationStatus: irrigationStatus[0],
                          maxDailyStatus: transformToMaxDailyStatus(dailyStatus),
                          timelineRainAndIrrigation: transformToTimelineRainAndIrrigation(dailyStatus),
                        },
                      })
                    ),
                    catchError(() => [parcelDetailsActions.FindHydroError()])
                  )
                )
              )
            ),
            catchError(() => [parcelDetailsActions.FindHydroError()])
          )
        )
      )
    )
  );

  getStageNotificationsOfSelectedParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindStageNotifications),
      withLatestFrom(this.store.pipe(select(fromOverview.selectIsAFarmer))),
      concatMap(([{ parcelId }, isAFarmer]) =>
        iif(
          () => !isAFarmer,
          of(parcelDetailsActions.FindStageNotificationsError()),
          defer(() =>
            this.farmStarService.getStageNotifications(parcelId).pipe(
              map((stageNotifications: StageNotifications[]) =>
                parcelDetailsActions.FindStageNotificationsSuccess({ stageNotifications })
              ),
              catchError(() => [parcelDetailsActions.FindStageNotificationsError()])
            )
          )
        )
      )
    )
  );

  subscribeStageNotificationsOfSelectedParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.SubscribeStageNotifications),
      concatMap(({ parcelId, phenologicalStageRefId }) =>
        this.farmStarService.subscribeStageNotifications(parcelId, phenologicalStageRefId).pipe(
          flatMap((stageNotification: StageNotifications) => [
            parcelDetailsActions.SubscribeStageNotificationsSuccess({ stageNotification }),
            DisplaySnackbarSuccess({
              successMessage:
                'Votre demande de notification a bien été enregistrée. Vous pouvez la modifier à tout moment dans le menu de gestion des notifications.',
            }),
          ]),
          catchError(() => [
            parcelDetailsActions.SubscribeStageNotificationsError(),
            DisplaySnackbarError({ errorMessage: 'Une erreur est survenue. Réessayer ulterieurement.' }),
          ])
        )
      )
    )
  );

  unsubscribeStageNotificationsOfSelectedParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.UnsubscribeStageNotifications),
      concatMap(({ stageNotification }) =>
        this.farmStarService.unsubscribeStageNotifications(stageNotification.id).pipe(
          flatMap(() => [
            parcelDetailsActions.UnsubscribeStageNotificationsSuccess({
              phenologicalStageRefId: stageNotification.phenologicalStageRefId,
            }),
            DisplaySnackbarSuccess({ successMessage: 'Votre demande de notification a été supprimée.' }),
          ]),
          catchError(() => [
            parcelDetailsActions.SubscribeStageNotificationsError(),
            DisplaySnackbarError({ errorMessage: 'Une erreur est survenue. Réessayer ulterieurement.' }),
          ])
        )
      )
    )
  );

  getStageEstimatesOfSelectedParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindStageEstimate),
      switchMap(({ parcel }) =>
        this.store.pipe(
          select(fromOverview.selectIsPhenologicalStagesLoaded),
          filter(isLoaded => !!isLoaded),
          map(() => parcel)
        )
      ),
      withLatestFrom(this.store.pipe(select(fromOverview.selectAllPhenologicalStagesEntities))),
      concatMap(([parcel, phenologicalStagesEntities]) =>
        this.farmStarService.getStageEstimates(parcel, phenologicalStagesEntities).pipe(
          map((stageEstimates: farmStarApiModel.StageEstimate[]) =>
            parcelDetailsActions.FindStageEstimateSuccess({ stageEstimates })
          ),
          catchError(() => [parcelDetailsActions.FindStageEstimateError()])
        )
      )
    )
  );

  getBiophyValuesOfSelectedParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindObservationValue),
      switchMap(({ parcelId }) =>
        this.farmStarService.findParcelObservationsNumberOfResults(parcelId).pipe(
          concatMap((numberOfResults: number) =>
            iif(
              () => numberOfResults === 0,
              of(parcelDetailsActions.FindObservationSuccess({ observations: [] })),
              defer(() => {
                return this.farmStarService.findParcelObservations(numberOfResults, parcelId).pipe(
                  map((observations: farmStarApiModel.ObservationBackend[]) => {
                    return parcelDetailsActions.FindObservationSuccess({ observations });
                  }),
                  catchError(() => [parcelDetailsActions.FindObservationError()])
                );
              })
            )
          ),
          catchError(() => [parcelDetailsActions.FindObservationError()])
        )
      )
    )
  );

  findBiophyOfPolygon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindObservationOfPolygon),
      withLatestFrom(this.store.pipe(select(fromParcelDetails.selectSelectedObservation))),
      concatMap(([{ polygon, featureId }, selectedObservation]) =>
        iif(
          () => selectedObservation && !!selectedObservation.id,
          defer(() =>
            this.farmStarService.getLayerStatOfPolygon(polygon, selectedObservation.id).pipe(
              map((medianValue: number) =>
                parcelDetailsActions.FindObservationOfPolygonSuccess({ featureId, medianBiophy: medianValue })
              ),
              catchError(() => [
                parcelDetailsActions.FindObservationOfPolygonSuccess({ featureId, medianBiophy: null }),
              ])
            )
          ),
          of(parcelDetailsActions.FindObservationOfPolygonSuccess({ featureId, medianBiophy: null }))
        )
      )
    )
  );

  findObservationOfPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindObservationOfPoint),
      withLatestFrom(this.store.pipe(select(fromParcelDetails.selectSelectedObservation))),
      switchMap(([{ point, coordinates }, observation]) =>
        iif(
          () => observation && !!observation.id,
          defer(() =>
            this.farmStarService.getLayerStatOfPoint(point, observation.id).pipe(
              map((medianValue: number) =>
                parcelDetailsActions.FindObservationOfPointSuccess({ medianBiophy: medianValue, coordinates })
              ),
              catchError(() => [
                parcelDetailsActions.FindObservationOfPointSuccess({ medianBiophy: null, coordinates }),
              ])
            )
          ),
          of(parcelDetailsActions.FindObservationOfPointSuccess({ medianBiophy: null, coordinates }))
        )
      )
    )
  );

  triggerBiophySelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.FindObservationSuccess, parcelDetailsActions.SaveFilterDate),
      withLatestFrom(
        this.store.pipe(select(fromParcelDetails.selectAllBiophy)),
        this.store.pipe(select(fromParcelDetails.selectDayOfMonthBeforeBeginDate)),
        this.store.pipe(select(fromParcelDetails.selectLastDayOfMonthEndDate)),
        this.store.pipe(select(fromParcelDetails.selectSelectedObservationId))
      ),
      filter(([_, biophyValues]) => biophyValues.length > 0),
      map(([_, observations, beginDate, endDate, selectedBiophyId]) => {
        const filteredBiophy = observations.filter(observation => {
          const biophyDate = new Date(observation.acquisition.sensingDateTime).getTime();
          return biophyDate >= beginDate && biophyDate <= endDate;
        });

        if (filteredBiophy.length === 0) {
          return parcelDetailsActions.SelectObservation({ observationId: null });
        } else {
          const selectedBiophy = filteredBiophy.find(biophy => biophy.id === selectedBiophyId);

          const id = selectedBiophy ? selectedBiophy.id : filteredBiophy[filteredBiophy.length - 1].id;

          return parcelDetailsActions.SelectObservation({ observationId: id });
        }
      })
    )
  );

  getParcelWhenSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParcelsViewerActions.Types.SelectFeature),
      withLatestFrom(this.store.pipe(select(fromParcelsViewer.selectSelectedParcel))),
      filter(([, parcel]) => parcel != null),
      flatMap(([_, parcel]: [ParcelsViewerActions.SelectFeature, Parcel]) => [
        TriggerFindParcelInfo({ parcel }),
        parcelDetailsActions.FindStageEstimate({ parcel }),
        parcelDetailsActions.FindObservationValue({ parcelId: parcel.id }),
        parcelDetailsActions.FindHydro({ parcel }),
        parcelDetailsActions.FindStageNotifications({ parcelId: parcel.id }),
      ])
    )
  );

  addFeatureToFeaturesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parcelDetailsActions.DrawEnd),
      withLatestFrom(this.store.pipe(select(fromParcelDetails.selectAllFeatures))),
      map(([{ feature, featureId }, features]) => {
        const olFeatureDrawed: OlFeature = new GeoJSON().readFeature(feature);
        olFeatureDrawed.setId(featureId);
        return parcelDetailsActions.AddFeatures({ features: this.addFeatureToFeatureList(olFeatureDrawed, features) });
      })
    )
  );

  findRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParcelsViewerActions.Types.SelectFeature),
      withLatestFrom(this.store.pipe(select(fromParcelsViewer.selectAllFieldsEntities))),
      concatMap(([action, parcels]) => {
        if (action.id !== null) {
          const parcel = parcels[action.id];
          return this.parcelRecommendationApi.findRecommendationsByCrop(parcel.agroData.crop.cropRefId).pipe(
            map((recommendations: farmStarApiModel.RecommendationBackend[]) => {
              const totalNRecommendation = recommendations.find(recommendation =>
                recommendation.code.includes('TOTALN')
              );
              return parcelDetailsActions.FindRecommendationSuccess({ recommendation: totalNRecommendation });
            }),
            catchError(() => of(parcelDetailsActions.FindRecommendationError()))
          );
        } else {
          return of(parcelDetailsActions.FindRecommendationError());
        }
      })
    )
  );

  addFeatureToFeatureList(olFeature: Feature, features: FeatureArea[]): FeatureArea[] {
    const featureFromOlFeature = this.createFeatureFromPolygon(olFeature);
    return [...features, featureFromOlFeature];
  }

  createFeatureFromPolygon(olFeature: Feature): FeatureArea {
    return {
      id: olFeature.getId(),
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: (olFeature.getGeometry() as Polygon).getCoordinates(),
      },
      area: formatArea(olFeature),
    } as FeatureArea;
  }
}

function formatArea(olFeature: Feature): string {
  const area = getArea(olFeature.getGeometry());
  if (area > 10000) {
    return Math.round((area / 1000000) * 100) / 100 + ' km²';
  } else {
    return Math.round(area * 100) / 100 + ' m²';
  }
}
