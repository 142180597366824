import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportDatabaseListComponent } from '@app/overview/import-database/core/import-database-list/import-database-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ImportDatabaseListItemModule } from '@app/overview/import-database/core/import-database-list/core/import-database-list-item/import-database-list-item.module';
import { HttpClientModule } from '@angular/common/http';
import { ImportDatabaseDialogComponent } from '@app/overview/import-database/core/import-database-list/core/import-database-dialog/import-database-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SendDatafileSuccessDialogComponent } from '@app/overview/import-database/core/import-database-list/core/send-datafile-success-dialog/send-datafile-success-dialog.component';
import { ImportDatabaseErrorDescriptionPipe } from '@app/overview/import-database/core/import-database-list/core/import-database-dialog/import-database-error-description.pipe';
import { PopupOverlayModule } from '@fret-ngx/button-popup';

@NgModule({
  declarations: [
    ImportDatabaseListComponent,
    ImportDatabaseDialogComponent,
    SendDatafileSuccessDialogComponent,
    ImportDatabaseErrorDescriptionPipe,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    PopupOverlayModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImportDatabaseListItemModule,
  ],
  exports: [ImportDatabaseListComponent],
  entryComponents: [ImportDatabaseDialogComponent, SendDatafileSuccessDialogComponent],
})
export class ImportDatabaseListModule {}
