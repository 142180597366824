import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LargeHelpPopupComponent } from '@app/overview/shared/large-help-popup/large-help-popup.component';
import { LaiHelpModule } from '@app/overview/shared/lai-help/lai-help.module';

@NgModule({
  declarations: [LargeHelpPopupComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatCheckboxModule, LaiHelpModule, MatDialogModule],
  exports: [LargeHelpPopupComponent],
})
export class LargeHelpPopupModule {}
