<div class="help-header" mat-dialog-title>
  <div class="help-header-title">Besoin d'aide ?</div>
  <div class="close-dialog"><mat-icon (click)="hideHelp()">close</mat-icon></div>
</div>
<mat-dialog-content>
  <fstar-lai-help></fstar-lai-help>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <mat-checkbox color="accent" [checked]="neverSeeAgain" (change)="neverSeeAgain = !neverSeeAgain"
    >Ne plus afficher</mat-checkbox
  >
</mat-dialog-actions>
