import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { OverviewModule } from '@app/overview/overview.module';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { AAAModule, authRoutes, AutoSilentRenewOnErrorService, OIDCConfig, TokenInterceptor } from '@fret-ngx/aaa';
import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';
import { FarmStarServiceModule } from '@app/overview/shared/farm-star/farm-star.service.module';
import { StoreModule } from '@ngrx/store';
import * as fromRoot from '@app/reducer';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomTokenInterceptor } from '@app/overview/core/auth/custom-token-interceptor';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterStoreHelperModule, SimpleRouterStateSerializer } from '@davinkevin/router-store-helper';
import { FileSaverService } from '@app/shared/file-saver/file-saver.service';
import { AppEffects } from '@app/app.effects';
import { IdService } from '@app/shared/id/id.service';
import { MAT_DATE_LOCALE } from '@angular/material';
import { routesNames } from '@app/shared/utils/routes.helper';
import { UserRoleResolver } from '@app/shared/resolver/user-role.resolver';
import { SplashScreenModule } from '@app/core/splash-screen/splash-screen.module';
import { nextProvider } from './shared/next/next';
import { NextService } from '@app/shared/next/next.service';
import { ImageService } from '@app/shared/image/image.service';
import { OIDCConfigFactory } from '@app/shared/oidc-config';
import { RemoveProjectionTokenInterceptor } from '@app/overview/core/projection/remove-projection-token-interceptor';

export const appRoutes: Routes = [...authRoutes, { path: '**', redirectTo: `/${routesNames.FARMS}` }];

export const WindowLocation = new InjectionToken('windowLocation');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    OverviewModule,
    SplashScreenModule,
    AAAModule.withConfig({}),
    FarmStarServiceModule.withConfig(environment.farmStarUrl),
    RouterModule.forRoot(appRoutes),
    StoreModule.forRoot(fromRoot.reducers, {
      runtimeChecks: environment.storeRuntimeChecks,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: SimpleRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    RouterStoreHelperModule,
    EffectsModule.forRoot([AppEffects]),
    ...environment.modules,
  ],
  providers: [
    nextProvider,
    NextService,
    IdService,
    ImageService,
    FarmStarService,
    FileSaverService,
    UserRoleResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RemoveProjectionTokenInterceptor,
      multi: true,
    },
    AutoSilentRenewOnErrorService,
    TokenInterceptor,
    {
      provide: WindowLocation,
      useValue: location,
    },
    {
      provide: OIDCConfig,
      useFactory: OIDCConfigFactory,
      deps: [WindowLocation],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
