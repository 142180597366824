import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UserRole } from '@app/overview/overview.models';
import { routesNames } from '@app/shared/utils/routes.helper';

@Injectable()
export class NotFarmerOrTechnicianGuard implements CanActivate {
  constructor(public store: Store<fromOverview.OverviewGlobalState>, public router: Router) {}

  findUserRole(): Observable<UserRole> {
    return this.store.pipe(
      select(fromOverview.selectUserRole),
      filter(userRole => userRole != null),
      take(1)
    );
  }

  getRedirectUrl(userRole: UserRole): UrlTree {
    return this.router.createUrlTree(
      [userRole === UserRole.farmer ? `/${routesNames.PARCELS}` : `/${routesNames.FARMS}`],
      { queryParamsHandling: 'preserve' }
    );
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.findUserRole().pipe(
      map((userRole: UserRole) =>
        userRole === UserRole.farmer || userRole === UserRole.technician ? this.getRedirectUrl(userRole) : true
      )
    );
  }
}
