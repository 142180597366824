import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIGURATION, Configuration } from '@app/overview/shared/farm-star/configuration/configuration';
import {
  SpreadingEquipment,
  EmbeddedSpreadingEquipments,
} from '@app/overview/shared/farm-star/spreading-equipment.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpreadingEquipmentApiService {
  public headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  private readonly API = `${this.config.url}/${this.config.api}/fs-core/api`;

  constructor(@Inject(CONFIGURATION) private config: Configuration, private http: HttpClient) {}

  getSpreadingEquipmentForUser(userId: string): Observable<SpreadingEquipment[]> {
    return this.http
      .get<EmbeddedSpreadingEquipments>(`${this.API}/spreadingEquipments`, {
        headers: this.headers,
        params: { 'user.id': userId, sort: 'name' },
      })
      .pipe(map(response => response._embedded.spreadingEquipments));
  }

  postSpreadingEquipment(equipment: SpreadingEquipment): Observable<SpreadingEquipment> {
    return this.http.post<SpreadingEquipment>(`${this.API}/spreadingEquipments`, equipment);
  }

  patchSpreadingEquipment(equipment: SpreadingEquipment): Observable<SpreadingEquipment> {
    return this.http.patch<SpreadingEquipment>(`${this.API}/spreadingEquipments/${equipment.id}`, equipment);
  }

  deleteSpreadingEquipment(equipmentId: string): Observable<void> {
    return this.http.delete<void>(`${this.API}/spreadingEquipments/${equipmentId}`);
  }

  changeMainSpreadingEquipment(equipmentId: string): Observable<void> {
    return this.http.post<void>(
      `${this.API}/spreadingEquipments/changeMain`,
      {},
      { params: new HttpParams().set('equipmentId', equipmentId) }
    );
  }
}
