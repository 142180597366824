import { environment } from '@env/environment';

export function OIDCConfigFactory(location: Location) {
  const baseUrl = `${location.protocol}//${location.host}`;

  return {
    ...environment.oidcConfig,
    redirect_uri: `${baseUrl}/auth-callback/signin`,
    silent_redirect_uri: `${baseUrl}/auth-callback/silent-renew`,
    post_logout_redirect_uri: `${baseUrl}/auth-callback/signout`,
  };
}
