<ng-container *ngIf="errorState != ErrorTypeEnum.NONE">
  <div class="import-database-dialog-header">
    <div class="import-database-dialog-header-title">
      <svg aria-hidden="true"><use href="assets/images/sprites.svg#warning"></use></svg>
      <span>{{ errorState | errorDescription: 'title' }}</span>
    </div>
    <div
      *ngIf="errorState != ErrorTypeEnum.NO_ACTIVE_CAMPAIGN && errorState != ErrorTypeEnum.NO_COOPERATIVE"
      class="import-database-dialog-header-description"
    >
      <span>{{ errorState | errorDescription: 'desc' }}</span>
    </div>
  </div>
</ng-container>
<div class="import-database-dialog-content">
  <ng-container *ngIf="campaigns && cooperatives">
    <form [formGroup]="coopAndCampaignForm">
      <div class="select-container">
        <mat-form-field floatLabel="never">
          <mat-label>Choisir une campagne</mat-label>
          <mat-select formControlName="selectedCampaign" panelClass="panel-class" disableOptionCentering="true">
            <mat-select-trigger>
              <div class="selected-campaign">
                <div>{{ selectedCampaign.value?.label }} - Récolte {{ selectedCampaign.value?.year }}</div>
                <small *ngIf="selectedCampaign.value?.cropsNames">
                  {{ selectedCampaign.value.cropsNames.join(' • ') }}
                </small>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let campaign of campaigns" [value]="campaign">
              <div class="campaign-item">
                <div>{{ campaign.label }} - Récolte {{ campaign.year }}</div>
                <small *ngIf="campaign.cropsNames"> {{ campaign.cropsNames.join(' • ') }} </small>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="never">
          <mat-label>Choisir une coopérative</mat-label>
          <mat-select formControlName="selectedCooperative" panelClass="panel-class" disableOptionCentering="true">
            <mat-select-trigger>
              <div class="selected-cooperative">{{ selectedCooperative.value?.name }}</div>
            </mat-select-trigger>
            <mat-option *ngFor="let cooperative of cooperatives" [value]="cooperative">
              <div class="cooperative-item">{{ cooperative.name }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="choose-file">
        <input type="file" #uploadInput name="file" id="file" (change)="onImport($event)" [accept]="['.zip']" />
        <button mat-raised-button tabindex="-1" (click)="uploadInput.click()">
          <label class="upload-file mat-raised-button">Choisir un fichier</label>
        </button>
        <div *ngIf="file.value" class="file-name">{{ file.value.name }}</div>
      </div>
      <mat-checkbox formControlName="partialImport"> Ingestion partielle </mat-checkbox>
    </form>
  </ng-container>
</div>
<div class="import-database-dialog-footer">
  <button mat-button (click)="onCancel()">Annuler</button>
  <button
    mat-raised-button
    tabindex="-1"
    [disabled]="
      !coopAndCampaignForm.valid || (errorState != ErrorTypeEnum.NONE && errorState != ErrorTypeEnum.DATAFILES_EMPTY)
    "
    (click)="onSubmit()"
  >
    Envoyer
  </button>
</div>
