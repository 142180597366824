import { createAction, props } from '@ngrx/store';
import { Campaign, CampaignStore, CustomerDataCampaign } from '@app/overview/import-database/import-database.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import DataFileItem = farmStarApiModel.DataFileItem;
import { CooperativeStore } from '@app/overview/overview.models';

export const FindCampaignsBySelectedCooperative = createAction(
  '[ImportDatabase] Find campaigns by selected cooperative',
  props<{ cooperative: CooperativeStore }>()
);
export const FindCampaignsWithCropsIds = createAction(
  '[ImportDatabase] Find campaigns with crops ids',
  props<{ campaigns: Campaign[] }>()
);
export const FindCampaignsWithCropsIdsSuccess = createAction(
  '[ImportDatabase] Find campaigns with crops ids success',
  props<{ campaigns: Campaign[] }>()
);
export const SendDatafile = createAction(
  '[ImportDatabase] Send datafile',
  props<{ datafileRequest: farmStarApiModel.DatafileRequest }>()
);
export const SendDatafileSuccess = createAction('[ImportDatabase] Send datafile success');
export const SendDataFileToProduction = createAction(
  '[ImportDatabase] Send datafile for production',
  props<{ datafileId: string }>()
);
export const SendDataFileToProductionSuccess = createAction('[ImportDatabase] Send datafile for production success');
export const SaveCropCampaign = createAction(
  '[ImportDatabase] Save crop campaigns',
  props<{ campaign: CampaignStore; cropName: string }>()
);
export const SaveCropCampaignError = createAction('[ImportDatabase] Save crop campaigns error');
export const SaveCropCampaignSuccess = createAction('[ImportDatabase] Save crop campaigns success');
export const OpenImportDatabaseDialog = createAction('[ImportDatabase] Open import database dialog');
export const LoadDataFileSuccess = createAction(
  '[import-database] Load data file success',
  props<{ customerDataCampaigns: CustomerDataCampaign[] }>()
);
export const IsDatafileListEmpty = createAction(
  '[import-database] Is datafile list empty',
  props<{ isDatafileListEmpty: boolean }>()
);
export const SelectDatafile = createAction(
  '[import-database] Select datafile',
  props<{ customerDataCampaignId: string }>()
);
export const LoadTestReportSuccess = createAction(
  '[import-database] Load test report success',
  props<{ testReport: farmStarApiModel.TestReportItem }>()
);
export const LoadTestReportFailed = createAction('[import-database] Load test report failed');
export const LoadTestErrorReportSuccess = createAction(
  '[import-database] Load test error report',
  props<{ testErrorReport: farmStarApiModel.TestErrorReportItem[] }>()
);
export const LoadImportReportSuccess = createAction(
  '[import-database] Load import report success',
  props<{ importReport: farmStarApiModel.ImportReportItem }>()
);
export const LoadImportReportFailed = createAction('[import-database] Load import report failed');
export const DownloadUploadedDatafile = createAction('[import-database] Download uploaded datafile');
export const DownloadTestErrorReport = createAction('[import-database] Download test error report');
export const ResetDatafiles = createAction('[import-database] Reset datafiles');
export const ResetImportDatabaseData = createAction('[import-database] Reset import database data');
export const OnRequestFailed = createAction('[import-database] On request failed');
export const ReloadDataFile = createAction('[import-database] Reload datafile', props<{ datafileId: string }>());
export const ReloadDataFileSuccess = createAction(
  '[import-database] Reload datafile success',
  props<{ dataFileUpdated: DataFileItem }>()
);
