<ng-container *ngIf="customerData">
  <header class="import-database-details-header">
    <div class="import-database-details-header-top">
      <button mat-button class="import-database-details-header-top-filename" (click)="downloadFile()">
        <svg aria-hidden="true"><use href="assets/images/sprites.svg#download"></use></svg>
        <span>{{ customerData.fileName }}</span>
      </button>
      <div class="import-database-details-header-top-date">
        <span>{{ customerData.uploadedOn | date: 'dd/MM/yyyy' }}</span>
        <span>{{ customerData.uploadedOn | date: 'HH:mm' }}</span>
      </div>
    </div>
    <div class="import-database-details-header-bottom">
      <ng-container [ngSwitch]="customerData.status">
        <ng-template [ngSwitchCase]="datafileStatus.IMPORTED">
          <ng-container
            *ngIf="
              testAndImportStates.testReportState !== LoadingState.NotLoaded &&
              testAndImportStates.importReportState !== LoadingState.NotLoaded
            "
          >
            <ng-container
              *ngIf="
                testAndImportStates.testReportState === LoadingState.Success &&
                  testAndImportStates.importReportState === LoadingState.Success &&
                  testAndImportStates.isValidData;
                else inconsistentData
              "
            >
              <div class="import-database-details-header-bottom-message-status success">
                <svg aria-hidden="true"><use href="assets/images/sprites.svg#success"></use></svg>
                <p>Le chargement des données de votre base est terminé.</p>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #inconsistentData>
            <div class="import-database-details-header-bottom-message-status">
              <svg aria-hidden="true"><use href="assets/images/sprites.svg#warning"></use></svg>
              <p>
                Le chargement des données de votre base est terminé. Nous avons rencontré quelques erreurs techniques.
                Nous analysons le problème pour le corriger au plus tôt.
              </p>
            </div>
          </ng-template>
        </ng-template>
        <ng-template [ngSwitchCase]="datafileStatus.IMPORTING">
          <div class="import-database-details-header-bottom-message-status">
            <svg aria-hidden="true"><use href="assets/images/sprites.svg#waiting"></use></svg>
            <p>Votre base est en cours de chargement.</p>
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="datafileStatus.UPLOADED"></ng-template>
        <ng-template [ngSwitchCase]="datafileStatus.TESTED">
          <ng-container *ngIf="testReport$ | async as testReport">
            <div
              *ngIf="testReport | isValidatedAndRejectedTestedData; else existingValidatedDataWithErrors"
              class="import-database-details-header-bottom-message-status"
            >
              <svg aria-hidden="true"><use href="assets/images/sprites.svg#warning"></use></svg>
              <p>
                Votre base comporte des erreurs, mais vos données valides peuvent être chargées, en cliquant sur &#171;
                <span class="highlighted-text">Charger les données</span> &#187;. Vous pourrez ensuite déposer une
                nouvelle base pour corriger les erreurs.
              </p>
            </div>
            <ng-template #existingValidatedDataWithErrors>
              <div
                *ngIf="!(testReport | isValidatedData); else onlyValidatedData"
                class="import-database-details-header-bottom-message-status"
              >
                <svg aria-hidden="true"><use href="assets/images/sprites.svg#warning"></use></svg>
                <p>
                  Votre base comporte des erreurs, il n'y a aucune donnée valide. Vous pouvez déposer une nouvelle base
                  pour corriger les erreurs.
                </p>
              </div>
            </ng-template>
            <ng-template #onlyValidatedData>
              <div
                *ngIf="!(testReport | isRejectedData)"
                class="import-database-details-header-bottom-message-status success"
              >
                <svg aria-hidden="true"><use href="assets/images/sprites.svg#success"></use></svg>
                <p>
                  Votre base est validée. Vous pouvez charger vos données en cliquant sur &#171; Charger les données
                  &#187;.
                </p>
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template [ngSwitchCase]="datafileStatus.TESTING"> </ng-template>
        <ng-template ngSwitchDefault></ng-template>
      </ng-container>
      <ng-container
        *ngIf="customerData.status != datafileStatus.IMPORTED && customerData.status != datafileStatus.IMPORTING"
      >
        <ng-container *ngIf="customerData.status === datafileStatus.TESTED; else inactiveSendToProductionButton">
          <ng-container *ngIf="testReport$ | async as testReport">
            <button
              *ngIf="(hasMostRecentLoadedDatafile$ | async) === true; else oldDatafile"
              (click)="openImportDialog(customerData)"
              [disabled]="!(testReport | isValidatedData)"
              mat-raised-button
              class="import-database-details-header-bottom-load-data"
            >
              <p>Charger les données</p>
            </button>
            <ng-template #oldDatafile>
              <button
                (click)="tryToLoadOldDatafile()"
                [disabled]="false"
                mat-raised-button
                class="import-database-details-header-bottom-load-data forbidden"
              >
                <p>Charger les données</p>
              </button>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #inactiveSendToProductionButton>
          <button [disabled]="true" mat-raised-button class="import-database-details-header-bottom-load-data">
            <p>Charger les données</p>
          </button>
        </ng-template>
      </ng-container>
    </div>
  </header>
  <ng-container [ngSwitch]="customerData.status">
    <ng-template [ngSwitchCase]="datafileStatus.UPLOADED">
      <ng-container *ngTemplateOutlet="testingDatabaseHeader"></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="datafileStatus.IMPORTED">
      <section class="reports-section">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex">
          <mat-tab label="Bilan des données testées">
            <div class="reports-section-box">
              <ng-container *ngIf="testReport$ | async as testReport">
                <div class="test-report-section">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Total</th>
                        <th>Rejetés</th>
                        <th>Validés</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Technicien</th>
                        <td>{{ testReport.technician.total }}</td>
                        <td>{{ testReport.technician.rejected }}</td>
                        <td>{{ testReport.technician.total - testReport.technician.rejected }}</td>
                      </tr>
                      <tr>
                        <th>Exploitant</th>
                        <td>{{ testReport.farmer.total }}</td>
                        <td>{{ testReport.farmer.rejected }}</td>
                        <td>{{ testReport.farmer.total - testReport.farmer.rejected }}</td>
                      </tr>
                      <tr>
                        <th>Parcelles</th>
                        <td>{{ testReport.parcel.total }}</td>
                        <td>{{ testReport.parcel.rejected }}</td>
                        <td>{{ testReport.parcel.total - testReport.parcel.rejected }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab label="Bilan des données chargées">
            <div class="reports-section-box">
              <ng-container *ngIf="importReport$ | async as importReport">
                <div class="import-report-section">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Ajoutés</th>
                        <th>Supprimés</th>
                        <th>Modifiés</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Technicien</th>
                        <td>{{ importReport.technician.added }}</td>
                        <td>{{ importReport.technician.deleted }}</td>
                        <td>{{ importReport.technician.updated }}</td>
                      </tr>
                      <tr>
                        <th>Exploitant</th>
                        <td>{{ importReport.farmer.added }}</td>
                        <td>{{ importReport.farmer.deleted }}</td>
                        <td>{{ importReport.farmer.updated }}</td>
                      </tr>
                      <tr>
                        <th>Parcelles</th>
                        <td>{{ importReport.parcel.added }}</td>
                        <td>{{ importReport.parcel.deleted }}</td>
                        <td>{{ importReport.parcel.updated }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <ng-container *ngIf="testErrorReport$ | async as testErrorReport">
            <mat-tab *ngIf="testErrorReport.length > 0" label="Messages d’erreur">
              <div class="reports-section-box">
                <fstar-test-error-report [testErrorReport]="testErrorReport"></fstar-test-error-report>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </section>
    </ng-template>
    <ng-template [ngSwitchCase]="datafileStatus.IMPORTING">
      <ng-container
        *ngTemplateOutlet="testedOrImportingDatabase; context: { status: customerData.status }"
      ></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="datafileStatus.TESTED">
      <ng-container
        *ngTemplateOutlet="testedOrImportingDatabase; context: { status: customerData.status }"
      ></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="datafileStatus.TESTING">
      <ng-container *ngTemplateOutlet="testingDatabaseHeader"></ng-container>
    </ng-template>
    <ng-template ngSwitchDefault></ng-template>
  </ng-container>
</ng-container>
<ng-template #testingDatabaseHeader>
  <section class="import-database-details-content">
    <svg aria-hidden="true"><use href="assets/images/sprites.svg#waiting"></use></svg>
    <h1>Votre base est en cours de test</h1>
  </section>
</ng-template>
<ng-template #testedOrImportingDatabase let-status="status">
  <section class="reports-section">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="Bilan des données testées">
        <div class="reports-section-box">
          <ng-container *ngIf="testReport$ | async as testReport">
            <div class="test-report-section">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Total</th>
                    <th>Rejetés</th>
                    <th>Validés</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Technicien</th>
                    <td>{{ testReport.technician.total }}</td>
                    <td>{{ testReport.technician.rejected }}</td>
                    <td>{{ testReport.technician.total - testReport.technician.rejected }}</td>
                  </tr>
                  <tr>
                    <th>Exploitant</th>
                    <td>{{ testReport.farmer.total }}</td>
                    <td>{{ testReport.farmer.rejected }}</td>
                    <td>{{ testReport.farmer.total - testReport.farmer.rejected }}</td>
                  </tr>
                  <tr>
                    <th>Parcelles</th>
                    <td>{{ testReport.parcel.total }}</td>
                    <td>{{ testReport.parcel.rejected }}</td>
                    <td>{{ testReport.parcel.total - testReport.parcel.rejected }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </mat-tab>
      <ng-container *ngIf="testErrorReport$ | async as testErrorReport">
        <mat-tab *ngIf="testErrorReport.length > 0" label="Messages d’erreur">
          <div class="reports-section-box">
            <fstar-test-error-report [testErrorReport]="testErrorReport"></fstar-test-error-report>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </section>
</ng-template>
