import { Component, Input, OnChanges } from '@angular/core';
import { UserRole } from '@app/overview/overview.models';
import { Parcel } from '@app/overview/shared/farm-star/parcel.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { CropCode } from '@app/overview/shared/farm-star/agro-datum.model';
import { CoreModel } from '@app/overview/shared/farm-star/core.model';

@Component({
  selector: 'fstar-parcel-identification',
  templateUrl: './parcel-identification.component.html',
  styleUrls: ['./parcel-identification.component.scss'],
})
export class ParcelIdentificationComponent implements OnChanges {
  @Input() userRole: UserRole;
  @Input() parcel: Parcel;
  @Input() farm: CoreModel.Farm;
  @Input() technician: farmStarApiModel.BasicUserData;

  userRoleEnum = UserRole;
  cropIcon: string;

  constructor() {}

  ngOnChanges() {
    if (this.parcel) {
      this.cropIcon = CropCode.fromParcel(this.parcel).key.toLowerCase();
    }
  }
}
