import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  FindObservationOfPolygon,
  FindObservationOfPolygonSuccess,
  ResetParcelData,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { FeatureBiophy } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { Action, createReducer, on } from '@ngrx/store';

export type State = EntityState<FeatureBiophy>;
export const adapter: EntityAdapter<FeatureBiophy> = createEntityAdapter<FeatureBiophy>({
  selectId: feature => feature.featureId.toString(),
});

const initialState: State = adapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(ResetParcelData, () => ({
    ...initialState,
  })),
  on(FindObservationOfPolygon, (state, { featureId }) =>
    adapter.addOne({ featureId, isLoading: true, isLoaded: false }, state)
  ),
  on(FindObservationOfPolygonSuccess, (state, { featureId, medianBiophy }) =>
    adapter.updateOne({ id: featureId, changes: { isLoading: false, medianBiophy, isLoaded: true } }, state)
  )
);

export function featuresBiophyEntityReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
