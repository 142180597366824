<section>
  <article class="spreading-equipment-header">
    <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#equipments"></use></svg>
    <aside>Mon Matériel</aside>
    <button mat-button (click)="addEquipment()">Ajouter</button>
  </article>
  <article class="spreading-equipment-content">
    <mat-list *ngIf="equipments && equipments.length > 0">
      <mat-list-item *ngFor="let equipment of equipments">
        <div class="equipment-list-item">
          <button mat-button [disabled]="equipment.mainEquipment" (click)="changeMain(equipment)">
            <fa-icon *ngIf="equipment.mainEquipment" [icon]="mainEquipmentIcon"></fa-icon>
            <fa-icon *ngIf="!equipment.mainEquipment" [icon]="equipmentIcon"></fa-icon>
          </button>
          <div class="equipment-labels">
            <div class="equipment-name-label">{{ equipment.name }}</div>
            <div class="equipment-second-line">
              <div class="equipment-console-label" *ngIf="equipment.formapTerminalId">
                {{ equipment.constuructorLabel }} - {{ equipment.consoleLabel }}
              </div>
              <div class="format-label">{{ equipment.fortmatLabel }}</div>
            </div>
          </div>
          <div class="equipment-action-buttons">
            <button (click)="download(equipment)" mat-button matTooltip="Télécharger un fichier de test">
              <fa-icon [icon]="downloadIcon"></fa-icon>
            </button>
            <button (click)="edit(equipment)" mat-button matTooltip="Editer">
              <fa-icon [icon]="editIcon"></fa-icon>
            </button>
            <button (click)="delete(equipment)" mat-button matTooltip="Supprimer">
              <fa-icon [icon]="deleteIcon"></fa-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
    <div class="no-equipments" *ngIf="equipments && equipments.length === 0">
      <svg aria-hidden="true"><use href="assets/images/sprites.svg#warning"></use></svg>
      <div>Vous n'avez pas de matériel enregistré</div>
    </div>
  </article>
  <article class="spreading-equipment-footer">
    <button mat-button (click)="onClose()">Fermer</button>
  </article>
</section>
