import { createAction, props } from '@ngrx/store';
import { SpreadingEquipment } from '../../../../shared/farm-star/spreading-equipment.model';
import { Terminal } from '../../../../shared/farm-star/vra-map.model';

export const FindTerminals = createAction('[SpreadingEquipment] Find Terminals');

export const FindTerminalsSuccess = createAction(
  '[SpreadingEquipment] Find Terminals success',
  props<{ terminals: Terminal[] }>()
);

export const FindSpreadingEquipments = createAction('[SpreadingEquipment] Find SpreadingEquipments');

export const FindSpreadingEquipmentsSuccess = createAction(
  '[SpreadingEquipment] Find SpreadingEquipments success',
  props<{ spreadingEquipments: SpreadingEquipment[] }>()
);

export const createSpreadingEquipment = createAction(
  '[SpreadingEquipment] Post SpreadingEquipments',
  props<{ spreadingEquipment: SpreadingEquipment }>()
);

export const patchSpreadingEquipment = createAction(
  '[SpreadingEquipment] Patch SpreadingEquipments',
  props<{ spreadingEquipment: SpreadingEquipment }>()
);

export const deleteSpreadingEquipment = createAction(
  '[SpreadingEquipment] Delete SpreadingEquipments',
  props<{ equipmentId: string }>()
);

export const changeMainSpreadingEquipment = createAction(
  '[SpreadingEquipment] Change Main SpreadingEquipments',
  props<{ equipmentId: string }>()
);
