import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { CropCode } from '../../farm-star/agro-datum.model';

interface CropRow {
  label: string;
  icon: string;
  code: string;
  count: number;
}

@Component({
  selector: 'fstar-crop-chooser-dialog',
  templateUrl: './crop-chooser-dialog.component.html',
  styleUrls: ['./crop-chooser-dialog.component.scss'],
})
export class CropChooserDialogComponent implements OnInit {
  public crops: CropRow[] = [];
  public selectedCrop: string;
  public totalCount = 0;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { countByCrop: Map<string, number>; currentFilter?: string }) {}

  ngOnInit() {
    this.selectedCrop = this.data.currentFilter || 'all';

    this.crops = CropCode.all()
      .map((code: CropCode) => ({
        label: code.label,
        icon: code.key.toLowerCase(),
        code: code.code,
        count: this.data.countByCrop.get(code.code) || 0,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    this.crops.forEach(({ count }) => (this.totalCount += count));
  }
}
