<button
  mat-icon-button
  [ngClass]="{ 'menu-opened': isMenuOpened, 'menu-not-opened': !isMenuOpened }"
  (click)="clickOnMenu()"
>
  <mat-icon>edit</mat-icon>
</button>
<div class="draw-list" [ngClass]="{ 'draw-list-visible': isMenuOpened, 'draw-list-hidden': !isMenuOpened }">
  <button
    mat-icon-button
    [class.mode-activated]="drawMode === drawModeEnum.polygon"
    (click)="clickOnDrawMode(drawModeEnum.polygon)"
  >
    <mat-icon>border_color</mat-icon>
  </button>
  <mat-divider></mat-divider>
  <button
    mat-icon-button
    [class.mode-activated]="drawMode === drawModeEnum.box"
    (click)="clickOnDrawMode(drawModeEnum.box)"
  >
    <mat-icon>check_box_outline_blank</mat-icon>
  </button>
  <mat-divider></mat-divider>
  <button [disabled]="!selectedFeatureId" mat-icon-button (click)="clickOnDelete()"><mat-icon>delete</mat-icon></button>
</div>
