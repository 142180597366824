<div class="close-dialog">
  <mat-icon (click)="onClose()">close</mat-icon>
</div>

<h3 mat-dialog-title>{{ nitrogenLabelsEnum.DIALOG_TITLE }}</h3>

<mat-dialog-content>
  <div class="total-dose-container">
    <div class="total-dose-grid">
      <div class="total-dose-header">{{ nitrogenLabelsEnum.NEEDS_TITLE }}</div>

      <div class="row-left">{{ nitrogenLabelsEnum.TOTAL_NEED }}</div>
      <div class="row-middle"></div>
      <div class="row-right">
        <ng-container *ngIf="data.agroData?.nitrogenStatus?.totalNeed | isNotNullOrZero; else valueNotDisplayed">
          {{ data.agroData.nitrogenStatus.totalNeed | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div></div>
      <div class="details-row-middle">
        {{ nitrogenLabelsEnum.MEDIAN_YIELD_TARGET }}
        <span>
          <ng-container *ngIf="data.agroData?.medianYieldTarget | isNotNullOrZero; else valueNotDisplayed">
            {{ data?.agroData?.medianYieldTarget | numberFr: oneDecimalFormat }}
          </ng-container>
        </span>
      </div>
      <div class="details-row-right operator-middle">+</div>

      <div class="row-left">{{ nitrogenLabelsEnum.N_CREDIT_YIELD }}</div>
      <div class="row-middle"></div>
      <div class="row-right">
        <ng-container
          *ngIf="data.agroData?.nitrogenStatus?.nitrogenCreditYield | isNotNullOrZero; else valueNotDisplayed"
        >
          {{ data.agroData.nitrogenStatus.nitrogenCreditYield | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div class="total-dose-header">{{ nitrogenLabelsEnum.SUPPLIES_TITLE }}</div>

      <div class="multi-row-left mineralization">
        <div class="mineralization-border"></div>
        <div class="mineralization-title">{{ nitrogenLabelsEnum.MINERALIZATION }}</div>
      </div>

      <div class="multi-row-middle gold-legend multi-row-middle-top">
        {{ nitrogenLabelsEnum.HUMUS_MINERALIZATION_EFFECT }}
      </div>
      <div class="multi-row-right multi-row-right-top">
        <ng-container
          *ngIf="data.agroData?.nitrogenStatus?.humusMineralizationEffect | isNotNullOrZero; else valueNotDisplayed"
        >
          {{ data.agroData.nitrogenStatus.humusMineralizationEffect | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div class="multi-row-middle orange-legend">{{ nitrogenLabelsEnum.PREVIOUS_CROP_MIN_EFFECT }}</div>
      <div class="multi-row-right">
        <ng-container
          *ngIf="data.agroData?.nitrogenStatus?.previousCropMinEffect | isNotNullOrZero; else valueNotDisplayed"
        >
          {{ data.agroData.nitrogenStatus.previousCropMinEffect | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div class="multi-row-middle red-legend">{{ nitrogenLabelsEnum.GRASSLAND_EFFECT }}</div>
      <div class="multi-row-right">
        <ng-container *ngIf="data.agroData?.nitrogenStatus?.grasslandEffect | isNotNullOrZero; else valueNotDisplayed">
          {{ data.agroData.nitrogenStatus.grasslandEffect | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div class="multi-row-middle green-legend multi-row-middle-bottom">
        {{ nitrogenLabelsEnum.CATCH_CROP_EFFECT }}
      </div>
      <div class="multi-row-right multi-row-right-bottom">
        <ng-container *ngIf="data.agroData?.nitrogenStatus?.catchCropEffect | isNotNullOrZero; else valueNotDisplayed">
          {{ data.agroData.nitrogenStatus.catchCropEffect | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div class="multi-row-left other-supplies">
        <div class="other-supplies-border"></div>
        <div class="other-supplies-title">{{ nitrogenLabelsEnum.OTHER_SUPPLIES }}</div>
      </div>

      <div class="multi-row-middle grey-legend multi-row-middle-top">{{ nitrogenLabelsEnum.IRRIGATION_EFFECT }}</div>
      <div class="multi-row-right multi-row-right-top">
        <ng-container *ngIf="data.agroData?.nitrogenStatus?.irrigationEffect | isNotNullOrZero; else valueNotDisplayed">
          {{ data.agroData.nitrogenStatus.irrigationEffect | numberFr: noDecimalFormat }}
        </ng-container>
      </div>

      <div class="multi-row-middle blue-legend multi-row-middle-bottom">
        {{ nitrogenLabelsEnum.N_CREDIT_BALANCE_BEGINNING }}
      </div>
      <div
        class="multi-row-right multi-row-right-bottom"
        *ngIf="
          data.agroData?.nitrogenStatus?.actualMeasuredNitrogenCredit | isNotNullOrZero;
          else nitrogenCreditBalanceBeginningBottom
        "
      >
        {{ data.agroData.nitrogenStatus.actualMeasuredNitrogenCredit | numberFr: noDecimalFormat }}
      </div>

      <ng-template #nitrogenCreditBalanceBeginningBottom>
        <div class="multi-row-right multi-row-right-bottom">
          <ng-container
            *ngIf="
              data.agroData?.nitrogenStatus?.nitrogenCreditBalanceBeginning | isNotNullOrZero;
              else valueNotDisplayed
            "
          >
            {{ data.agroData.nitrogenStatus.nitrogenCreditBalanceBeginning | numberFr: noDecimalFormat }}
          </ng-container>
        </div>
      </ng-template>

      <div class="sub-total"></div>
      <div class="row-right-without-border equal-prefix">{{ subTotal | numberFr: noDecimalFormat }}</div>

      <div class="details-row-span-left-middle">
        {{ nitrogenLabelsEnum.WORKING_COEFFICIENT }} <b>{{ nitrogenLabelsEnum.WORKING_COEFFICIENT_ACRONYM }}</b>
      </div>

      <div
        class="row-right-without-border"
        [class.divider-prefix]="data.agroData?.nitrogenStatus?.workingCoefficient !== null"
      >
        <ng-container
          *ngIf="data.agroData?.nitrogenStatus?.workingCoefficient | isNotNullOrZero; else valueNotDisplayed"
        >
          {{ data.agroData.nitrogenStatus.workingCoefficient | number: '1.1-2' }}
        </ng-container>
      </div>

      <div class="multi-row-left organic-waste-effect">
        <div class="organic-waste-effect-border"></div>
        <div class="organic-waste-effect-title">{{ nitrogenLabelsEnum.ORGANIC_WASTE_PRODUCTS }}</div>
      </div>
      <div class="multi-row-middle black-legend multi-row-middle-single">
        {{ nitrogenLabelsEnum.ORGANIC_WASTE_EFFECT }}
      </div>
      <div
        class="multi-row-right multi-row-right-bottom"
        [class.minus-prefix]="data.agroData?.nitrogenStatus?.organicWasteEffect !== null"
      >
        <ng-container
          *ngIf="data.agroData?.nitrogenStatus?.organicWasteEffect | isNotNullOrZero; else valueNotDisplayed"
        >
          {{ data.agroData.nitrogenStatus.organicWasteEffect | numberFr: noDecimalFormat }}
        </ng-container>
      </div>
      <div></div>
      <div></div>
      <div class="operator-footer">=</div>

      <div></div>
      <div></div>
      <div class="projected-dose">
        {{ data?.agroData?.nitrogenStatus?.projectedDose | numberFr: noDecimalFormat }}
      </div>
    </div>
    <div class="total-dose-charts">
      <div class="charts-header">{{ nitrogenLabelsEnum.SUPPLIES_TITLE }}</div>
      <div class="global">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="globalChartOptions"
          (chartInstance)="onChartInit($event)"
        ></highcharts-chart>
      </div>
      <div class="details">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="detailsChartOptions"
          (chartInstance)="onChartInit($event)"
        ></highcharts-chart>
      </div>
    </div>
  </div>
</mat-dialog-content>
<ng-template #valueNotDisplayed>
  <div class="value-not-displayed">Non renseigné</div>
</ng-template>
