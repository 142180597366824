import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import { ParcelWithAdvices } from '../../shared/farm-star/parcel.model';

export namespace fromParcelEntity {
  export const ParcelsAdapter: EntityAdapter<ParcelWithAdvices> = createEntityAdapter<ParcelWithAdvices>({
    selectId: parcel => parcel.id,
  });

  export interface State extends EntityState<ParcelWithAdvices> {
    hoveredId: string;
    selectedId: string;
    isLoadingAdvices: boolean;
    areStagesLoaded: boolean;
  }

  export const initialState: State = ParcelsAdapter.getInitialState({
    hoveredId: null,
    selectedId: null,
    isLoadingAdvices: false,
    areStagesLoaded: false,
  });

  export function reducer(state = initialState, action: ParcelsViewerActions.All): State {
    switch (action.type) {
      case ParcelsViewerActions.Types.FindFieldsWithLoader:
        return {
          ...initialState,
          areStagesLoaded: false,
        };
      case ParcelsViewerActions.Types.FindFieldsWithLoaderSuccess:
        return {
          ...ParcelsAdapter.addAll(action.fields, state),
          hoveredId: null,
          selectedId: null,
        };
      case ParcelsViewerActions.Types.HoverFeature:
        return {
          ...state,
          hoveredId: action.id,
        };
      case ParcelsViewerActions.Types.SelectFeature:
        return {
          ...state,
          selectedId: action.id,
          hoveredId: null,
        };
      case ParcelsViewerActions.Types.BindParcelStageEstimate:
        return {
          ...state,
          isLoadingAdvices: true,
          areStagesLoaded: false,
        };
      case ParcelsViewerActions.Types.BindParcelStageEstimateSuccess:
        return {
          ...ParcelsAdapter.updateMany(
            action.currentStageByParcelsIds.map(s => ({
              id: s.parcelId,
              changes: { currentStageCode: s.currentStageCode, isHarvestStage: s.isHarvestStage },
            })),
            state
          ),
          isLoadingAdvices: false,
          areStagesLoaded: true,
        };
      case ParcelsViewerActions.Types.UpdateParcelAgroData:
        return {
          ...ParcelsAdapter.updateOne(
            {
              id: action.parcel.id,
              changes: { agroData: action.parcel.agroData },
            },
            state
          ),
        };
      default: {
        return state;
      }
    }
  }
}
