import { Parcel } from '@app/overview/shared/farm-star/parcel.model';
import { ParcelTooltip } from '@app/overview/shared/parcels-map.models';
import { GeoJSON } from 'ol/format';
import { proj3857, proj4326 } from '@app/overview/parcels-viewer/shared/utils';
import { Polygon } from 'ol/geom';
import { centerOfMass } from '@turf/turf';
import { polygon } from '@turf/helpers';

export function featureToToolTip(feature: Parcel | null): ParcelTooltip | null {
  const geoJsonFormat = new GeoJSON();
  if (!feature) {
    return null;
  }
  const featureCoordinates = polygon(
    (geoJsonFormat.readGeometry(feature.aoi.polygon.geometry, {
      dataProjection: proj4326,
      featureProjection: proj3857,
    }) as Polygon).getCoordinates()
  );
  const centeredPoint = centerOfMass(featureCoordinates);

  return {
    id: feature.id,
    center: [centeredPoint.geometry.coordinates[0], centeredPoint.geometry.coordinates[1]],
    name: feature.name,
    numSaisi: feature.code,
  };
}
