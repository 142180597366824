import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { map, take } from 'rxjs/operators';
import { AppInitSuccess } from '@app/app.action';

@Injectable()
export class UserRoleResolver implements Resolve<boolean> {
  constructor(private actions: ActionsSubject) {}

  resolve(): Observable<boolean> {
    return this.actions.pipe(
      ofType(AppInitSuccess),
      take(1),
      map(() => true)
    );
  }
}
