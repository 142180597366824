<fstar-overlay-loader *ngIf="featureBiophy && featureBiophy.isLoading" [@fadeInOutTrans]></fstar-overlay-loader>
<div class="tooltip-feature">
  <div class="icon"><mat-icon>info</mat-icon></div>
  <svg class="area-img" [fstarFromGeoJson]="featureArea.geometry" size="30"></svg>
  <div class="content">
    <div class="info-container">
      <span class="info-label">Surface :</span> <span class="info-value">{{ featureArea.area }}</span>
    </div>
    <div class="info-container">
      <span class="info-label">LAI médian :</span
      ><span class="info-value" *ngIf="featureBiophy && featureBiophy.isLoaded">{{
        featureBiophy.medianBiophy ? featureBiophy.medianBiophy : 'couverture nuageuse'
      }}</span>
    </div>
  </div>
</div>
