import { Component } from '@angular/core';

@Component({
  selector: 'fstar-import-database',
  templateUrl: './import-database.component.html',
  styleUrls: ['./import-database.component.scss'],
})
export class ImportDatabaseComponent {
  constructor() {}
}
