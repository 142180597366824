import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;

export interface CooperativeStore {
  id?: string;
  code?: string;
  name?: string;
  urlRegisteredCooperatives?: string;
  urlLogo?: string;
}

export enum UserRole {
  farmer = 'FARMER',
  technician = 'TECHNICIAN',
  coordinator = 'COORDINATOR',
  otherUsers = 'OTHER_USERS',
  customerSupport = 'CUSTOMER_SUPPORT',
  superAdmin = 'SUPER_ADMIN',
  admin = 'ADMIN',
  expert = 'EXPERT',
  projectManager = 'PROJECT_MANAGER',
  agroInstitute = 'AGRO_INSTITUTE',
  regionalEngineer = 'REGIONAL_ENGINEER',
}

export const UserRoleTranslate = {
  [UserRole.farmer]: 'Exploitant',
  [UserRole.technician]: 'Technicien',
  [UserRole.coordinator]: 'Coordinateur',
  [UserRole.customerSupport]: 'Service client',
  [UserRole.superAdmin]: 'ADMIN',
  [UserRole.admin]: 'ADMIN',
  [UserRole.expert]: 'EXPERT',
  [UserRole.projectManager]: 'PROJECT_MANAGER',
  [UserRole.agroInstitute]: 'AGRO_INSTITUTE',
  [UserRole.regionalEngineer]: 'REGIONAL_ENGINEER',
};

export const svgByPhenologicalStageCode = {
  [PhenologicalStageCode.seedling]: 'assets/images/sprites-stages.svg#seedling',
  [PhenologicalStageCode.emergence]: 'assets/images/sprites-stages.svg#leaf',
  [PhenologicalStageCode.fourLeaves]: 'assets/images/sprites-stages.svg#leaf',
  [PhenologicalStageCode.sixLeaves]: 'assets/images/sprites-stages.svg#leaf',
  [PhenologicalStageCode.eightLeaves]: 'assets/images/sprites-stages.svg#leaf',
  [PhenologicalStageCode.tenLeaves]: 'assets/images/sprites-stages.svg#leaf',
  [PhenologicalStageCode.bloom]: 'assets/images/sprites-stages.svg#bloom',
  [PhenologicalStageCode.humidity50]: 'assets/images/sprites-stages.svg#corn',
  [PhenologicalStageCode.humidity45]: 'assets/images/sprites-stages.svg#corn',
  [PhenologicalStageCode.humidity35]: 'assets/images/sprites-stages.svg#corn',
  [PhenologicalStageCode.humidity32]: 'assets/images/sprites-stages.svg#corn',
  [PhenologicalStageCode.dryMatter35]: 'assets/images/sprites-stages.svg#straw',
  [PhenologicalStageCode.dryMatter32]: 'assets/images/sprites-stages.svg#straw',
};

export interface FilteredFarm {
  id: string;
  corporateName: string;
}
