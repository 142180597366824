import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  entityErrorTypeView,
  errorLevelView,
  TestErrorReportViewElement,
} from '@app/overview/import-database/import-database.model';
import { Store } from '@ngrx/store';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';

const HEADERS = {
  level: 'Type',
  entityCode: 'Identifiant',
  entityType: 'Objet',
  field: 'Champ',
  message: 'Message',
};

@Component({
  selector: 'fstar-test-error-report',
  templateUrl: './test-error-report.component.html',
  styleUrls: ['./test-error-report.component.scss'],
})
export class TestErrorReportComponent implements OnChanges {
  @Input() testErrorReport: TestErrorReportViewElement[];

  public displayedColumnsTestErrorReport: string[] = Object.keys(HEADERS);
  public readonly headers = HEADERS;
  public filterOpened = false;
  public testErrorReportFilterGroup: FormGroup;
  public errorsLevelArray: FormArray;
  public errorsEntityTypeArray: FormArray;
  filteredTestErrorReport: TestErrorReportViewElement[] = [];

  constructor(private store: Store<fromImportDatabase.GlobalState>, private fb: FormBuilder) {
    this.errorsLevelArray = this.fb.array(
      Object.keys(errorLevelView).map(key =>
        this.fb.group({
          value: errorLevelView[key],
          selected: this.fb.control(true),
        })
      )
    );

    this.errorsEntityTypeArray = this.fb.array(
      Object.keys(entityErrorTypeView).map(key =>
        this.fb.group({
          value: entityErrorTypeView[key],
          selected: this.fb.control(true),
        })
      )
    );

    this.testErrorReportFilterGroup = this.fb.group({
      errorsLevel: this.errorsLevelArray,
      errorsEntityType: this.errorsEntityTypeArray,
    });
  }

  ngOnChanges() {
    this.filteredTestErrorReport = this.testErrorReport;
  }

  onFilterClick() {
    this.filterOpened = !this.filterOpened;
  }

  onFiltersChange() {
    const errorLevelFilters = this.testErrorReportFilterGroup
      .get('errorsLevel')
      .value.filter((v: any) => v.selected)
      .map((v: any) => v.value);
    const errorEntityTypeFilter = this.testErrorReportFilterGroup
      .get('errorsEntityType')
      .value.filter((v: any) => v.selected)
      .map((v: any) => v.value);

    const hasLevelFilter =
      errorLevelFilters.length > 0 && errorLevelFilters.length !== Object.keys(errorLevelView).length;

    const hasEntityTypeFilter =
      errorEntityTypeFilter.length > 0 && errorEntityTypeFilter.length !== Object.keys(entityErrorTypeView).length;

    this.filteredTestErrorReport =
      hasLevelFilter || hasEntityTypeFilter
        ? this.testErrorReport
            .filter(error => (hasLevelFilter ? errorLevelFilters.includes(error.level) : true))
            .filter(error => (hasEntityTypeFilter ? errorEntityTypeFilter.includes(error.entityType) : true))
        : this.testErrorReport;
  }

  downloadFile() {
    this.store.dispatch(ImportDatabaseActions.DownloadTestErrorReport());
  }
}
