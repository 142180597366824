import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { ofRoute, oneOfRoutes, routesNames, routesRegex } from '@app/shared/utils/routes.helper';
import { select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { CooperativeStore, UserRole } from '@app/overview/overview.models';
import { of } from 'rxjs';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { HIDE_PARCEL_DETAILS_HELP, HideParcelDetailsHelpStatus } from '@app/shared/local-storage';
import * as OverviewActions from '@app/overview/overview.actions';
import { DisplaySnackbarError } from '@app/app.action';
import { CoreApiService } from '@app/shared/api/core/core-api.service';
import { RouterNavigateAction, RouterStateUrl } from '@davinkevin/router-store-helper';
import { CoreModel } from './shared/farm-star/core.model';
import { RegisteredUserWithRefs } from './shared/farm-star/farmer.model';
import { get } from 'lodash-es';
import { CoreApiModel } from '@app/shared/api/core/core-api.model';

@Injectable()
export class OverviewEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromOverview.OverviewGlobalState>,
    private coreApiService: CoreApiService
  ) {}

  hideParcelDetailsHelp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OverviewActions.HideParcelDetailsHelp),
        tap(({ status }) => {
          localStorage.setItem(HIDE_PARCEL_DETAILS_HELP, status);
          return;
        })
      ),
    { dispatch: false }
  );

  clearLocalStorageHelp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OverviewActions.ClearLocalStorageHelp),
        filter(
          () =>
            localStorage.getItem(HIDE_PARCEL_DETAILS_HELP) &&
            localStorage.getItem(HIDE_PARCEL_DETAILS_HELP) === HideParcelDetailsHelpStatus.CURRENT_SESSION
        ),
        tap(() => {
          localStorage.removeItem(HIDE_PARCEL_DETAILS_HELP);
          return;
        })
      ),
    { dispatch: false }
  );

  filterDatafilesWhenCooperativeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute(routesRegex.IMPORT_DATABASE),
      switchMap(() =>
        this.actions$.pipe(
          ofType(OverviewActions.SelectCooperative),
          takeUntil(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
          map(({ cooperative }) => cooperative)
        )
      ),
      flatMap((cooperative: CooperativeStore) => [
        ImportDatabaseActions.ResetDatafiles(),
        ImportDatabaseActions.ResetImportDatabaseData(),
        ImportDatabaseActions.FindCampaignsBySelectedCooperative({ cooperative }),
      ])
    )
  );

  findFarmsWhenCooperativeChanges$ = createEffect(() =>
    this.actions$.pipe(
      oneOfRoutes([
        routesRegex.FARMS,
        routesRegex.FARMS_PARCELS,
        routesRegex.FARMS_PARCELS_DETAILS,
        routesRegex.COMPARE,
        routesRegex.TAB_FARMS,
        routesRegex.TAB_FARMS_WITH_ID,
      ]),
      withLatestFrom(this.store.pipe(select(fromOverview.selectUserRole))),
      filter(([, userRole]: [any, UserRole]) => userRole !== UserRole.technician && userRole !== UserRole.farmer),
      switchMap(() =>
        this.actions$.pipe(
          ofType(OverviewActions.SelectCooperative),
          filter(cooperative => cooperative != null)
        )
      ),
      map(() => OverviewActions.TriggerFindFarmsByCooperative())
    )
  );

  triggerFindFarmsByCooperative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.TriggerFindFarmsByCooperative),
      withLatestFrom(this.store.pipe(select(fromOverview.selectSelectedCooperative))),
      filter(([, cooperative]: [any, CooperativeStore]) => cooperative != null),
      concatMap(([, cooperative]: [any, CooperativeStore]) =>
        this.coreApiService.getFarms({ 'cooperative.id': cooperative.id }).pipe(
          map(farms => OverviewActions.FindFarmsSuccess({ farms })),
          catchError(() => of(OverviewActions.FindFarmsError()))
        )
      )
    )
  );

  findCooperativeCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCooperativeCampaigns),
      concatMap(({ cooperativeId }) =>
        this.coreApiService.getCampaignsFromCooperative(cooperativeId).pipe(
          map((campaigns: CoreModel.CampaignBackend[]) =>
            OverviewActions.UpdateCampaignDataPublicationStatus({ campaigns })
          ),
          catchError(() =>
            of(
              OverviewActions.ClearCampaignDataPublicationStatus(),
              DisplaySnackbarError({ errorMessage: 'Une erreur est survenue lors de la récupération des campagnes.' })
            )
          )
        )
      )
    )
  );

  findFarmsByTechnicianOrFarmerWhenNavigate$ = createEffect(() =>
    this.actions$.pipe(
      oneOfRoutes([
        routesRegex.FARMS,
        routesRegex.FARMS_PARCELS,
        routesRegex.FARMS_PARCELS_DETAILS,
        routesRegex.COMPARE,
        routesRegex.TAB_FARMS,
        routesRegex.TAB_FARMS_WITH_ID,
      ]),
      withLatestFrom(
        this.store.pipe(select(fromOverview.selectRegisteredUsers)),
        this.store.pipe(select(fromOverview.selectUserRole))
      ),
      filter(
        ([, registeredUsers, userRole]: [any, RegisteredUserWithRefs[], UserRole]) =>
          registeredUsers.length && (userRole === UserRole.technician || userRole === UserRole.farmer)
      ),
      take(1),
      map(([, registeredUsers]: [any, RegisteredUserWithRefs[], UserRole]) =>
        OverviewActions.FindFarmsFromRegisteredUsers({ registeredUsers })
      )
    )
  );

  findFarmsByCooperativeWhenNavigate$ = createEffect(() =>
    this.actions$.pipe(
      oneOfRoutes([
        routesRegex.FARMS,
        routesRegex.FARMS_PARCELS,
        routesRegex.FARMS_PARCELS_DETAILS,
        routesRegex.COMPARE,
        routesRegex.TAB_FARMS,
        routesRegex.TAB_FARMS_WITH_ID,
      ]),
      withLatestFrom(this.store.pipe(select(fromOverview.selectUserRole))),
      filter(([, userRole]: [any, UserRole]) => userRole !== UserRole.technician && userRole !== UserRole.farmer),
      take(1),
      map(() => OverviewActions.TriggerFindFarmsByCooperative())
    )
  );

  findFarmsFromRegisteredUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindFarmsFromRegisteredUsers),
      concatMap(({ registeredUsers }) =>
        this.coreApiService.getFarms({ id: registeredUsers.map(ru => ru.farm.id) }).pipe(
          map(farms => OverviewActions.FindFarmsSuccess({ farms })),
          catchError(() => of(OverviewActions.FindFarmsError()))
        )
      )
    )
  );

  findFarmsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindFarmsSuccess),
      filter(({ farms }) => farms.length > 0),
      concatMap(({ farms }) =>
        this.coreApiService.findFarmCenters(farms.map(f => f.id)).pipe(
          map((farmCenters: CoreApiModel.FarmCenters) => {
            const updatedFarms = farms.map(farm => ({
              ...farm,
              coordinates: get(farmCenters[farm.id], 'geometry.coordinates', []),
            }));
            return OverviewActions.FindFarmInfoSuccess({ farms: updatedFarms });
          }),
          catchError(() => of(OverviewActions.FindFarmInfoError()))
        )
      )
    )
  );

  hideFarmsFeaturesWhenFarmsListEmpty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindFarmsSuccess),
      filter(({ farms }) => farms.length === 0),
      map(() => OverviewActions.ToggleFarmsFeatures({ showFarmsFeatures: false }))
    )
  );

  hideFarmsFeaturesWhenSomeFarmHasNoParcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindFarmInfoSuccess),
      filter(({ farms }) => farms.some(f => f.coordinates.length === 0)),
      map(() => OverviewActions.ToggleFarmsFeatures({ showFarmsFeatures: false }))
    )
  );

  showFarmsFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindFarmsSuccess),
      filter(({ farms }) => farms.length > 0),
      map(() => OverviewActions.ToggleFarmsFeatures({ showFarmsFeatures: true }))
    )
  );

  selectFarmFromUrlIfFarmExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindFarmsSuccess),
      withLatestFrom(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
      filter(([_, router]: [{}, RouterNavigationAction<RouterStateUrl>]) =>
        router.payload.routerState.url.includes(`/${routesNames.FARMS}/`)
      ),
      flatMap(([action, router]: [{ farms: CoreModel.FarmBackend[] }, RouterNavigationAction<RouterStateUrl>]) => {
        return action.farms.some(f => f.id === router.payload.routerState.params.farmId)
          ? [
              // Dispatch ChangeCurrentFarm again to handle page reload
              OverviewActions.ChangeCurrentFarm({ farmId: router.payload.routerState.params.farmId }),
              new RouterNavigateAction([router.payload.routerState.url.split('?')[0]], {
                queryParamsHandling: 'preserve',
              }),
            ]
          : [new RouterNavigateAction([`/${routesNames.FARMS}`], { queryParamsHandling: 'preserve' })];
      })
    )
  );

  prepareFarmSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.PrepareFarmSelection),
      filter(({ farmId }) => farmId != null),
      flatMap(({ farmId }) => [
        OverviewActions.ChangeCurrentFarm({ farmId }),
        new ParcelsViewerActions.ShowParcelsFeatures(),
        new ParcelsViewerActions.FindFieldsWithLoader(),
        new RouterNavigateAction([`/${routesNames.FARMS}/${farmId}/${routesNames.PARCELS}`], {
          queryParamsHandling: 'preserve',
        }),
      ])
    )
  );

  findFarmCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCampaigns),
      mergeMap(({ campaignIds }) => {
        return this.coreApiService.getCampaigns(campaignIds).pipe(
          map((campaigns: CoreModel.CampaignBackend[]) =>
            OverviewActions.UpdateCampaignDataPublicationStatus({ campaigns })
          ),
          catchError(() =>
            of(
              OverviewActions.ClearCampaignDataPublicationStatus(),
              DisplaySnackbarError({
                errorMessage: 'Une erreur est survenue lors de la récupération des campagnes.',
              })
            )
          )
        );
      })
    )
  );
}
