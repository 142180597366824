import { ActionReducerMap, createFeatureSelector, createSelector, createReducer, on } from '@ngrx/store';
import * as fromCampaignsEntity from '@app/overview/import-database/entities/campaigns-entity.reducers';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import {
  CustomerDataCampaign,
  entityErrorTypeView,
  errorLevelView,
  TestErrorReportViewElement,
  Campaign,
} from '@app/overview/import-database/import-database.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { getAddedAndImportedDataCount, getValidatedDataCount } from '@app/overview/import-database/core/reports-checks';
import * as fromDatafilesEntity from '@app/overview/import-database/entities/datafiles-entity.reducers';
import { Dictionary } from '@ngrx/entity';

export namespace fromImportDatabase {
  export interface GlobalState {
    campaigns: fromCampaignsEntity.State;
    datafiles: fromDatafilesEntity.State;
    importDatabase: State;
  }

  export enum LoadingState {
    NotLoaded,
    Success,
    Error,
  }

  export interface State {
    isDatafileListEmpty: boolean;
    testReport: farmStarApiModel.TestReportItem;
    testErrorReport: farmStarApiModel.TestErrorReportItem[];
    importReport: farmStarApiModel.ImportReportItem;
    selectedDatafileId: string;
    testReportState: LoadingState;
    importReportState: LoadingState;
  }

  const initialState: State = {
    isDatafileListEmpty: true,
    testReport: null,
    importReport: null,
    testErrorReport: [],
    selectedDatafileId: null,
    testReportState: LoadingState.NotLoaded,
    importReportState: LoadingState.NotLoaded,
  };

  const reducer = createReducer(
    initialState,
    on(ImportDatabaseActions.ResetImportDatabaseData, _ => ({ ...initialState })),
    on(ImportDatabaseActions.IsDatafileListEmpty, (state, { isDatafileListEmpty }) => ({
      ...state,
      isDatafileListEmpty,
    })),
    on(ImportDatabaseActions.SelectDatafile, (state, { customerDataCampaignId }) => ({
      ...state,
      testErrorReport: [],
      testReport: null,
      selectedDatafileId: customerDataCampaignId,
      importReport: null,
      testReportState: LoadingState.NotLoaded,
      importReportState: LoadingState.NotLoaded,
    })),
    on(ImportDatabaseActions.LoadTestReportSuccess, (state, { testReport }) => ({
      ...state,
      testReport,
      testReportState: LoadingState.Success,
    })),
    on(ImportDatabaseActions.LoadTestReportFailed, state => ({
      ...state,
      testReportState: LoadingState.Error,
    })),
    on(ImportDatabaseActions.LoadImportReportSuccess, (state, { importReport }) => ({
      ...state,
      importReport,
      importReportState: LoadingState.Success,
    })),
    on(ImportDatabaseActions.LoadImportReportFailed, state => ({
      ...state,
      importReportState: LoadingState.Error,
    })),
    on(ImportDatabaseActions.LoadTestErrorReportSuccess, (state, { testErrorReport }) => ({
      ...state,
      testErrorReport,
    }))
  );

  export const reducers: ActionReducerMap<GlobalState> = {
    campaigns: fromCampaignsEntity.reducer,
    datafiles: fromDatafilesEntity.reducer,
    importDatabase: reducer,
  };

  export const getImportDatabaseGlobalState = createFeatureSelector<GlobalState>('import-database');

  const getCampaignsEntitiesState = createSelector(getImportDatabaseGlobalState, state => state.campaigns);
  export const { selectAll: selectAllCampaigns } = fromCampaignsEntity.adapter.getSelectors(getCampaignsEntitiesState);
  export const selectCampaignsActivated = createSelector(selectAllCampaigns, (campaigns: Campaign[]) =>
    campaigns.filter(c => c.active)
  );

  const getDatafilesEntitiesState = createSelector(getImportDatabaseGlobalState, state => state.datafiles);

  export const {
    selectAll: selectCustomerDataCampaigns,
    selectEntities: selectCustomerDataCampaignsEntities,
  } = fromDatafilesEntity.adapter.getSelectors(getDatafilesEntitiesState);

  const getImportDatabaseState = createSelector(getImportDatabaseGlobalState, state => state.importDatabase);

  export const selectIsDatafileListEmpty = createSelector(getImportDatabaseState, state => state.isDatafileListEmpty);
  const selectSelectedCustomerDatafileId = createSelector(getImportDatabaseState, state => state.selectedDatafileId);
  export const selectSelectedCustomerDatafile = createSelector(
    selectCustomerDataCampaignsEntities,
    selectSelectedCustomerDatafileId,
    (data, id) => (id ? data[id] : null)
  );

  export const selectHasMostRecentLoadedDatafile = createSelector(
    selectCustomerDataCampaigns,
    selectCustomerDataCampaignsEntities,
    selectSelectedCustomerDatafileId,
    (dataArray: CustomerDataCampaign[], dataArrayEntities: Dictionary<CustomerDataCampaign>, id: string) => {
      const sortedByDateDatafile = dataArray.sort(
        (a: CustomerDataCampaign, b: CustomerDataCampaign) =>
          new Date(b.uploadedOn).getTime() - new Date(a.uploadedOn).getTime()
      );
      if (sortedByDateDatafile[0].id === id) {
        return true;
      } else {
        return sortedByDateDatafile
          .filter(data => new Date(data.uploadedOn).getTime() > new Date(dataArrayEntities[id].uploadedOn).getTime())
          .some(
            data =>
              data.status !== farmStarApiModel.DatafileStatus.IMPORTED &&
              data.status !== farmStarApiModel.DatafileStatus.IMPORTING
          );
      }
    }
  );
  export const selectTestReport = createSelector(getImportDatabaseState, state => state.testReport);
  export const selectTestErrorReport = createSelector(getImportDatabaseState, state =>
    state.testErrorReport.map(t => convertToTestReportViewElement(t))
  );

  function convertToTestReportViewElement(
    testReport: farmStarApiModel.TestErrorReportItem
  ): TestErrorReportViewElement {
    return {
      level: errorLevelView[testReport.level],
      entityCode: testReport.entityCode,
      entityType: entityErrorTypeView[testReport.entityType],
      field: testReport.field,
      message: testReport.message,
    };
  }

  export const selectImportReport = createSelector(getImportDatabaseState, state => state.importReport);
  export const selectIsLoading = createSelector(getDatafilesEntitiesState, state => state.isLoading);
  export const selectTestAndImportStates = createSelector(getImportDatabaseState, state => ({
    testReportState: state.testReportState,
    importReportState: state.importReportState,
    isValidData:
      state.testReportState === LoadingState.Success &&
      state.importReportState === LoadingState.Success &&
      getValidatedDataCount(state.testReport) === getAddedAndImportedDataCount(state.importReport),
  }));
}
