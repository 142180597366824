<section class="user">
  <article class="user-header">
    <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#subscription"></use></svg>
    <aside>Mon Profil</aside>
    <div class="close-dialog"><mat-icon (click)="onClose()">close</mat-icon></div>
  </article>
  <article class="user-personal-info">
    <div class="name-and-telephone">
      <div class="name">
        <mat-icon>gite</mat-icon>
        <div class="name-label">
          <p>Nom</p>
          <p>Prénom</p>
        </div>
        <div class="name-value">
          <p>{{ data.userData.lastName }}</p>
          <p>{{ data.userData.firstName }}</p>
        </div>
      </div>
      <div class="telephone">
        <mat-icon>call</mat-icon>
        <div class="telephone-numbers">
          <p *ngIf="data.userData.phone1">{{ data.userData.phone1 }}</p>
          <p *ngIf="data.userData.phone2">{{ data.userData.phone2 }}</p>
        </div>
      </div>
    </div>
    <div class="mail">
      <mat-icon>email</mat-icon>
      <p>{{ data.userData.email }}</p>
    </div>
    <div class="address" *ngIf="data.userRole === userRole.farmer || data.userRole === userRole.technician">
      <mat-icon>room</mat-icon>
      <p>{{ data.userData.adress }} {{ data.userData.zipCode }} {{ data.userData.city }}</p>
    </div>
  </article>
  <article class="user-administration-info" *ngIf="isFarmer(data)">
    <div class="business-id">
      <p>Numéro SIRET</p>
      <p>{{ data.userData.siret }}</p>
    </div>
    <div class="company-name">
      <p>Raison Sociale</p>
      <p>{{ data.userData.corporateName }}</p>
    </div>
  </article>
</section>
<section class="user-footer">
  <div class="pdf-files">
    <a target="_blank" [href]="cguLink">CGU</a> <a target="_blank" [href]="rgpdLink">Données personnelles</a>
  </div>
</section>
