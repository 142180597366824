<div class="wrapper">
  <header>
    <img class="brand-logo" src="assets/images/farmstar_logo.svg" />
  </header>
  <div class="content">
    <div class="error-label" *ngIf="(isErrorOnAppInit$ | async) === true && !showLoader" [@fadeInOutTrans]>
      Une erreur est survenue pendant la récupération de vos données. Merci de vous reconnecter ultérieurement.
    </div>
    <div
      class="signout-button"
      *ngIf="(isErrorOnAppInit$ | async) === true && !showLoader"
      [@fadeInOutTrans]
      (click)="logout()"
    >
      <button mat-stroked-button>Me reconnecter</button>
    </div>
    <div class="loader" *ngIf="showLoader" [@fadeInOut]="loaderState" (@fadeInOut.done)="animationDone($event)"></div>
  </div>
</div>
