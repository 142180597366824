<mat-toolbar color="primary">
  <img class="brand-logo" src="assets/images/farmstar_logo.svg" />
  <ng-container *ngIf="selectedCooperative; else chooseCooperative">
    <div
      class="cooperative-section multi-cooperatives"
      *ngIf="cooperatives.length > 1; else oneCooperative"
      [fretPopupOverlay]="cooperativePopup"
    >
      <fstar-image-wrapper [url]="selectedCooperative.urlLogo">
        <span>{{ selectedCooperative.name }}</span>
      </fstar-image-wrapper>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
    <ng-template #oneCooperative>
      <div class="cooperative-section">
        <fstar-image-wrapper [url]="selectedCooperative.urlLogo">
          <span>{{ selectedCooperative.name }}</span>
        </fstar-image-wrapper>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #chooseCooperative>
    <div class="cooperative-section multi-cooperatives" [fretPopupOverlay]="cooperativePopup">
      <span>Choisir un organisme</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </ng-template>
  <section class="user-info-section" [fretPopupOverlay]="popupOverlay">
    <span>
      <div>{{ userData?.firstName }} {{ userData?.lastName }}</div>
      {{ userRoleLabel }}
    </span>
    <mat-icon>account_circle</mat-icon>
  </section>
</mat-toolbar>

<fret-popup-overlay
  #cooperativePopup
  [offsetX]="-280"
  [offsetY]="-8"
  [position]="popupPositionEnd"
  [hasBackdrop]="true"
  [backdropClass]="''"
  [maxHeight]="280"
>
  <div class="cooperative-list" *ngIf="cooperatives.length > 1">
    <div
      class="cooperative-item"
      *ngFor="let cooperative of cooperatives"
      (click)="selectCooperative(cooperative); cooperativePopup.dispose()"
    >
      <fstar-image-wrapper [url]="cooperative.urlLogo">
        <span>{{ cooperative.name }}</span>
      </fstar-image-wrapper>
    </div>
  </div>
</fret-popup-overlay>

<fret-popup-overlay
  [offsetX]="-36"
  [offsetY]="-8"
  [position]="popupPositionEnd"
  [hasBackdrop]="true"
  [backdropClass]="''"
  #popupOverlay
>
  <section class="user-overlay-container">
    <article class="user-info-overlay">
      <span>
        <div>{{ userData?.firstName }} {{ userData?.lastName }}</div>
        {{ userRoleLabel }}
      </span>
      <mat-icon>account_circle</mat-icon>
    </article>
    <article (click)="displayUserInfo(); popupOverlay.dispose()" class="profile-items">
      <svg aria-hidden="true"><use href="assets/images/sprites.svg#subscription"></use></svg>
      <aside>Mon profil</aside>
    </article>
    <article (click)="displaySpreadingEquipments(); popupOverlay.dispose()" class="equipments-items">
      <svg aria-hidden="true"><use href="assets/images/sprites.svg#equipments"></use></svg>
      <aside>Mon matériel</aside>
    </article>
  </section>
  <div (click)="logout()" class="signout"><p>Deconnexion</p></div>
</fret-popup-overlay>
