import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const VISIBLE_STATE = 'visible';
export const HIDDEN_STATE = 'hidden';

export function fadeInOut(
  fadeInDuration: string,
  fadeOutDuration: string,
  fadeOutDelay: string = ''
): AnimationTriggerMetadata {
  return trigger('fadeInOut', [
    state(
      VISIBLE_STATE,
      style({
        opacity: '1',
      })
    ),
    state(
      HIDDEN_STATE,
      style({
        opacity: '0',
      })
    ),
    transition(`* => ${VISIBLE_STATE}`, [animate(`${fadeInDuration} ease-out`)]),
    transition(`${VISIBLE_STATE} => ${HIDDEN_STATE}`, [animate(`${fadeOutDuration} ${fadeOutDelay} ease-out`)]),
  ]);
}

export function fadeInOutWithTransition(fadeInDuration: string, fadeOutDuration: string): AnimationTriggerMetadata {
  return trigger('fadeInOutTrans', [
    transition(':enter', [style({ opacity: 0 }), animate(`${fadeInDuration} ease-out`, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(`${fadeOutDuration} ease-out`, style({ opacity: 0 }))]),
  ]);
}
