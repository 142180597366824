import { Component, EventEmitter, Input, Output } from '@angular/core';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import PhenologicalStageAction = farmStarApiModel.PhenologicalStageAction;
import { svgByPhenologicalStageCode } from '@app/overview/overview.models';
import { StageNotifications } from '@app/overview/shared/farm-star/parcel.model';

@Component({
  selector: 'fstar-stage-item',
  templateUrl: './stage-item.component.html',
  styleUrls: ['./stage-item.component.scss'],
})
export class StageItemComponent {
  @Input() phenologicalStage: farmStarApiModel.PhenologicalStageBackend;
  @Input() stageNotifications: StageNotifications;
  @Input() stageDate: string;
  @Input() isCurrentStage = false;
  @Input() isAvailableToNotifications = false;
  @Output() unsubscribeNotifications = new EventEmitter<StageNotifications>();
  @Output() subscribeNotifications = new EventEmitter<string>();
  phenologicalStageCodeEnum = PhenologicalStageCode;
  phenologicalStageActionEnum = PhenologicalStageAction;

  svgByCode = svgByPhenologicalStageCode;

  emitSubscribeNotification(): void {
    if (this.stageNotifications) {
      this.unsubscribeNotifications.emit(this.stageNotifications);
    } else {
      this.subscribeNotifications.emit(this.phenologicalStage.id);
    }
  }
}
