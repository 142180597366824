import { ObservationService } from './../observation/observation.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CONFIGURATION, Configuration } from './configuration/configuration';
import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
})
export class FarmStarServiceModule {
  static withConfig(configuration: Configuration): ModuleWithProviders {
    return {
      ngModule: FarmStarServiceModule,
      providers: [FarmStarService, { provide: CONFIGURATION, useValue: configuration }, ObservationService],
    };
  }
}
