import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  ParcelDetailData,
  ParcelWithAdvices,
  StageNotifications,
  UpdateTotalDoseRequest,
} from '@app/overview/shared/farm-star/parcel.model';
import * as fromParcelDetails from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.reducer';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { UserRole } from '@app/overview/overview.models';
import * as fromParcelsEntity from '@app/overview/shared/parcels/parcels-entity.reducer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarIconComponent } from '@app/overview/shared/snackbar-icon/snackbar-icon.component';
import { ofType } from '@ngrx/effects';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  CalculationMethodsCode,
  listCAUComputationMethods,
  nDoseComputationEngineFW,
  TOTAL_DOSE_DIALOG_MIN_WIDTH,
  TOTAL_DOSE_DIALOG_WIDTH,
} from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/total-dose-details';
import { DefaultFertiwebWithCauDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/default-fertiweb-with-cau-dialog/default-fertiweb-with-cau-dialog.component';
import { FwtLornChampDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/fwt-lorn-champ-dialog/fwt-lorn-champ-dialog.component';
import { FwtRaDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/fwt-ra-dialog/fwt-ra-dialog.component';
import { FwtBzDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/fwt-bz-dialog/fwt-bz-dialog.component';
import { DefaultFertiwebWithoutCauDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/default-fertiweb-without-cau-dialog/default-fertiweb-without-cau-dialog.component';
import { AzDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/az-dialog/az-dialog.component';
import { DefaultNotFertiwebDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/default-not-fertiweb-dialog/default-not-fertiweb-dialog.component';
import { HydroDataInformation } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { EditionMode } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/rain-irrigation-edition/rain-irrigation-edition.model';
import PhenologicalStageCode = farmStarApiModel.PhenologicalStageCode;
import BasicUserData = farmStarApiModel.BasicUserData;
import DoseCalculationMethods = farmStarApiModel.DoseCalculationMethods;
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CropCode } from '@app/overview/shared/farm-star/agro-datum.model';
import { CoreModel } from '@app/overview/shared/farm-star/core.model';

@Component({
  selector: 'fstar-parcel-information',
  templateUrl: './parcel-information.component.html',
  styleUrls: ['./parcel-information.component.scss'],
})
export class ParcelInformationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() parcel: ParcelWithAdvices;
  stageByPhenologicalCode$: Observable<(code: PhenologicalStageCode) => farmStarApiModel.StageEstimate>;
  stage$: Observable<farmStarApiModel.StageEstimate[]>;
  hydroDataInformation$: Observable<HydroDataInformation>;
  stageNotifications$: Observable<Dictionary<StageNotifications>>;
  userRole$: Observable<UserRole>;
  selectedFarm$: Observable<CoreModel.FarmBackend>;
  technicianOfSelectedFarm$: Observable<BasicUserData>;
  totalDoseCalculationMethods: DoseCalculationMethods;
  isFertiWebDoseCalculationMethod: boolean;
  isAvailableToNotifications$: Observable<boolean>;
  isDisplayMessageForSelectedNotificationsPreferences$: Observable<boolean>;
  phenologicalStage$: Observable<Dictionary<farmStarApiModel.PhenologicalStageBackend>>;
  harvestSectionIsDisabled: boolean;
  orderedPhenologicalStageCodeList: PhenologicalStageCode[] = [];
  parcelDetailData: ParcelDetailData;
  snackBarRef: MatSnackBarRef<SnackbarIconComponent>;
  minDate = new Date(new Date().getFullYear(), 0, 1);
  maxDate = new Date(new Date().getFullYear(), 11, 31);
  currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toDateString();
  remainingMeasuredForm: FormGroup;
  isSmallScreen: boolean;
  displayEditionLayer: boolean;
  editionMode: EditionMode;
  selectedIndex = 0;
  hoveredRainDate: string;
  hoveredIrrigationDate: string;
  kgPerHaFormat = '1.0-0';
  TPerHaFormat = '1.1-1';
  parcelRecommendationComment: string;
  showTotalNitrogenDose = false;
  displayRemainingMeasuredForm = false;

  private tabIndex = { INFOS: 0, DOSES: 1, STADES: 2, HYDRO: 3 };

  constructor(
    private store: Store<fromParcelDetails.ParcelDetailsGlobalState | fromOverview.OverviewGlobalState>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private actions: ActionsSubject,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 1590px)'])
      .pipe(untilDestroyed(this))
      .subscribe(({ matches }: BreakpointState) => (this.isSmallScreen = matches));

    this.actions
      .pipe(untilDestroyed(this), ofType(parcelDetailsActions.UpdateParcelTotalDoseError))
      .subscribe(({ errorMessage }) => {
        this.snackBarRef = this.snackBar.openFromComponent(SnackbarIconComponent, {
          panelClass: ['top-red-snackbar'],
          verticalPosition: 'top',
          data: {
            message: errorMessage,
            hasWhiteFillColor: true,
            hasCloseButton: true,
          },
        });
      });

    this.remainingMeasuredForm = this.formBuilder.group({
      remainingMeasured: [
        null,
        {
          validators: [Validators.min(0), Validators.max(200), Validators.pattern('[0-9]+')],
        },
      ],
    });

    this.stageByPhenologicalCode$ = this.store.pipe(select(fromParcelDetails.selectStageEstimateByPhenologicalCode));
    this.stage$ = this.store.pipe(select(fromParcelDetails.selectAllStagesEstimate));
    this.hydroDataInformation$ = this.store.pipe(select(fromParcelDetails.selectHydroDataInformation));
    this.stageNotifications$ = this.store.pipe(select(fromParcelDetails.selectStageNotificationsEntities));
    this.store
      .pipe(select(fromParcelDetails.selectHarvestSectionIsDisabled), untilDestroyed(this))
      .subscribe(harvestSectionIsDisabled => {
        this.harvestSectionIsDisabled = harvestSectionIsDisabled;
        this.orderedPhenologicalStageCodeList = harvestSectionIsDisabled
          ? [
              PhenologicalStageCode.bloom,
              PhenologicalStageCode.tenLeaves,
              PhenologicalStageCode.eightLeaves,
              PhenologicalStageCode.sixLeaves,
              PhenologicalStageCode.fourLeaves,
              PhenologicalStageCode.emergence,
              PhenologicalStageCode.seedling,
            ]
          : [
              PhenologicalStageCode.dryMatter35,
              PhenologicalStageCode.dryMatter32,
              PhenologicalStageCode.humidity32,
              PhenologicalStageCode.humidity35,
              PhenologicalStageCode.humidity45,
              PhenologicalStageCode.humidity50,
              PhenologicalStageCode.bloom,
              PhenologicalStageCode.tenLeaves,
              PhenologicalStageCode.eightLeaves,
              PhenologicalStageCode.sixLeaves,
              PhenologicalStageCode.fourLeaves,
              PhenologicalStageCode.emergence,
              PhenologicalStageCode.seedling,
            ];
      });
    this.phenologicalStage$ = this.store.pipe(select(fromOverview.selectAllPhenologicalStagesEntities));
    this.userRole$ = this.store.pipe(select(fromOverview.selectUserRole));
    this.isAvailableToNotifications$ = this.store.pipe(select(fromOverview.selectIsAFarmer));
    this.isDisplayMessageForSelectedNotificationsPreferences$ = this.store.pipe(
      select(fromParcelDetails.selectIsDisplayMessageForSelectedNotificationsPreferences$)
    );
    this.selectedFarm$ = this.store.pipe(select(fromOverview.selectSelectedFarm));
    this.technicianOfSelectedFarm$ = this.store.pipe(select(fromParcelDetails.selectTechnicianData));
    this.store
      .pipe(untilDestroyed(this), select(fromOverview.selectHideDataForCampaigns))
      .subscribe(hideForCampaigns => {
        this.showTotalNitrogenDose = false;
        if (this.parcel && hideForCampaigns.has(this.parcel.campaignId)) {
          const hiddenForCampaign = hideForCampaigns.get(this.parcel.campaignId).hideNitrogenDose;
          this.showTotalNitrogenDose = !hiddenForCampaign && this.parcel.isTotalNDelivered;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parcel.currentValue != null) {
      const selectParcelFn = fromParcelsEntity.selectParcelDetailDataById(this.parcel.id);
      this.store.pipe(untilDestroyed(this), select(selectParcelFn)).subscribe((extraData: ParcelDetailData) => {
        this.parcelDetailData = extraData;
        this.totalDoseCalculationMethods = extraData.nDoseComputationMethod;
        this.isFertiWebDoseCalculationMethod = extraData.nDoseComputationMethod
          ? !!extraData.nDoseComputationMethod.nDoseComputationEngine &&
            extraData.nDoseComputationMethod.nDoseComputationEngine === nDoseComputationEngineFW
          : false;

        if (extraData.lateWinterParcelRecommendation) {
          this.parcelRecommendationComment = extraData.lateWinterParcelRecommendation.comment;
        } else if (extraData.earlyWinterParcelRecommendation) {
          this.parcelRecommendationComment = extraData.earlyWinterParcelRecommendation.comment;
        } else {
          this.parcelRecommendationComment = undefined;
        }
      });
      this.displayRemainingMeasuredForm =
        this.isFertiWebDoseCalculationMethod || !CropCode.isCrop(this.parcel, CropCode.CORN);
    }
  }

  subscribeNotificationsChanged(phenologicalStageRefId: string) {
    this.store.dispatch(
      parcelDetailsActions.SubscribeStageNotifications({
        parcelId: this.parcel.id,
        phenologicalStageRefId,
      })
    );
  }

  unsubscribeNotificationsChanged(stageNotification: StageNotifications) {
    this.store.dispatch(
      parcelDetailsActions.UnsubscribeStageNotifications({
        stageNotification,
      })
    );
  }

  downloadNDosePdf() {
    this.store.dispatch(parcelDetailsActions.DownloadNDosePdf({ parcelCode: this.parcel.code }));
  }

  downloadBiomassReport(type: string) {
    const parcelRecommendation =
      type === 'LATE'
        ? this.parcelDetailData.lateWinterParcelRecommendation
        : this.parcelDetailData.earlyWinterParcelRecommendation;
    const titre =
      type === 'LATE' ? `biomass_sortie_hiver_${this.parcel.code}.pdf` : `biomass_entree_hiver_${this.parcel.code}.pdf`;
    this.store.dispatch(
      parcelDetailsActions.DownloadParcelRecommendationPdf({
        parcelCode: this.parcel.code,
        recommendationName: parcelRecommendation.recommendationName,
        titre,
      })
    );
  }

  openTotalDoseDetailsDialog() {
    const dialogConfig = {
      width: TOTAL_DOSE_DIALOG_WIDTH,
      minWidth: TOTAL_DOSE_DIALOG_MIN_WIDTH,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
      panelClass: 'total-dose-details-dialog',
      data: {
        ...this.parcel,
      },
    };
    const isAMethodWithCAU = listCAUComputationMethods.includes(this.totalDoseCalculationMethods.code);

    if (this.isFertiWebDoseCalculationMethod) {
      if (isAMethodWithCAU) {
        switch (this.totalDoseCalculationMethods.code) {
          case CalculationMethodsCode.FWT_LORN:
          case CalculationMethodsCode.FWT_CHAMP:
            this.dialog.open(FwtLornChampDialogComponent, dialogConfig);
            break;
          case CalculationMethodsCode.FWT_RA:
            this.dialog.open(FwtRaDialogComponent, dialogConfig);
            break;
          default:
            this.dialog.open(DefaultFertiwebWithCauDialogComponent, dialogConfig);
            break;
        }
      } else {
        switch (this.totalDoseCalculationMethods.code) {
          case CalculationMethodsCode.FWT_BZ:
            this.dialog.open(FwtBzDialogComponent, dialogConfig);
            break;
          default:
            this.dialog.open(DefaultFertiwebWithoutCauDialogComponent, dialogConfig);
            break;
        }
      }
    } else {
      switch (this.totalDoseCalculationMethods.code) {
        case CalculationMethodsCode.AZ:
          this.dialog.open(AzDialogComponent, dialogConfig);
          break;
        default:
          this.dialog.open(DefaultNotFertiwebDialogComponent, dialogConfig);
          break;
      }
    }
  }

  updateTotalDose() {
    const remainingMeasuredValue = this.remainingMeasuredForm.get('remainingMeasured').value;

    const parcelRequest: UpdateTotalDoseRequest = {
      parcelId: this.parcel.id,
      remainingMeasured: remainingMeasuredValue,
      crop: CropCode.fromParcel(this.parcel),
    };

    this.remainingMeasuredForm.reset();

    this.closeSnackBar();

    this.store.dispatch(parcelDetailsActions.UpdateParcelTotalDose({ parcelRequest }));
  }

  changePanel(event: MatTabChangeEvent) {
    this.store.dispatch(
      parcelDetailsActions.IsPanelHydroOpened({ isPanelHydroOpened: event.index === this.tabIndex.HYDRO })
    );
    this.store.dispatch(parcelDetailsActions.IsPanelDoseOpened({ isOpen: event.index === this.tabIndex.DOSES }));
  }

  closeSnackBar() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  isFutureDate(date: string) {
    return Date.parse(date) > Date.parse(this.currentDate);
  }

  openRainEdition() {
    this.editionMode = EditionMode.rain;
    this.displayEditionLayer = true;
  }

  openIrrigationEdition() {
    this.editionMode = EditionMode.irrigation;
    this.displayEditionLayer = true;
  }

  closeRainEdition() {
    this.selectedIndex = 3;
    this.editionMode = EditionMode.none;
    this.displayEditionLayer = false;
  }

  setCurrentlyHoveredIrrigationDate(date: string) {
    this.hoveredIrrigationDate = date;
  }

  setCurrentlyHoveredRainDate(date: string) {
    this.hoveredRainDate = date;
  }

  ngOnDestroy() {
    this.closeSnackBar();
  }
}
