<article class="campaign-data-container">
  <div class="campaign-data-information">
    <div class="date-and-filename">
      <ng-container *ngIf="data.uploadedOn">
        <span class="datafile-date">{{ data.uploadedOn | date: 'dd/MM/yyyy' }}</span>
        <span class="datafile-time">{{ data.uploadedOn | date: 'HH:mm' }}</span>
      </ng-container>
      <span *ngIf="data.fileName" class="datafile-name">{{ data.fileName }}</span>
    </div>
    <p class="campaign-status">{{ data.status | campaignStatus }}</p>
    <ul class="campaign-crops horizontal-list">
      <li *ngFor="let crop of data.crops">{{ crop }}</li>
    </ul>
  </div>
  <aside>
    <svg aria-hidden="true" [ngSwitch]="data.status">
      <use *ngSwitchCase="CampaignStatus.IMPORTED" href="assets/images/sprites.svg#success"></use>
      <use *ngSwitchCase="CampaignStatus.IMPORTING" href="assets/images/sprites.svg#waiting" class="waiting-icon"></use>
      <use *ngSwitchCase="CampaignStatus.UPLOADED" href="assets/images/sprites.svg#waiting" class="waiting-icon"></use>
      <use *ngSwitchCase="CampaignStatus.TESTED" href="assets/images/sprites.svg#success"></use>
      <use *ngSwitchCase="CampaignStatus.TESTING" href="assets/images/sprites.svg#waiting" class="waiting-icon"></use>
    </svg>
  </aside>
</article>
