<aol-map #map (onMoveEnd)="updateZoomLevel()" (onClick)="clickInteraction($event)">
  <aol-interaction-default></aol-interaction-default>
  <aol-interaction-draw
    *ngIf="isDrawModeActivated && drawMode === drawModeEnum.polygon"
    type="Polygon"
    (onDrawStart)="startDraw()"
    (onDrawEnd)="endDraw($event.feature)"
  ></aol-interaction-draw>
  <aol-interaction-draw
    *ngIf="isDrawModeActivated && drawMode === drawModeEnum.box"
    type="Circle"
    [geometryFunction]="drawBoxGeometryFunction"
    (onDrawStart)="startDraw()"
    (onDrawEnd)="endDraw($event.feature)"
  ></aol-interaction-draw>
  <aol-interaction-select
    [condition]="selectCondition()"
    [style]="selectedStyle"
    [wrapX]="true"
    #featureSelect
    [layers]="isFeaturesLayer"
    (onSelect)="onFeatureSelect($event.selected[0])"
  ></aol-interaction-select>

  <aol-layer-tile>
    <aol-source-xyz
      [wrapX]="false"
      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    >
    </aol-source-xyz>
  </aol-layer-tile>

  <aol-layer-image
    class="lai-layer"
    *ngIf="!showMarkers && !isPanelDoseOpen && (selectedObservation$ | async) as observation"
    [zIndex]="1"
  >
    <aol-source-imagestatic
      [imageLoadFunction]="imageLoadFunction"
      [url]="observationImageUrl"
      [imageExtent]="observationImageExtent"
    ></aol-source-imagestatic>
  </aol-layer-image>

  <aol-layer-image *ngIf="!showMarkers && isPanelDoseOpen && showTotalNitrogenDose" [zIndex]="1">
    <aol-source-imagestatic
      [imageLoadFunction]="imageLoadFunction"
      [url]="totalNitrogenImage.link"
      [imageExtent]="totalNitrogenImage.extent"
    ></aol-source-imagestatic>
  </aol-layer-image>

  <aol-layer-vector [visible]="!showMarkers" [zIndex]="2">
    <aol-source-vector #aoiSource> </aol-source-vector>
    <aol-style> <aol-style-stroke color="#FFFF00" width="2"></aol-style-stroke> </aol-style>
  </aol-layer-vector>

  <aol-layer-vector [visible]="showMarkers">
    <aol-source-vector #markersSource> </aol-source-vector>
    <aol-style>
      <aol-style-icon [src]="'assets/images/marker.svg'" [anchor]="[0.5, 1]" [scale]="0.5" [anchorOrigin]="'top-left'">
      </aol-style-icon>
    </aol-style>
  </aol-layer-vector>

  <aol-layer-group #featuresLayerGroup *ngIf="features$ | async as features">
    <aol-layer-vector *ngFor="let f of features; trackBy: trackByFeatureId" [zIndex]="3">
      <aol-source-vector>
        <aol-feature [id]="f.id">
          <aol-geometry-polygon>
            <aol-collection-coordinates [coordinates]="f.geometry.coordinates"></aol-collection-coordinates>
          </aol-geometry-polygon>
        </aol-feature>
      </aol-source-vector>
      <aol-style *ngIf="f.id !== selectedFeatureId; else selectedAolStyle">
        <aol-style-stroke color="#042237" width="2"></aol-style-stroke>
        <aol-style-fill color="rgba(4, 34, 55, 0.8)"></aol-style-fill>
      </aol-style>
      <ng-template #selectedAolStyle>
        <aol-style>
          <aol-style-stroke color="#042237" width="2"></aol-style-stroke>
          <aol-style-fill color="rgba(255, 255, 255, 0)"></aol-style-fill>
        </aol-style>
      </ng-template>
    </aol-layer-vector>
  </aol-layer-group>

  <aol-overlay *ngIf="pointWithBiophyValue$ | async as pointWithBiophyValue" [positioning]="'bottom-center'">
    <aol-coordinate
      [x]="pointWithBiophyValue.coordinates[0]"
      [y]="pointWithBiophyValue.coordinates[1]"
    ></aol-coordinate>
    <aol-content>
      <div class="tooltip">
        <div class="content" *ngIf="pointWithBiophyValue.medianBiophy; else biophyNotAvailable">
          <div class="content-header">
            <mat-icon (click)="resetPointBiophy()">close</mat-icon>
          </div>
          <div class="content-biophy">
            <div class="info-label">LAI :</div>
            <div class="info-value">{{ pointWithBiophyValue.medianBiophy }}</div>
          </div>
        </div>
        <ng-template #biophyNotAvailable>
          <div class="not-available-biophy">
            <div class="content-header">
              <mat-icon (click)="resetPointBiophy()">close</mat-icon>
            </div>
            <div class="content-biophy">
              <div class="info-label">LAI :</div>
              <div class="info-value">couverture nuageuse</div>
            </div>
          </div>
        </ng-template>
        <div class="arrow"></div>
      </div>
    </aol-content>
  </aol-overlay>

  <aol-view #view [minZoom]="3" [zoom]="6" [maxZoom]="18" [center]="center" [projection]="proj3857"></aol-view>
</aol-map>
<fstar-lai-legend *ngIf="!isPanelDoseOpen"></fstar-lai-legend>
<fstar-draw-info-tooltip
  *ngIf="selectedFeature$ | async as featureArea"
  [featureArea]="featureArea"
  [featureBiophy]="selectedFeatureBiophy$ | async"
></fstar-draw-info-tooltip>
