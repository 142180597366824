import { NgModule } from '@angular/core';

import { OverviewComponent } from './overview.component';
import { CommonModule } from '@angular/common';
import { NavbarModule } from '@app/overview/core/navbar/navbar.module';
import { RouterModule, Routes } from '@angular/router';
import { PanelLinkModule } from '@fret-ngx/panel-link';
import { ImportDatabaseComponent } from './import-database/import-database.component';
import { ImportDatabaseModule } from '@app/overview/import-database/import-database.module';
import { MatDividerModule, MatIconModule } from '@angular/material';
import { ParcelDetailsComponent } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.component';
import { ParcelsViewerComponent } from '@app/overview/parcels-viewer/parcels-viewer.component';
import { ParcelsViewerModule } from '@app/overview/parcels-viewer/parcels-viewer.module';
import { ParcelDetailsModule } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.module';
import { ToolbarModule } from '@fret-ngx/toolbar';
import { StoreModule } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OverviewEffects } from '@app/overview/overview.effects';
import { AuthGuard } from '@fret-ngx/aaa';
import { FarmerGuard } from '@app/shared/guards/farmer.guard';
import { routesNames } from '@app/shared/utils/routes.helper';
import { UserRoleResolver } from '@app/shared/resolver/user-role.resolver';
import { FarmParcelDetailsGuard } from '@app/overview/parcels-viewer/core/parcel-details/guards/farm-parcel-details.guard';
import { NotFarmerGuard } from '@app/shared/guards/not-farmer.guard';
import { NotFarmerOrTechnicianGuard } from '@app/shared/guards/not-farmer-or-technician.guard';

const overviewRoutes: Routes = [
  {
    path: '',
    redirectTo: routesNames.FARMS,
    pathMatch: 'full',
    resolve: { userRole: UserRoleResolver },
  },
  {
    path: '',
    component: OverviewComponent,
    canActivate: [AuthGuard],
    resolve: { userRole: UserRoleResolver },
    children: [
      // ALL USERS
      {
        path: routesNames.HELP,
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
      },
      {
        path: routesNames.COMPARE,
        loadChildren: () => import('./compare-viewer/compare-viewer.module').then(m => m.CompareViewerModule),
      },
      {
        path: routesNames.DIVISION,
        loadChildren: () => import('./division/division.module').then(m => m.DivisionModule),
      },
      {
        path: routesNames.FARMS,
        loadChildren: () => import('./farms-viewer/farms-viewer.module').then(m => m.FarmsViewerModule),
      },
      {
        path: `${routesNames.FARMS}/:farmId/${routesNames.PARCELS}`,
        component: ParcelsViewerComponent,
      },
      {
        path: `${routesNames.FARMS}/:farmId/${routesNames.PARCELS}/:parcelId`,
        component: ParcelDetailsComponent,
      },
      {
        path: routesNames.TAB_FARMS,
        loadChildren: () => import('./tab-farms/tab-farms.module').then(m => m.TabFarmsModule),
      },
      // USERS EXCEPTS FARMER OR TECHNICIAN
      {
        path: routesNames.IMPORT_DATABASE,
        component: ImportDatabaseComponent,
        canActivate: [NotFarmerOrTechnicianGuard],
      },
      // FARMERS ONLY
      {
        path: routesNames.NOTIFICATIONS,
        canActivate: [FarmerGuard],
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
    ],
  },
];

@NgModule({
  declarations: [OverviewComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    NavbarModule,
    ToolbarModule,
    PanelLinkModule,
    ParcelDetailsModule,
    ParcelsViewerModule,
    ImportDatabaseModule,
    RouterModule.forChild(overviewRoutes),
    StoreModule.forFeature('overview', fromOverview.reducers),
    EffectsModule.forFeature([OverviewEffects]),
  ],
  providers: [FarmerGuard, FarmParcelDetailsGuard, NotFarmerGuard, NotFarmerOrTechnicianGuard],
})
export class OverviewModule {}
