import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FindCropsError, FindCropsSuccess } from '@app/overview/overview.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Crop } from '../shared/farm-star/agro-datum.model';

export interface State extends EntityState<Crop> {
  isLoaded: boolean;
}
export const adapter: EntityAdapter<Crop> = createEntityAdapter<Crop>({
  selectId: crop => crop.id.toString(),
});

const initialState: State = adapter.getInitialState({
  isLoaded: false,
});

const reducer = createReducer(
  initialState,
  on(FindCropsSuccess, (state, { crops }) => adapter.addAll(crops, { ...state, isLoaded: true })),
  on(FindCropsError, state => ({ ...state, isLoaded: true }))
);

export function cropsReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
