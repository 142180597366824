import { RegisteredCropsBackend } from '@app/overview/import-database/import-database.model';
import { Link } from '@app/overview/shared/farm-star/common.model';
import { UserRole } from '@app/overview/overview.models';
import {
  DailyStatus,
  GenericParcel,
  IrrigationStatus,
  StageNotifications,
} from '@app/overview/shared/farm-star/parcel.model';
import { CalculationMethodsCode } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/total-dose-details';
import { CoreModel } from './core.model';

export namespace farmStarApiModel {
  export enum DatafileStatus {
    IMPORTED = 'IMPORTED',
    IMPORTING = 'IMPORTING',
    TESTING = 'TESTING',
    TESTED = 'TESTED',
    UPLOADED = 'UPLOADED',
    NONE = '',
  }

  export interface CampaignsResponse {
    _embedded: {
      campaigns: CoreModel.CampaignBackend[];
    };
  }

  export interface CampaignsCropsResponse {
    _embedded: {
      registeredCrops: RegisteredCropsBackend[];
    };
  }

  export interface DataFilesResponse {
    _embedded?: {
      dataFiles: DataFileItem[];
    };
  }

  export interface DataFileItem {
    id: string;
    status: DatafileStatus;
    cooperativeRefId?: string;
    campaignRefId: string;
    _links: {
      self: Link;
      dataFile: Link;
      campaign: Link;
      cooperative: Link;
      file: Link;
    };
  }

  export interface DataFileItemWithCrops extends DataFileItem {
    crops: string[];
  }

  export interface DataFile {
    id: string;
    name: string;
    uploadedOn: string;
    size: number;
    contentType: string;
    _links: {
      self: Link;
      file: Link;
      download: Link;
    };
  }

  export interface DataFileFilterIds {
    campaignRefId?: string;
    cooperativeRefId?: string;
    status?: string;
  }

  export interface DatafileRequest {
    file: File;
    cooperativeId?: string;
    campaignId: string;
    partialImport: boolean;
  }

  export interface TestReportRequest {
    dataFile: string;
  }

  export interface TestReportResponse {
    _embedded?: {
      testReports: TestReportItem[];
    };
  }

  export interface TestReportItem {
    id: string;
    beginDate: string;
    endDate: string;
    technician: {
      rejected: number;
      total: number;
    };
    farmer: {
      rejected: number;
      total: number;
    };
    parcel: {
      rejected: number;
      total: number;
    };
    _links: {
      self: Link;
      testReport: Link;
      errors: Link;
      dataFile: Link;
    };
  }

  export interface ReportResponse {
    _embedded?: {
      [key: string]: ReportItem[];
    };
  }

  interface ReportItem {
    id: string;
    beginDate: string;
    endDate: string;
    technician: {
      added: number;
      updated: number;
      deleted: number;
    };
    farmer: {
      added: number;
      updated: number;
      deleted: number;
    };
    parcel: {
      added: number;
      updated: number;
      deleted: number;
    };
    _links: {
      self: Link;
      [key: string]: Link;
      dataFile: Link;
    };
  }

  export interface TestErrorReportResponse {
    _embedded?: {
      testReportErrors: TestErrorReportItem[];
    };
  }

  export interface TestErrorReportItem {
    id: string;
    entityType: EntityErrorType;
    entityCode: string;
    field: string;
    level: ErrorLevel;
    message: string;
    _links: {
      self: Link;
      testReportError: Link;
    };
  }

  export enum EntityErrorType {
    technician = 'TECHNICIAN',
    farmer = 'FARMER',
    parcel = 'PARCEL',
  }

  export enum ErrorLevel {
    warning = 'WARNING',
    error = 'ERROR',
  }

  export interface ImportReportRequest {
    dataFile: string;
  }

  export interface ImportReportResponse extends ReportResponse {
    _embedded?: {
      importReports: ImportReportItem[];
    };
  }

  export interface ImportReportItem extends ReportItem {
    _links: {
      self: Link;
      importReport: Link;
      dataFile: Link;
    };
  }

  export interface StageEstimateBackend {
    phenologicalStageRefId?: string;
    stageDate?: string;
    estimateDate?: string;
    _links?: {
      self: Link;
      stageEstimate: Link;
      parcel: Link;
    };
  }

  export interface StageEstimate extends StageEstimateBackend {
    isCurrentStage?: boolean;
    isNextStage?: boolean;
  }

  export interface CurrentStageBackend {
    id: string;
    parcel: GenericParcel;
    phenologicalStageRefId: string;
    stageDate: string;
    estimateDate: string;
  }

  export interface StagesByParcel {
    parcelId: string;
    isLoading: boolean;
    currentStage?: StageEstimate;
    nextStage?: StageEstimate;
  }

  export interface BiophyByParcel {
    parcelId: string;
    isLoading: boolean;
    medianBiophy?: number;
  }

  export interface StageEstimatesResponse {
    _embedded?: {
      stageEstimates: StageEstimateBackend[];
    };
  }

  export enum PhenologicalStageCode {
    seedling = 'SEMIS',
    emergence = 'LEVEE',
    fourLeaves = '4F',
    sixLeaves = '6F',
    eightLeaves = '8F',
    tenLeaves = '10F',
    bloom = 'FLO',
    humidity50 = 'H50',
    humidity45 = 'H45',
    humidity35 = 'H35',
    humidity32 = 'H32',
    dryMatter32 = 'ms32',
    dryMatter35 = 'ms35',
  }

  export const allPhenologicalCodes = [
    PhenologicalStageCode.seedling,
    PhenologicalStageCode.emergence,
    PhenologicalStageCode.fourLeaves,
    PhenologicalStageCode.sixLeaves,
    PhenologicalStageCode.eightLeaves,
    PhenologicalStageCode.tenLeaves,
    PhenologicalStageCode.bloom,
    PhenologicalStageCode.humidity50,
    PhenologicalStageCode.humidity45,
    PhenologicalStageCode.humidity35,
    PhenologicalStageCode.humidity32,
    PhenologicalStageCode.dryMatter35,
    PhenologicalStageCode.dryMatter32,
  ];

  export const phenologicalSeedlingStageId = PhenologicalStageCode.seedling;

  export enum PhenologicalStageAction {
    harvest = 'HARVEST',
    intervention = 'INTERVENTION',
  }

  export interface PhenologicalStageBackend {
    id: string;
    cropRefId?: string;
    code: PhenologicalStageCode;
    label: string;
    advice: string;
    index?: number;
    action: PhenologicalStageAction;
    _links?: {
      self: Link;
      phenologicalStage: Link;
      crop: Link;
    };
  }

  export interface IrrigationStatusResponseBackEnd {
    _embedded: {
      irrigationStatuses: IrrigationStatus[];
    };
    _links: {
      self: Link;
      profile: Link;
      search: Link;
    };
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
    };
  }

  export interface StageNotificationsResponseBackEnd {
    _embedded: {
      stageNotifications: StageNotifications[];
    };
    _links: {
      self: Link;
      profile: Link;
      search: Link;
    };
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
    };
  }

  export interface DailyStatusResponseBackEnd {
    _embedded: {
      dailyStatuses: DailyStatus[];
    };
  }

  export interface BiophyValueByParcel {
    parcelId: string;
    median?: number;
    imageLink?: string;
    imageExtent?: [number, number, number, number];
  }

  export interface BiophyValue {
    id: string;
    sensingDate: string;
    median: number;
    imageLink: string;
    imageExtent: [number, number, number, number];
    backendBiophyId?: string;
  }

  export interface BiophyValueWithParcelId extends BiophyValue {
    parcelId: string;
    isLastBiophy: boolean;
  }

  export interface IllustrationBbox {
    init: boolean;
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  }

  export interface Illustration {
    filePath: string;
    type: string;
    bbox: IllustrationBbox;
  }

  export interface Layer {
    productDefinitionCode: string;
    tiffFilePath: string;
    metadata: {
      stats: {
        mean: number;
        median: number;
        sd: number;
        validity: number;
      };
    };
    illustrations: Illustration[];
  }

  export interface ObservationBackend {
    id: string;
    parcel: {
      id: string;
    };
    acquisition: {
      sensingDateTime: string;
      sensor: {
        name: string;
      };
      sourceId: string;
    };
    layers: Layer[];
    cloudFreeCover: number;
    processingDateTime: string;
    blacklisted: boolean;
    _links: {
      self: Link;
      observation: Link;
    };
  }

  export interface LayerStat {
    min: number;
    max: number;
    mean: number;
    median: number;
    sd: number;
    validity: number;
  }

  export interface ObservationsResponse {
    _embedded: {
      observations: ObservationBackend[];
    };
    _links: {
      first: Link;
      self: Link;
      next: Link;
      last: Link;
      profile: Link;
      search: Link;
    };
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
    };
  }

  export interface PhenologicalStageResponse {
    _embedded?: {
      phenologicalStages: PhenologicalStageBackend[];
    };
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
    };
  }

  export interface CooperativeBackend {
    id: string;
    code: string;
    name: string;
    _links: {
      self: Link;
      cooperative: Link;
      logoLarge: Link;
      logoSmall: Link;
      registeredCooperatives: Link;
    };
  }

  export interface CooperativesResponse {
    _embedded?: {
      cooperatives: CooperativeBackend[];
    };
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
    };
  }

  export interface CoordinatorCooperativesResponse {
    _embedded: {
      cooperatives: CooperativeBackend[];
    };
    _links: {
      self: Link;
    };
  }

  export interface UserRoleAndUserDataResponse {
    _embedded: AllUserResponse;
  }

  export interface TechnicianUserInfoResponse {
    technicians: BasicUserData[];
  }

  export interface FarmerListResponse {
    _embedded: FarmerUserInfoResponse;
  }

  export interface FarmerUserInfoResponse {
    farmers: BasicUserData[];
  }

  export interface CoordinatorUserInfoResponse {
    coordinators: CoordinatorData[];
  }

  export interface OtherUsersUserInfoResponse {
    otherUsers: OtherUsersData[];
  }

  export interface User {
    id: string;
    profile: string;
    idpIdentifier: string;
    lastModifiedDate: string;
    firstName: string;
    lastName: string;
    adress: string;
    phone1: string;
    phone2: string;
    login: string;
    zipCode: string;
    city: string;
    email: string;
    smsNotification: boolean;
    emailNotification: boolean;
    notificationDelayForStagesDays: number;
  }

  export interface BasicUserData extends User {
    code?: string;
    active?: boolean;
    siret?: string;
    corporateName?: string;
    _links?: {
      self?: Link;
      cooperative?: Link;
    };
  }

  export interface CoordinatorData extends User {
    _links: {
      self: Link;
      coordinator: Link;
      cooperatives: Link;
    };
  }

  export interface OtherUsersData extends User {
    _links: {
      self: Link;
      otherUser: Link;
    };
  }

  export type AllUserTypeData = BasicUserData | CoordinatorData | OtherUsersData;
  export type AllUserResponse =
    | TechnicianUserInfoResponse
    | FarmerUserInfoResponse
    | CoordinatorUserInfoResponse
    | OtherUsersUserInfoResponse;

  export interface FarmerDataAndUserRole {
    userData: BasicUserData;
    userRole: UserRole.farmer;
    userRoleLabel: string;
  }

  export interface UserDataAndUserRole {
    userData: AllUserTypeData;
    userRole: UserRole;
    userRoleLabel: string;
  }

  export interface DoseCalculationMethods {
    label: string;
    code: CalculationMethodsCode;
    nDoseComputationEngine: string;
  }

  export interface NDoseCalculationMethodsResponse {
    code: CalculationMethodsCode;
    id: string;
    label: string;
    ndoseComputationEngine: {
      code: string;
      id: string;
      internal: boolean;
      label: string;
    };
    _links: {
      nDoseComputationEngine: Link;
      nDoseComputationMethod: Link;
      self: Link;
    };
  }

  export interface SoilResponse {
    id: string;
    label: string;
    code: string;
    originDatabase: string;
    region: string;
    arvalisCode: string;
    _links: {
      self: Link;
      soil: Link;
      group: Link;
    };
  }

  export interface VarietyResponse {
    id: string;
    label: string;
    code: string;
    _links: {
      self: Link;
      variety: Link;
      crop: Link;
    };
  }

  export interface KindResponse {
    id: string;
    label: string;
    code: string;
    _links: {
      self: Link;
      kind: Link;
      parcels: Link;
      crop: Link;
    };
  }

  export interface RecommendationResponse {
    _embedded: {
      recommendations: RecommendationBackend[];
    };
    _links: {
      self: Link;
      profile: Link;
    };
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      number: number;
    };
  }

  export interface RecommendationBackend {
    id?: string;
    name?: string;
    code?: string;
    comment?: string;
    _links?: {
      self: Link;
      recommendation: Link;
      servicePacks: Link;
    };
  }
}
