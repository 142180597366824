import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { fadeInOut, HIDDEN_STATE, VISIBLE_STATE } from '@app/shared/animations/animations';
import { AnimationEvent } from '@angular/animations';
import { AppInitSuccess, DisplaySnackbarError, DisplaySnackbarSuccess } from '@app/app.action';
import { SnackbarIconComponent } from '@app/overview/shared/snackbar-icon/snackbar-icon.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fstar-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOut('500ms', '1s', '500ms')],
})
export class AppComponent implements OnInit, OnDestroy {
  state = VISIBLE_STATE;
  showSplashScreen = true;

  constructor(
    private actions: ActionsSubject,
    private snackBar: MatSnackBar,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.actions.pipe(untilDestroyed(this), ofType(AppInitSuccess)).subscribe(() => (this.state = HIDDEN_STATE));

    this.actions.pipe(untilDestroyed(this), ofType(DisplaySnackbarError)).subscribe(({ errorMessage }) => {
      this.snackBar.openFromComponent(SnackbarIconComponent, {
        panelClass: ['top-red-snackbar'],
        verticalPosition: 'top',
        data: {
          message: errorMessage,
          hasWhiteFillColor: true,
          hasCloseButton: true,
        },
      });
    });

    this.actions.pipe(untilDestroyed(this), ofType(DisplaySnackbarSuccess)).subscribe(({ successMessage }) => {
      this.snackBar.openFromComponent(SnackbarIconComponent, {
        panelClass: ['top-green-snackbar'],
        verticalPosition: 'top',
        duration: 3000,
        data: {
          message: successMessage,
          hasWhiteFillColor: true,
          hasCloseButton: true,
        },
      });
    });

    this.registerIcons();
  }

  animationDone(event: AnimationEvent) {
    if (event.fromState === VISIBLE_STATE && event.toState === HIDDEN_STATE) {
      this.showSplashScreen = false;
    }
  }

  private registerIcons() {
    this.registerIcon('barley', 'assets/images/crop-icons/barley.svg');
    this.registerIcon('corn', 'assets/images/crop-icons/corn.svg');
    this.registerIcon('rapeseed', 'assets/images/crop-icons/rapeseed.svg');
    this.registerIcon('triticale', 'assets/images/crop-icons/triticale.svg');
    this.registerIcon('wheat', 'assets/images/crop-icons/wheat.svg');
  }

  private registerIcon(name: string, path: string) {
    this.iconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }

  ngOnDestroy() {}
}
