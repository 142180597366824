import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ParcelDetailsComponent } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.component';
import { ParcelInformationComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/parcel-information.component';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HighchartsChartModule } from 'highcharts-angular';
import { ParcelChartComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-chart/parcel-chart.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ParcelDetailsMapModule } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-details-map/parcel-details-map.module';
import { DrawMenuComponent } from './core/draw-menu/draw-menu.component';
import * as fromParcelDetails from './parcel-details.reducer';
import { ParcelDetailsEffects } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.effects';
import { StageItemComponent } from './core/parcel-information/stage-item/stage-item.component';
import { OverlayLoaderModule } from '@app/overview/shared/overlay-loader/overlay-loader.module';
import { ParcelIdentificationComponent } from './core/parcel-information/parcel-identification/parcel-identification.component';
import { ParcelsModule } from '@app/overview/shared/parcels/parcels.module';
import { FilterWrapperModule } from '@app/overview/shared/filter-wrapper/filter-wrapper.module';
import { IsNotNullOrEmptyModule } from '@app/overview/shared/is-not-null-or-empty/is-not-null-or-empty.module';
import { IsNotNullOrZeroModule } from '@app/overview/shared/is-not-null-or-zero/is-not-null-or-zero.module';
import { PopupOverlayModule } from '@fret-ngx/button-popup';
import { SnackbarIconComponent } from '@app/overview/shared/snackbar-icon/snackbar-icon.component';
import { SmallHelpPopupModule } from '@app/overview/shared/small-help-popup/small-help-popup.module';
import { DefaultFertiwebWithCauDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/default-fertiweb-with-cau-dialog/default-fertiweb-with-cau-dialog.component';
import { FwtLornChampDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/fwt-lorn-champ-dialog/fwt-lorn-champ-dialog.component';
import { FwtRaDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/fwt-ra-dialog/fwt-ra-dialog.component';
import { FwtBzDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/fwt-bz-dialog/fwt-bz-dialog.component';
import { DefaultFertiwebWithoutCauDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/default-fertiweb-without-cau-dialog/default-fertiweb-without-cau-dialog.component';
import { AzDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/az-dialog/az-dialog.component';
import { DefaultNotFertiwebDialogComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-information/total-dose-details-dialog/default-not-fertiweb-dialog/default-not-fertiweb-dialog.component';
import { RainIrrigationEditionModule } from './core/parcel-information/rain-irrigation-edition/rain-irrigation-edition.module';
import { ParcelHydroChartComponent } from '@app/overview/parcels-viewer/core/parcel-details/core/parcel-hydro-chart/parcel-hydro-chart.component';
import { NumberFrModule } from '@app/shared/pipes/number-fr/number-fr.module';

@NgModule({
  declarations: [
    ParcelDetailsComponent,
    ParcelInformationComponent,
    ParcelChartComponent,
    ParcelHydroChartComponent,
    DrawMenuComponent,
    StageItemComponent,
    DefaultFertiwebWithoutCauDialogComponent,
    DefaultFertiwebWithCauDialogComponent,
    FwtLornChampDialogComponent,
    FwtRaDialogComponent,
    FwtBzDialogComponent,
    AzDialogComponent,
    DefaultNotFertiwebDialogComponent,
    ParcelIdentificationComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    OverlayLoaderModule,
    MatTooltipModule,
    MatTabsModule,
    MatButtonModule,
    PopupOverlayModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    ParcelDetailsMapModule,
    FilterWrapperModule,
    HighchartsChartModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatDividerModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    IsNotNullOrZeroModule,
    IsNotNullOrEmptyModule,
    SmallHelpPopupModule,
    StoreModule.forFeature('parcel-details', fromParcelDetails.reducers),
    EffectsModule.forFeature([ParcelDetailsEffects]),
    ParcelsModule,
    RainIrrigationEditionModule,
    NumberFrModule,
  ],
  entryComponents: [
    DefaultFertiwebWithoutCauDialogComponent,
    DefaultFertiwebWithCauDialogComponent,
    SnackbarIconComponent,
    FwtLornChampDialogComponent,
    FwtRaDialogComponent,
    FwtBzDialogComponent,
    AzDialogComponent,
    DefaultNotFertiwebDialogComponent,
  ],
})
export class ParcelDetailsModule {}
