import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { StageNotifications } from '@app/overview/shared/farm-star/parcel.model';
import { Action, createReducer, on } from '@ngrx/store';

export interface State extends EntityState<StageNotifications> {
  isLoadingStageNotifications: boolean;
}
export const adapter: EntityAdapter<StageNotifications> = createEntityAdapter<StageNotifications>({
  selectId: notifications => notifications.phenologicalStageRefId,
});

const initialState: State = adapter.getInitialState({
  isLoadingStageNotifications: false,
});

const reducer = createReducer(
  initialState,
  on(
    parcelDetailsActions.FindStageNotifications,
    parcelDetailsActions.SubscribeStageNotifications,
    parcelDetailsActions.UnsubscribeStageNotifications,
    state => ({
      ...state,
      isLoadingStageNotifications: true,
    })
  ),
  on(parcelDetailsActions.FindStageNotificationsSuccess, (state, { stageNotifications }) => ({
    ...adapter.addAll(stageNotifications, state),
    isLoadingStageNotifications: false,
  })),
  on(parcelDetailsActions.SubscribeStageNotificationsSuccess, (state, { stageNotification }) => ({
    ...adapter.addOne(stageNotification, state),
    isLoadingStageNotifications: false,
  })),
  on(parcelDetailsActions.UnsubscribeStageNotificationsSuccess, (state, { phenologicalStageRefId }) => ({
    ...adapter.removeOne(phenologicalStageRefId, state),
    isLoadingStageNotifications: false,
  })),
  on(parcelDetailsActions.SubscribeStageNotificationsError, state => ({
    ...state,
    isLoadingStageNotifications: false,
  })),
  on(parcelDetailsActions.FindStageNotificationsError, state => ({
    ...adapter.removeAll(state),
    isLoadingStageNotifications: false,
  }))
);

export function stagesNotificationsEntityReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
