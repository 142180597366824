import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIGURATION, Configuration } from '@app/overview/shared/farm-star/configuration/configuration';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import {
  ParcelRecommendationParsed,
  ParcelRecommendationResponse,
  ProcessEndingDateResponse,
} from '@app/overview/shared/farm-star/parcel.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParcelRecommendationApiService {
  public headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  private readonly API = `${this.config.url}/${this.config.api}/fs-core/api`;

  constructor(@Inject(CONFIGURATION) private config: Configuration, private http: HttpClient) {}

  findRecommendationsByCrop(cropId: string): Observable<farmStarApiModel.RecommendationBackend[]> {
    const params = new HttpParams().set('cropId', cropId);
    return this.http
      .get<farmStarApiModel.RecommendationResponse>(
        `${this.config.url}/${this.config.api}/fs-core/api/recommendations`,
        {
          headers: this.headers,
          params,
        }
      )
      .pipe(map(recommendationResponse => recommendationResponse._embedded.recommendations));
  }

  getTotalNRecommendationIllustrationUrl(parcelRecommendationId: string): string {
    return `${this.API}/parcelRecommendations/${parcelRecommendationId}/illustration/illustrationFile`;
  }

  findParcelRecommendation(parcelId: string, recommendationId: string): Observable<ParcelRecommendationParsed> {
    const params = {
      parcel: parcelId,
      recommendationId,
      active: 'true',
      'statuses.delivery.status': 'NOMINAL',
    };

    return this.http
      .get<ParcelRecommendationResponse>(`${this.config.url}/${this.config.api}/fs-core/api/parcelRecommendations`, {
        headers: this.headers,
        params,
      })
      .pipe(
        map((response: ParcelRecommendationResponse) => {
          if (response._embedded.parcelRecommendations && response._embedded.parcelRecommendations.length > 0) {
            const parcelRecom = response._embedded.parcelRecommendations[0];
            const parcelRecommendation = {
              id: parcelRecom.id,
              meanValue: parcelRecom.illustration.productForVisualization.collection_properties.data.mean,
            };
            return parcelRecommendation;
          } else {
            return undefined;
          }
        })
      );
  }

  findParcelRecommendationEndDate(parcelRecommendationId: string): Observable<string> {
    const params = {
      parcelRecommendation: parcelRecommendationId,
    };

    return this.http
      .get<ProcessEndingDateResponse>(`${this.config.url}/${this.config.api}/fs-core/api/processes`, {
        headers: this.headers,
        params,
      })
      .pipe(
        map((response: ProcessEndingDateResponse) => {
          return response._embedded.processes[0].endingDate;
        })
      );
  }
}
