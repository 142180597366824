import { createAction, props } from '@ngrx/store';
import { UserRole } from '@app/overview/overview.models';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';

export const InitAllData = createAction(
  '[AppInit] Init all data',
  props<{ userRole: UserRole; userData: farmStarApiModel.AllUserTypeData }>()
);
export const AppInitSuccess = createAction('[AppInit] App Init Success');
export const AppInitError = createAction('[AppInit] App Init Error');
export const DisplaySnackbarError = createAction('[App] Display snackbar Error', props<{ errorMessage: string }>());
export const DisplaySnackbarSuccess = createAction(
  '[App] Display snackbar Success',
  props<{ successMessage: string }>()
);
