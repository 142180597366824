import { NgModule } from '@angular/core';
import { ImportDatabaseDetailsComponent } from '@app/overview/import-database/core/import-database-details/import-database-details.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SendToProductionSuccessDialogComponent } from '@app/overview/import-database/core/import-database-details/core/send-to-production-success-dialog/send-to-production-success-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IsValidatedDataPipe } from '@app/overview/import-database/core/import-database-details/is-validated-data.pipe';
import { IsValidatedAndRejectedTestedDataPipe } from '@app/overview/import-database/core/import-database-details/is-validated-and-rejected-data.pipe';
import { IsRejectedDataPipe } from '@app/overview/import-database/core/import-database-details/is-rejected-data.pipe';
import { TestErrorReportComponent } from './core/test-error-report/test-error-report.component';
import { OldTestedDatafileDialogComponent } from '@app/overview/import-database/core/import-database-details/core/old-tested-datafile-dialog/old-tested-datafile-dialog.component';

@NgModule({
  declarations: [
    ImportDatabaseDetailsComponent,
    SendToProductionSuccessDialogComponent,
    OldTestedDatafileDialogComponent,
    TestErrorReportComponent,
    IsValidatedDataPipe,
    IsValidatedAndRejectedTestedDataPipe,
    IsRejectedDataPipe,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    MatDialogModule,
    MatTableModule,
    MatDividerModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  exports: [ImportDatabaseDetailsComponent],
  entryComponents: [SendToProductionSuccessDialogComponent, OldTestedDatafileDialogComponent],
})
export class ImportDatabaseDetailsModule {}
