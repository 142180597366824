import { Component, OnInit } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActionsSubject } from '@ngrx/store';
import { fadeInOut, fadeInOutWithTransition, HIDDEN_STATE, VISIBLE_STATE } from '@app/shared/animations/animations';
import { AuthService } from '@fret-ngx/aaa';
import { AnimationEvent } from '@angular/animations';
import { AppInitError } from '@app/app.action';

@Component({
  selector: 'fstar-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  animations: [fadeInOut('1s', '1s'), fadeInOutWithTransition('1s', '1s')],
})
export class SplashScreenComponent implements OnInit {
  loaderState = VISIBLE_STATE;
  public showLoader = true;
  public isErrorOnAppInit$: Observable<boolean>;
  constructor(private actions: ActionsSubject, public authService: AuthService) {}

  ngOnInit() {
    this.isErrorOnAppInit$ = this.actions.pipe(
      ofType(AppInitError),
      tap(() => (this.loaderState = HIDDEN_STATE)),
      map(() => true)
    );
  }

  animationDone(event: AnimationEvent) {
    if (event.fromState === VISIBLE_STATE && event.toState === HIDDEN_STATE) {
      this.showLoader = false;
    }
  }

  logout() {
    this.authService.startSignOut();
  }
}
