import { createAction, props } from '@ngrx/store';
import { DrawMode, FeatureArea, HydroDataInformation } from './parcel-details.models';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import {
  DailyStatus,
  GenericParcel,
  Parcel,
  StageNotifications,
  UpdateSeedlingDateRequest,
  UpdateTotalDoseRequest,
} from '@app/overview/shared/farm-star/parcel.model';
import { Feature, Point, Polygon } from 'geojson';
import BasicUserData = farmStarApiModel.BasicUserData;

export const UpdateParcelSeedlingDate = createAction(
  '[ParcelDetails] Update parcel seedling date',
  props<{ parcelRequest: UpdateSeedlingDateRequest }>()
);
export const UpdateParcelSeedlingDateSuccess = createAction(
  '[ParcelDetails] Update parcel seedling date success',
  props<{ successMessage: string }>()
);
export const UpdateParcelSeedlingDateError = createAction(
  '[ParcelDetails] Update parcel seedling date error',
  props<{ errorMessage: string }>()
);
export const UpdateParcelTotalDose = createAction(
  '[ParcelDetails] Update parcel total dose',
  props<{ parcelRequest: UpdateTotalDoseRequest }>()
);
export const UpdateParcelTotalDoseSuccess = createAction('[ParcelDetails] Update parcel total dose success');
export const UpdateParcelTotalDoseError = createAction(
  '[ParcelDetails] Update parcel total dose error',
  props<{ errorMessage: string }>()
);
export const TriggerUpdateParcelStages = createAction(
  '[ParcelDetails] Trigger update parcel stages',
  props<{ parcelUpdated: Parcel }>()
);
export const FindHydro = createAction('[ParcelDetails] Find Hydro', props<{ parcel: GenericParcel }>());
export const FindHydroSuccess = createAction(
  '[ParcelDetails] Find Hydro Success',
  props<{ dailyStatus: DailyStatus[]; hydroDataInformation: HydroDataInformation }>()
);
export const FindHydroError = createAction('[ParcelDetails] Find Hydro Error');
export const FindStageNotifications = createAction(
  '[ParcelDetails] Find Stage Notifications',
  props<{ parcelId: string }>()
);
export const FindStageNotificationsSuccess = createAction(
  '[ParcelDetails] Find Stage Notifications Success',
  props<{ stageNotifications: StageNotifications[] }>()
);
export const FindStageNotificationsError = createAction('[ParcelDetails] Find Stage Notifications Error');
export const SubscribeStageNotifications = createAction(
  '[ParcelDetails] Subscribe Stage Notifications',
  props<{ parcelId: string; phenologicalStageRefId: string }>()
);
export const SubscribeStageNotificationsSuccess = createAction(
  '[ParcelDetails] Subscribe Stage Notifications Success',
  props<{ stageNotification: StageNotifications }>()
);
export const UnsubscribeStageNotifications = createAction(
  '[ParcelDetails] Unsubscribe Stage Notifications',
  props<{ stageNotification: StageNotifications }>()
);
export const UnsubscribeStageNotificationsSuccess = createAction(
  '[ParcelDetails] Unsubscribe Stage Notifications Success',
  props<{ phenologicalStageRefId: string }>()
);
export const SubscribeStageNotificationsError = createAction('[ParcelDetails] Subscribe Stage Notifications Error');

export const FindStageEstimate = createAction('[ParcelDetails] Find Stage Estimate', props<{ parcel: Parcel }>());
export const FindStageEstimateSuccess = createAction(
  '[ParcelDetails] Find Stage Estimate Success',
  props<{ stageEstimates: farmStarApiModel.StageEstimate[] }>()
);
export const FindStageEstimateError = createAction('[ParcelDetails] Find Stage Estimate Error');
export const FindObservationValue = createAction(
  '[ParcelDetails] Find observation value',
  props<{ parcelId: string }>()
);
export const FindObservationSuccess = createAction(
  '[ParcelDetails] Find observation value Success',
  props<{ observations: farmStarApiModel.ObservationBackend[] }>()
);
export const FindObservationError = createAction('[ParcelDetails] Find observation value Error');
export const FindObservationOfPolygon = createAction(
  '[ParcelDetails] Find observation of polygon',
  props<{ polygon: Polygon; featureId: string }>()
);
export const FindObservationOfPolygonSuccess = createAction(
  '[ParcelDetails] Find observation of polygon success',
  props<{ featureId: string; medianBiophy: number }>()
);
export const FindObservationOfPoint = createAction(
  '[ParcelDetails] Find observation of point',
  props<{ point: Point; coordinates: [number, number] }>()
);
export const FindObservationOfPointSuccess = createAction(
  '[ParcelDetails] Find biophy of point success',
  props<{ medianBiophy: number; coordinates: [number, number] }>()
);
export const ResetBiophyOfPoint = createAction('[ParcelDetails] Reset biophy of point');
export const SelectObservation = createAction('[ParcelDetails] Select observation', props<{ observationId: string }>());
export const SaveFilterDate = createAction(
  '[ParcelDetails] Save filter date',
  props<{ year: number; beginMonthIndex: number; endMonthIndex: number }>()
);
export const FindTechnicianData = createAction('[ParcelDetails] Find technician data');
export const FindTechnicianDataSuccess = createAction(
  '[ParcelDetails] Find technician data success',
  props<{ technicianData: BasicUserData }>()
);
export const FindTechnicianDataError = createAction('[ParcelDetails] Find technician data error');
export const ResetParcelData = createAction('[ParcelDetails] Reset parcel data');
export const ActivateDrawMode = createAction('[ParcelDetails] Activate Draw Mode', props<{ drawMode: DrawMode }>());
export const EndDrawMode = createAction('[ParcelDetails] End Draw Mode');
export const DrawEnd = createAction('[ParcelDetails] Draw End', props<{ feature: Feature; featureId: string }>());
export const DeleteFeature = createAction('[ParcelDetails] Delete Features', props<{ featureId: string }>());
export const AddFeatures = createAction('[ParcelDetails] Add Features', props<{ features: FeatureArea[] }>());
export const SelectFeature = createAction('[ParcelDetails] Select Feature', props<{ featureId: string }>());
export const DownloadNDosePdf = createAction('[ParcelDetails] Download N Dose PDF', props<{ parcelCode: string }>());
export const DownloadParcelRecommendationPdf = createAction(
  '[ParcelDetails] Download Biomass PDF',
  props<{ parcelCode: string; recommendationName: string; titre: string }>()
);
export const IsPanelHydroOpened = createAction(
  '[ParcelDetails] Is panel hydro opened',
  props<{ isPanelHydroOpened: boolean }>()
);

export const IsPanelDoseOpened = createAction('[ParcelDetails] Is panel dose opened', props<{ isOpen: boolean }>());

export const FindRecommendationSuccess = createAction(
  '[ParcelDetails] Find recommendation success',
  props<{ recommendation: farmStarApiModel.RecommendationBackend }>()
);
export const FindRecommendationError = createAction('[ParcelDetails] Find recommendation error');
