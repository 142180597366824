import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

@Injectable()
export class FileSaverService {
  constructor() {}

  saveAs(blob: any, fileName: string) {
    FileSaver.saveAs(blob, fileName);
  }
}
