import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Crop } from '@app/overview/shared/farm-star/agro-datum.model';
import { CONFIGURATION, Configuration } from '@app/overview/shared/farm-star/configuration/configuration';
import { toHttpParams } from '@app/shared/utils/http-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgroDatumApiModel } from './agro-datum-api.model';

@Injectable({
  providedIn: 'root',
})
export class AgroDatumApiService {
  public headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  private readonly API = `${this.config.url}/${this.config.api}/fs-core/api`;

  constructor(@Inject(CONFIGURATION) private config: Configuration, private http: HttpClient) {}

  findCrops(params: AgroDatumApiModel.CropRequest): Observable<Crop[]> {
    return this.http
      .get<any>(`${this.API}/crops`, {
        headers: this.headers,
        params: toHttpParams(params),
      })
      .pipe(map(response => response._embedded.crops));
  }
}
