import { Pipe, PipeTransform } from '@angular/core';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { isValidatedAndRejectedTestedData } from '@app/overview/import-database/core/reports-checks';

@Pipe({
  name: 'isValidatedAndRejectedTestedData',
})
export class IsValidatedAndRejectedTestedDataPipe implements PipeTransform {
  transform(value: farmStarApiModel.TestReportItem): boolean {
    return isValidatedAndRejectedTestedData(value);
  }
}
