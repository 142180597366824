import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { ofType } from '@ngrx/effects';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RouterStateUrl } from '@davinkevin/router-store-helper';

export const is = (urlMatcherAsRegex: RegExp) => (url: string) => urlMatcherAsRegex.test(url);

export const ofRoute = (pattern: RegExp) =>
  pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<RouterStateUrl>) => pattern.test(action.payload.routerState.url))
  );

export const oneOfRoutes = (patterns: RegExp[]) =>
  pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<RouterStateUrl>) =>
      patterns.some(pattern => pattern.test(action.payload.routerState.url))
    )
  );

export enum routesNames {
  HELP = 'help',
  COMPARE = 'compare',
  FARMS = 'farms',
  PARCELS = 'parcels',
  TAB_FARMS = 'tab-farms',
  IMPORT_DATABASE = 'import-database',
  NOTIFICATIONS = 'notifications',
  DIVISION = 'division',
}

export const routesRegex = {
  FARMS: /\/farms\/?$/,
  FARMS_PARCELS: /\/farms\/[^\/?]+\/parcels$/,
  FARMS_PARCELS_DETAILS: /\/farms\/[^\/?]+\/parcels\/[^\/?]+$/,
  TAB_FARMS: /\/tab-farms\/?$/,
  TAB_FARMS_WITH_ID: /\/tab-farms\/[^\/?]+$/,
  PARCELS: /\/parcels\/?$/,
  PARCELS_DETAILS: /\/parcels\/[^\/?]+$/,
  IMPORT_DATABASE: /\/import-database$/,
  NOTIFICATIONS: /\/notifications$/,
  HELP: /\/help$/,
  COMPARE: /\/compare$/,
  COMPARE_PARCELS: /\/compare\/parcels$/,
  DIVISION: /\/division$/,
};

export const isFarmsUrl = is(routesRegex.FARMS);
export const isFarmsParcelsUrl = is(routesRegex.FARMS_PARCELS);
export const isFarmsParcelsDetailsUrl = is(routesRegex.FARMS_PARCELS_DETAILS);
export const isTabFarmsUrl = is(routesRegex.TAB_FARMS);
export const isTabFarmsWithIdUrl = is(routesRegex.TAB_FARMS_WITH_ID);
export const isParcelsUrl = is(routesRegex.PARCELS);
export const isParcelsDetailsUrl = is(routesRegex.PARCELS_DETAILS);
export const isImportDatabaseUrl = is(routesRegex.IMPORT_DATABASE);
export const isNotificationsUrl = is(routesRegex.NOTIFICATIONS);
export const isHelpUrl = is(routesRegex.HELP);
export const isCompareUrl = is(routesRegex.COMPARE);
export const isCompareParcelsUrl = is(routesRegex.COMPARE_PARCELS);
export const isDivisionUrl = is(routesRegex.DIVISION);
export const isTabViewUrl = (url: string) => isTabFarmsUrl(url) || isTabFarmsWithIdUrl(url);
export const isParcelsMapUrl = (url: string) => isFarmsParcelsUrl(url) || isFarmsParcelsDetailsUrl(url);
