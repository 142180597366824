<div class="help-title">Comprendre l’indice foliaire (LAI) en maïs</div>

<div class="help-section">
  <div class="help-section-description">
    L’indice foliaire, issu du traitement des images satellites, permet d’avoir une notion de la quantité de biomasse de
    la parcelle. Pour la culture du maïs, cet indicateur est généralement situé entre 0 (pas de végétation) et 7
    (végétation fortement développée). Il est lié à la densité de plantes/ha et au gabarit de la variété utilisée. Il
    traduit le niveau d’interception lumineuse par la plante, composante déterminant le potentiel de rendement
    atteignable maximum de la parcelle.
  </div>
</div>
<div class="word-definition">
  <div class="word-definition-top-section">
    <div class="word-definition-left-section">
      <mat-icon class="info-icon">info</mat-icon>
      <span class="word-definition-left-section-label">Potentiel de rendement atteignable maximum</span>
    </div>
    <button mat-button *ngIf="!showDefinition; else showDefinitionTemplate" (click)="showDefinition = !showDefinition">
      <span>EN SAVOIR PLUS</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <ng-template #showDefinitionTemplate>
      <button mat-button (click)="showDefinition = !showDefinition">
        <span>EN SAVOIR PLUS</span>
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
    </ng-template>
  </div>
  <div class="hidden-definition" *ngIf="showDefinition">
    Potentiel de rendement de la parcelle en l’absence de stress (azoté, hydrique, ravageurs, maladies, etc…) et
    d’accidents (climatiques par exemple). En situation optimale, la valeur d’indice foliaire à viser se situe entre 5
    et 6 à floraison. Cela signifie que 90 % de la lumière est interceptée et que les plantes sont en densité suffisante
    pour valoriser correctement l’énergie lumineuse et la transformer (en biomasse verte et en grain). Une valeur plus
    basse peut être recherchée si, par exemple, l’alimentation en eau est incertaine pendant la période estivale (phase
    de remplissage du grain). Pour les variétés les plus précoces, semées avec des écartements de rangs compris entre 75
    et 80 cm, les valeurs de LAI seront plutôt inférieures en raison du plus petit gabarit des plantes.
  </div>
</div>
<div class="help-section">
  <div class="help-section-description">
    <span
      >L’analyse des cartes d’indice foliaire à plusieurs dates permet de repérer les irrégularités de la parcelle et
      d’en trouver les causes au champ, comme par exemple:</span
    >
    <ul>
      <li>Une densité de semis trop faible par rapport à la variété,</li>
      <li>Des dégâts de ravageurs (taupins, vers gris, scutigérelles, sangliers, etc…)</li>
      <li>Un stress lié au manque d'irrigation, à la grêle ou à un défaut de fertilisation</li>
    </ul>
  </div>
</div>
<div class="help-video">
  <video controls>
    <source [src]="laiVideo" type="video/mp4" />
  </video>
</div>
