import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export const SEND_TO_PRODUCTION_SUCCESS_DIALOG_WIDTH = '650px';
@Component({
  selector: 'fstar-import-database-details-dialog',
  templateUrl: './send-to-production-success-dialog.component.html',
  styleUrls: ['./send-to-production-success-dialog.component.scss'],
})
export class SendToProductionSuccessDialogComponent {
  constructor(private dialogRef: MatDialogRef<SendToProductionSuccessDialogComponent>) {}

  onClose() {
    this.dialogRef.close();
  }
}
