<mat-tab-group
  mat-stretch-tabs
  [selectedIndex]="selectedIndex"
  (selectedTabChange)="changePanel($event)"
  *ngIf="!displayEditionLayer; else rainEdition"
>
  <ng-container *ngIf="parcelDetailData && parcel">
    <mat-tab label="INFOS">
      <div class="info-tab">
        <fstar-parcel-identification
          [userRole]="userRole$ | async"
          [parcel]="parcel"
          [farm]="selectedFarm$ | async"
          [technician]="technicianOfSelectedFarm$ | async"
        ></fstar-parcel-identification>

        <div class="parcel-data">
          <div>Fiche parcelle</div>
          <table>
            <tbody>
              <tr>
                <td>Surface mesurée</td>
                <td *ngIf="parcel.measuredSurface | isNotNullOrZero; else parcelValueNotSpecified">
                  {{ parcel.measuredSurface | numberFr }} ha
                </td>
              </tr>
              <tr>
                <td>Ilôt</td>
                <td *ngIf="parcel.pacBlockNumber; else parcelValueNotSpecified">{{ parcel.pacBlockNumber }}</td>
              </tr>
              <tr>
                <td>Culture</td>
                <td *ngIf="parcelDetailData.cropLabel as cropLabel; else parcelValueNotSpecified">{{ cropLabel }}</td>
              </tr>
              <tr>
                <td>Espèce</td>
                <td *ngIf="parcelDetailData.kindType as varietyType; else parcelValueNotSpecified">
                  {{ varietyType }}
                </td>
              </tr>
              <ng-container *ngIf="parcel.agroData?.crop?.linkedVarietyUsed; else onlySownVarietyOnInfoTab">
                <tr>
                  <td>Variété semée</td>
                  <td *ngIf="parcel.agroData?.crop?.linkedVarietyName; else parcelValueNotSpecified">
                    {{ parcel.agroData.crop.linkedVarietyName }}
                  </td>
                </tr>
                <tr>
                  <td>Variété de rattachement</td>
                  <td *ngIf="parcelDetailData.varietyType as varietyType; else parcelValueNotSpecified">
                    {{ varietyType }}
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td>Type de sol</td>
                <td
                  *ngIf="
                    parcel.agroData?.soilInputLabel | isNotNullOrEmpty;
                    else parcelSoilWithoutSoilInputLabelInInfoTab
                  "
                >
                  {{ parcel.agroData.soilInputLabel }}
                </td>
              </tr>
              <tr>
                <td>Précédent</td>
                <td *ngIf="parcelDetailData.previousCrop as previousCrop; else parcelValueNotSpecified">
                  {{ previousCrop }}
                </td>
              </tr>
              <tr>
                <td>Objectif de rendement</td>
                <td *ngIf="parcel.agroData?.medianYieldTarget | isNotNullOrZero; else parcelValueNotSpecified">
                  {{ parcel.agroData.medianYieldTarget | numberFr: TPerHaFormat }} T/Ha
                </td>
              </tr>
              <tr>
                <td>Date de semis</td>
                <td
                  *ngIf="
                    parcel.agroData?.activities?.seedingActivity?.actualSeedingDate ||
                      parcel.agroData?.activities?.seedingActivity?.seedingDate;
                    else parcelValueNotSpecified
                  "
                >
                  {{
                    parcel.agroData.activities.seedingActivity.actualSeedingDate ||
                      parcel.agroData.activities.seedingActivity.seedingDate | date: 'dd/MM/yyyy'
                  }}
                </td>
              </tr>
              <tr>
                <td>Densité de semis</td>
                <td
                  *ngIf="
                    parcel.agroData?.activities?.seedingActivity?.density | isNotNullOrZero;
                    else parcelValueNotSpecified
                  "
                >
                  {{ parcel.agroData.activities.seedingActivity.density | numberFr: kgPerHaFormat }} plantes/ha
                </td>
              </tr>
              <tr>
                <td>Irrigation</td>
                <td>{{ parcel.agroData.activities.irrigation.irrigated ? 'oui' : 'non' }}</td>
              </tr>
              <tr>
                <td>Devenir du résidu du précédent</td>
                <td
                  *ngIf="
                    parcelDetailData.previousCropWasteUsage as previousCropWasteUsage;
                    else parcelValueNotSpecified
                  "
                >
                  {{ previousCropWasteUsage }}
                </td>
              </tr>
              <tr>
                <td>Effet retournement prairie</td>
                <td
                  *ngIf="
                    parcel.agroData?.nitrogenStatus?.grasslandEffect | isNotNullOrZero;
                    else parcelValueNotSpecified
                  "
                >
                  {{ parcel.agroData.nitrogenStatus.grasslandEffect | numberFr: kgPerHaFormat }} Kg/Ha
                </td>
              </tr>
              <tr>
                <td>Effet CIPAN</td>
                <td
                  *ngIf="
                    parcel.agroData?.nitrogenStatus?.catchCropEffect | isNotNullOrZero;
                    else parcelValueNotSpecified
                  "
                >
                  {{ parcel.agroData.nitrogenStatus.catchCropEffect | numberFr: kgPerHaFormat }} Kg/Ha
                </td>
              </tr>
            </tbody>
          </table>
          <ng-template #onlySownVarietyOnInfoTab>
            <tr>
              <td>Variété semée</td>
              <td *ngIf="parcelDetailData.varietyType as varietyType; else parcelValueNotSpecified">
                {{ varietyType }}
              </td>
            </tr>
          </ng-template>
          <ng-template #parcelSoilWithoutSoilInputLabelInInfoTab>
            <td *ngIf="parcelDetailData.soilType; else parcelValueNotSpecified">
              {{ parcelDetailData.soilType }}
            </td>
          </ng-template>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="DOSE TOTALE">
      <div class="dose-tab">
        <fstar-parcel-identification
          [userRole]="userRole$ | async"
          [parcel]="parcel"
          [farm]="selectedFarm$ | async"
          [technician]="technicianOfSelectedFarm$ | async"
        ></fstar-parcel-identification>
        <div class="total-nitrogen data-background">
          <div class="total-nitrogen-label">Dose totale azote</div>
          <div
            class="total-nitrogen-value"
            *ngIf="
              (parcel.agroData?.nitrogenStatus?.projectedDose | isNotNullOrZero) && showTotalNitrogenDose;
              else valueNotSpecified
            "
          >
            {{ parcel.agroData.nitrogenStatus.projectedDose | numberFr: kgPerHaFormat }} U
          </div>
        </div>
        <div
          class="dose-buttons-container"
          [class.see-details-visible]="parcel.agroData?.nitrogenStatus?.totalNeed && totalDoseCalculationMethods?.label"
        >
          <button
            class="download-total-dose"
            [disabled]="!showTotalNitrogenDose"
            mat-button
            (click)="downloadNDosePdf()"
          >
            <svg aria-hidden="true">
              <use href="assets/images/sprites.svg#download"></use>
            </svg>
            <span>{{ showTotalNitrogenDose ? 'Télécharger le PDF' : 'Téléchargement non disponible' }}</span>
          </button>
        </div>
        <div class="total-dose-details data-background">
          <div class="total-dose-details-title">Données initiales :</div>
          <div class="detail-data">
            <div class="detail-data-label">Objectif de rendement :</div>
            <div
              class="detail-data-value"
              *ngIf="parcel.agroData?.medianYieldTarget | isNotNullOrZero; else valueNotSpecified"
            >
              {{ parcel.agroData.medianYieldTarget | numberFr: TPerHaFormat }} T/Ha
            </div>
          </div>
          <ng-container *ngIf="parcel.agroData?.crop?.linkedVarietyUsed; else onlySownVarietyOnDoseTab">
            <div class="detail-data">
              <div class="detail-data-label">Variété semée :</div>
              <div class="detail-data-value" *ngIf="parcel.agroData?.crop?.linkedVarietyName; else valueNotSpecified">
                {{ parcel.agroData.crop.linkedVarietyName }}
              </div>
            </div>
            <div class="detail-data">
              <div class="detail-data-label">Variété de rattachement :</div>
              <div
                class="detail-data-value"
                *ngIf="parcelDetailData.varietyType as varietyType; else valueNotSpecified"
              >
                {{ varietyType }}
              </div>
            </div>
          </ng-container>
          <div class="detail-data">
            <div class="detail-data-label">Type de sol :</div>
            <div
              class="detail-data-value"
              *ngIf="parcel.agroData?.soilInputLabel | isNotNullOrEmpty; else parcelSoilWithoutSoilInputLabelInDoseTab"
            >
              {{ parcel.agroData.soilInputLabel }}
            </div>
          </div>

          <div class="detail-data">
            <div class="detail-data-label">Date de semis :</div>
            <div
              class="detail-data-value"
              *ngIf="parcel.agroData?.activities?.seedingActivity?.seedingDate; else valueNotSpecified"
            >
              {{ parcel.agroData.activities.seedingActivity.seedingDate | date: 'dd/MM/yyyy' }}
            </div>
          </div>

          <div class="calculation-information">
            <div class="detail-data">
              <div class="detail-data-label">Date de calcul :</div>
              <div
                class="detail-data-value"
                *ngIf="
                  parcel.agroData?.nitrogenStatus?.projectedDoseComputationDate && showTotalNitrogenDose;
                  else valueNotSpecified
                "
              >
                {{ parcel.agroData.nitrogenStatus.projectedDoseComputationDate | date: 'dd/MM/yyyy' }}
              </div>
            </div>
            <div class="detail-data">
              <div class="detail-data-label">Méthode de calcul :</div>
              <div class="detail-data-value" *ngIf="totalDoseCalculationMethods?.label; else valueNotSpecified">
                {{ totalDoseCalculationMethods.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="update-parcel-data-section data-background" *ngIf="displayRemainingMeasuredForm">
          <div class="update-parcel-data-section-title">Mise à jour des données :</div>
          <div class="form-container" [class.vertical-form-container]="isSmallScreen">
            <!-- Move to STADES tab later-->
            <!-- <form [formGroup]="seedlingDateForm">
              <div class="field-section-grid">
                <div class="field-section">
                  <div class="field-section-label">Date de semis :</div>
                  <div
                    class="field-section-previous-value"
                    *ngIf="
                      parcel.agroData?.activities?.seedingActivity?.actualSeedingDate | isNotNullOrEmpty;
                      else actualSeedingDateNotUpdated
                    "
                  >
                    {{ parcel.agroData.activities.seedingActivity.actualSeedingDate | date: 'dd/MM/yyyy' }}
                  </div>
                  <ng-template #actualSeedingDateNotUpdated>
                    <div class="field-section-previous-value">pas de mise à jour</div>
                  </ng-template>
                </div>
                <div class="update-parcel-field">
                  <mat-form-field floatLabel="never">
                    <input
                      matInput
                      readonly
                      placeholder="__\__\____"
                      (click)="dpSeedlingDate.open()"
                      [min]="minDate"
                      [max]="maxDate"
                      [matDatepicker]="dpSeedlingDate"
                      formControlName="seedlingDate"
                    />
                    <mat-datepicker-toggle matSuffix [for]="dpSeedlingDate">
                      <svg matDatepickerToggleIcon aria-hidden="true">
                        <use xlink:href="assets/images/sprites.svg#date"></use>
                      </svg>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dpSeedlingDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <button
                  mat-raised-button
                  class="update-parcel-button"
                  (click)="updateSeedlingDate()"
                  [disabled]="!seedlingDateForm.get('seedlingDate').value"
                  [class.update-parcel-button-disabled]="!seedlingDateForm.get('seedlingDate').value"
                >
                  <p>Mettre à jour</p>
                </button>
              </div>
            </form> -->
            <form [formGroup]="remainingMeasuredForm">
              <div class="field-section-grid">
                <div class="field-section">
                  <div class="field-section-label">Reliquat mesuré :</div>
                  <div
                    class="field-section-previous-value"
                    *ngIf="
                      parcel.agroData?.nitrogenStatus?.actualMeasuredNitrogenCredit | isNotNullOrZero;
                      else remainingMeasuredNotUpdated
                    "
                  >
                    {{ parcel.agroData.nitrogenStatus.actualMeasuredNitrogenCredit | numberFr: kgPerHaFormat }}
                    U
                  </div>
                  <ng-template #remainingMeasuredNotUpdated>
                    <div class="field-section-previous-value">Non renseigné</div>
                  </ng-template>
                </div>
                <div class="update-parcel-field">
                  <mat-form-field>
                    <input
                      matInput
                      type="number"
                      placeholder=""
                      min="0"
                      max="200"
                      autocomplete="off"
                      formControlName="remainingMeasured"
                    />
                    <div class="update-parcel-field-suffix" matSuffix>U</div>
                    <mat-error *ngIf="remainingMeasuredForm.invalid">
                      La valeur doit être un entier compris entre 0 et 200
                    </mat-error>
                  </mat-form-field>
                </div>
                <button
                  mat-raised-button
                  class="update-parcel-button"
                  (click)="updateTotalDose()"
                  [disabled]="
                    !(remainingMeasuredForm.get('remainingMeasured').value | isNotNullOrZero) ||
                    remainingMeasuredForm.invalid
                  "
                  [class.update-parcel-button-disabled]="
                    !(remainingMeasuredForm.get('remainingMeasured').value | isNotNullOrZero) ||
                    remainingMeasuredForm.invalid
                  "
                >
                  <p>Mettre à jour</p>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="see-details">
          <button *ngIf="showTotalNitrogenDose" mat-raised-button (click)="openTotalDoseDetailsDialog()">
            <p>Voir détails</p>
          </button>
        </div>
      </div>
      <ng-template #onlySownVarietyOnDoseTab>
        <div class="detail-data">
          <div class="detail-data-label">Variété semée :</div>
          <div class="detail-data-value" *ngIf="parcelDetailData.varietyType as varietyType; else valueNotSpecified">
            {{ varietyType }}
          </div>
        </div>
      </ng-template>
      <ng-template #parcelSoilWithoutSoilInputLabelInDoseTab>
        <div class="detail-data-value" *ngIf="parcelDetailData.soilType; else parcelValueNotSpecified">
          {{ parcelDetailData.soilType }}
        </div>
      </ng-template>
      <ng-template #parcelValueNotSpecified>
        <td class="parcel-value-not-specified">Non renseigné</td>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="parcelDetailData.cropLabel !== 'COLZA'" label="STADES">
      <div class="stage-tab">
        <fstar-parcel-identification
          [userRole]="userRole$ | async"
          [parcel]="parcel"
          [farm]="selectedFarm$ | async"
          [technician]="technicianOfSelectedFarm$ | async"
        ></fstar-parcel-identification>

        <ng-container *ngIf="stage$ | async as stages">
          <div class="available-soon" *ngIf="stages.length === 0">
            <svg aria-hidden="true">
              <use href="assets/images/sprites.svg#waiting" class="waiting-icon"></use>
            </svg>
            <div>Informations bientôt disponibles</div>
          </div>
          <ul *ngIf="stages.length > 0">
            <div
              class="stage-notifications-information"
              *ngIf="isDisplayMessageForSelectedNotificationsPreferences$ | async"
            >
              <mat-icon>warning</mat-icon>
              <span
                >Vous n'avez saisi aucune préférence de notification. Pour vous inscrire à des notifications de
                changement de stade, merci de configurer vos préférences de notification</span
              >
            </div>
            <div class="harvest-container" [class.harvest-container-disabled]="harvestSectionIsDisabled">
              <svg aria-hidden="true">
                <use href="assets/images/sprites-stages.svg#corn"></use>
              </svg>
              <span>Récolte</span>
            </div>
            <ng-container *ngFor="let code of orderedPhenologicalStageCodeList">
              <fstar-stage-item
                *ngIf="(stageByPhenologicalCode$ | async)(code) as stage"
                [stageDate]="stage.stageDate"
                [phenologicalStage]="(phenologicalStage$ | async)[stage.phenologicalStageRefId]"
                [stageNotifications]="(stageNotifications$ | async)[stage.phenologicalStageRefId]"
                [isCurrentStage]="stage.isCurrentStage"
                [isAvailableToNotifications]="isAvailableToNotifications$ | async"
                (subscribeNotifications)="subscribeNotificationsChanged($event)"
                (unsubscribeNotifications)="unsubscribeNotificationsChanged($event)"
              >
              </fstar-stage-item>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab *ngIf="parcelDetailData.cropLabel !== 'COLZA'" label="HYDRO">
      <div class="hydro-tab">
        <fstar-parcel-identification
          [userRole]="userRole$ | async"
          [parcel]="parcel"
          [farm]="selectedFarm$ | async"
          [technician]="technicianOfSelectedFarm$ | async"
        ></fstar-parcel-identification>
        <div class="water-deficit data-background">
          <div class="water-deficit-label">Déficit hydrique</div>
          <div
            class="water-deficit-value"
            *ngIf="
              (hydroDataInformation$ | async)?.irrigationStatus?.lastDailyStatus?.waterDeficit | isNotNullOrZero;
              else noWaterDeficitValue
            "
          >
            {{ (hydroDataInformation$ | async).irrigationStatus.lastDailyStatus.waterDeficit | number: '1.0-0' }} mm
          </div>
          <ng-template #noWaterDeficitValue>
            <div class="value-not-specified">-</div>
          </ng-template>
        </div>
        <ng-container *ngIf="hydroDataInformation$ | async as hydroDataInformation">
          <div class="available-soon" *ngIf="!hydroDataInformation.maxDailyStatus; else dailyStatusValues">
            <svg aria-hidden="true">
              <use href="assets/images/sprites.svg#waiting" class="waiting-icon"></use>
            </svg>
            <div>Informations bientôt disponibles</div>
          </div>
          <ng-template #dailyStatusValues>
            <div class="daily-status">
              <div class="daily-status-label">RU Max</div>
              <div class="daily-status-value">
                {{ hydroDataInformation.maxDailyStatus.usableReserve | number: '1.0-0' }} mm
              </div>
            </div>
            <div class="daily-status">
              <div class="daily-status-label">RFU Max</div>
              <div class="daily-status-value">
                {{ hydroDataInformation.maxDailyStatus.easilyUsableReserve | number: '1.0-0' }} mm
              </div>
            </div>
            <ul class="rain-irrigation">
              <li class="rain-irrigation-header">
                <div></div>
                <div>
                  <svg aria-hidden="true">
                    <use href="assets/images/sprites.svg#rain"></use>
                  </svg>
                  <span>Pluie</span>
                </div>
                <div>
                  <svg aria-hidden="true">
                    <use href="assets/images/sprites.svg#tapwater"></use>
                  </svg>
                  <span>Irrigation</span>
                </div>
              </li>
              <li class="rain-irrigation-item" *ngFor="let timeline of hydroDataInformation.timelineRainAndIrrigation">
                <div>
                  <span class="date-month">
                    {{ timeline.date | date: 'MMMM':'':'fr' | titlecase }}
                    <div class="date-year">
                      {{ timeline.date | date: 'yyyy' }}
                    </div>
                  </span>
                </div>
                <div class="rain-item" [class.rain-item-current]="timeline.date === currentDate">
                  <span>{{ timeline.rain | number: '1.0-0' }} mm</span>
                  <button
                    (mouseenter)="setCurrentlyHoveredRainDate(timeline.date)"
                    (mouseleave)="setCurrentlyHoveredRainDate(null)"
                    (click)="openRainEdition()"
                    class="hydro-edit"
                    [class.hydro-edit-button]="timeline.date !== hoveredRainDate"
                    mat-mini-fab
                    *ngIf="!isFutureDate(timeline.date)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
                <div>
                  <span>{{ timeline.irrigation | number: '1.0-0' }} mm</span>
                  <button
                    (click)="openIrrigationEdition()"
                    (mouseenter)="setCurrentlyHoveredIrrigationDate(timeline.date)"
                    (mouseleave)="setCurrentlyHoveredIrrigationDate(null)"
                    class="hydro-edit"
                    [class.hydro-edit-button]="timeline.date !== hoveredIrrigationDate"
                    mat-mini-fab
                    *ngIf="!isFutureDate(timeline.date)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </li>
            </ul>
          </ng-template>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab *ngIf="parcelDetailData.cropLabel === 'COLZA'" label="BIOMASSE">
      <div class="biomass-tab">
        <fstar-parcel-identification
          [userRole]="userRole$ | async"
          [parcel]="parcel"
          [farm]="selectedFarm$ | async"
          [technician]="technicianOfSelectedFarm$ | async"
        ></fstar-parcel-identification>
        <div class="data-background" style="margin-bottom: 50px">
          <div class="biomass-comment" *ngIf="parcelRecommendationComment | isNotNullOrEmpty; else valueNotSpecified">
            {{ parcelRecommendationComment }}
          </div>
        </div>
        <div class="data-background">
          <div class="biomass">
            <div class="biomass-label">Biomasse entrée d'hiver</div>
            <div
              class="biomass-value"
              *ngIf="
                parcelDetailData?.earlyWinterParcelRecommendation?.meanValue | isNotNullOrZero;
                else valueNotSpecified
              "
            >
              {{ parcelDetailData?.earlyWinterParcelRecommendation.meanValue }} kg/m<sup>2</sup>
            </div>
          </div>

          <div class="biomass-date" *ngIf="parcelDetailData?.earlyWinterParcelRecommendation?.date | isNotNullOrZero">
            Production : {{ parcelDetailData?.earlyWinterParcelRecommendation.date }}
          </div>
        </div>
        <div
          class="biomass-buttons-container"
          [class.see-details-visible]="parcel.agroData?.nitrogenStatus?.totalNeed && totalDoseCalculationMethods?.label"
        >
          <button
            class="download-early-biomass"
            [disabled]="!parcelDetailData.earlyWinterParcelRecommendation"
            mat-button
            (click)="downloadBiomassReport('EARLY')"
          >
            <svg aria-hidden="true">
              <use href="assets/images/sprites.svg#download"></use>
            </svg>
            <span>{{
              parcelDetailData.earlyWinterParcelRecommendation ? 'Télécharger' : 'Téléchargement non disponible'
            }}</span>
          </button>
        </div>
        <div class="data-background">
          <div class="biomass">
            <div class="biomass-label">Biomasse sortie d'hiver</div>
            <div
              class="biomass-value"
              *ngIf="
                parcelDetailData?.lateWinterParcelRecommendation?.meanValue | isNotNullOrZero;
                else valueNotSpecified
              "
            >
              {{ parcelDetailData?.lateWinterParcelRecommendation.meanValue }} kg/m<sup>2</sup>
            </div>
          </div>
          <div class="biomass-date" *ngIf="parcelDetailData?.lateWinterParcelRecommendation?.date | isNotNullOrZero">
            Production : {{ parcelDetailData?.lateWinterParcelRecommendation.date }}
          </div>
        </div>
        <div
          class="biomass-buttons-container"
          [class.see-details-visible]="parcel.agroData?.nitrogenStatus?.totalNeed && totalDoseCalculationMethods?.label"
        >
          <button
            class="download-late-biomass"
            [disabled]="!parcelDetailData.lateWinterParcelRecommendation"
            mat-button
            (click)="downloadBiomassReport('LATE')"
          >
            <svg aria-hidden="true">
              <use href="assets/images/sprites.svg#download"></use>
            </svg>
            <span>{{
              parcelDetailData.lateWinterParcelRecommendation ? 'Télécharger' : 'Téléchargement non disponible'
            }}</span>
          </button>
        </div>
      </div>
    </mat-tab>
  </ng-container>
</mat-tab-group>
<ng-template #valueNotSpecified>
  <div class="value-not-specified">Non renseigné</div>
</ng-template>

<ng-template #rainEdition>
  <fstar-rain-irrigation-edition
    [editionMode]="editionMode"
    (closeComponent)="closeRainEdition()"
  ></fstar-rain-irrigation-edition>
</ng-template>
