import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  FindObservationValue,
  FindObservationError,
  FindObservationSuccess,
  SelectObservation,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { Action, createReducer, on } from '@ngrx/store';

export interface State extends EntityState<farmStarApiModel.ObservationBackend> {
  isLoadingBiophy: boolean;
  selectedBiophyId: string;
}
export const adapter: EntityAdapter<farmStarApiModel.ObservationBackend> = createEntityAdapter<
  farmStarApiModel.ObservationBackend
>({
  selectId: observation => observation.id,
});

const initialState: State = adapter.getInitialState({
  isLoadingBiophy: false,
  selectedBiophyId: null,
});

const reducer = createReducer(
  initialState,
  on(FindObservationValue, state => ({
    ...state,
    isLoadingBiophy: true,
  })),
  on(SelectObservation, (state, { observationId: selectedBiophyId }) => ({
    ...state,
    selectedBiophyId,
  })),
  on(FindObservationSuccess, (state, { observations }) => ({
    ...adapter.addAll(observations, state),
    isLoadingBiophy: false,
  })),
  on(FindObservationError, state => ({ ...adapter.addAll([], state), isLoadingBiophy: false }))
);

export function biophyEntityReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
