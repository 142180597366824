import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

const decimalPipe = new DecimalPipe('fr-FR');

@Pipe({
  name: 'numberFr',
})
export class NumberFrPipe implements PipeTransform {
  transform(value: number, digitsInfo?: string): string {
    const param = digitsInfo ? digitsInfo : '1.2-2';
    return decimalPipe.transform(value, param);
  }
}
