import { ObservationService } from './../../observation/observation.service';
import { Inject, Injectable } from '@angular/core';
import { CONFIGURATION, Configuration } from '../configuration/configuration';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CropBackend,
  CropResponse,
  CustomerDataCampaign,
  PreviousCropBackend,
  PreviousCropWasteUsageBackend,
  RegisteredCropsBackend,
} from '@app/overview/import-database/import-database.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { toHttpParams } from '@app/shared/utils/http-utils';
import {
  DailyStatus,
  IrrigationStatus,
  Parcel,
  ParcelResponse,
  ParcelResponseBackend,
  RegisteredFarmersParamRequest,
  RegisteredTechniciansParamRequest,
  StageNotifications,
  UpdateSeedlingDateRequest,
  UpdateTotalDoseRequest,
} from '@app/overview/shared/farm-star/parcel.model';
import { Feature, Polygon, Point } from 'geojson';
import {
  RegisteredCooperativeRequest,
  RegisteredCooperativeResponse,
  RegisteredFarmersResponse,
  RegisteredTechniciansResponse,
} from '@app/overview/shared/farm-star/farmer.model';
import { UserRole, UserRoleTranslate } from '@app/overview/overview.models';
import { map, tap } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';
import { transformExtent } from 'ol/proj';
import CooperativeBackend = farmStarApiModel.CooperativeBackend;
import UserRoleAndUserDataResponse = farmStarApiModel.UserRoleAndUserDataResponse;
import UserDataAndUserRole = farmStarApiModel.UserDataAndUserRole;
import AllUserTypeData = farmStarApiModel.AllUserTypeData;
import CoordinatorCooperativesResponse = farmStarApiModel.CoordinatorCooperativesResponse;
import CooperativesResponse = farmStarApiModel.CooperativesResponse;
import IrrigationStatusResponseBackEnd = farmStarApiModel.IrrigationStatusResponseBackEnd;
import DailyStatusResponseBackEnd = farmStarApiModel.DailyStatusResponseBackEnd;
import StageNotificationsResponseBackEnd = farmStarApiModel.StageNotificationsResponseBackEnd;
import { Preferences } from '@app/overview/notifications/notifications.model';
import RecommendationResponse = farmStarApiModel.RecommendationResponse;
import RecommendationBackend = farmStarApiModel.RecommendationBackend;
import { Crop } from '../agro-datum.model';
import { CoreModel } from '../core.model';

@Injectable()
export class FarmStarService {
  headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private observationService: ObservationService,
    private http: HttpClient,
    @Inject(CONFIGURATION) private config: Configuration
  ) {}

  getUserIdpIdentifier(): Observable<string> {
    return this.http
      .get<AllUserTypeData>(`${this.config.url}/${this.config.api}/fs-core/api/users/me`, {
        headers: this.headers,
      })
      .pipe(map((response: AllUserTypeData) => response.idpIdentifier));
  }

  findRecommendation(recommendationCode: string): Observable<RecommendationBackend> {
    return this.http
      .get<RecommendationResponse>(
        `${this.config.url}/${this.config.api}/fs-core/api/recommendations?code=${recommendationCode}`,
        {
          headers: this.headers,
        }
      )
      .pipe(map(recommendationResponse => recommendationResponse._embedded.recommendations[0]));
  }

  findUserRoleAndUserData(idpIdentifier: string): Observable<UserDataAndUserRole> {
    return this.http
      .get<UserRoleAndUserDataResponse>(`${this.config.url}/${this.config.api}/fs-core/api/users`, {
        headers: this.headers,
        params: toHttpParams({ idpIdentifier }),
      })
      .pipe(
        tap(response => {
          if (
            Object.keys(response._embedded).length === 0 ||
            (Object.keys(response._embedded).length > 0 && Object.values(response._embedded)[0].length === 0)
          ) {
            throw new Error("Vous n'avez pas de rôle");
          }
        }),
        map((response: UserRoleAndUserDataResponse) => {
          const userInfo: AllUserTypeData[] = Object.values(response._embedded)[0];
          return {
            userData: userInfo[0],
            userRole: this.getUserRole(userInfo[0]),
            userRoleLabel: UserRoleTranslate[userInfo[0].profile],
          };
        })
      );
  }

  saveNotificationsPreferences(preferences: Preferences, userId: string): Observable<farmStarApiModel.AllUserTypeData> {
    return this.http.patch<farmStarApiModel.AllUserTypeData>(
      `${this.config.url}/${this.config.api}/fs-core/api/users/${userId}`,
      preferences,
      {
        headers: this.headers,
      }
    );
  }

  getLayerStatOfPolygon(geometry: Polygon, observationId: string): Observable<number> {
    return this.http
      .post<farmStarApiModel.LayerStat>(
        `${this.config.url}/${this.config.api}/fs-core/api/observations/${observationId}/lai/statistics`,
        geometry
      )
      .pipe(map(response => (response ? parseFloat(response.median.toFixed(1)) : null)));
  }

  getLayerStatOfPoint(geometry: Point, observationId: string): Observable<number> {
    return this.http
      .post<[number]>(
        `${this.config.url}/${this.config.api}/fs-core/api/observations/${observationId}/lai/pointValue`,
        geometry
      )
      .pipe(
        map(response => {
          const biophyValue = response ? parseFloat(response[0].toFixed(1)) : null;
          return biophyValue > -1 ? biophyValue : null;
        })
      );
  }

  findParcelObservationsNumberOfResults(parcelId: string): Observable<number> {
    return this.http
      .get<farmStarApiModel.ObservationsResponse>(
        `${this.config.url}/${this.config.api}/fs-core/api/observations?blacklisted=false&parcel.id=${parcelId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(map(response => response.page.totalElements));
  }

  findMultiParcelObservationsNumberOfResults(parcelIds: string[]): Observable<number> {
    const params = {
      'parcel.id': parcelIds,
      blacklisted: 'false',
      projection: 'observationWithLayers',
    };
    return this.http
      .get<farmStarApiModel.ObservationsResponse>(`${this.config.url}/${this.config.api}/fs-core/api/observations`, {
        headers: this.headers,
        params,
      })
      .pipe(map(response => response.page.totalElements));
  }

  findParcelObservations(size: number, parcelId: string): Observable<farmStarApiModel.ObservationBackend[]> {
    const params = new HttpParams()
      .set('blacklisted', 'false')
      .set('parcel.id', parcelId)
      .set('size', size.toString())
      .set('projection', 'observationWithLayers');
    return this.http
      .get<farmStarApiModel.ObservationsResponse>(`${this.config.url}/${this.config.api}/fs-core/api/observations`, {
        headers: this.headers,
        params,
      })
      .pipe(
        map((response: farmStarApiModel.ObservationsResponse) => {
          return response._embedded.observations.sort(
            (a: farmStarApiModel.ObservationBackend, b: farmStarApiModel.ObservationBackend) =>
              new Date(a.acquisition.sensingDateTime).getTime() - new Date(b.acquisition.sensingDateTime).getTime()
          );
        })
      );
  }

  findMultiParcelLastObservation(
    size: number,
    parcelIds: string[]
  ): Observable<farmStarApiModel.BiophyValueByParcel[]> {
    const params = {
      size: size.toString(),
      'parcel.id': parcelIds,
      blacklisted: 'false',
      projection: 'observationWithLayers',
    };
    return this.http
      .get<farmStarApiModel.ObservationsResponse>(`${this.config.url}/${this.config.api}/fs-core/api/observations`, {
        headers: this.headers,
        params,
      })
      .pipe(
        map((response: farmStarApiModel.ObservationsResponse) => {
          const observationsResponse = response._embedded.observations;

          return parcelIds.map(parcelId => {
            const observations = observationsResponse
              .filter((observation: farmStarApiModel.ObservationBackend) => observation.parcel.id === parcelId)
              .sort(
                (a: farmStarApiModel.ObservationBackend, b: farmStarApiModel.ObservationBackend) =>
                  new Date(a.acquisition.sensingDateTime).getTime() - new Date(b.acquisition.sensingDateTime).getTime()
              );

            const lastObservation = observations.length > 0 ? observations[observations.length - 1] : null;
            return {
              parcelId,
              median: lastObservation
                ? parseFloat(this.observationService.findLaiLayer(lastObservation).metadata.stats.median.toFixed(1))
                : null,
              imageLink: lastObservation ? this.observationService.getLaiLayerLargePngUrl(lastObservation) : null,
              imageExtent: lastObservation
                ? this.transformImageExtent(
                    this.observationService.getExtent(
                      this.observationService.getA3iDisplayFullCycleIllustration(lastObservation)
                    )
                  )
                : null,
            };
          });
        })
      );
  }

  findAllCooperatives(size?: number): Observable<CooperativesResponse> {
    const params = size ? { size: `${size}` } : {};
    return this.http
      .get<farmStarApiModel.CooperativesResponse>(`${this.config.url}/${this.config.api}/fs-core/api/cooperatives`, {
        headers: this.headers,
        params,
      })
      .pipe(map(response => response));
  }

  findCoordinatorCooperatives(url: string): Observable<CooperativeBackend[]> {
    return this.http
      .get<CoordinatorCooperativesResponse>(url, {
        headers: this.headers,
      })
      .pipe(map((response: CoordinatorCooperativesResponse) => response._embedded.cooperatives));
  }

  findAllPhenologicalStages(url: string, size?: number): Observable<farmStarApiModel.PhenologicalStageResponse> {
    const params = size ? { size: `${size}` } : {};
    return this.http
      .get<farmStarApiModel.PhenologicalStageResponse>(url, {
        headers: this.headers,
        params,
      })
      .pipe(map(response => response));
  }

  getStageEstimates(
    parcel: Parcel,
    phenologicalStagesEntities: Dictionary<farmStarApiModel.PhenologicalStageBackend>
  ): Observable<farmStarApiModel.StageEstimate[]> {
    return this.http
      .get<farmStarApiModel.StageEstimatesResponse>(
        `${this.config.url}/${this.config.api}/fs-core/api/stageEstimates/search/phenologicalStagesLastEstimateStageBy`,
        {
          headers: this.headers,
          params: toHttpParams({ parcelId: parcel.id, projection: 'phenologicalStage' }),
        }
      )
      .pipe(
        map(response => response._embedded.stageEstimates),
        map((stageEstimates: farmStarApiModel.StageEstimateBackend[]) =>
          this.removeEmptyStageAndUnknownStages(stageEstimates, phenologicalStagesEntities)
        ),
        map(this.removeDuplicatesStages),
        map((stageEstimates: farmStarApiModel.StageEstimateBackend[]) => {
          return stageEstimates.length > 0
            ? this.findCurrentStageAndNextStage(this.addSeedlingStage(stageEstimates, parcel))
            : [];
        })
      );
  }

  updateParcelMeasuredNitrogenCredit(parcelRequest: UpdateTotalDoseRequest): Observable<Parcel> {
    const body = {
      agroData: {
        nitrogenStatus: {
          actualMeasuredNitrogenCredit: parcelRequest.remainingMeasured,
        },
      },
    };

    return this.http
      .patch<Parcel>(`${this.config.url}/${this.config.api}/fs-core/api/parcels/${parcelRequest.parcelId}`, body, {
        headers: this.headers,
      })
      .pipe(map((parcel: Parcel) => parcel));
  }

  updateParcelSeedlingDate(parcelRequest: UpdateSeedlingDateRequest): Observable<Parcel> {
    const body = {
      agroData: {
        activities: {
          seedingActivity: {
            actualSeedingDate: parcelRequest.seedlingDate,
          },
        },
      },
    };

    return this.http
      .patch<Parcel>(`${this.config.url}/${this.config.api}/fs-core/api/parcels/${parcelRequest.parcelId}`, body, {
        headers: this.headers,
      })
      .pipe(map((parcel: Parcel) => parcel));
  }

  triggerTotalNCalcul(parcelId: string): Observable<any> {
    return this.http.post<any>(`${this.config.url}/${this.config.api}/fs-totaln/api/registerParcel/${parcelId}`, null, {
      headers: this.headers,
    });
  }

  getIrrigationStatus(parcelIds: string[]): Observable<IrrigationStatus[]> {
    const params = {
      parcelRefId: parcelIds,
    };
    return this.http
      .get<IrrigationStatusResponseBackEnd>(
        `${this.config.url}/${this.config.api}/fs-irrigation/api/irrigationStatuses`,
        {
          headers: this.headers,
          params,
        }
      )
      .pipe(map((response: IrrigationStatusResponseBackEnd) => response._embedded.irrigationStatuses));
  }

  getStageNotifications(parcelId: string): Observable<StageNotifications[]> {
    return this.http
      .get<StageNotificationsResponseBackEnd>(
        `${this.config.url}/${this.config.api}/fs-core/api/stageNotifications?parcel.id=${parcelId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(map((response: StageNotificationsResponseBackEnd) => response._embedded.stageNotifications));
  }

  subscribeStageNotifications(parcelId: string, phenologicalStageRefId: string): Observable<StageNotifications> {
    const body = {
      notified: false,
      parcel: `${this.config.url}/${this.config.api}/fs-core/api/parcels/${parcelId}`,
      phenologicalStageRefId,
    };
    return this.http.post<StageNotifications>(
      `${this.config.url}/${this.config.api}/fs-core/api/stageNotifications`,
      body
    );
  }

  unsubscribeStageNotifications(stageNotificationsId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.config.url}/${this.config.api}/fs-core/api/stageNotifications/${stageNotificationsId}`
    );
  }

  getDailyStatus(id: string): Observable<DailyStatus[]> {
    return this.http
      .get<DailyStatusResponseBackEnd>(
        `${this.config.url}/${this.config.api}/fs-irrigation/api/irrigationStatuses/${id}/dailyStatuses`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((response: DailyStatusResponseBackEnd) =>
          response._embedded.dailyStatuses.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        )
      );
  }

  getCurrentStage(parcelId: string): Observable<string> {
    return this.http
      .get<farmStarApiModel.CurrentStageBackend>(
        `${this.config.url}/${this.config.api}/fs-core/api/parcels/${parcelId}/currentstage`,
        {
          headers: this.headers,
        }
      )
      .pipe(map(response => response.phenologicalStageRefId));
  }

  findParcelsWithMultipleIds(ids: string[]): Observable<ParcelResponse> {
    const params = {
      id: ids,
      active: 'true',
    };

    return this.http
      .get<ParcelResponseBackend>(`${this.config.url}/${this.config.api}/fs-core/api/parcels`, {
        headers: this.headers,
        params,
      })
      .pipe(
        map((response: ParcelResponseBackend) => {
          const parcels: Parcel[] = response._embedded.parcels.map(
            p =>
              ({
                ...p,
                aoi: {
                  ...p.aoi,
                  polygon: this.transformPolygonToFeature(p.aoi.polygon),
                  originPolygon: this.transformPolygonToFeature(p.aoi.originPolygon),
                },
              } as Parcel)
          );

          return { ...response, _embedded: { parcels } } as ParcelResponse;
        })
      );
  }

  findRegisteredFarmers(params?: RegisteredFarmersParamRequest): Observable<RegisteredFarmersResponse> {
    const httpParams = { ...params, 'campaign.active': true };
    return this.http
      .get<RegisteredFarmersResponse>(`${this.config.url}/${this.config.api}/fs-core/api/registeredFarmers`, {
        headers: this.headers,
        params: toHttpParams(httpParams),
      })
      .pipe(map((response: RegisteredFarmersResponse) => response));
  }

  // TODO merge with findRegisteredTechnicians of cor-api-service
  findRegisteredTechnicians(params?: RegisteredTechniciansParamRequest): Observable<RegisteredTechniciansResponse> {
    const httpParams = { ...params, 'campaign.active': true };
    return this.http
      .get<RegisteredTechniciansResponse>(`${this.config.url}/${this.config.api}/fs-core/api/registeredTechnicians`, {
        headers: this.headers,
        params: toHttpParams(httpParams),
      })
      .pipe(map((response: RegisteredTechniciansResponse) => response));
  }

  findCooperative(url: string): Observable<farmStarApiModel.CooperativeBackend> {
    return this.http
      .get<farmStarApiModel.CooperativeBackend>(url, {
        headers: this.headers,
      })
      .pipe(map((response: farmStarApiModel.CooperativeBackend) => response));
  }

  findRegisteredCooperativeByCooperativeId(
    params: RegisteredCooperativeRequest
  ): Observable<RegisteredCooperativeResponse> {
    const httpParams = { ...params, 'campaign.active': true };
    return this.http
      .get<RegisteredCooperativeResponse>(`${this.config.url}/${this.config.api}/fs-core/api/registeredCooperatives`, {
        headers: this.headers,
        params: toHttpParams(httpParams),
      })
      .pipe(map((response: RegisteredCooperativeResponse) => response));
  }

  findRegisteredCooperativesByUrl(url: string): Observable<RegisteredCooperativeResponse> {
    return this.http.get<RegisteredCooperativeResponse>(url, {
      headers: this.headers,
    });
  }

  findCampaignByUrl(url: string): Observable<CoreModel.CampaignBackend> {
    return this.http.get<CoreModel.CampaignBackend>(url, {
      headers: this.headers,
    });
  }

  findCampaignCrops(url: string): Observable<RegisteredCropsBackend[]> {
    return this.http
      .get<farmStarApiModel.CampaignsCropsResponse>(url, {
        headers: this.headers,
      })
      .pipe(map(response => response._embedded.registeredCrops));
  }

  findCornCrop(): Observable<CropBackend> {
    return this.http
      .get<CropResponse>(`${this.config.url}/${this.config.api}/fs-core/api/crops`, {
        headers: this.headers,
        params: toHttpParams({ code: 'MAIS' }),
      })
      .pipe(map((response: CropResponse) => response._embedded.crops[0]));
  }

  findCrops(cropIds?: string[]): Observable<Crop[]> {
    return this.http
      .get<CropResponse>(`${this.config.url}/${this.config.api}/fs-core/api/crops`, {
        headers: this.headers,
        params: toHttpParams({ id: cropIds }),
      })
      .pipe(map((response: CropResponse) => response._embedded.crops));
  }

  findCrop(cropId: string): Observable<CropBackend> {
    return this.http.get<CropBackend>(`${this.config.url}/${this.config.api}/fs-core/api/crops/${cropId}`, {
      headers: this.headers,
    });
  }

  getSpecificDataFile(dataFileId: string): Observable<farmStarApiModel.DataFileItem> {
    return this.http.get<farmStarApiModel.DataFileItem>(
      `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/dataFiles/${dataFileId}`,
      {
        headers: this.headers,
      }
    );
  }

  getFilteredDataFiles(f: farmStarApiModel.DataFileFilterIds): Observable<farmStarApiModel.DataFileItem[]> {
    return this.http
      .get<farmStarApiModel.DataFilesResponse>(
        `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/dataFiles`,
        {
          headers: this.headers,
          params: toHttpParams(f),
        }
      )
      .pipe(
        map((response: farmStarApiModel.DataFilesResponse) =>
          response._embedded && response._embedded.dataFiles ? response._embedded.dataFiles : []
        )
      );
  }

  getFile(dataFileItemWithCrops: farmStarApiModel.DataFileItemWithCrops): Observable<CustomerDataCampaign> {
    return this.http
      .get<farmStarApiModel.DataFile>(dataFileItemWithCrops._links.file.href, {
        headers: this.headers,
      })
      .pipe(
        map((dataFile: farmStarApiModel.DataFile) => {
          return this.convertToCustomerDataFile(dataFileItemWithCrops, dataFile);
        })
      );
  }

  sendDatafile(datafileRequest: farmStarApiModel.DatafileRequest): Observable<any> {
    const body = new FormData();
    body.append('file', datafileRequest.file, datafileRequest.file.name);
    body.append('cooperativeRefId', datafileRequest.cooperativeId);
    body.append('campaignRefId', datafileRequest.campaignId);
    body.append('partialImport', String(datafileRequest.partialImport));
    return this.http.post<farmStarApiModel.CampaignsResponse>(
      `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/dataFiles`,
      body
    );
  }

  sendDataFileToProduction(datafileId: string) {
    const body = {};
    return this.http.post(
      `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/dataFiles/${datafileId}/import`,
      body
    );
  }

  downloadRecommendationFile(parcelCode: string, recommendationName: string): Observable<Blob> {
    const params = new HttpParams().set('parcelCode', parcelCode).set('recommendationName', recommendationName);
    const url = `${this.config.url}/${this.config.api}/fs-core/api/recommendationFiles/reports`;
    return this.http.get(url, {
      headers: this.headers,
      params,
      responseType: 'blob',
    });
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  downloadTestErrorReport(datafileId: string): Observable<Blob> {
    const url = `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/dataFiles/${datafileId}/downloadCsv`;
    return this.http.get(url, {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  loadTestReports(
    testReportRequest: farmStarApiModel.TestReportRequest
  ): Observable<farmStarApiModel.TestReportItem[]> {
    return this.http
      .get<farmStarApiModel.TestReportResponse>(
        `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/testReports`,
        {
          headers: this.headers,
          params: toHttpParams(testReportRequest),
        }
      )
      .pipe(
        map((response: farmStarApiModel.TestReportResponse) =>
          response._embedded && response._embedded.testReports ? response._embedded.testReports : []
        )
      );
  }

  loadTestErrorReport(testErrorReportUri: string): Observable<farmStarApiModel.TestErrorReportItem[]> {
    return this.http
      .get<farmStarApiModel.TestErrorReportResponse>(testErrorReportUri, {
        headers: this.headers,
      })
      .pipe(
        map((response: farmStarApiModel.TestErrorReportResponse) =>
          response._embedded && response._embedded.testReportErrors ? response._embedded.testReportErrors : []
        )
      );
  }

  loadImportReports(
    importReportRequest: farmStarApiModel.ImportReportRequest
  ): Observable<farmStarApiModel.ImportReportItem[]> {
    return this.http
      .get<farmStarApiModel.ImportReportResponse>(
        `${this.config.url}/${this.config.api}/fs-customer-dataflow/api/importReports`,
        {
          headers: this.headers,
          params: toHttpParams(importReportRequest),
        }
      )
      .pipe(
        map((response: farmStarApiModel.ImportReportResponse) =>
          response._embedded && response._embedded.importReports ? response._embedded.importReports : []
        )
      );
  }

  findPreviousCropLabelById(cropId: string): Observable<string> {
    return this.http
      .get<PreviousCropBackend>(`${this.config.url}/${this.config.api}/fs-core/api/previousCrops/${cropId}`, {
        headers: this.headers,
      })
      .pipe(map(response => response.label));
  }

  findPreviousCropWasteUsageLabelById(cropId: string): Observable<string> {
    return this.http
      .get<PreviousCropWasteUsageBackend>(
        `${this.config.url}/${this.config.api}/fs-core/api/previousCropWasteUsages/${cropId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(map(response => response.label));
  }

  findKindLabelById(kindId: string): Observable<string> {
    return this.http
      .get<farmStarApiModel.VarietyResponse>(`${this.config.url}/${this.config.api}/fs-core/api/kinds/${kindId}`, {
        headers: this.headers,
      })
      .pipe(map(response => response.label));
  }

  findVarietyLabelById(varietyId: string): Observable<string> {
    return this.http
      .get<farmStarApiModel.VarietyResponse>(
        `${this.config.url}/${this.config.api}/fs-core/api/varieties/${varietyId}`,
        { headers: this.headers }
      )
      .pipe(map(response => response.label));
  }

  findSoilLabelById(soilId: string): Observable<string> {
    return this.http
      .get<farmStarApiModel.SoilResponse>(`${this.config.url}/${this.config.api}/fs-core/api/soils/${soilId}`, {
        headers: this.headers,
      })
      .pipe(map(response => response.label));
  }

  findNDoseCalculationMethodsById(
    nDoseCalculationMethodsId: string
  ): Observable<farmStarApiModel.DoseCalculationMethods> {
    return this.http
      .get<farmStarApiModel.NDoseCalculationMethodsResponse>(
        `${this.config.url}/${this.config.api}/fs-core/api/nDoseComputationMethods/${nDoseCalculationMethodsId}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((response: farmStarApiModel.NDoseCalculationMethodsResponse) => ({
          code: response.code,
          label: response.label,
          nDoseComputationEngine: response.ndoseComputationEngine ? response.ndoseComputationEngine.code : null,
        }))
      );
  }

  private getUserRole(userInfo: AllUserTypeData): UserRole {
    switch (userInfo.profile) {
      case UserRole.farmer:
        return UserRole.farmer;
      case UserRole.technician:
        return UserRole.technician;
      case UserRole.coordinator:
        return UserRole.coordinator;
      case UserRole.customerSupport:
      case UserRole.superAdmin:
      case UserRole.admin:
      case UserRole.expert:
      case UserRole.projectManager:
      case UserRole.agroInstitute:
      case UserRole.regionalEngineer:
        return UserRole.otherUsers;
      default:
        throw new Error("Vous n'avez pas de rôle");
    }
  }

  private removeEmptyStageAndUnknownStages(
    stageEstimates: farmStarApiModel.StageEstimateBackend[],
    phenologicalStagesEntities: Dictionary<farmStarApiModel.PhenologicalStageBackend>
  ): farmStarApiModel.StageEstimateBackend[] {
    return stageEstimates.filter(
      s => !!s.stageDate && !!s.phenologicalStageRefId && !!phenologicalStagesEntities[s.phenologicalStageRefId]
    );
  }

  private removeDuplicatesStages(
    stageEstimates: farmStarApiModel.StageEstimateBackend[]
  ): farmStarApiModel.StageEstimateBackend[] {
    const stageSortedByEstimateDate = stageEstimates.sort(
      (a, b) => new Date(a.estimateDate).getTime() - new Date(b.estimateDate).getTime()
    );

    const stageDictionary = stageSortedByEstimateDate.reduce(
      (dictionary, stage) => ({
        ...dictionary,
        [stage.phenologicalStageRefId]: stage,
      }),
      {}
    );

    return Object.entries(stageDictionary).reduce(
      (
        stages: farmStarApiModel.StageEstimateBackend[],
        [_, stage]: [string, farmStarApiModel.StageEstimateBackend]
      ) => {
        return [...stages, stage];
      },
      []
    );
  }

  private addSeedlingStage(
    stageEstimates: farmStarApiModel.StageEstimateBackend[],
    parcel: Parcel
  ): farmStarApiModel.StageEstimateBackend[] {
    const seedlingStage: farmStarApiModel.StageEstimateBackend = {
      phenologicalStageRefId: farmStarApiModel.phenologicalSeedlingStageId,
      stageDate:
        parcel.agroData.activities.seedingActivity.actualSeedingDate ||
        parcel.agroData.activities.seedingActivity.seedingDate,
    };
    return [seedlingStage, ...stageEstimates];
  }

  private findCurrentStageAndNextStage(
    stageEstimates: farmStarApiModel.StageEstimateBackend[]
  ): farmStarApiModel.StageEstimate[] {
    const today = new Date();

    const stageEstimatesSorted = stageEstimates.sort(
      (a, b) => new Date(a.stageDate).getTime() - new Date(b.stageDate).getTime()
    );

    const currentStage = stageEstimatesSorted
      .map((stage, index) => ({
        ...stage,
        stageEndDate: stageEstimatesSorted[index + 1] ? stageEstimatesSorted[index + 1].stageDate : null,
      }))
      .find(s => new Date(s.stageDate) <= today && (s.stageEndDate === null || new Date(s.stageEndDate) > today));

    const currentStageIndex = stageEstimatesSorted.findIndex(
      s => currentStage && currentStage.phenologicalStageRefId === s.phenologicalStageRefId
    );

    let nextStage: farmStarApiModel.StageEstimateBackend = null;

    if (currentStageIndex > -1 && currentStageIndex < stageEstimates.length - 1) {
      nextStage = stageEstimatesSorted[currentStageIndex + 1];
    }

    return stageEstimates.map(s => ({
      ...s,
      isCurrentStage: currentStage && s.phenologicalStageRefId === currentStage.phenologicalStageRefId,
      isNextStage: nextStage ? s.phenologicalStageRefId === nextStage.phenologicalStageRefId : false,
    }));
  }

  private convertToCustomerDataFile(
    dataFileItemWithCrops: farmStarApiModel.DataFileItemWithCrops,
    dataFile: farmStarApiModel.DataFile
  ): CustomerDataCampaign {
    return {
      id: dataFileItemWithCrops.id,
      fileName: dataFile.name,
      uploadedOn: dataFile.uploadedOn,
      fileLink: dataFile._links.download.href,
      status: dataFileItemWithCrops.status,
      crops: dataFileItemWithCrops.crops,
    };
  }

  private transformPolygonToFeature(polygon: Polygon): Feature<any> {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        ...polygon,
      },
    };
  }

  public transformImageExtent(extent: [number, number, number, number]): [number, number, number, number] {
    const proj3857 = 'EPSG:3857';
    const proj4326 = 'EPSG:4326';
    return transformExtent(extent, proj4326, proj3857) as [number, number, number, number];
  }
}
