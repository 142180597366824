import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanionComponent } from '@davinkevin/companion-component';
import { select, Store } from '@ngrx/store';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { AdviceMode } from '@app/overview/parcels-viewer/core/switch-advice-mode/advice-mode';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import { MatSlideToggleChange } from '@angular/material/typings/slide-toggle';
import { Observable } from 'rxjs';

@Component({
  selector: 'fstar-switch-advice-mode',
  templateUrl: './switch-advice-mode.component.html',
  styleUrls: ['./switch-advice-mode.component.scss'],
})
export class SwitchAdviceModeComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();

  selectedAdviceMode = AdviceMode.none;
  adviceMode = AdviceMode;
  isAdviceModeContainerOpened$: Observable<boolean>;

  constructor(private store: Store<fromParcelsViewer.State>) {}

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.store.pipe(select(fromParcelsViewer.selectAdviceMode), untilDestroy()).subscribe(mode => {
      this.selectedAdviceMode = mode;
    });

    this.isAdviceModeContainerOpened$ = this.store.pipe(select(fromParcelsViewer.selectIsAdviceModeContainerOpened));
  }

  ngOnDestroy() {
    this.companion.destroy();
  }

  onSlideToggleChange(event: MatSlideToggleChange, mode: AdviceMode) {
    const selectedMode = event.checked ? mode : AdviceMode.none;
    this.store.dispatch(new ParcelsViewerActions.ChangeAdviceMode(selectedMode));
  }

  toggleAdviceModeContainer() {
    this.store.dispatch(new ParcelsViewerActions.ToggleAdviceModeContainer());
  }
}
