import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export const SEND_DATAFILE_SUCCESS_DIALOG_WIDTH = '650px';

@Component({
  selector: 'fstar-send-datafile-success-dialog',
  templateUrl: './send-datafile-success-dialog.component.html',
  styleUrls: ['./send-datafile-success-dialog.component.scss'],
})
export class SendDatafileSuccessDialogComponent {
  constructor(private dialogRef: MatDialogRef<SendDatafileSuccessDialogComponent>) {}

  onClose() {
    this.dialogRef.close();
  }
}
