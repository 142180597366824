// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const environment = {
  production: true,
  next: false,
  oidcConfig: {
    authority: 'https://authenticate.oneatlas.airbus.com/auth/realms/IDP',
    client_id: 'IDP',
    response_type: 'id_token token',
    scope: 'openid',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    autoSilentRenewOnErrorInterval: 10000,
  },
  modules: [
    StoreDevtoolsModule.instrument({
      name: 'farmstar',
      maxAge: 100,
    }),
    BrowserAnimationsModule,
  ],
  storeRuntimeChecks: {
    strictStateSerializability: true,
    strictActionSerializability: false,
    strictStateImmutability: true,
    strictActionImmutability: true,
  },
  securedUrls: [
    'fs-core/api',
    'fs-totaln/api',
    'fs-customer-dataflow/api',
    'fs-irrigation/api',
    'fs-vra-map/api',
  ],
  farmStarUrl: {
    url: 'https://demo.agri.geoapi-airbusds.com/api/v1',
    api: '',
  },
  staticAssets: {
    cgu: 'https://storage.googleapis.com/d-awf-farmstarui-assets/CGU_Farmstar.pdf',
    rgpd: 'https://storage.googleapis.com/d-awf-farmstarui-assets/RGPD_Farmstar.pdf',
    laiVideo: 'https://storage.googleapis.com/d-awf-farmstarui-assets/lai.mp4',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
