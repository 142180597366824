<mat-sidenav-container [hasBackdrop]="false" [autosize]="true" #viewContainerRef>
  <mat-sidenav-content>
    <div class="map-wrapper" [ngClass]="{ blur: isLoadingParcelsAdvices$ | async }">
      <fstar-parcels-map [parcels]="parcels$"> </fstar-parcels-map>
      <div class="top-filters">
        <fstar-switch-view></fstar-switch-view>
        <!-- <fstar-parcel-switch></fstar-parcel-switch> -->
        <fstar-crop-chooser *ngIf="areParcelsFeaturesActivated"></fstar-crop-chooser>
      </div>
      <fstar-filter-wrapper
        [selectedParcel]="selectedParcel$ | async"
        [filteredParcels]="filteredParcels$ | async"
        [areParcelsFeaturesActivated]="areParcelsFeaturesActivated"
        [isDetailsView]="false"
        (selectedParcelId)="parcelToSelect($event)"
        (parcelTermChanged)="filterTermChanged($event)"
        [filteredFarms]="filteredFarms$ | async"
        [selectedFarm]="selectedFarm$ | async"
        [isFarmView]="false"
        (selectedFarmId)="farmToSelect($event)"
        (farmTermChanged)="filterFarmTermChanged($event)"
        (parcelHovered)="onParcelHovered($event)"
      ></fstar-filter-wrapper>
      <fstar-switch-advice-mode
        *ngIf="areParcelsFeaturesActivated"
        [class.switch-advice-small]="(isAdviceModeContainerOpened$ | async) === false"
      ></fstar-switch-advice-mode>
    </div>
    <fstar-overlay-loader *ngIf="isLoadingParcelsAdvices$ | async" [@fadeInOutTrans]></fstar-overlay-loader>
    <fstar-lai-legend *ngIf="(isLaiModeActivated$ | async) === true"></fstar-lai-legend>
  </mat-sidenav-content>
</mat-sidenav-container>
