import { Component, Input } from '@angular/core';
import { FeatureArea, FeatureBiophy } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { fadeInOutWithTransition } from '@app/shared/animations/animations';

@Component({
  selector: 'fstar-draw-info-tooltip',
  templateUrl: './draw-info-tooltip.component.html',
  styleUrls: ['./draw-info-tooltip.component.scss'],
  animations: [fadeInOutWithTransition('0s', '0.3s')],
})
export class DrawInfoTooltipComponent {
  @Input() featureArea: FeatureArea;
  @Input() featureBiophy: FeatureBiophy;

  constructor() {}
}
