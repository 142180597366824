import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SmallHelpPopupComponent } from '@app/overview/shared/small-help-popup/small-help-popup.component';
import { LargeHelpPopupModule } from '@app/overview/shared/large-help-popup/large-help-popup.module';
import { LargeHelpPopupComponent } from '@app/overview/shared/large-help-popup/large-help-popup.component';

@NgModule({
  declarations: [SmallHelpPopupComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatCheckboxModule, LargeHelpPopupModule, MatDialogModule],
  exports: [SmallHelpPopupComponent],
  entryComponents: [LargeHelpPopupComponent],
})
export class SmallHelpPopupModule {}
