<fstar-overlay-loader *ngIf="(isAllParcelInfoLoading$ | async) === true" [@fadeInOutTrans]></fstar-overlay-loader>
<div class="parcel-details-wrapper" [ngClass]="{ blur: isAllParcelInfoLoading$ | async }">
  <div class="map-and-chart-container">
    <fstar-parcel-details-map [parcel]="parcel$"> </fstar-parcel-details-map>
    <fstar-draw-menu></fstar-draw-menu>

    <fstar-filter-wrapper
      [selectedParcel]="selectedParcel$ | async"
      [filteredParcels]="filteredParcels$ | async"
      [isDetailsView]="true"
      (selectedParcelId)="parcelToSelect($event)"
      (parcelTermChanged)="filterTermChanged($event)"
      [filteredFarms]="filteredFarms$ | async"
      [selectedFarm]="selectedFarm$ | async"
      [isFarmView]="false"
      (selectedFarmId)="farmToSelect($event)"
      (farmTermChanged)="filterFarmTermChanged($event)"
    ></fstar-filter-wrapper>
    <div
      class="chart"
      *ngIf="(isPanelHydroOpened$ | async) === false && (isPanelDoseOpen$ | async) === false; else hydroChartPanel"
    >
      <div
        class="chart-overlay"
        *ngIf="(stage$ | async).length === 0 && (biophySeriesLineDataOptions$ | async).length === 0"
      >
        <div>Informations bientôt disponibles</div>
      </div>
      <div
        class="chart-wrapper"
        [ngClass]="{
          'chart-blur': (stage$ | async).length === 0 && (biophySeriesLineDataOptions$ | async).length === 0
        }"
      >
        <fstar-parcel-chart
          [showStage]="showStage"
          [year]="selectedYear"
          [beginMonth]="selectedBeginMonth"
          [endMonth]="selectedEndMonth"
          [phenologicalStageByEstimateDate]="phenologicalStageByEstimateDate$ | async"
          [biophySeriesLineDataOptions]="biophySeriesLineDataOptions$ | async"
        ></fstar-parcel-chart>
        <div class="button-container">
          <button class="button-filter" type="button" mat-button [fretPopupOverlay]="popupOverlay">
            <span class="filter-text">Filtres</span>
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-template #hydroChartPanel>
      <div class="chart" *ngIf="(isPanelDoseOpen$ | async) === false">
        <div
          class="chart-overlay"
          *ngIf="(stage$ | async).length === 0 && (timelineRainAndIrrigation$ | async) === null"
        >
          <div>Informations bientôt disponibles</div>
        </div>
        <div
          class="chart-wrapper"
          [ngClass]="{
            'chart-blur': (stage$ | async).length === 0 && (timelineRainAndIrrigation$ | async) === null
          }"
        >
          <fstar-parcel-hydro-chart
            [beginDate]="(beginAndEndDatesOfTimelineRainAndIrrigation$ | async).beginDate"
            [endDate]="(beginAndEndDatesOfTimelineRainAndIrrigation$ | async).endDate"
            [phenologicalStageByEstimateDate]="phenologicalStageByEstimateDate$ | async"
            [dailyStatusMultiLineDataOptions]="dailyStatusMultiLineDataOptions$ | async"
          ></fstar-parcel-hydro-chart>
        </div>
      </div>
    </ng-template>
    <fstar-small-help-popup
      *ngIf="(showParcelDetailsHelp$ | async) === true"
      [style.padding-left]="(openingToolbarStatus$ | async) === true ? '116px' : '0px'"
    ></fstar-small-help-popup>
  </div>
  <div class="parcel-info-container">
    <fstar-parcel-information [parcel]="parcel$ | async"></fstar-parcel-information>
  </div>
</div>

<fret-popup-overlay
  [offsetX]="-195"
  [offsetY]="-4"
  [position]="popupPosition"
  [hasBackdrop]="true"
  [backdropClass]="''"
  #popupOverlay
>
  <form [formGroup]="filterGroup">
    <div class="chart-filter-container">
      <button class="button-filter" type="button" mat-button (click)="popupOverlay.dispose()">
        <span class="filter-text">Filtres</span>
        <mat-icon>close</mat-icon>
      </button>
      <div class="filter-section">
        <div>Période</div>
        <mat-form-field class="year-filter">
          <mat-select formControlName="year" panelClass="year-filter-panel">
            <mat-option *ngFor="let year of years" [value]="year"> {{ year }} </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="period-filter">
          <mat-form-field class="month-filter">
            <mat-select formControlName="beginMonth">
              <mat-option *ngFor="let month of availableMonths" [value]="month"> {{ month }} </mat-option>
            </mat-select>
          </mat-form-field>
          <span>à</span>
          <mat-form-field class="month-filter">
            <mat-select formControlName="endMonth">
              <mat-option *ngFor="let month of filteredEndMonths" [value]="month"> {{ month }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-slide-toggle color="accent" formControlName="showStage"> <div>Afficher les stades</div> </mat-slide-toggle>
      </div>
    </div>
  </form>
</fret-popup-overlay>
