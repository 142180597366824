import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FarmFilterComponent } from '@app/overview/shared/farm-filter/farm-filter.component';
import { FromGeoJsonModule } from '@app/shared/directives/from-geojson.module';

@NgModule({
  declarations: [FarmFilterComponent],
  imports: [
    CommonModule,
    FromGeoJsonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatDividerModule,
  ],
  exports: [FarmFilterComponent],
})
export class FarmFilterModule {}
