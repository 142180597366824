import { Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2 } from '@angular/core';
import * as geojson2svg from 'geojson2svg';
import { timer } from 'rxjs';
import { CompanionComponent } from '@davinkevin/companion-component';
import { AllGeoJSON } from '@turf/helpers';
import { default as tBbox } from '@turf/bbox';
import { BBox } from 'geojson';

@Directive({
  selector: '[fstarFromGeoJson]',
})
export class FromGeoJsonDirective implements OnChanges, OnDestroy {
  private companion = new CompanionComponent();

  @Input() fstarFromGeoJson: AllGeoJSON;
  @Input() size: number;

  readonly path: SVGPathElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.el.nativeElement.appendChild(this.path);
  }

  ngOnChanges() {
    const converter = geojson2svg({
      mapExtent: extentToExtentObj(tBbox(this.fstarFromGeoJson)),
      output: 'path',
      viewportSize: { width: this.size, height: this.size },
    });

    this.hidePath();
    this.renderer.setAttribute(this.path, 'd', converter.convert(this.fstarFromGeoJson));
    this.setViewBoxAfterRendering();
  }

  setViewBoxAfterRendering() {
    const untilDestroy = this.companion.untilDestroy();

    timer(1)
      .pipe(untilDestroy())
      .subscribe(() => {
        this.renderer.setAttribute(this.el.nativeElement, 'viewBox', bboxToViewBox(this.path.getBBox()));
        this.showPathWithSmoothTransition();
      });
  }

  hidePath() {
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');
  }

  showPathWithSmoothTransition() {
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'opacity 0.20s ease-in-out');
  }

  ngOnDestroy() {
    this.companion.destroy();
    this.renderer.removeChild(this.el.nativeElement, this.path);
  }
}

function extentToExtentObj(bbox: BBox) {
  return {
    left: bbox[0],
    bottom: bbox[1],
    right: bbox[2],
    top: bbox[3],
  };
}

function bboxToViewBox(bbox: DOMRect) {
  return `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
}
