import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIGURATION, Configuration } from '@app/overview/shared/farm-star/configuration/configuration';
import { Terminal, TerminalsResponse } from '@app/overview/shared/farm-star/vra-map.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VraMapApiService {
  public headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  private readonly API = `${this.config.url}/${this.config.api}/fs-vra-map/api`;

  constructor(@Inject(CONFIGURATION) private config: Configuration, private http: HttpClient) {}

  getTerminals(): Observable<Terminal[]> {
    return this.http
      .get<TerminalsResponse>(`${this.API}/terminals`, {
        headers: this.headers,
      })
      .pipe(map(response => response.terminals));
  }
}
