import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarIconComponent } from '@app/overview/shared/snackbar-icon/snackbar-icon.component';

@NgModule({
  declarations: [SnackbarIconComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [SnackbarIconComponent],
})
export class SnackbarIconModule {}
