import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ParcelSwitchComponent } from '@app/overview/shared/parcel-switch/parcel-switch.component';

@NgModule({
  declarations: [ParcelSwitchComponent],
  imports: [CommonModule, MatSlideToggleModule],
  exports: [ParcelSwitchComponent],
})
export class ParcelSwitchModule {}
