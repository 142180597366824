import { Pipe, PipeTransform } from '@angular/core';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { isExistingRejectedData } from '@app/overview/import-database/core/reports-checks';

@Pipe({
  name: 'isRejectedData',
})
export class IsRejectedDataPipe implements PipeTransform {
  transform(value: farmStarApiModel.TestReportItem): boolean {
    return isExistingRejectedData(value);
  }
}
