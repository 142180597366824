<li
  [class.yield]="!isCurrentStage && phenologicalStage.action === phenologicalStageActionEnum.harvest"
  [class.current]="isCurrentStage"
>
  <div
    class="stage-image"
    [class.stage-image-current]="
      isCurrentStage &&
      phenologicalStage.action === phenologicalStageActionEnum.intervention &&
      phenologicalStage.code !== phenologicalStageCodeEnum.seedling
    "
  >
    <ng-container [ngSwitch]="phenologicalStage.code">
      <ng-template [ngSwitchCase]="phenologicalStageCodeEnum.emergence"> </ng-template>
      <ng-template [ngSwitchCase]="phenologicalStageCodeEnum.fourLeaves"> <span>4</span> </ng-template>
      <ng-template [ngSwitchCase]="phenologicalStageCodeEnum.sixLeaves"> <span>6</span> </ng-template>
      <ng-template [ngSwitchCase]="phenologicalStageCodeEnum.eightLeaves"> <span>8</span> </ng-template>
      <ng-template [ngSwitchCase]="phenologicalStageCodeEnum.tenLeaves"> <span>10</span> </ng-template>
    </ng-container>
    <svg [class.svg-seedling]="phenologicalStage.code === phenologicalStageCodeEnum.seedling" aria-hidden="true">
      <use [attr.xlink:href]="svgByCode[phenologicalStage.code]"></use>
    </svg>
  </div>

  <div class="stage-status">
    <div class="stage-date">{{ stageDate | date: 'dd/MM/yyyy' }}</div>
    <div class="stage-state">{{ phenologicalStage.label }}</div>
    <div class="stage-desc">{{ phenologicalStage.advice }}</div>
  </div>

  <div class="actions" *ngIf="isAvailableToNotifications">
    <mat-icon (click)="emitSubscribeNotification()" [class.no-notification]="!stageNotifications">
      {{ stageNotifications ? 'notifications' : 'notifications_none' }}
    </mat-icon>
  </div>
</li>
