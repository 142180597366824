import { Pipe, PipeTransform } from '@angular/core';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';

@Pipe({
  name: 'campaignStatus',
})
export class CampaignStatusTranslatePipe implements PipeTransform {
  transform(value: string): string {
    let textToDisplay: string;
    switch (value) {
      case farmStarApiModel.DatafileStatus.IMPORTED:
        textToDisplay = 'Chargement terminé';
        break;
      case farmStarApiModel.DatafileStatus.IMPORTING:
        textToDisplay = 'Chargement en cours';
        break;
      case farmStarApiModel.DatafileStatus.TESTING:
        textToDisplay = 'Test en cours';
        break;
      case farmStarApiModel.DatafileStatus.TESTED:
        textToDisplay = 'Test finalisé';
        break;
      case farmStarApiModel.DatafileStatus.UPLOADED:
        textToDisplay = 'Test en cours';
        break;
      default:
        textToDisplay = '';
        break;
    }
    return textToDisplay;
  }
}
