import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CurrentStageByParcelId, Parcel, ParcelWithAdvices } from '@app/overview/shared/farm-star/parcel.model';
import { RouterNavigateAction, RouterStateUrl } from '@davinkevin/router-store-helper';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { isParcelsMapUrl, routesNames } from '@app/shared/utils/routes.helper';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Dictionary } from '@ngrx/entity';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { AdviceMode } from '@app/overview/parcels-viewer/core/switch-advice-mode/advice-mode';
import { ParcelApiService } from '@app/shared/api/parcel/parcel-api.service';
import { FindFarmInfoSuccess } from '../overview.actions';

@Injectable()
export class ParcelsViewerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromOverview.OverviewGlobalState | fromParcelsViewer.State>,
    private parcelApi: ParcelApiService
  ) {}

  @Effect()
  findFarmFieldsOnMapNavigation$ = this.actions$.pipe(
    ofType(FindFarmInfoSuccess),
    withLatestFrom(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
    filter(([, router]: [any, RouterNavigationAction<RouterStateUrl>]) =>
      isParcelsMapUrl(router.payload.routerState.url)
    ),
    map(() => new ParcelsViewerActions.FindFieldsWithLoader())
  );

  @Effect()
  findFarmFieldsWithLoader$ = this.actions$.pipe(
    ofType(ParcelsViewerActions.Types.FindFieldsWithLoader),
    withLatestFrom(this.store.pipe(select(fromOverview.selectCurrentFarmId))),
    switchMap(([, currentFarmId]) =>
      this.parcelApi.getActiveParcelsOfActiveCampaigns(currentFarmId).pipe(catchError(() => of([])))
    ),
    map((parcels: Parcel[]) => new ParcelsViewerActions.FindFieldsWithLoaderSuccess(parcels))
  );

  @Effect()
  hideParcelsFeaturesWhenParcelsListEmpty$ = this.actions$.pipe(
    ofType(ParcelsViewerActions.Types.FindFieldsWithLoaderSuccess),
    filter((action: ParcelsViewerActions.FindFieldsWithLoaderSuccess) => action.fields.length === 0),
    map(() => new ParcelsViewerActions.HideParcelsFeatures())
  );

  @Effect()
  selectedParcel$ = this.actions$.pipe(
    ofType(ParcelsViewerActions.Types.SelectFeature),
    withLatestFrom(this.store.pipe(select(fromOverview.selectCurrentFarmId))),
    filter(([action]: [ParcelsViewerActions.SelectFeature, string]) => action.id != null),
    map(
      ([action, farmId]: [ParcelsViewerActions.SelectFeature, string]) =>
        new RouterNavigateAction([`/${routesNames.FARMS}/${farmId}/${routesNames.PARCELS}/${action.id}`], {
          queryParamsHandling: 'preserve',
        })
    )
  );

  @Effect()
  selectParcelFromUrl$ = this.actions$.pipe(
    ofType(ParcelsViewerActions.Types.FindFieldsWithLoaderSuccess),
    withLatestFrom(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
    filter(([_, router]: [ParcelsViewerActions.FindFieldsWithLoaderSuccess, RouterNavigationAction<RouterStateUrl>]) =>
      router.payload.routerState.url.includes(`/${routesNames.PARCELS}/`)
    ),
    map(
      ([action, router]: [
        ParcelsViewerActions.FindFieldsWithLoaderSuccess,
        RouterNavigationAction<RouterStateUrl>
      ]) => {
        const parcelId = router.payload.routerState.params.parcelId;
        const farmId = router.payload.routerState.params.farmId;
        return action.fields.some(f => f.id === parcelId)
          ? new ParcelsViewerActions.SelectFeature(parcelId)
          : new RouterNavigateAction([`/${routesNames.FARMS}/${farmId}/${routesNames.PARCELS}`], {
              queryParamsHandling: 'preserve',
            });
      }
    )
  );

  @Effect()
  findStageEstimateOnStageMode$ = this.actions$.pipe(
    ofType(ParcelsViewerActions.Types.ChangeAdviceMode),
    filter((action: ParcelsViewerActions.ChangeAdviceMode) => action.mode === AdviceMode.stage),
    withLatestFrom(this.store.pipe(select(fromParcelsViewer.selectAreStagesLoaded))),
    filter(([, isStagesLoaded]: [ParcelsViewerActions.ChangeAdviceMode, boolean]) => !isStagesLoaded),
    map(() => new ParcelsViewerActions.BindParcelStageEstimate())
  );

  @Effect()
  bindPhenologicalStagesToParcels$ = this.actions$.pipe(
    ofType(ParcelsViewerActions.Types.BindParcelStageEstimate),
    withLatestFrom(
      this.store.pipe(select(fromParcelsViewer.selectAllFields)),
      this.store.pipe(select(fromOverview.selectAllPhenologicalStagesEntities))
    ),
    map(
      ([, fields, phenologicalStages]: [
        ParcelsViewerActions.BindParcelStageEstimate,
        ParcelWithAdvices[],
        Dictionary<farmStarApiModel.PhenologicalStageBackend>
      ]) => {
        const stagesByParcelId: CurrentStageByParcelId[] = fields.map(field => {
          const phenologicalStage: any = phenologicalStages[field.currentStageEstimate.phenologicalStageRefId] || {};
          return {
            currentStageCode: phenologicalStage.code,
            isHarvestStage: phenologicalStage.action === farmStarApiModel.PhenologicalStageAction.harvest,
            parcelId: field.id,
          };
        });

        return new ParcelsViewerActions.BindParcelStageEstimateSuccess(stagesByParcelId);
      }
    )
  );
}
