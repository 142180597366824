import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanionComponent } from '@davinkevin/companion-component';
import { HideParcelDetailsHelpStatus } from '@app/shared/local-storage';
import { HideParcelDetailsHelp } from '@app/overview/overview.actions';

export const LARGE_HELP_DIALOG_WIDTH = '1200px';
export const LARGE_HELP_DIALOG_MIN_WIDTH = '500px';
export const LARGE_HELP_DIALOG_MAX_HEIGHT = 'maxHeight';

@Component({
  selector: 'fstar-large-help-popup',
  templateUrl: './large-help-popup.component.html',
  styleUrls: ['./large-help-popup.component.scss'],
})
export class LargeHelpPopupComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();
  neverSeeAgain = false;

  constructor(
    private store: Store<fromOverview.OverviewGlobalState>,
    private dialogRef: MatDialogRef<LargeHelpPopupComponent>
  ) {}

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.dialogRef
      .backdropClick()
      .pipe(untilDestroy())
      .subscribe(() => {
        this.hideHelp();
      });
  }

  ngOnDestroy() {
    this.companion.destroy();
  }

  hideHelp() {
    this.dialogRef.close();
    this.store.dispatch(
      HideParcelDetailsHelp({
        status: this.neverSeeAgain ? HideParcelDetailsHelpStatus.ALWAYS : HideParcelDetailsHelpStatus.CURRENT_SESSION,
      })
    );
  }
}
