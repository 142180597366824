<div class="header" mat-dialog-title>
  <span class="title">Cultures</span>
  <span class="subtitle">Choisir le type ou les cultures que vous souhaitez visualiser.</span>
</div>

<mat-dialog-content>
  <mat-radio-group [(ngModel)]="selectedCrop">
    <mat-radio-button value="all">Toutes les cultures ({{ totalCount }})</mat-radio-button>
    <mat-radio-button *ngFor="let crop of crops" [value]="crop.code">
      <div class="crop-label">{{ crop.label }} ({{ crop.count }})</div>
      <mat-icon [svgIcon]="crop.icon"></mat-icon>
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>

<div class="footer" mat-dialog-actions>
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="selectedCrop" class="btn-validate">Valider</button>
</div>
