<form [formGroup]="testErrorReportFilterGroup" (submit)="onFiltersChange()">
  <div class="test-reports-errors-filters" [class.filter-hidden]="!filterOpened">
    <div class="filter-close" *ngIf="!filterOpened; else filterContainer">
      <button class="filter-button" type="button" mat-button (click)="onFilterClick()">
        Filtres
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <ng-template #filterContainer>
      <div class="filter-open">
        <div class="filters">
          <div class="filter-container">
            <span class="filter-title">Type d'erreur</span>
            <div class="filter-values">
              <mat-checkbox
                *ngFor="let errorsLevel of errorsLevelArray.controls"
                [formControl]="errorsLevel.get('selected')"
                [value]="errorsLevel.value.selected"
              >
                {{ errorsLevel.value.value }}
              </mat-checkbox>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div class="filter-container">
            <span class="filter-title">Nature de la donnée</span>
            <div class="filter-values">
              <mat-checkbox
                *ngFor="let errorsEntityType of errorsEntityTypeArray.controls"
                [formControl]="errorsEntityType.get('selected')"
                [value]="errorsEntityType.value.selected"
              >
                {{ errorsEntityType.value.value }}
              </mat-checkbox>
            </div>
          </div>
          <div class="submit-filters"><button type="submit" mat-raised-button>Valider</button></div>
        </div>
        <button class="filter-button" type="button" mat-button (click)="onFilterClick()">
          Filtres
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
    </ng-template>
  </div>
</form>
<div class="test-report-errors-section">
  <div class="test-report-errors-section-header">
    <button mat-button (click)="downloadFile()">
      <svg aria-hidden="true"><use href="assets/images/sprites.svg#download"></use></svg>
      <span>Exporter les erreurs</span>
    </button>
  </div>
  <mat-table>
    <ng-container *ngFor="let column of displayedColumnsTestErrorReport" [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef>{{ headers[column] }}</mat-header-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsTestErrorReport"></mat-header-row>
  </mat-table>
  <div class="test-report-errors-section-data">
    <mat-table [dataSource]="filteredTestErrorReport">
      <ng-container matColumnDef="level">
        <mat-cell mat-cell *matCellDef="let element"> {{ element.level }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="entityCode">
        <mat-cell *matCellDef="let element"> {{ element.entityCode }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="entityType">
        <mat-cell mat-cell *matCellDef="let element"> {{ element.entityType }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="field">
        <mat-cell mat-cell *matCellDef="let element"> {{ element.field }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="message">
        <mat-cell *matCellDef="let element"> {{ element.message }} </mat-cell>
      </ng-container>
      <mat-row class="element-row" *matRowDef="let row; columns: displayedColumnsTestErrorReport"></mat-row>
    </mat-table>
  </div>
</div>
