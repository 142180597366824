import { Pipe, PipeTransform } from '@angular/core';
import { ErrorType } from '@app/overview/import-database/core/import-database-list/core/import-database-dialog/import-database-dialog.component';

@Pipe({
  name: 'errorDescription',
})
export class ImportDatabaseErrorDescriptionPipe implements PipeTransform {
  transform(value: ErrorType, labelType: 'title' | 'desc'): string {
    let textToDisplay: string;
    switch (value) {
      case ErrorType.NO_ACTIVE_CAMPAIGN:
        if (labelType === 'title') {
          textToDisplay = 'Aucune campagne active disponible';
        } else {
          textToDisplay = '';
        }
        break;
      case ErrorType.NO_COOPERATIVE:
        if (labelType === 'title') {
          textToDisplay = 'Aucun distributeur disponible';
        } else {
          textToDisplay = '';
        }
        break;
      case ErrorType.NOT_AUTHORIZED_FILE_FORMAT:
        if (labelType === 'title') {
          textToDisplay = 'Mauvais format de fichier';
        } else {
          textToDisplay = "Votre fichier n'est pas au bon format. Le format accepté est 'zip'";
        }
        break;
      case ErrorType.DATAFILES_EMPTY:
        if (labelType === 'title') {
          textToDisplay = 'Aucun dépôt de base';
        } else {
          textToDisplay = 'Veuillez sélectionner une campagne pour déposer une base';
        }
        break;
      default:
        textToDisplay = '';
        break;
    }
    return textToDisplay;
  }
}
