import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration, CONFIGURATION } from '@app/overview/shared/farm-star/configuration/configuration';

@Injectable()
export class RemoveProjectionTokenInterceptor implements HttpInterceptor {
  constructor(@Inject(CONFIGURATION) private config: Configuration) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const projectionOptionalParam = '{?projection}';
    if (request.url.endsWith(projectionOptionalParam) && request.url.startsWith(this.config.url)) {
      const normalizedRequest = request.clone({
        url: request.url.slice(0, request.url.length - projectionOptionalParam.length),
      });

      return next.handle(normalizedRequest);
    }

    return next.handle(request);
  }
}
