import { NgModule } from '@angular/core';
import { ImportDatabaseListItemComponent } from '@app/overview/import-database/core/import-database-list/core/import-database-list-item/import-database-list-item.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CampaignStatusTranslatePipe } from '@app/overview/import-database/core/import-database-list/core/import-database-list-item/campaign-status-translate.pipe';

@NgModule({
  declarations: [ImportDatabaseListItemComponent, CampaignStatusTranslatePipe],
  imports: [CommonModule, MatIconModule],
  exports: [ImportDatabaseListItemComponent],
})
export class ImportDatabaseListItemModule {}
