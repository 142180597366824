<form class="filter-form">
  <mat-form-field class="filter-input" floatLabel="never">
    <svg matPrefix aria-hidden="true" [class.check-circle-icon]="!!customColor" [style.fill]="customColor">
      <use
        [attr.xlink:href]="
          customColor ? 'assets/images/sprites.svg#checkCircle' : 'assets/images/sprites.svg#parcelFilter'
        "
      ></use>
    </svg>
    <div class="selected-parcel" *ngIf="selectedParcel && !hasInputFocus">
      <div class="selected-parcel-name" [style.color]="customColor">
        {{ selectedParcel?.name }}
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="selected-parcel-code">{{ selectedParcel?.numSaisi }}</div>
    </div>
    <input
      #inputFilter
      autocomplete="off"
      matInput
      placeholder="Rechercher une parcelle"
      [matAutocomplete]="auto"
      [formControl]="filterControl"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="onKeyDown()"
      (keyup.enter)="inputFilter.blur()"
      [style.color]="customColor"
    />
    <button
      mat-button
      *ngIf="canResetTerm && (filterControl.value || !hasInputFocus)"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (mousedown)="onResetFilter()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" class="filter-overlay-panel">
      <mat-option
        *ngFor="let parcel of filteredParcels; trackBy: trackByParcelId"
        [id]="parcel.id"
        (onSelectionChange)="onSelect(parcel.id)"
        (mousedown)="onSelect(parcel.id)"
        (mouseenter)="onParcelHovered(parcel.id)"
        (mouseleave)="onParcelHovered(null)"
      >
        <div class="filter-option-container">
          <svg
            viewBox="0 0 25 25"
            *ngIf="parcel.aoi"
            class="filter-option-img"
            [fstarFromGeoJson]="parcel.aoi"
            size="25"
          ></svg>
          <span>{{ parcel.name }}</span>
          <div class="separator">-</div>
          <span>{{ parcel.numSaisi }}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
