import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { FindPhenologicalStagesError, FindPhenologicalStagesSuccess } from '@app/overview/overview.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface State extends EntityState<farmStarApiModel.PhenologicalStageBackend> {
  isLoaded: boolean;
}
export const adapter: EntityAdapter<farmStarApiModel.PhenologicalStageBackend> = createEntityAdapter<
  farmStarApiModel.PhenologicalStageBackend
>({
  selectId: phenologicalStage => phenologicalStage.id.toString(),
});

const initialState: State = adapter.getInitialState({
  isLoaded: false,
});

const reducer = createReducer(
  initialState,
  on(FindPhenologicalStagesSuccess, (state, { phenologicalStages }) =>
    adapter.addAll(phenologicalStages, { ...state, isLoaded: true })
  ),
  on(FindPhenologicalStagesError, state => ({ ...state, isLoaded: true }))
);

export function phenologicalStagesReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
