import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { Link } from '@app/overview/shared/farm-star/common.model';

export interface Campaign {
  id: string;
  code: string;
  label: string;
  year: number;
  active: boolean;
  publishTotalN: boolean;
  publishIrrigation: boolean;
  subscriptionAllowed: boolean;
  cropsIds?: string[];
  _links: {
    self: Link;
    campaign: Link;
    crops: Link;
  };
}

export interface RegisteredCropsBackend {
  id: string;
  cropRefId: string;
  _links: {
    self: Link;
    registeredCrop: Link;
    crop: Link;
    campaign: Link;
  };
}

export interface CropResponse {
  _embedded: {
    crops: CropBackend[];
  };
}

export interface CropBackend {
  id: string;
  label: string;
  code: string;
  _links?: {
    self: Link;
    crop: Link;
    parcels: Link;
    phenologicalStages: Link;
    cropRefs: Link;
  };
}

export interface PreviousCropBackend {
  id: string;
  label: string;
  code: string;
  ndoseComputationEngines: NDoseComputationEngines;
  _links: {
    self: Link;
    previousCrop: Link;
    parcels: Link;
    nDoseComputationEngines: Link;
  };
}

export interface PreviousCropWasteUsageBackend {
  code: string;
  id: string;
  label: string;
  _links: {
    self: Link;
    previousCropWasteUsage: Link;
    parcels: Link;
  };
}

interface NDoseComputationEngines {
  code: string;
  id: string;
  internal: true;
  label: string;
  nDoseComputationMethods: [NDoseComputationMethods];
}

interface NDoseComputationMethods {
  code: string;
  id: string;
  label: string;
}
export interface CampaignStore extends Campaign {
  cropsNames?: string[];
}

export interface CustomerDataCampaign {
  id: string;
  fileName: string;
  uploadedOn: string;
  fileLink: string;
  status: farmStarApiModel.DatafileStatus;
  crops?: string[];
}

export interface TestErrorReportViewElement {
  level: string;
  entityCode: string;
  entityType: string;
  field: string;
  message: string;
}

export const entityErrorTypeView = {
  [farmStarApiModel.EntityErrorType.farmer]: 'Exploitant',
  [farmStarApiModel.EntityErrorType.technician]: 'Technicien',
  [farmStarApiModel.EntityErrorType.parcel]: 'Parcelle',
};

export const errorLevelView = {
  [farmStarApiModel.ErrorLevel.warning]: 'Alerte',
  [farmStarApiModel.ErrorLevel.error]: 'Erreur',
};
