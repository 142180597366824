<fstar-navbar></fstar-navbar>
<div class="app-overlay-choose-cooperative" *ngIf="(mustChooseHisCooperative$ | async) === true">
  <div class="choose-cooperative-message">
    <svg aria-hidden="true"><use href="assets/images/sprites.svg#warning"></use></svg>
    <span> Veuillez sélectionner, ci-dessus, l'organisme que vous souhaitez consulter. </span>
  </div>
</div>
<fret-toolbar
  [toolbarShown]="true"
  expandMode="over"
  [expandWidth]="180"
  [expandDuration]="150"
  [expandDelay]="500"
  (expansionChange)="onExpansionChange($event)"
>
  <div>
    <fret-toolbar-toggle-button
      [class.button-is-active]="isMapOpened"
      [activeColor]="activeColor"
      [inactiveColor]="inactiveColor"
      [active]="isMapOpened"
      [fretPanelLink]="[routesNames.PARCELS]"
      panelBase="/"
      queryParamsHandling="preserve"
      label="Accueil"
    >
      <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#home"></use></svg>
    </fret-toolbar-toggle-button>
    <fret-toolbar-toggle-button
      [class.button-is-active]="isDivisionOpen"
      [activeColor]="activeColor"
      [inactiveColor]="inactiveColor"
      [active]="isDivisionOpen"
      [fretPanelLink]="[routesNames.DIVISION]"
      panelBase="/"
      queryParamsHandling="preserve"
      label="Mon atelier Azote"
    >
      <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#division"></use></svg>
    </fret-toolbar-toggle-button>
    <fret-toolbar-toggle-button
      *ngIf="canSeeImportDatabase"
      [class.button-is-active]="isImportDatabaseOpened"
      [activeColor]="activeColor"
      [inactiveColor]="inactiveColor"
      [active]="isImportDatabaseOpened"
      [fretPanelLink]="[routesNames.IMPORT_DATABASE]"
      panelBase="/"
      queryParamsHandling="preserve"
      label="Administration"
    >
      <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#subscription"></use></svg>
    </fret-toolbar-toggle-button>
    <fret-toolbar-toggle-button
      *ngIf="canSeeNotifications"
      [class.button-is-active]="isNotificationsOpened"
      [activeColor]="activeColor"
      [inactiveColor]="inactiveColor"
      [active]="isNotificationsOpened"
      [fretPanelLink]="[routesNames.NOTIFICATIONS]"
      panelBase="/"
      queryParamsHandling="preserve"
      label="Notifications"
    >
      <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#bell"></use></svg>
    </fret-toolbar-toggle-button>
    <fret-toolbar-toggle-button
      [class.button-is-active]="isCompareOpened"
      [activeColor]="activeColor"
      [inactiveColor]="inactiveColor"
      [active]="isCompareOpened"
      [fretPanelLink]="[routesNames.COMPARE]"
      panelBase="/"
      queryParamsHandling="preserve"
      label="Comparer"
    >
      <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#comparison"></use></svg>
    </fret-toolbar-toggle-button>
  </div>
  <div class="buttons-bottom">
    <mat-divider></mat-divider>
    <fret-toolbar-toggle-button
      [class.button-is-active]="isHelpOpened"
      [activeColor]="activeColor"
      [inactiveColor]="inactiveColor"
      [active]="isHelpOpened"
      [fretPanelLink]="[routesNames.HELP]"
      panelBase="/"
      queryParamsHandling="preserve"
      label="Aide"
    >
      <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#help"></use></svg>
    </fret-toolbar-toggle-button>
  </div>
</fret-toolbar>
<div class="selected-toolbar-component"><router-outlet></router-outlet></div>
