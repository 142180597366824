import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SwitchAdviceModeComponent } from '@app/overview/parcels-viewer/core/switch-advice-mode/switch-advice-mode.component';

@NgModule({
  declarations: [SwitchAdviceModeComponent],
  imports: [CommonModule, MatSlideToggleModule, MatButtonModule, MatIconModule],
  exports: [SwitchAdviceModeComponent],
})
export class SwitchAdviceModeModule {}
