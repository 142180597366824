import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { catchError, concatMap, filter, flatMap, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@fret-ngx/aaa';
import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';
import { CooperativeStore, UserRole } from '@app/overview/overview.models';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { of } from 'rxjs';
import {
  RegisteredFarmersResponse,
  RegisteredTechniciansResponse,
  RegisteredUserWithRefs,
} from '@app/overview/shared/farm-star/farmer.model';
import { select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { CropBackend } from '@app/overview/import-database/import-database.model';
import { COOPERATIVE_KEY } from '@app/shared/local-storage';
import UserDataAndUserRole = farmStarApiModel.UserDataAndUserRole;
import CooperativesResponse = farmStarApiModel.CooperativesResponse;
import * as OverviewActions from '@app/overview/overview.actions';
import { AppInitError, AppInitSuccess, InitAllData } from '@app/app.action';
import { Crop } from './overview/shared/farm-star/agro-datum.model';
import { AgroDatumApiService } from './shared/api/agro-datum/agro-datum-api.service';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private farmStarService: FarmStarService,
    private authService: AuthService,
    private agroDatumApiService: AgroDatumApiService,
    private store: Store<fromOverview.OverviewGlobalState>
  ) {}

  findUserIdpIdentifier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap(() => this.authService.user.pipe(filter(user => user !== null && !user.expired))),
      take(1),
      concatMap(() =>
        this.farmStarService.getUserIdpIdentifier().pipe(
          map((idpIdentifier: string) => OverviewActions.FindUserIdpIdentifierSuccess({ idpIdentifier })),
          catchError(() => [AppInitError()])
        )
      )
    )
  );

  findUserRoleAndUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindUserIdpIdentifierSuccess),
      concatMap(({ idpIdentifier }) =>
        this.farmStarService.findUserRoleAndUserData(idpIdentifier).pipe(
          flatMap((userDataAndUserRole: UserDataAndUserRole) => [
            OverviewActions.FindUserRoleSuccess({ userRole: userDataAndUserRole.userRole }),
            OverviewActions.FindUserDataSuccess({ userData: userDataAndUserRole.userData }),
            OverviewActions.FindUserRoleLabelSuccess({ userRoleLabel: userDataAndUserRole.userRoleLabel }),
            InitAllData({ userRole: userDataAndUserRole.userRole, userData: userDataAndUserRole.userData }),
          ]),
          catchError(() => [AppInitError()])
        )
      )
    )
  );

  initFarmerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitAllData),
      filter(({ userRole }) => userRole === UserRole.farmer),
      concatMap(({ userData }) =>
        this.farmStarService
          .findRegisteredFarmers({ 'basicUser.id': userData.id, projection: 'registeredUserRefs' })
          .pipe(
            map(
              (registeredFarmersResponse: RegisteredFarmersResponse) =>
                registeredFarmersResponse._embedded.registeredFarmers
            ),
            flatMap((registeredFarmers: RegisteredUserWithRefs[]) => {
              const campaigns = new Set(registeredFarmers.map(rf => rf.campaign.id));
              return [
                OverviewActions.FindCooperative({
                  url: (userData as farmStarApiModel.BasicUserData)._links.cooperative.href,
                }),
                OverviewActions.FindCampaigns({ campaignIds: [...campaigns] }),
                OverviewActions.SaveRegisteredUsers({ registeredUsers: registeredFarmers }),
              ];
            }),
            catchError(() => [AppInitError()])
          )
      )
    )
  );

  initTechnicianData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitAllData),
      filter(({ userRole }) => userRole === UserRole.technician),
      concatMap(({ userData }) =>
        this.farmStarService
          .findRegisteredTechnicians({ 'basicUser.id': userData.id, projection: 'registeredUserRefs' })
          .pipe(
            concatMap((registeredTechniciansResponse: RegisteredTechniciansResponse) => {
              const technicians = registeredTechniciansResponse._embedded
                .registeredTechnicians as RegisteredUserWithRefs[];
              const campaigns = new Set(technicians.map(rt => rt.campaign.id));
              return [
                OverviewActions.FindCooperative({
                  url: (userData as farmStarApiModel.BasicUserData)._links.cooperative.href,
                }),
                OverviewActions.FindCampaigns({ campaignIds: [...campaigns] }),
                OverviewActions.SaveRegisteredUsers({ registeredUsers: technicians }),
              ];
            }),
            catchError(() => [AppInitError()])
          )
      )
    )
  );

  initCoordinatorData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitAllData),
      filter(({ userRole }) => userRole === UserRole.coordinator),
      map(({ userData }) => userData),
      concatMap((userData: farmStarApiModel.CoordinatorData) =>
        this.farmStarService.findCoordinatorCooperatives(userData._links.cooperatives.href).pipe(
          map(convertToCooperativesStore),
          flatMap((cooperatives: CooperativeStore[]) => [
            OverviewActions.FindCooperativesSuccess({ cooperatives }),
            OverviewActions.FindCampaigns({}),
          ]),
          catchError(() => [AppInitError()])
        )
      )
    )
  );

  initFindCooperative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCooperative),
      concatMap(({ url }) =>
        this.farmStarService.findCooperative(url).pipe(
          map((cooperativeResponse: farmStarApiModel.CooperativeBackend) => [cooperativeResponse]),
          map(convertToCooperativesStore),
          map((cooperatives: CooperativeStore[]) => OverviewActions.FindCooperativesSuccess({ cooperatives })),
          catchError(() => [AppInitError()])
        )
      )
    )
  );

  initOtherUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitAllData),
      filter(({ userRole }) => userRole === UserRole.otherUsers),
      flatMap(() => [OverviewActions.LoadAllCooperativesForOtherUsers(), OverviewActions.FindCampaigns({})])
    )
  );

  findAllCooperativesForOtherUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.LoadAllCooperativesForOtherUsers),
      concatMap(() =>
        this.farmStarService.findAllCooperatives().pipe(
          concatMap((cooperativesResponse: CooperativesResponse) =>
            this.farmStarService.findAllCooperatives(cooperativesResponse.page.totalElements).pipe(
              map((response: CooperativesResponse) => response._embedded.cooperatives),
              map(convertToCooperativesStore),
              map((cooperatives: CooperativeStore[]) => OverviewActions.FindCooperativesSuccess({ cooperatives })),
              catchError(() => [AppInitError()])
            )
          ),
          catchError(() => [AppInitError()])
        )
      )
    )
  );

  cooperativesEmpty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCooperativesSuccess),
      filter(({ cooperatives }) => cooperatives.length === 0),
      map(() => AppInitError())
    )
  );

  preSelectCooperative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCooperativesSuccess),
      filter(({ cooperatives }) => cooperatives.length === 1),
      flatMap(({ cooperatives }) => [
        AppInitSuccess(),
        OverviewActions.SelectCooperative({ cooperative: cooperatives[0] }),
      ])
    )
  );

  moreThan1CooperativeAndLocalStorageIsEmpty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCooperativesSuccess),
      filter(({ cooperatives }) => cooperatives.length > 1 && !localStorage.getItem(COOPERATIVE_KEY)),
      map(() => OverviewActions.UserMustChooseHisCooperative())
    )
  );

  getCooperativeFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.FindCooperativesSuccess),
      filter(({ cooperatives }) => cooperatives.length > 1 && !!localStorage.getItem(COOPERATIVE_KEY)),
      map(({ cooperatives }) => {
        const cooperativeCode = localStorage.getItem(COOPERATIVE_KEY);
        const cooperativeSelected = cooperatives.find(c => c.code === cooperativeCode);
        return cooperativeSelected != null
          ? OverviewActions.SelectCooperativeFromLocalStorage({ cooperative: cooperativeSelected })
          : OverviewActions.UserMustChooseHisCooperative();
      })
    )
  );

  userMustChooseHisCooperative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.UserMustChooseHisCooperative),
      flatMap(() => [AppInitSuccess(), OverviewActions.InviteUserToChooseHisCooperative()])
    )
  );

  preSelectCooperativeFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.SelectCooperativeFromLocalStorage),
      flatMap(({ cooperative }) => [OverviewActions.SelectCooperative({ cooperative }), AppInitSuccess()])
    )
  );

  allowUserToSeeAppWhenCooperativeIsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OverviewActions.SelectCooperative),
      withLatestFrom(this.store.pipe(select(fromOverview.selectMustChooseHisCooperative))),
      filter(([, mustChooseHisCooperative]) => mustChooseHisCooperative),
      take(1),
      map(() => OverviewActions.EnableAppWhenUserHasChosenHisCooperative())
    )
  );

  saveCooperativeInLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OverviewActions.SelectCooperative),
        tap(({ cooperative }) => {
          localStorage.setItem(COOPERATIVE_KEY, cooperative.code);
          return;
        })
      ),
    { dispatch: false }
  );

  findAllCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppInitSuccess),
      concatMap(() => this.authService.user.pipe(filter(user => user !== null && !user.expired))),
      take(1),
      concatMap(() =>
        this.agroDatumApiService.findCrops({}).pipe(
          concatMap((crops: Crop[]) => of(OverviewActions.FindCropsSuccess({ crops }))),
          catchError(() => of(OverviewActions.FindCropsError()))
        )
      )
    )
  );

  findAllPhenologicalStages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppInitSuccess),
      concatMap(() => this.authService.user.pipe(filter(user => user !== null && !user.expired))),
      take(1),
      concatMap(() =>
        this.farmStarService.findCornCrop().pipe(
          concatMap((crop: CropBackend) =>
            this.farmStarService.findAllPhenologicalStages(crop._links.phenologicalStages.href).pipe(
              concatMap((responses: farmStarApiModel.PhenologicalStageResponse) =>
                this.farmStarService
                  .findAllPhenologicalStages(crop._links.phenologicalStages.href, responses.page.totalElements)
                  .pipe(
                    map(convertToPhenologicalStageStore),
                    map((phenologicalStages: farmStarApiModel.PhenologicalStageBackend[]) =>
                      OverviewActions.FindPhenologicalStagesSuccess({ phenologicalStages })
                    ),
                    catchError(() => of(OverviewActions.FindPhenologicalStagesError()))
                  )
              ),
              catchError(() => of(OverviewActions.FindPhenologicalStagesError()))
            )
          ),
          catchError(() => of(OverviewActions.FindPhenologicalStagesError()))
        )
      )
    )
  );
}

function convertToCooperativesStore(cooperatives: farmStarApiModel.CooperativeBackend[]): CooperativeStore[] {
  return cooperatives.map(c => ({
    id: c.id,
    name: c.name,
    code: c.code,
    urlRegisteredCooperatives: c._links.registeredCooperatives.href,
    urlLogo: c._links.logoSmall.href,
  }));
}

function convertToPhenologicalStageStore(
  responses: farmStarApiModel.PhenologicalStageResponse
): farmStarApiModel.PhenologicalStageBackend[] {
  const phenologicalStages = responses._embedded.phenologicalStages.filter(p =>
    farmStarApiModel.allPhenologicalCodes.includes(p.code)
  );
  return [
    {
      id: farmStarApiModel.phenologicalSeedlingStageId,
      code: farmStarApiModel.PhenologicalStageCode.seedling,
      label: 'Semis',
      advice: '',
      action: farmStarApiModel.PhenologicalStageAction.intervention,
    },
    ...phenologicalStages,
  ];
}
