<section class="spreading-equipments-edit">
  <article class="spreading-equipments-edit-header">
    <svg aria-hidden="true"><use xlink:href="assets/images/sprites.svg#equipments"></use></svg>
    <aside>{{ addOrEditLabel }} d'un matériel</aside>
  </article>
  <form [formGroup]="equipmentForm" class="spreading-equipments-edit-content">
    <mat-form-field class="full-width">
      <mat-label>Nom de l'équipememnt (par defaut : "Console")</mat-label>
      <input matInput formControlName="name" placeholder="Console" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Constructeur</mat-label>
      <mat-select formControlName="selectedBrand" (selectionChange)="onBrandSelected()">
        <mat-option *ngFor="let brand of brands" [value]="brand">{{ brand }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Console</mat-label>
      <mat-select formControlName="selectedConsole" (selectionChange)="onConsoleSelected()">
        <mat-option *ngFor="let console of consoles" [value]="console">{{ console }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Format</mat-label>
      <mat-select formControlName="selectedFormat" (selectionChange)="onFormatSelected()">
        <mat-option *ngFor="let format of formats" [value]="format">{{ format }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <article class="spreading-equipments-edit-footer">
    <button mat-button (click)="onClose()">Annuler</button>
    <div class="middle-space"></div>
    <button mat-button [disabled]="!equipmentForm.valid" (click)="onSave()">Enregistrer</button>
  </article>
</section>
