<form class="filter-form">
  <mat-form-field class="filter-input" floatLabel="never">
    <mat-icon matPrefix class="selected-farm-icon" [style.color]="customColor">gite</mat-icon>
    <div class="selected-farm" *ngIf="selectedFarm && !isFarmView && !hasInputFocus">
      <div class="selected-farm-name" [class.black-font-color]="!!customColor">
        {{ selectedFarm.corporateName }}
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="selected-farm-role">Exploitation</div>
    </div>
    <input
      #inputFilter
      autocomplete="off"
      matInput
      placeholder="Rechercher une exploitation"
      [matAutocomplete]="auto"
      [formControl]="filterControl"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="onKeyDown()"
      (keyup.enter)="inputFilter.blur()"
      [class.black-font-color]="!!customColor"
    />
    <button
      mat-button
      *ngIf="canResetTerm && (filterControl.value || !hasInputFocus)"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (mousedown)="onResetFilter()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" class="filter-overlay-panel">
      <mat-option
        *ngFor="let farm of filteredFarms; trackBy: trackByFarmId"
        [id]="farm.id"
        (onSelectionChange)="onSelect(farm.id)"
        (mousedown)="onSelect(farm.id)"
        (mouseenter)="onFarmHovered(farm.id)"
        (mouseleave)="onFarmHovered(null)"
      >
        <div class="filter-option-container">
          <div class="filter-option-container-left">
            <mat-icon>gite</mat-icon>
            <span>{{ farm.corporateName }}</span>
          </div>
          <div class="filter-option-container-right">
            <mat-divider [vertical]="true"></mat-divider>
            <span>Exploitation</span>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
