import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, concatMap, filter, flatMap, map, reduce, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';
import {
  Campaign,
  CampaignStore,
  CropBackend,
  CustomerDataCampaign,
  RegisteredCropsBackend,
} from '@app/overview/import-database/import-database.model';
import { forkJoin, merge, of } from 'rxjs';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import { select, Store, Action } from '@ngrx/store';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { FileSaverService } from '@app/shared/file-saver/file-saver.service';
import { Dictionary } from '@ngrx/entity';
import * as fromOverview from '@app/overview/overview.reducer';
import { CooperativeStore } from '@app/overview/overview.models';
import { ofRoute, routesRegex } from '@app/shared/utils/routes.helper';
import { RegisteredCooperative, RegisteredCooperativeResponse } from '@app/overview/shared/farm-star/farmer.model';
import DataFileItem = farmStarApiModel.DataFileItem;
import selectSelectedCooperative = fromOverview.selectSelectedCooperative;
import { CoreModel } from '../shared/farm-star/core.model';

@Injectable()
export class ImportDatabaseEffects {
  constructor(
    private actions$: Actions,
    private farmStarService: FarmStarService,
    private store: Store<fromImportDatabase.State>,
    private fileSaverService: FileSaverService
  ) {}

  loadCampaignsWhenNavigateToImportDatabase$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute(routesRegex.IMPORT_DATABASE),
      withLatestFrom(this.store.pipe(select(selectSelectedCooperative))),
      filter(([, cooperative]: [any, CooperativeStore]) => cooperative != null),
      flatMap(([, cooperative]: [any, CooperativeStore]) => [
        ImportDatabaseActions.ResetDatafiles(),
        ImportDatabaseActions.ResetImportDatabaseData(),
        ImportDatabaseActions.FindCampaignsBySelectedCooperative({ cooperative }),
      ])
    )
  );

  reloadDataFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.ReloadDataFile),
      switchMap(({ datafileId }) => {
        return this.farmStarService.getSpecificDataFile(datafileId).pipe(
          flatMap((dataFile: farmStarApiModel.DataFileItem) => [
            ImportDatabaseActions.ReloadDataFileSuccess({
              dataFileUpdated: { ...dataFile, id: datafileId } as DataFileItem,
            }),
            ImportDatabaseActions.SelectDatafile({ customerDataCampaignId: datafileId }),
          ]),
          catchError(() => [])
        );
      })
    )
  );

  findCampaignsByCooperative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.FindCampaignsBySelectedCooperative),
      concatMap(({ cooperative }) =>
        this.farmStarService
          .findRegisteredCooperativeByCooperativeId({ ['cooperative.id']: cooperative.id, size: 1 })
          .pipe(
            concatMap((registeredCooperativeResponse: RegisteredCooperativeResponse) =>
              merge(
                this.activeCampaignsNotEmpty(registeredCooperativeResponse.page.totalElements, cooperative.id),
                this.activeCampaignsEmpty(registeredCooperativeResponse.page.totalElements)
              )
            ),
            catchError(() => this.activeCampaignsEmpty(0))
          )
      )
    )
  );

  findCampaignsWithCropsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.FindCampaignsWithCropsIds),
      filter(({ campaigns }) => campaigns.length > 0),
      switchMap(({ campaigns }) =>
        forkJoin(
          campaigns.map((campaign: Campaign) =>
            this.farmStarService.findCampaignCrops(campaign._links.crops.href).pipe(
              map((registeredCropsBackend: RegisteredCropsBackend[]) =>
                registeredCropsBackend.map((r: RegisteredCropsBackend) => r.cropRefId)
              ),
              map((cropsIds: string[]) => ({ ...campaign, cropsIds })),
              catchError(() => of(campaign))
            )
          )
        )
      ),
      map((c: Campaign[]) => ImportDatabaseActions.FindCampaignsWithCropsIdsSuccess({ campaigns: c }))
    )
  );

  downloadUploadedDatafile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ImportDatabaseActions.DownloadUploadedDatafile),
        withLatestFrom(this.store.pipe(select(fromImportDatabase.selectSelectedCustomerDatafile))),
        concatMap(([_, data]: [any, CustomerDataCampaign]) => {
          return this.farmStarService.downloadFile(data.fileLink).pipe(
            tap((downloadedFileData: Blob) => {
              this.fileSaverService.saveAs(downloadedFileData, data.fileName);
            }),
            catchError(() => [])
          );
        })
      ),
    { dispatch: false }
  );

  downloadErrorTestReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ImportDatabaseActions.DownloadTestErrorReport),
        withLatestFrom(this.store.pipe(select(fromImportDatabase.selectSelectedCustomerDatafile))),
        concatMap(([, data]) => {
          return this.farmStarService.downloadTestErrorReport(data.id).pipe(
            tap((downloadedFileData: Blob) => {
              this.fileSaverService.saveAs(downloadedFileData, generateTestErrorReportFileName(data.fileName));
            }),
            catchError(() => [])
          );
        })
      ),
    { dispatch: false }
  );

  sendDatafile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.SendDatafile),
      concatMap(action =>
        this.farmStarService.sendDatafile(action.datafileRequest).pipe(
          map(() => ImportDatabaseActions.SendDatafileSuccess()),
          catchError(() => [])
        )
      )
    )
  );

  sendDatafileToProduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.SendDataFileToProduction),
      concatMap(action =>
        this.farmStarService.sendDataFileToProduction(action.datafileId).pipe(
          map(() => ImportDatabaseActions.SendDataFileToProductionSuccess()),
          catchError(() => [])
        )
      )
    )
  );

  findCampaignsCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.FindCampaignsWithCropsIdsSuccess),
      concatMap(({ campaigns }) =>
        forkJoin(
          campaigns.reduce((actions, campaign: Campaign) => {
            const actionsByCampaign = campaign.cropsIds.map((cropsId: string) => {
              return this.farmStarService.findCrop(cropsId).pipe(
                map((crop: CropBackend) => ImportDatabaseActions.SaveCropCampaign({ campaign, cropName: crop.label })),
                catchError(() => of(ImportDatabaseActions.SaveCropCampaignError()))
              );
            });
            return [...actions, ...actionsByCampaign];
          }, [])
        )
      ),
      flatMap((v: Action[]) => [...v, ImportDatabaseActions.SaveCropCampaignSuccess()])
    )
  );

  selectFirstDatafile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.LoadDataFileSuccess),
      map(({ customerDataCampaigns }) =>
        ImportDatabaseActions.SelectDatafile({ customerDataCampaignId: customerDataCampaigns[0].id })
      )
    )
  );

  loadTestReportsOfSelectedDataFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.SelectDatafile),
      withLatestFrom(this.store.pipe(select(fromImportDatabase.selectCustomerDataCampaignsEntities))),
      filter(
        filterByStatus(
          farmStarApiModel.DatafileStatus.TESTED,
          farmStarApiModel.DatafileStatus.IMPORTED,
          farmStarApiModel.DatafileStatus.IMPORTING
        )
      ),
      concatMap(([{ customerDataCampaignId }, _]) =>
        this.farmStarService.loadTestReports({ dataFile: customerDataCampaignId }).pipe(
          filter((testReports: farmStarApiModel.TestReportItem[]) => testReports && testReports.length > 0),
          map(getMostRecentResult),
          map((testReport: farmStarApiModel.TestReportItem) =>
            ImportDatabaseActions.LoadTestReportSuccess({ testReport })
          ),
          catchError(() => [ImportDatabaseActions.LoadTestReportFailed()])
        )
      )
    )
  );

  loadTestErrorReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.LoadTestReportSuccess),
      filter(({ testReport }) => testReport != null),
      concatMap(({ testReport }) =>
        this.farmStarService.loadTestErrorReport(testReport._links.errors.href).pipe(
          map((testErrorReport: farmStarApiModel.TestErrorReportItem[]) =>
            ImportDatabaseActions.LoadTestErrorReportSuccess({ testErrorReport })
          ),
          catchError(() => [])
        )
      )
    )
  );

  loadImportReportsOfSelectedDataFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.SelectDatafile),
      withLatestFrom(this.store.pipe(select(fromImportDatabase.selectCustomerDataCampaignsEntities))),
      filter(filterByStatus(farmStarApiModel.DatafileStatus.IMPORTED)),
      concatMap(([{ customerDataCampaignId }, _]) =>
        this.farmStarService
          .loadImportReports({
            dataFile: customerDataCampaignId,
          })
          .pipe(
            filter((importReports: farmStarApiModel.ImportReportItem[]) => importReports && importReports.length > 0),
            map(getMostRecentResult),
            map((importReport: farmStarApiModel.ImportReportItem) =>
              ImportDatabaseActions.LoadImportReportSuccess({ importReport })
            ),
            catchError(() => [ImportDatabaseActions.LoadImportReportFailed()])
          )
      )
    )
  );

  loadDataFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportDatabaseActions.SaveCropCampaignSuccess, ImportDatabaseActions.SendDatafileSuccess),
      withLatestFrom(
        this.store.pipe(select(fromImportDatabase.selectCampaignsActivated)),
        this.store.pipe(
          select(fromOverview.selectSelectedCooperative),
          filter((cooperative: CooperativeStore) => cooperative != null),
          map((cooperative: CooperativeStore) => cooperative.id)
        )
      ),
      filter(([_, campaigns]: [any, CampaignStore[], string]) => campaigns.length > 0),
      switchMap(([_, campaigns, cooperativeRefId]: [any, CampaignStore[], string]) => {
        return forkJoin(
          campaigns.map(c =>
            this.farmStarService.getFilteredDataFiles({ campaignRefId: c.id, cooperativeRefId }).pipe(
              map((dataFiles: farmStarApiModel.DataFileItem[]) => {
                return dataFiles.map(datafile => ({ ...datafile, crops: c.cropsNames }));
              }),
              catchError(() => of([]))
            )
          )
        ).pipe(
          map(v =>
            v.reduce(
              (acc: farmStarApiModel.DataFileItemWithCrops[], val: farmStarApiModel.DataFileItemWithCrops[]) => [
                ...acc,
                ...val,
              ],
              []
            )
          )
        );
      }),
      switchMap((dataFileItems: farmStarApiModel.DataFileItemWithCrops[]) =>
        merge(this.dataFilesEmpty(dataFileItems), this.dataFilesNotEmpty(dataFileItems))
      )
    )
  );

  activeCampaignsEmpty(registeredCooperativeLength: number) {
    return of(registeredCooperativeLength).pipe(
      filter(() => registeredCooperativeLength === 0),
      flatMap(() => [
        ImportDatabaseActions.OnRequestFailed(),
        ImportDatabaseActions.OpenImportDatabaseDialog(),
        ImportDatabaseActions.IsDatafileListEmpty({ isDatafileListEmpty: true }),
        ImportDatabaseActions.FindCampaignsWithCropsIdsSuccess({ campaigns: [] }),
      ])
    );
  }

  activeCampaignsNotEmpty(registeredCooperativeLength: number, id: string) {
    return of(registeredCooperativeLength).pipe(
      filter(() => registeredCooperativeLength > 0),
      concatMap(() =>
        this.farmStarService
          .findRegisteredCooperativeByCooperativeId({
            ['cooperative.id']: id,
            size: registeredCooperativeLength,
          })
          .pipe(
            map((coop: RegisteredCooperativeResponse) => coop._embedded.registeredCooperatives),
            switchMap((registeredCooperatives: RegisteredCooperative[]) =>
              forkJoin(
                registeredCooperatives.map(registeredCooperative =>
                  this.farmStarService
                    .findCampaignByUrl(registeredCooperative._links.campaign.href)
                    .pipe(catchError(() => of(null)))
                )
              )
            ),
            map(campaigns => campaigns.filter(c => c != null)),
            map((campaigns: CoreModel.CampaignBackend[]) =>
              ImportDatabaseActions.FindCampaignsWithCropsIds({ campaigns })
            ),
            catchError(() => this.activeCampaignsEmpty(0))
          )
      )
    );
  }

  dataFilesEmpty(dataFileItems: farmStarApiModel.DataFileItemWithCrops[]) {
    return of(dataFileItems).pipe(
      filter(d => d.length === 0),
      flatMap(() => [
        ImportDatabaseActions.OnRequestFailed(),
        ImportDatabaseActions.OpenImportDatabaseDialog(),
        ImportDatabaseActions.IsDatafileListEmpty({ isDatafileListEmpty: true }),
      ])
    );
  }

  dataFilesNotEmpty(dataFileItems: farmStarApiModel.DataFileItemWithCrops[]) {
    return of(dataFileItems).pipe(
      filter(d => d.length > 0),
      switchMap((dataFilesItemWithCrops: farmStarApiModel.DataFileItemWithCrops[]) => {
        return forkJoin(
          dataFilesItemWithCrops.map(dataFileItemWithCrops => {
            return this.farmStarService.getFile(dataFileItemWithCrops).pipe(
              map((customerDataCampaign: CustomerDataCampaign) => customerDataCampaign),
              catchError(() => of([]))
            );
          })
        ).pipe(reduce((acc: CustomerDataCampaign[], val: CustomerDataCampaign[]) => acc.concat(...val), []));
      }),
      filter((d: CustomerDataCampaign[]) => d.length > 0),
      map((d: CustomerDataCampaign[]) => {
        return d.sort((a, b) => new Date(b.uploadedOn).getTime() - new Date(a.uploadedOn).getTime());
      }),
      flatMap((d: CustomerDataCampaign[]) => [
        ImportDatabaseActions.LoadDataFileSuccess({ customerDataCampaigns: d }),
        ImportDatabaseActions.IsDatafileListEmpty({ isDatafileListEmpty: false }),
      ])
    );
  }
}

type FilteringProcessingsFn = ([action, customerDataCampaign]: [
  { customerDataCampaignId: string },
  Dictionary<CustomerDataCampaign>
]) => boolean;

function filterByStatus(...statusArray: farmStarApiModel.DatafileStatus[]): FilteringProcessingsFn {
  return ([action, customerDataCampaign]: [{ customerDataCampaignId: string }, Dictionary<CustomerDataCampaign>]) => {
    return (
      customerDataCampaign[action.customerDataCampaignId] != null &&
      statusArray.some(
        (status: farmStarApiModel.DatafileStatus) =>
          status === customerDataCampaign[action.customerDataCampaignId].status
      )
    );
  };
}

type Reports = farmStarApiModel.ImportReportItem | farmStarApiModel.TestReportItem;

function getMostRecentResult(results: Reports[]): Reports {
  return results.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())[0];
}

function generateTestErrorReportFileName(datafileName: string) {
  const extensionRegex = /([^\/.]+)\.[^.]*$/g;
  let testErrorReportFileName;
  if (extensionRegex.test(datafileName)) {
    extensionRegex.lastIndex = 0;
    const exec = extensionRegex.exec(datafileName);
    testErrorReportFileName = exec[1] + '_testErrorReport.csv';
  } else {
    testErrorReportFileName = 'testErrorReport.csv';
  }
  return testErrorReportFileName;
}
