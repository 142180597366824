import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { RouterNavigateAction } from '@davinkevin/router-store-helper';
import { routesNames } from '@app/shared/utils/routes.helper';
import * as fromOverview from '@app/overview/overview.reducer';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { FilteredFarm } from '@app/overview/overview.models';
import { CompanionComponent } from '@davinkevin/companion-component';
import { ofType } from '@ngrx/effects';
import { BlurFarmFilter } from '@app/overview/overview.actions';

@Component({
  selector: 'fstar-farm-filter',
  templateUrl: './farm-filter.component.html',
  styleUrls: ['./farm-filter.component.scss'],
})
export class FarmFilterComponent implements OnInit, OnDestroy {
  @ViewChild('auto', { static: false }) autoComplete: MatAutocomplete;
  @ViewChild('inputFilter', { static: false }) inputFilter: ElementRef;

  @Input() filteredFarms: FilteredFarm[];
  @Input() selectedFarm: FilteredFarm;
  @Input() isFarmView: boolean;
  @Input() isTabFarmsByIdView: boolean;
  @Input() canResetTerm: boolean;
  @Input() shouldRedirect: boolean;
  @Input() customColor: string;
  @Output() farmTermChanged = new EventEmitter<string>();
  @Output() selectedFarmId = new EventEmitter<string>();
  @Output() farmHovered = new EventEmitter<string>();

  public hasInputFocus = false;

  private companion = new CompanionComponent();

  filterControl = new FormControl();

  constructor(private actions: ActionsSubject, private store: Store<fromOverview.OverviewGlobalState>) {}

  ngOnDestroy() {
    this.companion.destroy();
  }

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.actions.pipe(untilDestroy(), ofType(BlurFarmFilter)).subscribe(() => {
      this.inputFilter.nativeElement.blur();
    });

    this.filterControl.valueChanges.pipe(untilDestroy()).subscribe((v: string) => {
      this.farmTermChanged.emit(!!v ? v : null);
    });
  }

  onKeyDown() {
    const selectedOption: MatOption = this.autoComplete.options.find(option => option.active);
    if (selectedOption) {
      this.onFarmHovered(selectedOption.id);
    }
  }

  onFarmHovered(id: string) {
    this.farmHovered.emit(id);
  }

  trackByFarmId(_: any, farm: FilteredFarm) {
    return farm.id;
  }

  onBlur() {
    this.filterControl.setValue('', { emitEvent: false });
    this.farmTermChanged.emit(null);
    this.hasInputFocus = false;
  }

  onFocus() {
    if (this.isFarmView || this.isTabFarmsByIdView) {
      this.filterControl.setValue('', { emitEvent: false });
      this.farmTermChanged.emit(null);
    }
    this.hasInputFocus = true;
  }

  onResetFilter() {
    if (this.shouldRedirect) {
      const navigationUrl = this.isTabFarmsByIdView ? `/${routesNames.TAB_FARMS}` : `/${routesNames.FARMS}`;
      this.store.dispatch(new RouterNavigateAction([navigationUrl], { queryParamsHandling: 'preserve' }));
    }
    this.selectedFarmId.emit(null);
  }

  onSelect(farmId: string) {
    this.selectedFarmId.emit(farmId);
  }
}
