import { NgModule } from '@angular/core';
import { FilterWrapperComponent } from '@app/overview/shared/filter-wrapper/filter-wrapper.component';
import { FarmFilterModule } from '@app/overview/shared/farm-filter/farm-filter.module';
import { ParcelFilterModule } from '@app/overview/shared/parcel-filter/parcel-filter.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FilterWrapperComponent],
  imports: [CommonModule, FarmFilterModule, ParcelFilterModule],
  exports: [FilterWrapperComponent],
})
export class FilterWrapperModule {}
