import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserInformationDialogComponent } from '@app/overview/core/navbar/core/user-information/user-information.dialog.component';
import { ImageWrapperComponent } from './core/image-wrapper/image-wrapper.component';
import { PopupOverlayModule } from '@fret-ngx/button-popup';
import { EffectsModule } from '@ngrx/effects';
import { SpreadingEquipmentsEffects } from './core/spreading-equipments/spreading-equipments.effects';
import { StoreModule } from '@ngrx/store';
import { fromNavBar } from './navbar.reducer';
import { SpreadingEquipmentsComponent } from './core/spreading-equipments/spreading-equipments.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SpreadingEquipmentsEditComponent } from './core/spreading-equipments/spreading-equipments-edit/spreading-equipments-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    NavbarComponent,
    UserInformationDialogComponent,
    ImageWrapperComponent,
    SpreadingEquipmentsComponent,
    SpreadingEquipmentsEditComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    PopupOverlayModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('navbar', fromNavBar.reducers),
    EffectsModule.forFeature([SpreadingEquipmentsEffects]),
  ],
  exports: [NavbarComponent],
  entryComponents: [UserInformationDialogComponent, SpreadingEquipmentsComponent, SpreadingEquipmentsEditComponent],
})
export class NavbarModule {}
