import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export const OLD_TESTED_DATAFILE_DIALOG_WIDTH = '650px';
@Component({
  selector: 'fstar-old-tested-datafile-dialog',
  templateUrl: './old-tested-datafile-dialog.component.html',
  styleUrls: ['./old-tested-datafile-dialog.component.scss'],
})
export class OldTestedDatafileDialogComponent {
  constructor(private dialogRef: MatDialogRef<OldTestedDatafileDialogComponent>) {}

  onClose() {
    this.dialogRef.close();
  }
}
