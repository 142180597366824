import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  AddFeatures,
  DeleteFeature,
  ResetParcelData,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { FeatureArea } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { Action, createReducer, on } from '@ngrx/store';

export type State = EntityState<FeatureArea>;
export const adapter: EntityAdapter<FeatureArea> = createEntityAdapter<FeatureArea>({
  selectId: feature => feature.id.toString(),
});

const initialState: State = adapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(ResetParcelData, () => ({
    ...initialState,
  })),
  on(AddFeatures, (state, { features }) => adapter.addAll(features, state)),
  on(DeleteFeature, (state, { featureId }) => adapter.removeOne(featureId, state))
);

export function featuresEntityReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
