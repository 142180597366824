<div class="legend-description">
  <div>Indice foliaire</div>
  <div>(surface de feuille / m2)</div>
</div>
<div class="lai-legend-container">
  <div class="scale-number">
    <span>6</span>
    <span>5</span>
    <span>4</span>
    <span>3</span>
    <span>2</span>
    <span>1</span>
    <span>0</span>
  </div>
  <div class="scale-color"></div>
</div>
