import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@fret-ngx/aaa';
import { CooperativeStore, UserRole } from '@app/overview/overview.models';
import { select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import { CompanionComponent } from '@davinkevin/companion-component';
import { MatDialog } from '@angular/material/dialog';
import {
  USER_INFO_DIALOG_WIDTH,
  UserInformationDialogComponent,
} from '@app/overview/core/navbar/core/user-information/user-information.dialog.component';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { PopupButtonPosition } from '@fret-ngx/button-popup';
import { ClearLocalStorageHelp, SelectCooperative } from '@app/overview/overview.actions';
import {
  SpreadingEquipmentsComponent,
  SPREADING_EQUIPMENTS_DIALOG_WIDTH,
} from './core/spreading-equipments/spreading-equipments.component';

@Component({
  selector: 'fstar-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();
  popupPositionEnd: PopupButtonPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
  };
  cooperatives: CooperativeStore[] = [];
  selectedCooperative: CooperativeStore = null;
  userData: farmStarApiModel.AllUserTypeData;
  userRoleLabel: string;
  userRole: UserRole;

  constructor(public authService: AuthService, private store: Store<fromOverview.State>, public dialog: MatDialog) {}

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectUserData))
      .subscribe(userData => (this.userData = userData));

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectUserRole))
      .subscribe(userRole => (this.userRole = userRole));

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectUserRoleLabel))
      .subscribe(userRoleLabel => (this.userRoleLabel = userRoleLabel));

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectAllCooperatives))
      .subscribe(cooperatives => (this.cooperatives = cooperatives));

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectSelectedCooperative))
      .subscribe(selectedCooperative => (this.selectedCooperative = selectedCooperative));
  }

  ngOnDestroy(): void {
    this.companion.destroy();
  }

  logout() {
    this.store.dispatch(ClearLocalStorageHelp());
    this.authService.startSignOut();
  }

  selectCooperative(cooperative: CooperativeStore) {
    if (!this.selectedCooperative || this.selectedCooperative.id !== cooperative.id) {
      this.store.dispatch(SelectCooperative({ cooperative }));
    }
  }

  displayUserInfo() {
    this.dialog.open(UserInformationDialogComponent, {
      width: USER_INFO_DIALOG_WIDTH,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
      panelClass: 'user-info-dialog',
      data: { userRole: this.userRole, userData: this.userData },
    });
  }

  displaySpreadingEquipments() {
    this.dialog.open(SpreadingEquipmentsComponent, {
      width: SPREADING_EQUIPMENTS_DIALOG_WIDTH,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
      panelClass: 'spreading-equipments-dialog',
    });
  }
}
