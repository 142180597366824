import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as fromRoot from '@app/reducer';
import { select, Store } from '@ngrx/store';
import { CompanionComponent } from '@davinkevin/companion-component';
import { RouterNavigateAction } from '@davinkevin/router-store-helper';
import { filter } from 'rxjs/operators';
import { routesNames } from '@app/shared/utils/routes.helper';

@Component({
  selector: 'fstar-switch-view',
  templateUrl: './switch-view.component.html',
  styleUrls: ['./switch-view.component.scss'],
})
export class SwitchViewComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();

  isTabUrl = false;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.store
      .pipe(
        select(fromRoot.selectRoute),
        filter(r => r != null),
        untilDestroy()
      )
      .subscribe(routeData => {
        this.isTabUrl = routeData.state.url.includes(`/${routesNames.TAB_FARMS}`);
      });
  }

  ngOnDestroy() {
    this.companion.destroy();
  }

  onToggleChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.store.dispatch(new RouterNavigateAction([`/${routesNames.TAB_FARMS}`], { queryParamsHandling: 'preserve' }));
    } else {
      this.store.dispatch(new RouterNavigateAction([`/${routesNames.FARMS}`], { queryParamsHandling: 'preserve' }));
    }
  }
}
