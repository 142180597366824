<div class="import-database-header">
  <h1>Dépôt de base</h1>
  <button mat-button class="import-button" (click)="openImportDialog()">
    <mat-icon>add</mat-icon>
    <span>Déposer une base</span>
  </button>
</div>
<ng-container *ngIf="(isLoading$ | async) === true; else customerDataCampaigns">
  <div class="loader">
    <div class="ball-pulse">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-container>
<ng-template #customerDataCampaigns>
  <div class="import-database-list" *ngIf="customerDataCampaigns$ | async as customerDataCampaigns">
    <fstar-import-database-list-item
      *ngFor="let item of customerDataCampaigns"
      (click)="setClickedRow(item)"
      [data]="item"
      [class.active]="item.id === (selectedRow$ | async)?.id"
    >
    </fstar-import-database-list-item>
  </div>
</ng-template>
