import { FilteredFarm } from '@app/overview/overview.models';
import { CoreModel } from '@app/overview/shared/farm-star/core.model';

export function filterFarms(farms: CoreModel.FarmBackend[], term: string): FilteredFarm[] {
  const termToLowerCase = term && term.toLowerCase();
  return term && term.length >= 3
    ? farms.reduce((filteredFarms, farm) => {
        if (farm.corporateName.toLowerCase().includes(termToLowerCase)) {
          filteredFarms = [
            ...filteredFarms,
            {
              id: farm.id,
              corporateName: farm.corporateName,
            },
          ];
        }
        return filteredFarms;
      }, [])
    : [];
}
