import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromOverview from '@app/overview/overview.reducer';
import * as fromRoutes from '@app/shared/utils/routes.helper';
import * as fromRoot from '@app/reducer';
import { Observable } from 'rxjs';
import { UserRole } from '@app/overview/overview.models';
import { OpeningToolbarStatus } from '@app/overview/overview.actions';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'fstar-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  mustChooseHisCooperative$: Observable<boolean>;
  routesNames = fromRoutes.routesNames;
  activeColor = '#9eba11';
  inactiveColor = '#042237';
  isImportDatabaseOpened: boolean;
  isNotificationsOpened: boolean;
  isHelpOpened: boolean;
  isCompareOpened: boolean;
  isMapOpened: boolean;
  isDivisionOpen: boolean;
  canSeeImportDatabase = false;
  canSeeNotifications = false;

  constructor(private store: Store<fromOverview.State | fromRoot.State>) {}

  ngOnInit() {
    this.mustChooseHisCooperative$ = this.store.pipe(select(fromOverview.selectMustChooseHisCooperative));

    this.store.pipe(select(fromOverview.selectUserRole, untilDestroyed(this))).subscribe(userRole => {
      this.canSeeImportDatabase = !(userRole === UserRole.farmer || userRole === UserRole.technician);
      this.canSeeNotifications = userRole === UserRole.farmer;
    });

    this.store
      .pipe(
        select(fromRoot.selectRoute),
        filter(routeData => routeData != null),
        map(routeData => routeData.state.url),
        untilDestroyed(this)
      )
      .subscribe(url => {
        this.isImportDatabaseOpened = fromRoutes.isImportDatabaseUrl(url);
        this.isNotificationsOpened = fromRoutes.isNotificationsUrl(url);
        this.isHelpOpened = fromRoutes.isHelpUrl(url);
        this.isCompareOpened = fromRoutes.isCompareUrl(url) || fromRoutes.isCompareParcelsUrl(url);
        this.isMapOpened = isMapOpenedFromRoutes(url);
        this.isDivisionOpen = fromRoutes.isDivisionUrl(url);
      });
  }

  ngOnDestroy() {}

  onExpansionChange(openedChange: boolean) {
    this.store.dispatch(OpeningToolbarStatus({ openedChange }));
  }
}

function isMapOpenedFromRoutes(url: string) {
  return (
    !fromRoutes.isCompareParcelsUrl(url) &&
    (fromRoutes.isParcelsUrl(url) ||
      fromRoutes.isParcelsDetailsUrl(url) ||
      fromRoutes.isTabFarmsUrl(url) ||
      fromRoutes.isTabFarmsWithIdUrl(url) ||
      fromRoutes.isFarmsUrl(url) ||
      fromRoutes.isFarmsParcelsUrl(url) ||
      fromRoutes.isFarmsParcelsDetailsUrl(url))
  );
}
