import { createAction, props } from '@ngrx/store';
import { ParcelDetailDataParams, Parcel, ParcelRecommendation } from '@app/overview/shared/farm-star/parcel.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import DoseCalculationMethods = farmStarApiModel.DoseCalculationMethods;

export const TriggerFindParcelInfo = createAction('[TabParcels] Trigger find parcel info', props<{ parcel: Parcel }>());
export const FindParcelDetailData = createAction(
  '[TabParcels] Find extra agro data',
  props<{ parcelDetailDataParams: ParcelDetailDataParams }>()
);
export const FindParcelDetailDataSuccess = createAction(
  '[TabParcels] Find extra agro data success',
  props<{
    parcelId: string;
    cropLabel: string;
    soilType: string;
    kindType: string;
    varietyType: string;
    previousCrop: string;
    previousCropWasteUsage: string;
    nDoseComputationMethods: DoseCalculationMethods;
  }>()
);

export const FindBiomassParcelRecommendationValue = createAction(
  '[ParcelRecommendation] find parcel recommendation',
  props<{
    parcelId: string;
  }>()
);

export const FindBiomassParcelRecommendationValueSuccess = createAction(
  '[ParcelRecommendation] find parcel recommendation success',
  props<{
    parcelId: string;
    earlyWinterParcelRecommendation: ParcelRecommendation;
    lateWinterParcelRecommendation: ParcelRecommendation;
  }>()
);

export const FindParcelDetail = createAction('[ParcelDetail] Find parcel detail', props<{ parcelId: string }>());
