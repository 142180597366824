import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportDatabaseComponent } from '@app/overview/import-database/import-database.component';
import { ImportDatabaseListModule } from '@app/overview/import-database/core/import-database-list/import-database-list.module';
import { StoreModule } from '@ngrx/store';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ImportDatabaseEffects } from '@app/overview/import-database/import-database.effects';
import { ImportDatabaseDetailsModule } from '@app/overview/import-database/core/import-database-details/import-database-details.module';

@NgModule({
  declarations: [ImportDatabaseComponent],
  imports: [
    CommonModule,
    ImportDatabaseListModule,
    ImportDatabaseDetailsModule,
    StoreModule.forFeature('import-database', fromImportDatabase.reducers),
    EffectsModule.forFeature([ImportDatabaseEffects]),
  ],
})
export class ImportDatabaseModule {}
