<div class="popup">
  <div class="popup-header">
    <div class="popup-header-title">Besoin d'aide ?</div>
    <button mat-icon-button (click)="hideHelp()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-sub-title">Comprendre l’indice foliaire (LAI) en maïs</div>
  <div class="popup-description">
    <span
      >Nous vous proposons de suivre, dans le temps, l’état de croissance de votre culture de maïs sous forme de carte
      (variabilité intra-parcellaire au moment de la prise d’image par le satellite) et de courbe (évolution des valeurs
      médianes).</span
    >
    <span class="popup-description-see-more" (click)="showLargePopup()">Voir la vidéo</span>
  </div>
  <div class="popup-bottom">
    <mat-checkbox color="accent" [checked]="neverSeeAgain" (change)="neverSeeAgain = !neverSeeAgain"
      >Ne plus afficher</mat-checkbox
    >
  </div>
</div>
