import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarIconConfiguration } from '@app/overview/shared/snackbar-icon/snackbar-icon';

@Component({
  selector: 'fstar-snackbar-icon',
  templateUrl: './snackbar-icon.component.html',
  styleUrls: ['./snackbar-icon.component.scss'],
})
export class SnackbarIconComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarIconConfiguration,
    public snackBarRef: MatSnackBarRef<SnackbarIconComponent>
  ) {}

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}
