import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  SpreadingEquipment,
  SpreadingEquipmentsEditDialogData,
} from '../../../../shared/farm-star/spreading-equipment.model';
import { changeMainSpreadingEquipment, deleteSpreadingEquipment, FindTerminals } from './spreading-equipments.action';
import { fromNavBar } from '../../navbar.reducer';
import { MatDialog, MatDialogRef } from '@angular/material';

import { faStar as faStarO } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faPen, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Terminal } from '@app/overview/shared/farm-star/vra-map.model';
import {
  SpreadingEquipmentsEditComponent,
  SPREADING_EQUIPMENTS_EDIT_DIALOG_WIDTH,
} from './spreading-equipments-edit/spreading-equipments-edit.component';

export const SPREADING_EQUIPMENTS_DIALOG_WIDTH = '800px';

@Component({
  selector: 'fstar-spreading-equipments',
  templateUrl: './spreading-equipments.component.html',
  styleUrls: ['./spreading-equipments.component.scss'],
})
export class SpreadingEquipmentsComponent implements OnInit {
  public equipments: SpreadingEquipment[];
  public testLength: number;
  public terminals: Terminal[];
  mainEquipmentIcon = faStar;
  equipmentIcon = faStarO;
  downloadIcon = faDownload;
  editIcon = faPen;
  deleteIcon = faTrash;

  constructor(
    private store: Store<fromNavBar.State>,
    private dialogRef: MatDialogRef<SpreadingEquipmentsComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store.dispatch(FindTerminals());
    this.store.select(fromNavBar.selectTerminals).subscribe(terminals => (this.terminals = terminals));
    this.store.select(fromNavBar.selectUserSpreadingEquipment).subscribe(spreadingEquipments => {
      if (spreadingEquipments) {
        this.equipments = spreadingEquipments.map(spreadingEquipment => {
          const equipment: SpreadingEquipment = {
            id: spreadingEquipment.id,
            name: spreadingEquipment.name,
            formapTerminalId: spreadingEquipment.formapTerminalId,
            format: spreadingEquipment.format,
            mainEquipment: spreadingEquipment.mainEquipment,
          };
          if (spreadingEquipment.formapTerminalId) {
            const equipmentTerminal = this.terminals.filter(
              terminal => terminal.id === spreadingEquipment.formapTerminalId
            )[0];
            equipment.constuructorLabel = equipmentTerminal.brand;
            equipment.consoleLabel = equipmentTerminal.name;
            equipment.fortmatLabel = equipmentTerminal.format;
          } else {
            equipment.fortmatLabel = spreadingEquipment.format;
          }
          return equipment;
        });
      } else {
        this.equipments = spreadingEquipments;
      }
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  download(equipment: SpreadingEquipment) {
    // TODO
    console.log('TODO download equipment', equipment);
  }

  addEquipment() {
    this.openEditDialog({ terminals: this.terminals, create: true, equipment: null });
  }

  edit(equipment: SpreadingEquipment) {
    this.openEditDialog({ terminals: this.terminals, create: false, equipment });
  }

  openEditDialog(data: SpreadingEquipmentsEditDialogData) {
    this.dialog.open(SpreadingEquipmentsEditComponent, {
      width: SPREADING_EQUIPMENTS_EDIT_DIALOG_WIDTH,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
      panelClass: 'spreading-equipments-edit-dialog',
      data,
    });
  }

  delete(equipment: SpreadingEquipment) {
    this.store.dispatch(deleteSpreadingEquipment({ equipmentId: equipment.id }));
  }

  changeMain(equipment: SpreadingEquipment) {
    this.store.dispatch(changeMainSpreadingEquipment({ equipmentId: equipment.id }));
  }
}
