import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { CropChooserComponent } from '@app/overview/shared/crop-chooser/crop-chooser.component';
import { CropChooserDialogComponent } from '@app/overview/shared/crop-chooser/crop-chooser-dialog/crop-chooser-dialog.component';

@NgModule({
  declarations: [CropChooserComponent, CropChooserDialogComponent],
  imports: [CommonModule, FormsModule, MatIconModule, MatDialogModule, MatRadioModule, MatButtonModule],
  exports: [CropChooserComponent],
  entryComponents: [CropChooserDialogComponent],
})
export class CropChooserModule {}
