import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { Action, createReducer, on } from '@ngrx/store';

export interface State extends EntityState<farmStarApiModel.StageEstimate> {
  isLoadingStageEstimate: boolean;
}
export const adapter: EntityAdapter<farmStarApiModel.StageEstimate> = createEntityAdapter<
  farmStarApiModel.StageEstimate
>({
  selectId: stageEstimates => stageEstimates.phenologicalStageRefId.toString(),
});

const initialState: State = adapter.getInitialState({
  isLoadingStageEstimate: false,
});

const reducer = createReducer(
  initialState,
  on(parcelDetailsActions.FindStageEstimate, state => ({
    ...state,
    isLoadingStageEstimate: true,
  })),
  on(parcelDetailsActions.FindStageEstimateSuccess, (state, { stageEstimates }) => ({
    ...adapter.addAll(stageEstimates, state),
    isLoadingStageEstimate: false,
  })),
  on(parcelDetailsActions.FindStageEstimateError, state => ({
    ...adapter.addAll([], state),
    isLoadingStageEstimate: false,
  }))
);

export function stagesEstimateEntityReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
