import { createAction, props } from '@ngrx/store';
import { CooperativeStore, UserRole } from '@app/overview/overview.models';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { RegisteredUserWithRefs } from '@app/overview/shared/farm-star/farmer.model';
import { HideParcelDetailsHelpStatus } from '@app/shared/local-storage';
import { CoreModel } from './shared/farm-star/core.model';
import { Crop } from './shared/farm-star/agro-datum.model';

export const OpeningToolbarStatus = createAction(
  '[Toolbar] opening toolbar status',
  props<{ openedChange: boolean }>()
);
export const InviteUserToChooseHisCooperative = createAction('[Cooperative] Invite user to choose his cooperative');
export const EnableAppWhenUserHasChosenHisCooperative = createAction(
  '[Cooperative] Enable app when user has chosen his cooperative'
);
export const FindUserIdpIdentifierSuccess = createAction(
  '[User] Find user idpIdentifier success',
  props<{ idpIdentifier: string }>()
);
export const FindUserRoleSuccess = createAction('[User] Find user role success', props<{ userRole: UserRole }>());
export const FindUserRoleLabelSuccess = createAction(
  '[User] Find user role label success',
  props<{ userRoleLabel: string }>()
);
export const FindUserDataSuccess = createAction(
  '[User] Find user data success',
  props<{ userData: farmStarApiModel.AllUserTypeData }>()
);
export const FilterFarm = createAction('[Farms] Filter farm', props<{ filter: string }>());
export const FindCampaigns = createAction('[User] Find campaigns', props<{ campaignIds?: string[] }>());
export const UpdateCampaignDataPublicationStatus = createAction(
  '[User] Update campaign data publication status',
  props<{ campaigns: CoreModel.CampaignBackend[] }>()
);
export const ClearCampaignDataPublicationStatus = createAction('[User] Clear campaign data publication status');
export const LoadAllCooperativesForOtherUsers = createAction('[User] Load all cooperatives for other users');
export const FindCooperativesSuccess = createAction(
  '[Cooperatives] Find cooperatives success',
  props<{ cooperatives: CooperativeStore[] }>()
);
export const FindCooperative = createAction('[Cooperatives] Find cooperative', props<{ url: string }>());
export const FindCooperativeCampaigns = createAction(
  '[Cooperative] Find cooperative campaigns',
  props<{ cooperativeId: string }>()
);

export const SelectCooperativeFromLocalStorage = createAction(
  '[Cooperatives] Select cooperative from local storage',
  props<{ cooperative: CooperativeStore }>()
);
export const UserMustChooseHisCooperative = createAction('[Cooperatives] User must choose his cooperative success');
export const FindPhenologicalStagesSuccess = createAction(
  '[Phenological stages] Find Phenological Stages Success',
  props<{ phenologicalStages: farmStarApiModel.PhenologicalStageBackend[] }>()
);
export const FindPhenologicalStagesError = createAction('[Phenological stages] Find Phenological Stages Error');
export const SelectCooperative = createAction(
  '[Cooperatives] Select cooperative',
  props<{ cooperative: CooperativeStore }>()
);
export const FindFarmInfoSuccess = createAction(
  '[Farms] Find farms info success',
  props<{ farms: CoreModel.Farm[] }>()
);
export const FindFarmInfoError = createAction('[Farms] Find farms info error');
// FIXME: n'a pas l'air utilisé en dehors des fichiers .spec => à supprimer ?
export const FindFarmsWithLoaderSuccess = createAction(
  '[Farms] Find farms with loader success',
  props<{ farms: CoreModel.FarmBackend[] }>()
);

export const PrepareFarmSelection = createAction('[Farms Fields] Prepare farm selection', props<{ farmId: string }>());
export const ToggleFarmsFeatures = createAction(
  '[Farms] Toggle Farms Features',
  props<{ showFarmsFeatures: boolean }>()
);
export const HoverFarm = createAction('[MapFeature] Hover farm', props<{ farmId: string }>());
export const ShowParcelDetailsHelp = createAction('[Overview] Show parcel details help');
export const HideParcelDetailsHelp = createAction(
  '[Overview] Hide parcel details help',
  props<{ status: HideParcelDetailsHelpStatus }>()
);
export const ClearLocalStorageHelp = createAction('[Overview] Clear local storage help');
export const BlurFarmFilter = createAction('[Overview] Blur farm filter');
export const BlurParcelFilter = createAction('[Overview] Blur parcel filter');
export const ChangeCurrentFarm = createAction('[Farms] Change current farm', props<{ farmId?: string }>());
export const TriggerFindFarmsByCooperative = createAction('[Farms] Trigger find farms by cooperative');
export const SaveRegisteredUsers = createAction(
  '[Overview] Save registered users',
  props<{ registeredUsers?: RegisteredUserWithRefs[] }>()
);
export const FindFarmsFromRegisteredUsers = createAction(
  '[Farms] Find farms from registered users',
  props<{ registeredUsers?: RegisteredUserWithRefs[] }>()
);
export const FindFarmsSuccess = createAction('[Farms] Find farms success', props<{ farms?: CoreModel.Farm[] }>());
export const FindFarmsError = createAction('[Farms] Find farms error');
export const FindCropsSuccess = createAction('[Crops] Find crops success', props<{ crops: Crop[] }>());
export const FindCropsError = createAction('[Crops] Find crops error');
