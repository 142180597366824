import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanionComponent } from '@davinkevin/companion-component';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { Parcel } from '@app/overview/shared/farm-star/parcel.model';
import { Observable } from 'rxjs';
import { FilteredParcel } from '@app/overview/shared/parcels-map.models';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { aprilIndex, novemberIndex, getAvailableYears, getMonths } from '@app/shared/utils/date-utils';
import {
  PhenologicalStageByEstimateDate,
  TimelineRainAndIrrigation,
} from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import * as fromParcelDetails from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.reducer';
import { fadeInOutWithTransition } from '@app/shared/animations/animations';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import { FilteredFarm } from '@app/overview/overview.models';
import * as fromOverview from '@app/overview/overview.reducer';
import { PopupButtonPosition } from '@fret-ngx/button-popup';
import { SeriesLineDataOptions } from 'highcharts';
import selectSelectedParcel = fromParcelsViewer.selectSelectedParcel;
import selectStageEstimateDateBoundWithPhenologicalCode = fromParcelDetails.selectStageEstimateDateBoundWithPhenologicalCode;
import { FilterFarm, PrepareFarmSelection } from '@app/overview/overview.actions';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';

@Component({
  selector: 'fstar-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrls: ['./parcel-details.component.scss'],
  animations: [fadeInOutWithTransition('0s', '0.3s')],
})
export class ParcelDetailsComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();
  public parcel$: Observable<Parcel>;
  public isAllParcelInfoLoading$: Observable<boolean>;
  public selectedParcel$: Observable<FilteredParcel>;
  public stage$: Observable<farmStarApiModel.StageEstimate[]>;
  public filteredFarms$: Observable<FilteredFarm[]>;
  public selectedFarm$: Observable<FilteredFarm>;
  popupPosition: PopupButtonPosition = {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
  };
  public filteredParcels$: Observable<FilteredParcel[]>;
  filterGroup: FormGroup;

  years = getAvailableYears();
  availableMonths: string[];
  filteredEndMonths: string[];
  selectedYear: number;
  showStage = true;
  selectedBeginMonth: number;
  selectedEndMonth: number;
  phenologicalStageByEstimateDate$: Observable<PhenologicalStageByEstimateDate[]>;
  biophySeriesLineDataOptions$: Observable<Array<SeriesLineDataOptions>>;
  dailyStatusMultiLineDataOptions$: Observable<Array<SeriesLineDataOptions>[]>;
  openingToolbarStatus$: Observable<boolean>;
  showParcelDetailsHelp$: Observable<boolean>;
  isPanelHydroOpened$: Observable<boolean>;
  isPanelDoseOpen$: Observable<boolean>;
  timelineRainAndIrrigation$: Observable<TimelineRainAndIrrigation[]>;
  beginAndEndDatesOfTimelineRainAndIrrigation$: Observable<{ beginDate: string; endDate: string }>;

  constructor(
    private store: Store<
      fromParcelsViewer.State | fromParcelDetails.ParcelDetailsGlobalState | fromOverview.OverviewGlobalState
    >,
    private fb: FormBuilder
  ) {}

  ngOnDestroy() {
    this.companion.destroy();
  }

  ngOnInit(): void {
    const untilDestroy = this.companion.untilDestroy();

    this.showParcelDetailsHelp$ = this.store.pipe(select(fromOverview.selectShowParcelDetailsHelp));
    this.openingToolbarStatus$ = this.store.pipe(select(fromOverview.selectCurrentOpeningToolbarStatus));
    this.filteredFarms$ = this.store.pipe(select(fromOverview.selectFilteredFarms));
    this.selectedFarm$ = this.store.pipe(select(fromOverview.selectSelectedFilteredFarm));
    this.selectedParcel$ = this.store.pipe(select(fromParcelsViewer.selectSelectedParcelFiltered));
    this.isPanelHydroOpened$ = this.store.pipe(select(fromParcelDetails.selectIsPanelHydroOpened));
    this.isPanelDoseOpen$ = this.store.pipe(untilDestroy(), select(fromParcelDetails.selectIsPanelDoseOpened));
    this.timelineRainAndIrrigation$ = this.store.pipe(select(fromParcelDetails.selectTimelineRainAndIrrigation));
    this.beginAndEndDatesOfTimelineRainAndIrrigation$ = this.store.pipe(
      select(fromParcelDetails.selectBeginAndEndDatesOfTimelineRainAndIrrigation)
    );

    this.parcel$ = this.store.pipe(
      untilDestroy(),
      select(selectSelectedParcel),
      filter(field => field != null),
      map(field => field)
    );

    this.isAllParcelInfoLoading$ = this.store.pipe(select(fromParcelDetails.selectAllParcelInfoLoading));

    this.stage$ = this.store.pipe(select(fromParcelDetails.selectAllStagesEstimate));

    this.filteredParcels$ = this.store.pipe(untilDestroy(), select(fromParcelsViewer.selectFilteredFields));

    this.selectedBeginMonth = aprilIndex;
    this.selectedEndMonth = novemberIndex;
    const currentYear = new Date().getFullYear();
    this.selectedYear = currentYear;

    this.availableMonths = getMonths();
    this.filteredEndMonths = this.initFilteredEndMonthsList(aprilIndex);

    this.filterGroup = this.fb.group({
      year: [currentYear],
      beginMonth: [this.availableMonths[aprilIndex]],
      endMonth: [this.availableMonths[novemberIndex]],
      showStage: [true],
    });

    this.filterGroup.valueChanges.pipe(untilDestroy()).subscribe(() => {
      this.showStage = this.filterGroup.get('showStage').value;
      this.filteredEndMonths = this.generateEndMonthsList();
      const year = this.filterGroup.get('year').value;
      const beginMonthIndex = this.getIndexMonth('beginMonth');
      const endMonthIndex = this.getIndexMonth('endMonth');
      this.selectedYear = year;
      this.selectedBeginMonth = beginMonthIndex;
      this.selectedEndMonth = endMonthIndex;
      this.store.dispatch(parcelDetailsActions.SaveFilterDate({ year, beginMonthIndex, endMonthIndex }));
    });

    this.phenologicalStageByEstimateDate$ = this.store.pipe(
      untilDestroy(),
      select(selectStageEstimateDateBoundWithPhenologicalCode)
    );

    this.biophySeriesLineDataOptions$ = this.store.pipe(select(fromParcelDetails.selectAllBiophySeriesLineDataOptions));
    this.dailyStatusMultiLineDataOptions$ = this.store.pipe(
      select(fromParcelDetails.selectAllMultiSeriesLineDataOptions)
    );
  }

  filterTermChanged(term: string) {
    this.store.dispatch(new ParcelsViewerActions.FilterFeatures(term));
  }

  parcelToSelect(parcelId: string) {
    this.store.dispatch(new ParcelsViewerActions.SelectFeature(parcelId));
  }

  filterFarmTermChanged(term: string) {
    this.store.dispatch(FilterFarm({ filter: term }));
  }

  farmToSelect(farmId: string) {
    this.store.dispatch(PrepareFarmSelection({ farmId }));
  }

  initFilteredEndMonthsList(beginIndex: number): string[] {
    return this.availableMonths.slice(beginIndex, this.availableMonths.length);
  }

  generateEndMonthsList(): string[] {
    const beginMonthIndex = this.getIndexMonth('beginMonth');
    const endMonthIndex = this.getIndexMonth('endMonth');
    if (beginMonthIndex > endMonthIndex) {
      this.filterGroup.get('endMonth').setValue(this.availableMonths[beginMonthIndex]);
    }
    return this.availableMonths.slice(beginMonthIndex, this.availableMonths.length);
  }

  getIndexMonth(monthLabel: string): number {
    const monthValue = this.filterGroup.get(monthLabel).value;
    return this.availableMonths.indexOf(monthValue);
  }
}
