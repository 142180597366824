import { NgModule } from '@angular/core';
import { SplashScreenComponent } from '@app/core/splash-screen/splash-screen.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material';

@NgModule({
  declarations: [SplashScreenComponent],
  imports: [CommonModule, MatButtonModule],
  providers: [],
  exports: [SplashScreenComponent],
})
export class SplashScreenModule {}
