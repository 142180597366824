import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  SpreadingEquipment,
  SpreadingEquipmentsEditDialogData,
} from '@app/overview/shared/farm-star/spreading-equipment.model';
import { Store } from '@ngrx/store';
import { fromNavBar } from '../../../navbar.reducer';
import { createSpreadingEquipment, patchSpreadingEquipment } from '../spreading-equipments.action';

export const SPREADING_EQUIPMENTS_EDIT_DIALOG_WIDTH = '600px';
@Component({
  selector: 'fstar-spreading-equipments-edit',
  templateUrl: './spreading-equipments-edit.component.html',
  styleUrls: ['./spreading-equipments-edit.component.scss'],
})
export class SpreadingEquipmentsEditComponent implements OnInit {
  equipment: SpreadingEquipment;
  selectedFormat = new FormControl('', Validators.required);
  selectedConsole = new FormControl('');
  selectedBrand = new FormControl('');
  name = new FormControl('');

  brands: string[];
  consoles: string[];
  formats: string[];
  addOrEditLabel: string;
  equipmentForm: FormGroup;

  constructor(
    private store: Store<fromNavBar.State>,
    private dialogRef: MatDialogRef<SpreadingEquipmentsEditComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    private data: SpreadingEquipmentsEditDialogData
  ) {}

  ngOnInit() {
    this.equipmentForm = this.fb.group({
      name: this.name,
      selectedBrand: this.selectedBrand,
      selectedConsole: this.selectedConsole,
      selectedFormat: this.selectedFormat,
    });
    this.brands = [...new Set(this.data.terminals.map(terminal => terminal.brand))].sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    this.formats = [...new Set(this.data.terminals.map(terminal => terminal.format))].sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    if (this.data.create) {
      this.addOrEditLabel = 'Ajout';
      this.equipment = {
        id: undefined,
        name: undefined,
        mainEquipment: false,
        formapTerminalId: undefined,
        format: undefined,
      };
    } else {
      this.addOrEditLabel = 'Modification';
      this.equipment = {
        id: this.data.equipment.id,
        name: this.data.equipment.name,
        formapTerminalId: this.data.equipment.formapTerminalId,
        format: this.data.equipment.format,
        mainEquipment: this.data.equipment.mainEquipment,
      };
      this.name.setValue(this.data.equipment.name);
      if (!this.equipment.formapTerminalId) {
        this.selectedFormat.setValue(this.equipment.format);
      } else {
        const equipmentTerminal = this.data.terminals.filter(
          terminal => terminal.id === this.equipment.formapTerminalId
        )[0];
        this.selectedFormat.setValue(equipmentTerminal.format);
        this.selectedConsole.setValue(equipmentTerminal.name);
        this.selectedBrand.setValue(equipmentTerminal.brand);
      }
    }
    if (!this.equipment.formapTerminalId) {
      this.NoFilterConsoleOptions();
    } else {
      this.filterConsoleOptions();
    }
  }

  onBrandSelected() {
    this.selectedConsole.setValue('');
    this.selectedFormat.setValue('');
    this.filterConsoleOptions();
    if (this.consoles.length === 1) {
      this.selectedConsole.setValue(this.consoles[0]);
      this.onConsoleSelected();
    }
  }

  onConsoleSelected() {
    const terminals = this.data.terminals.filter(
      terminal => terminal.brand === this.selectedBrand.value && terminal.name === this.selectedConsole.value
    );
    if (terminals.length === 1) {
      this.selectedFormat.setValue(terminals[0].format);
    }
  }

  onFormatSelected() {
    // reset brand and console if format not compatible
    if (
      this.selectedBrand.value === '' ||
      this.selectedConsole.value === '' ||
      this.data.terminals.filter(
        terminal =>
          terminal.brand === this.selectedBrand.value &&
          terminal.name === this.selectedConsole.value &&
          terminal.format === this.selectedFormat.value
      ).length === 0
    ) {
      this.selectedBrand.setValue('');
      this.selectedConsole.setValue('');
      this.NoFilterConsoleOptions();
    }
  }

  onSave() {
    const selectedTerminals = this.data.terminals.filter(
      terminal =>
        terminal.brand === this.selectedBrand.value &&
        terminal.name === this.selectedConsole.value &&
        terminal.format === this.selectedFormat.value
    );
    if (selectedTerminals.length === 0) {
      this.equipment.format = this.selectedFormat.value;
      this.equipment.formapTerminalId = null;
    } else {
      this.equipment.format = null;
      this.equipment.formapTerminalId = selectedTerminals[0].id;
    }
    if (this.name.value === '') {
      this.equipment.name = 'Console';
    } else {
      this.equipment.name = this.name.value;
    }

    if (this.data.create) {
      this.store.dispatch(createSpreadingEquipment({ spreadingEquipment: this.equipment }));
      this.onClose();
    } else {
      this.store.dispatch(patchSpreadingEquipment({ spreadingEquipment: this.equipment }));
      this.onClose();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  filterConsoleOptions() {
    this.consoles = [
      ...new Set(
        this.data.terminals
          .filter(terminal => terminal.brand === this.selectedBrand.value)
          .map(terminal => terminal.name)
      ),
    ].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  NoFilterConsoleOptions() {
    this.consoles = [...new Set(this.data.terminals.map(terminal => terminal.name))].sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
  }
}
