import { ActionsSubject, select, Store } from '@ngrx/store';
import { CompanionComponent } from '@davinkevin/companion-component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IMPORT_DIALOG_WIDTH,
  ImportDatabaseDialogComponent,
} from './core/import-database-dialog/import-database-dialog.component';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import { Observable } from 'rxjs';
import { CustomerDataCampaign } from '@app/overview/import-database/import-database.model';
import { ofType } from '@ngrx/effects';
import {
  SEND_DATAFILE_SUCCESS_DIALOG_WIDTH,
  SendDatafileSuccessDialogComponent,
} from '@app/overview/import-database/core/import-database-list/core/send-datafile-success-dialog/send-datafile-success-dialog.component';
import selectSelectedCustomerDatafile = fromImportDatabase.selectSelectedCustomerDatafile;
import selectIsLoading = fromImportDatabase.selectIsLoading;

@Component({
  selector: 'fstar-import-database-list',
  templateUrl: './import-database-list.component.html',
  styleUrls: ['./import-database-list.component.scss'],
})
export class ImportDatabaseListComponent implements OnDestroy, OnInit {
  public customerDataCampaigns$: Observable<CustomerDataCampaign[]>;
  public selectedRow$: Observable<CustomerDataCampaign>;
  public isLoading$: Observable<boolean>;
  private companion = new CompanionComponent();

  constructor(
    private store: Store<fromImportDatabase.GlobalState>,
    public dialog: MatDialog,
    private action: ActionsSubject
  ) {}

  ngOnInit(): void {
    const untilDestroy = this.companion.untilDestroy();

    this.customerDataCampaigns$ = this.store.pipe(
      untilDestroy(),
      select(fromImportDatabase.selectCustomerDataCampaigns)
    );
    this.selectedRow$ = this.store.pipe(untilDestroy(), select(selectSelectedCustomerDatafile));
    this.isLoading$ = this.store.pipe(untilDestroy(), select(selectIsLoading));

    this.action.pipe(untilDestroy(), ofType(ImportDatabaseActions.OpenImportDatabaseDialog)).subscribe(() => {
      this.openImportDialog();
    });

    this.action.pipe(untilDestroy(), ofType(ImportDatabaseActions.SendDatafileSuccess)).subscribe(() => {
      this.dialog.open(SendDatafileSuccessDialogComponent, {
        width: SEND_DATAFILE_SUCCESS_DIALOG_WIDTH,
        backdropClass: 'custom-opacity-backdrop',
      });
    });
  }

  openImportDialog() {
    this.dialog.open(ImportDatabaseDialogComponent, {
      width: IMPORT_DIALOG_WIDTH,
      backdropClass: 'custom-opacity-backdrop',
      autoFocus: false,
    });
  }

  public setClickedRow(data: CustomerDataCampaign) {
    this.store.dispatch(ImportDatabaseActions.SelectDatafile({ customerDataCampaignId: data.id }));
  }

  ngOnDestroy(): void {
    this.companion.destroy();
  }
}
