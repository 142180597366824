import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'fstar-lai-help',
  templateUrl: './lai-help.component.html',
  styleUrls: ['./lai-help.component.scss'],
})
export class LaiHelpComponent {
  showDefinition = false;
  laiVideo = environment.staticAssets.laiVideo;
}
