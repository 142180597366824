import * as Highcharts from 'highcharts';
import { SeriesLineDataOptions } from 'highcharts';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { PhenologicalStageByEstimateDate } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { firstDayOfMonthBeginDate, lastDayOfMonthEndDate } from '@app/shared/utils/date-utils';
import { Store } from '@ngrx/store';
import * as fromParcelDetails from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.reducer';
import {
  createPlotLines,
  formatDateForChart,
  resetMouseEventWhenChartRendered,
} from '@app/overview/parcels-viewer/core/parcel-details/utils';
import * as parcelDetailsActions from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.actions';

registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'fstar-parcel-chart',
  templateUrl: './parcel-chart.component.html',
  styleUrls: ['./parcel-chart.component.scss'],
})
export class ParcelChartComponent implements OnInit, OnChanges {
  @Input() year: number;
  @Input() beginMonth: number;
  @Input() endMonth: number;
  @Input() showStage: boolean;
  @Input() phenologicalStageByEstimateDate: PhenologicalStageByEstimateDate[];
  @Input() biophySeriesLineDataOptions: Array<SeriesLineDataOptions>;

  constructor(private store: Store<fromParcelDetails.ParcelDetailsGlobalState>) {}

  Highcharts = Highcharts;
  chartInstance: Highcharts.Chart = null;
  chartOptions: Highcharts.Options = null;

  ngOnInit() {
    this.initChartOptions();
  }

  ngOnChanges() {
    this.initChartOptions();
  }

  onChartInit(chartInstance: Highcharts.Chart) {
    this.chartInstance = chartInstance;
    this.chartInstance.redraw();
  }

  private initChartOptions() {
    const beginDay = firstDayOfMonthBeginDate(this.year, this.beginMonth);
    const endDay = lastDayOfMonthEndDate(this.year, this.endMonth);
    const range = endDay - beginDay;
    const biophySeriesOptions = this.getBiophyData(beginDay, endDay);
    this.chartOptions = this.mapToChartOptions(beginDay, endDay, range, biophySeriesOptions);
  }

  getBiophyData(beginDate: number, endDate: number): Array<SeriesLineDataOptions> {
    return this.biophySeriesLineDataOptions
      .filter(biophy => biophy.x >= beginDate && biophy.x <= endDate)
      .map(biophy => ({
        ...biophy,
        events: {
          select: () => {
            this.store.dispatch(parcelDetailsActions.SelectObservation({ observationId: biophy.id }));
          },
        },
      }));
  }

  mapToChartOptions(
    beginDate: number,
    endDay: number,
    range: number,
    biophySeriesOptions: Array<SeriesLineDataOptions>
  ): Highcharts.Options {
    return {
      chart: {
        type: 'spline',
        marginTop: 80,
        ignoreHiddenSeries: false,
        events: {
          render: () => {
            resetMouseEventWhenChartRendered(this.chartInstance, this.phenologicalStageByEstimateDate);
          },
        },
      },
      title: {
        text: 'Indice foliaire médian et prévision de stades',
        style: {
          color: '#052338',
          fontSize: '13px',
          fontFamily: 'Lato, Roboto, Arial, sans-serif',
          fontWeight: '300',
          letterSpacing: '0.3px',
        },
      },
      xAxis: {
        alternateGridColor: 'rgba(204, 204, 204, 0.1)',
        startOnTick: true,
        endOnTick: true,
        type: 'datetime',
        gridLineWidth: 0,
        showFirstLabel: true,
        showLastLabel: true,
        min: beginDate,
        max: endDay,
        minRange: range,
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          formatter() {
            return convertTimeToMonth(this.value);
          },
          style: {
            fontFamily: 'Lato, Roboto, Arial, sans-serif',
            fontSize: '10px',
            fontWeight: '300',
            letterSpacing: '0.3px',
          },
        },
        plotLines: this.showStage
          ? createPlotLines(
              this.phenologicalStageByEstimateDate,
              firstDayOfMonthBeginDate(this.year, this.beginMonth),
              lastDayOfMonthEndDate(this.year, this.endMonth)
            )
          : null,
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: '',
        },
        min: 0,
        max: 7,
        labels: {
          style: {
            fontFamily: 'Lato, Roboto, Arial, sans-serif',
            fontSize: '10px',
            fontWeight: '300',
            letterSpacing: '0.3px',
          },
        },
        tickAmount: 8,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
          dataLabels: {
            allowOverlap: true,
            enabled: true,
            formatter() {
              const date = formatDateForChart(this.point.x);
              const laiValue = this.point.y;
              return this.point.selected
                ? `<div style="
                  font: 400 14px Lato, Roboto, Arial, sans-serif;
                  letter-spacing: 0.3px;
                  color: #9EBA11">LAI médian : ${laiValue}</div><br><div style="
                  font: 400 14px Lato, Roboto, Arial, sans-serif;
                  letter-spacing: 0.3px;
                  color: #000000">Date : ${date}</div>`
                : '';
            },
            style: {
              pointerEvents: 'none',
            },
          },
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        hideDelay: 100,
        shadow: false,
        useHTML: true,
        style: {
          padding: '0',
        },
        // @ts-ignore
        positioner(labelWidth, labelHeight, point) {
          return {
            x: point.plotX - 80,
            y: 70,
          };
        },
        crosshairs: true,
        formatter() {
          const date = formatDateForChart(this.point.x);
          const laiValue = this.point.y;
          return `<div style="
            font: 400 14px Lato, Roboto, Arial, sans-serif;
            letter-spacing: 0.3px;
            color: #9EBA11">Indice foliaire médian: ${laiValue}</div><div style="
                  font: 400 14px Lato, Roboto, Arial, sans-serif;
                  letter-spacing: 0.3px;
                  color: #000000">Date d'acquisition: ${date}</div>`;
        },
      },
      series: [
        {
          type: 'line',
          allowPointSelect: true,
          cursor: 'pointer',
          color: '#000000',
          name: 'LAI',
          point: {
            events: {
              select: () => {
                this.initChartOptions();
              },
              unselect() {
                return false;
              },
            },
          },
          marker: {
            fillColor: '#000000',
            lineWidth: 2,
            lineColor: '#FFFFFF',
            radius: 5,
            states: {
              select: {
                fillColor: '#9EBA11',
                lineWidth: 0,
                radius: 7,
              },
              hover: {
                fillColor: '#9EBA11',
                lineWidth: 0,
                radius: 7,
              },
            },
          },
          findNearestPointBy: 'xy',
          data: biophySeriesOptions && biophySeriesOptions.length > 0 ? biophySeriesOptions : emptySeriesData(),
        },
      ],
    };
  }
}

function emptySeriesData() {
  return [
    {
      type: 'spline',
      name: 'Indice foliaire',
      data: [] as any[],
      visible: false,
    },
  ];
}

function convertTimeToMonth(timestamp: number) {
  const datePipe = new DatePipe('fr-FR');
  const titleCasePipe = new TitleCasePipe();
  return `01 ${titleCasePipe.transform(datePipe.transform(new Date(timestamp), 'MMMM'))}`;
}
