import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignStore } from '@app/overview/import-database/import-database.model';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@app/reducer';
import { fromImportDatabase } from '@app/overview/import-database/import-database.reducer';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanionComponent } from '@davinkevin/companion-component';
import { CooperativeStore } from '@app/overview/overview.models';
import * as fromOverview from '@app/overview/overview.reducer';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { filter, map } from 'rxjs/operators';

export const IMPORT_DIALOG_WIDTH = '650px';

export enum ErrorType {
  DATAFILES_EMPTY,
  NO_ACTIVE_CAMPAIGN,
  NO_COOPERATIVE,
  NOT_AUTHORIZED_FILE_FORMAT,
  NONE,
}

@Component({
  selector: 'fstar-import-database-dialog',
  templateUrl: './import-database-dialog.component.html',
  styleUrls: ['./import-database-dialog.component.scss'],
})
export class ImportDatabaseDialogComponent implements OnInit, OnDestroy {
  private companion = new CompanionComponent();
  public campaigns: CampaignStore[];
  public cooperatives: CooperativeStore[];
  public isDatafileListEmpty: boolean;
  public coopAndCampaignForm: FormGroup;
  public ErrorTypeEnum = ErrorType;
  public errorState = ErrorType.NONE;
  public hasNoActiveCampaign = false;
  public hasNoCooperative = false;
  public test = ErrorType.DATAFILES_EMPTY || ErrorType.NONE;
  public file = new FormControl('', [Validators.required]);
  public selectedCampaign = new FormControl('', [Validators.required]);
  public selectedCooperative = new FormControl('', [Validators.required]);
  public partialImport = new FormControl(false, [Validators.required]);

  constructor(
    private store: Store<fromRoot.State>,
    private dialogRef: MatDialogRef<ImportDatabaseDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.coopAndCampaignForm = this.fb.group({
      file: this.file,
      selectedCampaign: this.selectedCampaign,
      selectedCooperative: this.selectedCooperative,
      partialImport: this.partialImport,
    });

    this.file.valueChanges.pipe(untilDestroy()).subscribe(() => {
      this.calculateErrorState();
    });

    this.store
      .pipe(untilDestroy(), select(fromImportDatabase.selectIsDatafileListEmpty))
      .subscribe((isEmpty: boolean) => {
        this.isDatafileListEmpty = isEmpty;
        this.calculateErrorState();
      });

    this.store
      .pipe(
        untilDestroy(),
        select(fromImportDatabase.selectCampaignsActivated),
        map(campaigns => campaigns.filter(c => c.subscriptionAllowed))
      )
      .subscribe((campaignsStore: CampaignStore[]) => {
        this.campaigns = campaignsStore;
        this.hasNoActiveCampaign = !(this.campaigns.length > 0);
        if (this.campaigns.length === 0) {
          this.errorState = ErrorType.NO_ACTIVE_CAMPAIGN;
          this.selectedCampaign.disable();
        } else if (this.campaigns.length === 1) {
          this.selectedCampaign.setValue(this.campaigns[0]);
          this.selectedCampaign.disable();
        }
        this.calculateErrorState();
      });

    this.store
      .pipe(untilDestroy(), select(fromOverview.selectAllCooperatives))
      .subscribe((cooperativeStore: CooperativeStore[]) => {
        this.cooperatives = cooperativeStore;
        this.hasNoCooperative = !(this.cooperatives.length > 0);
        if (this.cooperatives.length === 0) {
          this.errorState = ErrorType.NO_COOPERATIVE;
          this.selectedCooperative.disable();
        } else if (this.cooperatives.length === 1) {
          this.selectedCooperative.setValue(this.cooperatives[0]);
          this.selectedCooperative.disable();
        }
        this.calculateErrorState();
      });

    this.store
      .pipe(
        untilDestroy(),
        select(fromOverview.selectSelectedCooperative),
        filter(cooperative => cooperative != null)
      )
      .subscribe((cooperativeStore: CooperativeStore) => {
        this.selectedCooperative.setValue(cooperativeStore);
      });
  }

  ngOnDestroy(): void {
    this.companion.destroy();
  }

  onImport(event: any) {
    this.file.setValue(event.target.files[0]);
    event.target.value = '';
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    const sendDataPayload = {
      file: this.file.value,
      campaignId: this.selectedCampaign.value.id,
      cooperativeId: this.selectedCooperative.value.id,
      partialImport: this.partialImport.value,
    };
    this.store.dispatch(ImportDatabaseActions.SendDatafile({ datafileRequest: sendDataPayload }));
    this.dialogRef.close();
  }

  calculateErrorState() {
    if (this.file.value && !fileHasCorrectFileExtension(this.file.value)) {
      this.errorState = ErrorType.NOT_AUTHORIZED_FILE_FORMAT;
    } else if (this.hasNoActiveCampaign) {
      this.errorState = ErrorType.NO_ACTIVE_CAMPAIGN;
    } else if (this.hasNoCooperative) {
      this.errorState = ErrorType.NO_COOPERATIVE;
    } else if (this.isDatafileListEmpty) {
      this.errorState = ErrorType.DATAFILES_EMPTY;
    } else {
      this.errorState = ErrorType.NONE;
    }
  }
}

function fileHasCorrectFileExtension(file: File): boolean {
  return (getFileExtension(file.name) || '').toUpperCase() === 'ZIP';
}

function getFileExtension(filename: string): string {
  const match = /\.([^.]+)$/.exec(filename);
  return match ? match[1] : null;
}
