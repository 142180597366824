import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { Parcel } from '@app/overview/shared/farm-star/parcel.model';
import { Dictionary } from '@ngrx/entity';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';
import { FilteredParcel } from '@app/overview/shared/parcels-map.models';
import { AdviceMode } from '@app/overview/parcels-viewer/core/switch-advice-mode/advice-mode';
import { fromParcelEntity } from '@app/overview/parcels-viewer/entities/parcel-entity';
import { featureToToolTip } from '@app/overview/shared/parcels-map-utils';

export namespace fromParcelsViewer {
  export interface ParcelsViewerGlobalState {
    parcels: fromParcelEntity.State;
    parcelsViewer: State;
  }

  export interface State {
    filter: string;
    cropFilter: string;
    isLoadingParcels: boolean;
    areParcelsFeaturesActivated: boolean;
    adviceMode: AdviceMode;
    isAdviceModeContainerOpened: boolean;
  }

  const initialState: State = {
    filter: null,
    cropFilter: null,
    isLoadingParcels: false,
    areParcelsFeaturesActivated: true,
    adviceMode: AdviceMode.none,
    isAdviceModeContainerOpened: true,
  };

  function reducer(state = initialState, action: ParcelsViewerActions.All): State {
    switch (action.type) {
      case ParcelsViewerActions.Types.ChangeAdviceMode:
        return {
          ...state,
          adviceMode: action.mode,
        };

      case ParcelsViewerActions.Types.ToggleAdviceModeContainer:
        return {
          ...state,
          isAdviceModeContainerOpened: !state.isAdviceModeContainerOpened,
        };

      case ParcelsViewerActions.Types.HideParcelsFeatures:
        return {
          ...state,
          areParcelsFeaturesActivated: false,
        };
      case ParcelsViewerActions.Types.ShowParcelsFeatures:
        return {
          ...state,
          areParcelsFeaturesActivated: true,
        };

      case ParcelsViewerActions.Types.FindFieldsWithLoader:
        return {
          ...state,
          isLoadingParcels: true,
          adviceMode: AdviceMode.none,
        };

      case ParcelsViewerActions.Types.FindFieldsWithLoaderSuccess:
        return {
          ...state,
          isLoadingParcels: false,
          filter: null,
        };
      case ParcelsViewerActions.Types.FindFieldsWithLoaderError:
        return {
          ...state,
          isLoadingParcels: false,
        };
      case ParcelsViewerActions.Types.FilterFeatures:
        return {
          ...state,
          filter: action.filter,
        };
      case ParcelsViewerActions.Types.FilterFeaturesByCrop:
        return {
          ...state,
          cropFilter: action.crop,
        };
      default: {
        return state;
      }
    }
  }

  export const reducers: ActionReducerMap<ParcelsViewerGlobalState> = {
    parcels: fromParcelEntity.reducer,
    parcelsViewer: reducer,
  };

  export const getParcelsViewerGlobalState = createFeatureSelector<ParcelsViewerGlobalState>('parcels-viewer');

  const getParcelEntitiesState = createSelector(getParcelsViewerGlobalState, state => state.parcels);

  const getParcelsViewerState = createSelector(getParcelsViewerGlobalState, state => state.parcelsViewer);

  export const {
    selectAll: selectAllFields,
    selectEntities: selectAllFieldsEntities,
  } = fromParcelEntity.ParcelsAdapter.getSelectors(getParcelEntitiesState);

  export const selectIsLoadingParcels = createSelector(getParcelsViewerState, state => state.isLoadingParcels);

  export const selectIsLoadingParcelsOrAdvices = createSelector(
    getParcelsViewerState,
    getParcelEntitiesState,
    (parcelsViewerState, parcelEntitiesState) =>
      parcelsViewerState.isLoadingParcels || parcelEntitiesState.isLoadingAdvices
  );

  export const selectAreStagesLoaded = createSelector(getParcelEntitiesState, state => state.areStagesLoaded);

  export const selectAreParcelsFeaturesActivated = createSelector(
    getParcelsViewerState,
    state => state.areParcelsFeaturesActivated
  );
  export const selectAdviceMode = createSelector(getParcelsViewerState, state => state.adviceMode);

  export const selectIsDoseMode = createSelector(
    selectAdviceMode,
    adviceMode => adviceMode && adviceMode === AdviceMode.dose
  );

  export const selectIsStageMode = createSelector(
    selectAdviceMode,
    adviceMode => adviceMode && adviceMode === AdviceMode.stage
  );

  export const selectIsLaiMode = createSelector(
    selectAdviceMode,
    adviceMode => adviceMode && adviceMode === AdviceMode.lai
  );

  export const selectIsHydroMode = createSelector(
    selectAdviceMode,
    adviceMode => adviceMode && adviceMode === AdviceMode.hydro
  );

  export const selectIsAdviceModeContainerOpened = createSelector(
    getParcelsViewerState,
    state => state.isAdviceModeContainerOpened
  );
  export const selectSelectedFeatureId = createSelector(getParcelEntitiesState, state => state.selectedId);
  export const selectSelectedParcel = createSelector(
    selectAllFieldsEntities,
    selectSelectedFeatureId,
    (fields: Dictionary<Parcel>, parcelId: string) => fields[parcelId]
  );

  export const selectSelectedParcelFiltered = createSelector(
    selectAllFieldsEntities,
    selectSelectedFeatureId,
    (fields: Dictionary<Parcel>, parcelId: string) =>
      fields[parcelId]
        ? {
            id: fields[parcelId].id,
            name: fields[parcelId].name,
            numSaisi: fields[parcelId].code,
            aoi: fields[parcelId].aoi.polygon,
          }
        : null
  );

  export const selectFilter = createSelector(getParcelsViewerState, state => state.filter);
  const getHoveredFeature = (state: fromParcelEntity.State) => featureToToolTip(state.entities[state.hoveredId]);
  export const selectHoveredFeature = createSelector(getParcelEntitiesState, getHoveredFeature);

  const getFilteredFields = (parcels: Parcel[], parcelFilter: string) => filter(parcels, parcelFilter);
  export const selectFilteredFields = createSelector(selectAllFields, selectFilter, getFilteredFields);

  export const selectCropFilter = createSelector(getParcelsViewerState, state => state.cropFilter);
}

function filter(features: Parcel[], term: string): FilteredParcel[] {
  return term && term.length >= 3
    ? features
        .filter(
          field =>
            field.name.toLowerCase().includes(term.toLowerCase()) ||
            field.code.toLowerCase().includes(term.toLowerCase())
        )
        .map(g => ({ id: g.id, name: g.name, numSaisi: g.code, aoi: g.aoi.polygon }))
    : [];
}
