import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { CropCode } from '../farm-star/agro-datum.model';
import { CropChooserDialogComponent } from './crop-chooser-dialog/crop-chooser-dialog.component';
import { ParcelWithAdvices } from '../farm-star/parcel.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';

@Component({
  selector: 'fstar-crop-chooser',
  templateUrl: './crop-chooser.component.html',
  styleUrls: ['./crop-chooser.component.scss'],
})
export class CropChooserComponent implements OnInit, OnDestroy {
  public currentFilterLabel = 'Toutes cultures';
  public countByCrop = new Map<string, number>();

  private currentFilter: string;

  constructor(public dialog: MatDialog, private store: Store<fromParcelsViewer.State>) {}

  ngOnInit() {
    this.store
      .pipe(untilDestroyed(this), select(fromParcelsViewer.selectAllFields))
      .subscribe((fields: ParcelWithAdvices[]) => {
        this.countByCrop.clear();

        fields.forEach(field => {
          const crop = field.agroData.crop.refData.crop.code;
          const count = (this.countByCrop.get(crop) || 0) + 1;
          this.countByCrop.set(crop, count);
        });
      });
  }

  openCropChooser() {
    const ref = this.dialog.open(CropChooserDialogComponent, {
      width: '300px',
      data: { countByCrop: this.countByCrop, currentFilter: this.currentFilter },
    });

    ref.afterClosed().subscribe(crop => {
      if (crop) {
        this.currentFilter = crop;
        if (crop === 'all') {
          this.currentFilterLabel = 'Toutes cultures';
          this.store.dispatch(new ParcelsViewerActions.FilterFeaturesByCrop());
        } else {
          this.currentFilterLabel = CropCode.fromCode(crop).label;
          this.store.dispatch(new ParcelsViewerActions.FilterFeaturesByCrop(crop));
        }
      }
    });
  }

  ngOnDestroy() {}
}
