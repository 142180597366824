import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ParcelsViewerComponent } from '@app/overview/parcels-viewer/parcels-viewer.component';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { ParcelsViewerEffects } from '@app/overview/parcels-viewer/parcels-viewer.effects';
import { ParcelsMapModule } from '@app/overview/parcels-viewer/core/parcels-map/parcels-map.module';
import { RouterModule } from '@angular/router';
import { PanelLinkModule } from '@fret-ngx/panel-link';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToolbarModule } from '@fret-ngx/toolbar';
import { SwitchViewModule } from '@app/overview/shared/switch-view/switch-view.module';
import { SnackbarIconModule } from '@app/overview/shared/snackbar-icon/snackbar-icon.module';
import { OverlayLoaderModule } from '@app/overview/shared/overlay-loader/overlay-loader.module';
import { SnackbarIconComponent } from '@app/overview/shared/snackbar-icon/snackbar-icon.component';
import { SwitchAdviceModeModule } from '@app/overview/parcels-viewer/core/switch-advice-mode/switch-advice-mode.module';
import { FilterWrapperModule } from '@app/overview/shared/filter-wrapper/filter-wrapper.module';
import { LaiLegendModule } from '@app/overview/shared/lai-legend/lai-legend.module';
import { ParcelSwitchModule } from '@app/overview/shared/parcel-switch/parcel-switch.module';
import { CropChooserModule } from '@app/overview/shared/crop-chooser/crop-chooser.module';

@NgModule({
  declarations: [ParcelsViewerComponent],
  entryComponents: [SnackbarIconComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    SnackbarIconModule,
    OverlayLoaderModule,
    SwitchViewModule,
    SwitchAdviceModeModule,
    ParcelsMapModule,
    FilterWrapperModule,
    LaiLegendModule,
    ParcelSwitchModule,
    CropChooserModule,
    StoreModule.forFeature('parcels-viewer', fromParcelsViewer.reducers),
    EffectsModule.forFeature([ParcelsViewerEffects]),
    RouterModule,
    ToolbarModule,
    PanelLinkModule,
    MatSidenavModule,
  ],
})
export class ParcelsViewerModule {}
