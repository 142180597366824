import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIGURATION, Configuration } from '@app/overview/shared/farm-star/configuration/configuration';
import {
  Parcel,
  ParcelResponseBackend,
  ParcelWithAdvices,
  RecommendationCode,
} from '@app/overview/shared/farm-star/parcel.model';
import { ObservationService } from '@app/overview/shared/observation/observation.service';
import { toHttpParams } from '@app/shared/utils/http-utils';
import { polygonToFeature } from '@app/shared/utils/ol-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParcelApiModel } from './parcel-api.model';
import { parcelDtoToParcel } from './utils';

@Injectable({
  providedIn: 'root',
})
export class ParcelApiService {
  public headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  private readonly API = `${this.config.url}/${this.config.api}/fs-core/api`;

  constructor(
    @Inject(CONFIGURATION) private config: Configuration,
    private http: HttpClient,
    private observationService: ObservationService
  ) {}

  getActiveParcelsOfActiveCampaigns(farmId: string): Observable<ParcelWithAdvices[]> {
    return this.http
      .get<ParcelApiModel.ClientPortalParcelDto[]>(`${this.API}/parcels/getActiveParcelsOfActiveCampaigns`, {
        headers: this.headers,
        params: { farmId },
      })
      .pipe(
        map(parcelDtos =>
          parcelDtos.map(dto => {
            const parcel: ParcelWithAdvices = parcelDtoToParcel(dto);
            if (parcel.biophyImageLink) {
              parcel.biophyImageLink = this.observationService.getImageUrl(parcel.biophyImageLink);
            }
            return parcel;
          })
        )
      );
  }

  getParcelDetail(parcelId: string): Observable<ParcelApiModel.ParcelDetail> {
    return this.http.get<ParcelApiModel.ParcelDetail>(`${this.API}/parcels/${parcelId}/detail`, {
      headers: this.headers,
    });
  }

  getIllustrationExtent(parcelId: string): Observable<[number, number, number, number]> {
    return this.http.get<[number, number, number, number]>(`${this.API}/parcels/${parcelId}/illustration-extent`, {
      headers: this.headers,
    });
  }

  getDivision(
    farmIds: string[],
    cropRef: string,
    recommendation: RecommendationCode
  ): Observable<ParcelApiModel.ParcelDivisionDto[]> {
    return this.http.get<ParcelApiModel.ParcelDivisionDto[]>(`${this.API}/parcels/division`, {
      headers: this.headers,
      params: toHttpParams({ farmIds, crop: cropRef, recommendation }),
    });
  }

  getDivisionCounts(farmIds: string[], cropRefs: string[]): Observable<Map<string, number>> {
    return this.http
      .get<number>(`${this.API}/parcels/division/count`, {
        headers: this.headers,
        params: toHttpParams({ farmIds, crop: cropRefs }),
      })
      .pipe(
        map(countByCrop => {
          const countMap = new Map();
          Object.entries(countByCrop).forEach(([cropRef, count]) => countMap.set(cropRef, count));
          return countMap;
        })
      );
  }

  getParcels(params: ParcelApiModel.ParcelRequestParams): Observable<Parcel[]> {
    return this.http
      .get<ParcelResponseBackend>(`${this.API}/parcels`, {
        headers: this.headers,
        params: toHttpParams({ ...params, active: true }),
      })
      .pipe(
        map(response => {
          return response._embedded.parcels.map(
            p =>
              ({
                ...p,
                aoi: {
                  ...p.aoi,
                  polygon: p.aoi.polygon ? polygonToFeature(p.aoi.polygon) : null,
                  originPolygon: p.aoi.originPolygon ? polygonToFeature(p.aoi.originPolygon) : null,
                },
              } as Parcel)
          );
        })
      );
  }
}
