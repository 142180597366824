import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { RouterNavigateAction } from '@davinkevin/router-store-helper';
import { CompanionComponent } from '@davinkevin/companion-component';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { FilteredParcel } from '@app/overview/shared/parcels-map.models';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import * as fromOverview from '@app/overview/overview.reducer';
import { routesNames } from '@app/shared/utils/routes.helper';
import { UserRole } from '@app/overview/overview.models';
import { ofType } from '@ngrx/effects';
import { BlurParcelFilter } from '@app/overview/overview.actions';

@Component({
  selector: 'fstar-parcel-filter',
  templateUrl: './parcel-filter.component.html',
  styleUrls: ['./parcel-filter.component.scss'],
})
export class ParcelFilterComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('auto', { static: false }) autoComplete: MatAutocomplete;
  @ViewChild('inputFilter', { static: false }) inputFilter: ElementRef;

  @Input() filteredParcels: FilteredParcel[];
  @Input() isDetailsView: boolean;
  @Input() isTabParcelsByIdView: boolean;
  @Input() canResetTerm: boolean;
  @Input() shouldRedirect: boolean;
  @Input() customColor: string;
  @Input() isDisabled: boolean;
  @Input() selectedParcel: FilteredParcel;
  @Output() parcelTermChanged = new EventEmitter<string>();
  @Output() selectedParcelId = new EventEmitter<string>();
  @Output() parcelHovered = new EventEmitter<string>();
  public hasInputFocus = false;
  public userRole: UserRole;
  public selectedFarmId: string;
  private companion = new CompanionComponent();
  public filterControl = new FormControl();

  constructor(
    private actions: ActionsSubject,
    private store: Store<fromParcelsViewer.State | fromOverview.OverviewGlobalState>
  ) {}

  ngOnDestroy() {
    this.companion.destroy();
  }

  ngOnChanges(): void {
    this.isDisabled ? this.filterControl.disable() : this.filterControl.enable();
  }

  ngOnInit() {
    const untilDestroy = this.companion.untilDestroy();

    this.actions.pipe(untilDestroy(), ofType(BlurParcelFilter)).subscribe(() => {
      this.inputFilter.nativeElement.blur();
    });

    this.filterControl.valueChanges.pipe(untilDestroy()).subscribe((v: string) => {
      this.parcelTermChanged.emit(!!v ? v : null);
    });

    this.store
      .pipe(select(fromOverview.selectUserRole), untilDestroy())
      .subscribe(userRole => (this.userRole = userRole));

    this.store
      .pipe(select(fromOverview.selectCurrentFarmId), untilDestroy())
      .subscribe(selectedFarmId => (this.selectedFarmId = selectedFarmId));
  }

  trackByParcelId(_: any, parcel: FilteredParcel) {
    return parcel.id;
  }

  onKeyDown() {
    const selectedOption: MatOption = this.autoComplete.options.find(option => option.active);
    if (selectedOption) {
      this.onParcelHovered(selectedOption.id);
    }
  }

  onParcelHovered(id: string) {
    this.parcelHovered.emit(id);
  }

  onBlur() {
    this.filterControl.setValue('', { emitEvent: false });
    this.parcelTermChanged.emit(null);
    this.hasInputFocus = false;
  }

  onFocus() {
    if (this.canResetTerm) {
      this.filterControl.setValue('', { emitEvent: false });
      this.parcelTermChanged.emit(null);
    }
    this.hasInputFocus = true;
  }

  onResetFilter() {
    if (this.shouldRedirect) {
      const navigationUrl = `/${routesNames.FARMS}/${this.selectedFarmId}/${routesNames.PARCELS}`;
      this.store.dispatch(new RouterNavigateAction([navigationUrl], { queryParamsHandling: 'preserve' }));
    }
    this.selectedParcelId.emit(null);
  }

  onSelect(parcelId: string) {
    this.selectedParcelId.emit(parcelId);
  }
}
