import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  FindFarmsError,
  FindFarmsSuccess,
  FindFarmsWithLoaderSuccess,
  HoverFarm,
  PrepareFarmSelection,
  ToggleFarmsFeatures,
  TriggerFindFarmsByCooperative,
  FindFarmInfoSuccess,
  FindFarmInfoError,
  FindFarmsFromRegisteredUsers,
} from '@app/overview/overview.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { CoreModel } from '@app/overview/shared/farm-star/core.model';

export const adapter: EntityAdapter<CoreModel.Farm> = createEntityAdapter<CoreModel.Farm>({
  selectId: farm => farm.id,
});

export interface State extends EntityState<CoreModel.Farm> {
  isLoadingFarms: boolean;
  hoveredFarmId: string;
  showFarmsFeatures: boolean;
}

const initialState: State = adapter.getInitialState({
  isLoadingFarms: false,
  hoveredFarmId: null,
  showFarmsFeatures: true,
});

const reducer = createReducer(
  initialState,
  on(ToggleFarmsFeatures, (state, { showFarmsFeatures }) => ({
    ...state,
    showFarmsFeatures,
  })),
  on(FindFarmsWithLoaderSuccess, (state, { farms }) => ({
    ...adapter.addAll(farms, state),
    isLoadingFarms: false,
  })),
  on(PrepareFarmSelection, state => ({
    ...state,
    hoveredFarmId: null,
  })),
  on(HoverFarm, (state, { farmId }) => ({
    ...state,
    hoveredFarmId: farmId,
  })),
  on(TriggerFindFarmsByCooperative, state => ({
    ...state,
    isLoadingFarms: true,
  })),
  on(FindFarmsSuccess, (state, { farms }) => ({
    ...adapter.addAll(farms, state),
  })),
  on(FindFarmInfoSuccess, (state, { farms }) => ({
    ...adapter.updateMany(
      farms.map(f => ({ id: f.id, changes: { coordinates: f.coordinates } })),
      state
    ),
    isLoadingFarms: false,
  })),
  on(FindFarmInfoError, state => ({
    ...state,
    isLoadingFarms: false,
  })),
  on(FindFarmsError, state => ({
    ...adapter.removeAll(state),
    isLoadingFarms: false,
  })),
  on(FindFarmsFromRegisteredUsers, state => ({
    ...state,
    isLoadingFarms: true,
  }))
);

export function farmsReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
