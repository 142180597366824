import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { ParcelDetailData } from '@app/overview/shared/farm-star/parcel.model';
import {
  FindParcelDetailData,
  FindParcelDetailDataSuccess,
  FindBiomassParcelRecommendationValueSuccess,
} from '@app/overview/shared/parcels/parcels.actions';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import selectSelectedParcel = fromParcelsViewer.selectSelectedParcel;

export type State = EntityState<ParcelDetailData>;

const adapter: EntityAdapter<ParcelDetailData> = createEntityAdapter<ParcelDetailData>({
  selectId: extraData => extraData.parcelId,
});

const initialState: State = adapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(FindParcelDetailData, (state, { parcelDetailDataParams: parcelDetailDataParams }) =>
    adapter.addOne(
      { parcelId: parcelDetailDataParams.parcelId, isExtraDataLoading: true, isExtraDataLoaded: false },
      state
    )
  ),
  on(
    FindParcelDetailDataSuccess,
    (
      state,
      {
        parcelId,
        cropLabel,
        soilType,
        kindType,
        varietyType,
        previousCrop,
        previousCropWasteUsage,
        nDoseComputationMethods,
      }
    ) =>
      adapter.updateOne(
        {
          id: parcelId,
          changes: {
            cropLabel,
            soilType,
            kindType,
            varietyType,
            previousCrop,
            previousCropWasteUsage,
            nDoseComputationMethod: nDoseComputationMethods,
            isExtraDataLoading: false,
            isExtraDataLoaded: true,
          },
        },
        state
      )
  ),
  on(
    FindBiomassParcelRecommendationValueSuccess,
    (state, { parcelId, earlyWinterParcelRecommendation, lateWinterParcelRecommendation }) =>
      adapter.updateOne(
        {
          id: parcelId,
          changes: {
            earlyWinterParcelRecommendation,
            lateWinterParcelRecommendation,
          },
        },
        state
      )
  )
);

export function parcelsReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

const getParcelsState = createFeatureSelector<State>('parcels');

export const { selectEntities: selectAllParcelsEntities } = adapter.getSelectors(getParcelsState);

export const selectParcelDetailDataById = (parcelId: string) =>
  createSelector(selectAllParcelsEntities, (parcelsEntities: Dictionary<ParcelDetailData>) => {
    return parcelsEntities[parcelId] || { parcelId, isExtraDataLoading: false, isExtraDataLoaded: false };
  });

export const selectIsSelectedParcelDetailDataLoading = createSelector(
  selectAllParcelsEntities,
  selectSelectedParcel,
  (parcelsEntities, selectedParcel) =>
    selectedParcel != null &&
    parcelsEntities[selectedParcel.id] != null &&
    parcelsEntities[selectedParcel.id].isExtraDataLoading
);
