import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

export const aprilIndex = 3;
export const novemberIndex = 10;

export function transformToUTCDate(date: string): number {
  const currentDate = new Date(date);
  return Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1);
}

export function firstDayOfMonthBeginDate(year: number, beginMonth: number): number {
  return Date.UTC(year, beginMonth, 1);
}

export function lastDayOfMonthEndDate(year: number, endMonth: number): number {
  return Date.UTC(year, endMonth, 30);
}

export function getAvailableYears(): number[] {
  const years = [];
  for (let year = 2016, endYear = new Date().getFullYear(); year <= endYear; year++) {
    years.push(year);
  }
  return years;
}

export function getMonths(): string[] {
  return Array.from(Array(12)).map((_, index) => {
    return getMonthLabel(index);
  });
}

function getMonthLabel(monthIndex: number): string {
  const date = new Date('2018-01-01T00:00:00.000Z');
  date.setMonth(monthIndex);
  const datePipe = new DatePipe('fr-FR');
  const titleCasePipe = new TitleCasePipe();
  return titleCasePipe.transform(datePipe.transform(date, 'MMMM'));
}
