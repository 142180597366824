import { SpreadingEquipment } from '../../shared/farm-star/spreading-equipment.model';
import { Terminal } from '../../shared/farm-star/vra-map.model';
import { ActionReducerMap, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  FindSpreadingEquipmentsSuccess,
  FindTerminals,
  FindTerminalsSuccess,
} from './core/spreading-equipments/spreading-equipments.action';

export namespace fromNavBar {
  export interface GlobalState {
    spreadingEquipments: State;
  }

  export interface State {
    userSpreadingEquipments: SpreadingEquipment[];
    terminals: Terminal[];
  }

  const initialState: State = {
    userSpreadingEquipments: null,
    terminals: null,
  };

  const reducer = createReducer(
    initialState,
    on(FindTerminals, _ => ({ userSpreadingEquipments: null, terminals: null })),
    on(FindTerminalsSuccess, (state, { terminals }) => ({
      userSpreadingEquipments: state.userSpreadingEquipments,
      terminals,
    })),
    on(FindSpreadingEquipmentsSuccess, (state, { spreadingEquipments }) => ({
      userSpreadingEquipments: spreadingEquipments,
      terminals: state.terminals,
    }))
  );

  export const reducers: ActionReducerMap<GlobalState> = {
    spreadingEquipments: reducer,
  };

  const getSpreadingEquipmentsGlobalState = createFeatureSelector<GlobalState>('navbar');

  const getSpreadingEquipmentsState = createSelector(
    getSpreadingEquipmentsGlobalState,
    state => state.spreadingEquipments
  );

  export const selectUserSpreadingEquipment = createSelector(
    getSpreadingEquipmentsState,
    state => state.userSpreadingEquipments
  );

  export const selectTerminals = createSelector(getSpreadingEquipmentsState, state => state.terminals);
}
