import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { PhenologicalStageByEstimateDate } from '@app/overview/parcels-viewer/core/parcel-details/parcel-details.models';
import { transformToUTCDate } from '@app/shared/utils/date-utils';
import { SeriesLineDataOptions } from 'highcharts';
import {
  buildWaterDeficitAreaLineDataOptions,
  createPlotLines,
  resetMouseEventWhenChartRendered,
} from '@app/overview/parcels-viewer/core/parcel-details/utils';
import { PlotArearangeZonesOptions } from 'highcharts';

HC_more(Highcharts);
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'fstar-parcel-hydro-chart',
  templateUrl: './parcel-hydro-chart.component.html',
  styleUrls: ['./parcel-hydro-chart.component.scss'],
})
export class ParcelHydroChartComponent implements OnInit, OnChanges {
  @Input() beginDate: string;
  @Input() endDate: string;
  @Input() phenologicalStageByEstimateDate: PhenologicalStageByEstimateDate[];
  @Input() dailyStatusMultiLineDataOptions: Array<SeriesLineDataOptions>[];

  constructor() {}

  basicStyleText = {
    fontFamily: 'Lato, Roboto, Arial, sans-serif',
    fontSize: '10px',
    fontWeight: '300',
    letterSpacing: '0.3px',
  };

  Highcharts = Highcharts;
  chartInstance: Highcharts.Chart = null;
  chartOptions: Highcharts.Options = null;

  currentDate = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  waterDeficit: { areaLineDataOptions: Array<PlotArearangeZonesOptions>; zones: Array<SeriesLineDataOptions> };

  ngOnInit() {
    this.initChartOptions();
  }

  ngOnChanges() {
    this.initChartOptions();
  }

  onChartInit(chartInstance: Highcharts.Chart) {
    this.chartInstance = chartInstance;
    this.chartInstance.redraw();
  }

  private initChartOptions() {
    const beginDate = transformToUTCDate(this.beginDate);
    const endDate = transformToUTCDate(this.endDate);
    const range = endDate - beginDate;
    this.waterDeficit = buildWaterDeficitAreaLineDataOptions(
      this.dailyStatusMultiLineDataOptions[1],
      this.dailyStatusMultiLineDataOptions[2],
      this.currentDate
    );
    this.chartOptions = this.mapToChartOptions(beginDate, range);
  }

  mapToChartOptions(beginDate: number, range: number): Highcharts.Options {
    return {
      chart: {
        type: 'spline',
        marginTop: 80,
        ignoreHiddenSeries: false,
        events: {
          render: () => {
            resetMouseEventWhenChartRendered(this.chartInstance, this.phenologicalStageByEstimateDate);
          },
        },
      },
      title: {
        text: 'Bilan hydrique',
        style: {
          color: '#052338',
          fontSize: '13px',
          fontFamily: 'Lato, Roboto, Arial, sans-serif',
          fontWeight: '300',
          letterSpacing: '0.3px',
        },
      },
      xAxis: {
        alternateGridColor: 'rgba(204, 204, 204, 0.1)',
        type: 'datetime',
        gridLineWidth: 0,
        min: beginDate,
        minRange: range,
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          formatter() {
            return convertTimeToDate(this.value);
          },
          style: this.basicStyleText,
        },
        plotLines: createPlotLines(
          this.phenologicalStageByEstimateDate,
          transformToUTCDate(this.beginDate),
          transformToUTCDate(this.endDate)
        ),
      },
      yAxis: {
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: '',
        },
        min: -10,
        max: 120,
        labels: {
          style: this.basicStyleText,
        },
        reversed: true,
        tickAmount: 14,
        startOnTick: true,
        endOnTick: true,
      },
      tooltip: {
        xDateFormat: '%d/%m/%Y',
        pointFormatter() {
          const y = Math.round(this.y);
          if (this.series.name.startsWith('Déficit')) {
            this.series.name =
              this.x < Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                ? 'Déficit en eau du sol'
                : 'Déficit prévisionnel';
          }
          return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${y}</b><br/>`;
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: () => {
              return false;
            },
          },
        },
        column: {
          pointPadding: 0.46,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: true,
        itemStyle: this.basicStyleText,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'line',
          cursor: 'pointer',
          color: '#000000',
          name: 'RU',
          marker: {
            radius: 0,
          },
          data:
            this.dailyStatusMultiLineDataOptions.length > 0
              ? this.dailyStatusMultiLineDataOptions[0]
              : emptySeriesData(),
        },
        {
          type: 'line',
          cursor: 'pointer',
          color: '#e92e2e',
          name: 'RFU',
          marker: {
            radius: 0,
          },
          data:
            this.dailyStatusMultiLineDataOptions.length > 0
              ? this.dailyStatusMultiLineDataOptions[1]
              : emptySeriesData(),
        },
        {
          type: 'line',
          color: '#0000ff',
          name: 'Déficit en eau du sol',
          marker: {
            radius: 0,
          },
        },
        {
          type: 'line',
          dashStyle: 'ShortDot',
          cursor: 'pointer',
          color: '#0000ff',
          name: 'Déficit prévisionnel',
          marker: {
            radius: 0,
          },
          data:
            this.dailyStatusMultiLineDataOptions.length > 0
              ? this.dailyStatusMultiLineDataOptions[2]
              : emptySeriesData(),
          zoneAxis: 'x',
          zones: [
            {
              value: this.currentDate,
              dashStyle: 'Solid',
            },
          ],
        },
        {
          type: 'arearange',
          fillOpacity: 0.1,
          enableMouseTracking: false,
          color: 'rgba(208, 2, 27, 0.4)',
          name: 'Stress hydrique',
          lineWidth: 0,
          data: this.waterDeficit.areaLineDataOptions,
          zoneAxis: 'x',
          zones: this.waterDeficit.zones,
        },
        {
          type: 'column',
          name: "Apports d'eau",
          color: '#14ccff',
          data:
            this.dailyStatusMultiLineDataOptions.length > 0
              ? this.dailyStatusMultiLineDataOptions[3]
              : emptySeriesData(),
        },
        {
          type: 'column',
          name: 'Drainage',
          color: '#ffaa66',
          data:
            this.dailyStatusMultiLineDataOptions.length > 0
              ? this.dailyStatusMultiLineDataOptions[4]
              : emptySeriesData(),
        },
      ],
    };
  }
}

function emptySeriesData() {
  return [
    {
      type: 'spline',
      name: '',
      data: [] as any[],
      visible: false,
    },
  ];
}

function convertTimeToDate(timestamp: number) {
  const datePipe = new DatePipe('fr-FR');
  const titleCasePipe = new TitleCasePipe();
  return `${titleCasePipe.transform(datePipe.transform(new Date(timestamp), 'MMMM yyyy'))}`;
}
