<div class="filters-others-users">
  <fstar-farm-filter
    *ngIf="(!isDetailsView && !areParcelsFeaturesActivated) || isTabFarmsByIdView || isFarmView; else filterWrapper"
    [filteredFarms]="filteredFarms"
    [selectedFarm]="selectedFarm"
    [isFarmView]="isFarmView"
    [canResetTerm]="!isFarmView"
    [isTabFarmsByIdView]="isTabFarmsByIdView"
    [shouldRedirect]="true"
    (selectedFarmId)="farmToSelect($event)"
    (farmTermChanged)="filterFarmTermChanged($event)"
    (farmHovered)="onFarmHovered($event)"
  >
  </fstar-farm-filter>
  <ng-template #filterWrapper>
    <div class="filters-wrapper-container">
      <fstar-farm-filter
        class="farm-filter-wrapper"
        [filteredFarms]="filteredFarms"
        [selectedFarm]="selectedFarm"
        [isFarmView]="isFarmView"
        [canResetTerm]="!isFarmView"
        [shouldRedirect]="true"
        (selectedFarmId)="farmToSelect($event)"
        (farmTermChanged)="filterFarmTermChanged($event)"
        (farmHovered)="onFarmHovered($event)"
      ></fstar-farm-filter>
      <fstar-parcel-filter
        class="parcel-filter-wrapper"
        [selectedParcel]="selectedParcel"
        [filteredParcels]="filteredParcels"
        [isDetailsView]="isDetailsView"
        [canResetTerm]="true"
        [shouldRedirect]="true"
        (selectedParcelId)="parcelToSelect($event)"
        (parcelTermChanged)="filterTermChanged($event)"
        (parcelHovered)="onParcelHovered($event)"
      ></fstar-parcel-filter>
    </div>
  </ng-template>
</div>
