import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerDataCampaign } from '@app/overview/import-database/import-database.model';
import * as ImportDatabaseActions from '@app/overview/import-database/import-database.actions';
import { createReducer, on } from '@ngrx/store';

export interface State extends EntityState<CustomerDataCampaign> {
  isLoading: boolean;
}
export const adapter: EntityAdapter<CustomerDataCampaign> = createEntityAdapter<CustomerDataCampaign>({
  selectId: datafile => datafile.id,
});

const initialState: State = adapter.getInitialState({
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(ImportDatabaseActions.LoadDataFileSuccess, (state, { customerDataCampaigns }) => ({
    ...adapter.addAll(customerDataCampaigns, state),
    isLoading: false,
  })),
  on(ImportDatabaseActions.ReloadDataFileSuccess, (state, { dataFileUpdated }) =>
    adapter.updateOne({ id: dataFileUpdated.id, changes: { status: dataFileUpdated.status } }, state)
  ),
  on(ImportDatabaseActions.ResetDatafiles, state => ({
    ...adapter.removeAll(state),
    isLoading: true,
  })),
  on(ImportDatabaseActions.OnRequestFailed, state => ({
    ...state,
    isLoading: false,
  }))
);
