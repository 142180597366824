import * as OverviewActions from '@app/overview/overview.actions';
import { Action, ActionReducerMap, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CooperativeStore, UserRole } from '@app/overview/overview.models';
import * as fromPhenologicalStages from '@app/overview/entities/phenological-stages-entity.reducer';
import * as fromCooperativeEntity from '@app/overview/entities/cooperative-entity.reducer';
import * as fromCropEntity from '@app/overview/entities/crop-entity.reducer';
import { Dictionary } from '@ngrx/entity';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import * as fromFarmsEntity from '@app/overview/entities/farms-entity.reducer';
import { filterFarms } from '@app/shared/utils/filters-utils';
import { CoreModel } from './shared/farm-star/core.model';
import { RegisteredUserWithRefs } from './shared/farm-star/farmer.model';

export interface OverviewGlobalState {
  phenologicalStages: fromPhenologicalStages.State;
  cooperatives: fromCooperativeEntity.State;
  crops: fromCropEntity.State;
  farms: fromFarmsEntity.State;
  overview: State;
}

export interface HideCampaignData {
  campaignId: string;
  hideNitrogenDose: boolean;
  hideIrrigationAmount: boolean;
}

export interface State {
  mustChooseHisCooperative: boolean;
  openingToolbarStatus: boolean;
  selectedCooperative: CooperativeStore;
  userRole: UserRole;
  userData: farmStarApiModel.AllUserTypeData;
  userRoleLabel: string;
  farmFilter: string;
  showParcelDetailsHelp: boolean;
  hideCampaignData: HideCampaignData[];
  cornRecommendationId: string;
  currentFarmId: string;
  registeredUsers: RegisteredUserWithRefs[];
}

const initialState: State = {
  mustChooseHisCooperative: false,
  openingToolbarStatus: false,
  selectedCooperative: null,
  userRole: null,
  userData: null,
  userRoleLabel: null,
  farmFilter: null,
  showParcelDetailsHelp: false,
  hideCampaignData: [],
  cornRecommendationId: null,
  currentFarmId: null,
  registeredUsers: [],
};

const reducer = createReducer(
  initialState,
  on(OverviewActions.ShowParcelDetailsHelp, state => ({ ...state, showParcelDetailsHelp: true })),
  on(OverviewActions.HideParcelDetailsHelp, state => ({ ...state, showParcelDetailsHelp: false })),
  on(OverviewActions.InviteUserToChooseHisCooperative, state => ({ ...state, mustChooseHisCooperative: true })),
  on(OverviewActions.EnableAppWhenUserHasChosenHisCooperative, state => ({
    ...state,
    mustChooseHisCooperative: false,
  })),
  on(OverviewActions.FilterFarm, (state, { filter }) => ({
    ...state,
    farmFilter: filter,
  })),
  on(OverviewActions.FindUserRoleSuccess, (state, { userRole }) => ({
    ...state,
    userRole,
  })),
  on(OverviewActions.UpdateCampaignDataPublicationStatus, (state, { campaigns }) => ({
    ...state,
    hideCampaignData: updateCampaignDataPublicationStatus(state.hideCampaignData, campaigns),
  })),
  on(OverviewActions.ClearCampaignDataPublicationStatus, state => ({
    ...state,
    hideCampaignData: clearCampaignDataPublicationStatus(state.hideCampaignData),
  })),
  on(OverviewActions.FindUserRoleLabelSuccess, (state, { userRoleLabel }) => ({
    ...state,
    userRoleLabel,
  })),
  on(OverviewActions.FindUserDataSuccess, (state, { userData }) => ({
    ...state,
    userData,
  })),
  on(OverviewActions.OpeningToolbarStatus, (state, { openedChange }) => ({
    ...state,
    openingToolbarStatus: openedChange,
  })),
  on(OverviewActions.SelectCooperative, (state, { cooperative }) => ({
    ...state,
    selectedCooperative: cooperative,
  })),
  on(OverviewActions.ChangeCurrentFarm, (state, { farmId }) => ({
    ...state,
    currentFarmId: farmId,
  })),
  on(OverviewActions.SaveRegisteredUsers, (state, { registeredUsers }) => ({
    ...state,
    registeredUsers,
  }))
);

function updateCampaignDataPublicationStatus(
  hideCampaignData: HideCampaignData[],
  campaigns: CoreModel.CampaignBackend[]
) {
  const arrayCopy = [...hideCampaignData];
  campaigns.forEach(campaign => {
    const campaignDataIndex = arrayCopy.findIndex(x => x.campaignId === campaign.id);
    if (campaignDataIndex === -1) {
      arrayCopy.push({
        campaignId: campaign.id,
        hideNitrogenDose: !campaign.publishTotalN,
        hideIrrigationAmount: !campaign.publishIrrigation,
      });
    } else {
      arrayCopy[campaignDataIndex] = {
        ...arrayCopy[campaignDataIndex],
        hideNitrogenDose: !campaign.publishTotalN,
        hideIrrigationAmount: !campaign.publishIrrigation,
      };
    }
  });

  return arrayCopy;
}

function clearCampaignDataPublicationStatus(hideCampaignData: HideCampaignData[]) {
  return hideCampaignData.map(data => ({
    campaignId: data.campaignId,
    hideIrrigationAmount: true,
    hideNitrogenDose: true,
  }));
}

export function overviewReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const reducers: ActionReducerMap<OverviewGlobalState> = {
  phenologicalStages: fromPhenologicalStages.phenologicalStagesReducer,
  cooperatives: fromCooperativeEntity.cooperativeReducer,
  farms: fromFarmsEntity.farmsReducer,
  overview: overviewReducer,
  crops: fromCropEntity.cropsReducer,
};

const getOverviewGlobalState = createFeatureSelector<OverviewGlobalState>('overview');

const getPhenologicalStagesEntitiesState = createSelector(getOverviewGlobalState, state => state.phenologicalStages);

export const {
  selectAll: selectAllPhenologicalStages,
  selectEntities: selectAllPhenologicalStagesEntities,
} = fromPhenologicalStages.adapter.getSelectors(getPhenologicalStagesEntitiesState);

export const selectIsPhenologicalStagesLoaded = createSelector(
  getPhenologicalStagesEntitiesState,
  state => state.isLoaded
);

const getCropEntitiesState = createSelector(getOverviewGlobalState, state => state.crops);
export const { selectAll: selectAllCrops, selectEntities: selectAllCropEntities } = fromCropEntity.adapter.getSelectors(
  getCropEntitiesState
);

const getOverviewState = createSelector(getOverviewGlobalState, state => state.overview);

export const selectShowParcelDetailsHelp = createSelector(getOverviewState, state => state.showParcelDetailsHelp);

export const selectMustChooseHisCooperative = createSelector(getOverviewState, state => state.mustChooseHisCooperative);

export const selectCurrentOpeningToolbarStatus = createSelector(getOverviewState, state => state.openingToolbarStatus);

export const selectFarmFilter = createSelector(getOverviewState, state => state.farmFilter);

const getCooperativeEntitiesState = createSelector(getOverviewGlobalState, state => state.cooperatives);

export const {
  selectAll: selectAllCooperatives,
  selectEntities: selectAllCooperativeEntities,
} = fromCooperativeEntity.adapter.getSelectors(getCooperativeEntitiesState);

export const selectSelectedCooperative = createSelector(getOverviewState, state => state.selectedCooperative);

const getFarmsEntitiesState = createSelector(getOverviewGlobalState, state => state.farms);

export const {
  selectAll: selectAllFarms,
  selectEntities: selectAllFarmsEntities,
} = fromFarmsEntity.adapter.getSelectors(getFarmsEntitiesState);

export const selectCurrentFarmId = createSelector(getOverviewState, state => state.currentFarmId);
export const selectIsLoadingFarms = createSelector(getFarmsEntitiesState, state => state.isLoadingFarms);
export const selectSelectedFarm = createSelector(
  selectAllFarmsEntities,
  selectCurrentFarmId,
  (farmEntities: Dictionary<CoreModel.FarmBackend>, farmId: string) => farmEntities[farmId]
);

export const selectSelectedFilteredFarm = createSelector(
  selectAllFarmsEntities,
  selectCurrentFarmId,
  (farmEntities: Dictionary<CoreModel.FarmBackend>, farmId: string) => {
    return farmEntities[farmId]
      ? {
          id: farmEntities[farmId].id,
          corporateName: farmEntities[farmId].corporateName,
        }
      : null;
  }
);

export const selectShowFarmsFeatures = createSelector(getFarmsEntitiesState, state => state.showFarmsFeatures);

const getFilteredFarms = (farms: CoreModel.FarmBackend[], farmFilter: string) => filterFarms(farms, farmFilter);
export const selectFilteredFarms = createSelector(selectAllFarms, selectFarmFilter, getFilteredFarms);

export const selectUserRole = createSelector(getOverviewState, state => state.userRole);
export const selectUserData = createSelector(getOverviewState, state => state.userData);
export const selectUserRoleLabel = createSelector(getOverviewState, state => state.userRoleLabel);
export const selectHoveredFarmId = createSelector(getFarmsEntitiesState, state => state.hoveredFarmId);

export const selectIsAFarmer = createSelector(selectUserRole, userRole => userRole === UserRole.farmer);

export const selectHideDataForCampaigns = createSelector(getOverviewState, state => {
  const hideByCampaign = new Map<string, HideCampaignData>();
  state.hideCampaignData.forEach(hideCampaign => {
    hideByCampaign.set(hideCampaign.campaignId, hideCampaign);
  });
  return hideByCampaign;
});

export const selectRegisteredUsers = createSelector(getOverviewState, state => state.registeredUsers);
export const selectAllLoadedFarms = createSelector(
  selectIsLoadingFarms,
  selectAllFarms,
  (isLoading: boolean, farms: CoreModel.Farm[]) => (isLoading ? [] : farms)
);
export const selectIsFarmListEmpty = createSelector(selectAllFarms, farms => farms.length === 0);
