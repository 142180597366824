import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { ParcelsMapComponent } from '@app/overview/parcels-viewer/core/parcels-map/parcels-map.component';

@NgModule({
  declarations: [ParcelsMapComponent],
  imports: [CommonModule, /* OL Modules */ AngularOpenlayersModule],
  exports: [ParcelsMapComponent],
})
export class ParcelsMapModule {}
