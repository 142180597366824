import { Component, Input } from '@angular/core';
import { CustomerDataCampaign } from '@app/overview/import-database/import-database.model';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';

@Component({
  selector: 'fstar-import-database-list-item',
  templateUrl: './import-database-list-item.component.html',
  styleUrls: ['./import-database-list-item.component.scss'],
})
export class ImportDatabaseListItemComponent {
  @Input() data: CustomerDataCampaign;
  public CampaignStatus = farmStarApiModel.DatafileStatus;
}
