<div class="edition-layer">
  <div class="header">
    <ng-container *ngIf="editionMode === EditionMode.rain; else irrigationHeader">
      <div>
        <svg aria-hidden="true">
          <use href="assets/images/sprites.svg#rain"></use>
        </svg>
      </div>
      <div class="header-title">Pluie</div>
    </ng-container>
    <div>
      <mat-icon class="close-button" (click)="closeEditionLayer()">close</mat-icon>
    </div>
  </div>
  <div class="content"></div>
  <div class="footer">
    <button mat-button (click)="closeEditionLayer()">Annuler</button>
    <button class="save-button" mat-button (click)="closeEditionLayer()">Enregistrer</button>
  </div>
</div>

<ng-template #irrigationHeader>
  <div>
    <svg>
      <use href="assets/images/sprites.svg#tapwater"></use>
    </svg>
  </div>
  <div class="header-title">Irrigation</div>
</ng-template>
