import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotNullOrZero',
})
export class IsNotNullOrZeroPipe implements PipeTransform {
  transform(value: number): boolean {
    return !!value || value === 0;
  }
}
