<button
  class="switch-mode-button"
  mat-stroked-button
  *ngIf="(isAdviceModeContainerOpened$ | async) === false; else modeContainer"
  (click)="toggleAdviceModeContainer()"
>
  <mat-icon>remove_red_eye</mat-icon>
  Modes carte
</button>

<ng-template #modeContainer>
  <div class="switch-mode-container">
    <mat-slide-toggle
      [checked]="selectedAdviceMode === adviceMode.dose"
      (change)="onSlideToggleChange($event, adviceMode.dose)"
    >
      Doses N
    </mat-slide-toggle>
    <mat-slide-toggle
      [checked]="selectedAdviceMode === adviceMode.lai"
      (change)="onSlideToggleChange($event, adviceMode.lai)"
    >
      LAI
    </mat-slide-toggle>
    <mat-slide-toggle
      [checked]="selectedAdviceMode === adviceMode.stage"
      (change)="onSlideToggleChange($event, adviceMode.stage)"
    >
      Stades
    </mat-slide-toggle>
    <mat-slide-toggle
      [checked]="selectedAdviceMode === adviceMode.hydro"
      (change)="onSlideToggleChange($event, adviceMode.hydro)"
    >
      Déficit hydrique
    </mat-slide-toggle>
    <button mat-icon-button (click)="toggleAdviceModeContainer()"><mat-icon>close</mat-icon></button>
  </div>
</ng-template>
