import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Parcel, ParcelDetailDataParams, ParcelRecommendation } from '@app/overview/shared/farm-star/parcel.model';
import { forkJoin, iif, of } from 'rxjs';
import { CropBackend } from '@app/overview/import-database/import-database.model';
import { Injectable } from '@angular/core';
import { FarmStarService } from '@app/overview/shared/farm-star/service/farm-star.service';
import * as fromParcelsEntity from '@app/overview/shared/parcels/parcels-entity.reducer';
import { farmStarApiModel } from '@app/overview/shared/farm-star/farm-star.model';
import DoseCalculationMethods = farmStarApiModel.DoseCalculationMethods;
import { switchMap, concatMap } from 'rxjs/operators';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { RouterStateUrl } from '@davinkevin/router-store-helper';
import { ParcelRecommendationApiService } from '@app/shared/api/parcel/recommendation/parcel-recommendation-api.service';
import { cloneDeep, merge } from 'lodash-es';

import { isTabViewUrl } from '@app/shared/utils/routes.helper';
import {
  FindParcelDetailData,
  FindParcelDetailDataSuccess,
  FindBiomassParcelRecommendationValue,
  FindBiomassParcelRecommendationValueSuccess,
  TriggerFindParcelInfo,
  FindParcelDetail,
} from '@app/overview/shared/parcels/parcels.actions';
import { Observable } from 'rxjs';
import { fromParcelsViewer } from '@app/overview/parcels-viewer/parcels-viewer.reducer';
import { ParcelApiService } from '@app/shared/api/parcel/parcel-api.service';
import { ParcelsViewerActions } from '@app/overview/parcels-viewer/parcels-viewer.actions';

@Injectable()
export class ParcelsEffects {
  constructor(
    private actions$: Actions,
    private farmStarService: FarmStarService,
    private store: Store<fromParcelsEntity.State>,
    private parcelRecommendationApi: ParcelRecommendationApiService,
    private parcelApi: ParcelApiService
  ) {}

  triggerFindParcelInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TriggerFindParcelInfo),
      withLatestFrom(this.store.pipe(select(fromParcelsEntity.selectAllParcelsEntities))),
      filter(([{ parcel }, extraDataEntities]) =>
        extraDataEntities[parcel.id] ? !extraDataEntities[parcel.id].isExtraDataLoaded : true
      ),
      map(([{ parcel }]) => ({
        parcelId: parcel.id,
        cropRefId: parcel.agroData.crop.cropRefId,
        kindRefId: parcel.agroData.crop.kindRefId,
        varietyRefId: parcel.agroData.crop.varietyRefId,
        soilRefId: !!parcel.agroData.soilInputLabel ? null : parcel.agroData.soilRefId,
        previousCropRefId: parcel.agroData.activities.previous.previousCropRefId,
        previousCropWasteUsageRefId: parcel.agroData.activities.previous.previousCropWasteUsageRefId,
        nDoseComputationMethodsRefId: parcel.agroData.ndoseComputationMethodRefId,
      })),
      map((params: ParcelDetailDataParams) => FindParcelDetailData({ parcelDetailDataParams: params }))
    )
  );

  getParcelDetailData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindParcelDetailData),
      withLatestFrom(this.actions$.pipe(ofType(ROUTER_NAVIGATION))),
      switchMap(
        ([action, router]: [
          { parcelDetailDataParams: ParcelDetailDataParams },
          RouterNavigationAction<RouterStateUrl>
        ]) =>
          forkJoin([
            this.farmStarService.findCrop(action.parcelDetailDataParams.cropRefId).pipe(
              map((crop: CropBackend) => crop.label),
              catchError(() => of(null))
            ),
            iif(
              () => action.parcelDetailDataParams.soilRefId === null,
              of(null),
              this.farmStarService.findSoilLabelById(action.parcelDetailDataParams.soilRefId).pipe(
                map(label => label),
                catchError(() => of(null))
              )
            ),
            this.farmStarService.findKindLabelById(action.parcelDetailDataParams.kindRefId).pipe(
              map(label => label),
              catchError(() => of(null))
            ),
            this.farmStarService.findVarietyLabelById(action.parcelDetailDataParams.varietyRefId).pipe(
              map(label => label),
              catchError(() => of(null))
            ),
            this.farmStarService.findPreviousCropLabelById(action.parcelDetailDataParams.previousCropRefId).pipe(
              map(label => label),
              catchError(() => of(null))
            ),
            this.farmStarService
              .findPreviousCropWasteUsageLabelById(action.parcelDetailDataParams.previousCropWasteUsageRefId)
              .pipe(
                map(label => label),
                catchError(() => of(null))
              ),
            iif(
              () => isTabViewUrl(router.payload.routerState.url),
              of(null),
              this.farmStarService
                .findNDoseCalculationMethodsById(action.parcelDetailDataParams.nDoseComputationMethodsRefId)
                .pipe(
                  map(doseCalculationMethods => doseCalculationMethods),
                  catchError(() => of(null))
                )
            ),
            of(router.payload.routerState.url),
          ]).pipe(
            map((responses: (string | DoseCalculationMethods)[]) => [
              action.parcelDetailDataParams.parcelId,
              ...responses,
            ])
          )
      ),
      switchMap((responses: (string | DoseCalculationMethods)[]) => {
        const parcelId = responses[0] as string;
        const cropLabel = responses[1] as string;
        const actions: any[] = [
          FindParcelDetailDataSuccess({
            parcelId,
            cropLabel,
            soilType: responses[2] as string,
            kindType: responses[3] as string,
            varietyType: responses[4] as string,
            previousCrop: responses[5] as string,
            previousCropWasteUsage: responses[6] as string,
            nDoseComputationMethods: responses[7] as DoseCalculationMethods,
          }),
        ];
        if (!isTabViewUrl(responses[responses.length - 1] as string)) {
          actions.push(FindParcelDetail({ parcelId }));

          if (cropLabel === 'COLZA') {
            actions.push(FindBiomassParcelRecommendationValue({ parcelId }));
          }
        }

        return actions;
      })
    )
  );

  getParcelDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindParcelDetail),
      withLatestFrom(this.store.pipe(select(fromParcelsViewer.selectAllFieldsEntities))),
      switchMap(([action, parcels]) => {
        const parcelEntity = parcels[action.parcelId];
        return forkJoin([
          of(parcelEntity),
          this.parcelApi.getParcelDetail(action.parcelId).pipe(catchError(() => of(null))),
        ]);
      }),
      switchMap(([parcel, parcelDetail]) => {
        if (parcelDetail) {
          const updatedParcel: Parcel = cloneDeep(parcel);
          merge(updatedParcel.agroData, parcelDetail);
          return [new ParcelsViewerActions.UpdateParcelAgroData(updatedParcel)];
        } else {
          return [];
        }
      })
    )
  );

  getRecommendationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindBiomassParcelRecommendationValue),
      withLatestFrom(this.store.pipe(select(fromParcelsViewer.selectAllFieldsEntities))),
      concatMap(([action, parcels]) => {
        const parcel = parcels[action.parcelId];
        const recommendations = this.parcelRecommendationApi.findRecommendationsByCrop(parcel.agroData.crop.cropRefId);
        return forkJoin([of(action), recommendations]);
      }),
      concatMap(([action, recommendations]) => {
        return forkJoin([
          of(action.parcelId),
          this.getParcelRecommendationValue(action.parcelId, recommendations, 'EARLY_WINTER'),
          this.getParcelRecommendationValue(action.parcelId, recommendations, 'LATE_WINTER'),
        ]);
      }),
      map(([parcelId, earlyWinterParcelRecommendation, lateWinterParcelRecommendation]) => {
        return FindBiomassParcelRecommendationValueSuccess({
          parcelId,
          earlyWinterParcelRecommendation,
          lateWinterParcelRecommendation,
        });
      })
    );
  });

  getParcelRecommendationValue(
    parcelId: string,
    recommendations: farmStarApiModel.RecommendationBackend[],
    recommendationCode: string
  ): Observable<ParcelRecommendation> {
    const biomassRecommendation = recommendations.find(recommendation =>
      recommendation.code.includes(recommendationCode)
    );
    return this.parcelRecommendationApi.findParcelRecommendation(parcelId, biomassRecommendation.id).pipe(
      switchMap(parcelRecommendation => {
        if (parcelRecommendation) {
          return this.parcelRecommendationApi.findParcelRecommendationEndDate(parcelRecommendation.id).pipe(
            switchMap(endDate => {
              return of({
                meanValue: parcelRecommendation.meanValue,
                date: endDate.substring(0, 10).replace(/\-/gi, '/'),
                comment: biomassRecommendation.comment,
                recommendationName: biomassRecommendation.name,
              });
            })
          );
        } else {
          return of(undefined);
        }
      })
    );
  }
}
