import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilteredParcel } from '@app/overview/shared/parcels-map.models';
import { FilteredFarm } from '@app/overview/overview.models';

@Component({
  selector: 'fstar-filter-wrapper',
  templateUrl: './filter-wrapper.component.html',
  styleUrls: ['./filter-wrapper.component.scss'],
})
export class FilterWrapperComponent implements OnInit {
  @Input() areParcelsFeaturesActivated: boolean;
  @Input() filteredParcels: FilteredParcel[];
  @Input() isDetailsView: boolean;
  @Input() isTabParcelsByIdView: boolean;
  @Input() isTabFarmsByIdView: boolean;
  @Input() selectedParcel: FilteredParcel;
  @Input() filteredFarms: FilteredFarm[];
  @Input() selectedFarm: FilteredFarm;
  @Input() isFarmView: boolean;

  @Output() parcelTermChanged = new EventEmitter<string>();
  @Output() selectedParcelId = new EventEmitter<string>();
  @Output() farmTermChanged = new EventEmitter<string>();
  @Output() farmHovered = new EventEmitter<string>();
  @Output() parcelHovered = new EventEmitter<string>();
  @Output() selectedFarmId = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  parcelToSelect(value: string) {
    this.selectedParcelId.emit(value);
  }

  filterTermChanged(value: string) {
    this.parcelTermChanged.emit(value);
  }

  filterFarmTermChanged(value: string) {
    this.farmTermChanged.emit(value);
  }

  farmToSelect(value: string) {
    this.selectedFarmId.emit(value);
  }

  onFarmHovered(value: string) {
    this.farmHovered.emit(value);
  }

  onParcelHovered(value: string) {
    this.parcelHovered.emit(value);
  }
}
