<aol-map #map class="parcels-map" (onMoveEnd)="updateZoomLevel()">
  <aol-interaction-default></aol-interaction-default>
  <aol-interaction-select
    #parcelSelect
    [condition]="selectOnHoverCondition"
    [style]="styleHoverAoiFunction"
    (onSelect)="displayTooltip($event)"
  >
  </aol-interaction-select>
  <aol-interaction-select
    [condition]="selectOnClickCondition"
    [style]="styleInteractionSelected"
    (onSelect)="onClick($event)"
  >
  </aol-interaction-select>

  <aol-layer-tile>
    <aol-source-xyz
      [wrapX]="false"
      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    >
    </aol-source-xyz>
  </aol-layer-tile>

  <ng-container *ngIf="isLaiModeSelected && !showMarkers && allParcelsLastBiophy.length > 0">
    <aol-layer-image *ngFor="let biophy of allParcelsLastBiophy" [zIndex]="1">
      <aol-source-imagestatic
        [url]="biophy.biophyImageLink"
        [imageExtent]="biophy.biophyImageExtent"
      ></aol-source-imagestatic>
    </aol-layer-image>
  </ng-container>

  <aol-layer-vector #aoiLayer [visible]="toggledLayers.layers.crop" [zIndex]="2">
    <aol-source-vector #aoiSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.markers.crop">
    <aol-source-vector #markersSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.layers.dose" [zIndex]="2">
    <aol-source-vector #doseSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.markers.dose">
    <aol-source-vector #doseMarkersSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.layers.stage" [zIndex]="2">
    <aol-source-vector #stageSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.markers.stage">
    <aol-source-vector #stageMarkersSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.layers.lai" [zIndex]="2">
    <aol-source-vector #laiSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.markers.lai">
    <aol-source-vector #laiMarkersSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.layers.hydro" [zIndex]="2">
    <aol-source-vector #hydroSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-layer-vector [visible]="toggledLayers.markers.hydro">
    <aol-source-vector #hydroMarkersSource [wrapX]="false"> </aol-source-vector>
  </aol-layer-vector>

  <aol-overlay *ngIf="hoveredFeature$ | async as feature" [positioning]="showMarkers ? 'bottom-left' : 'center-left'">
    <aol-coordinate [x]="feature.center[0]" [y]="feature.center[1]"></aol-coordinate>
    <aol-content>
      <div class="tooltip" [ngClass]="{ markerVisible: showMarkers }">
        <div class="arrow"></div>
        <div class="content">
          <div>{{ feature.name }}</div>
          <div>{{ feature.numSaisi }}</div>
        </div>
      </div>
    </aol-content>
  </aol-overlay>

  <aol-view #view [minZoom]="3" [zoom]="6" [maxZoom]="18" [center]="center" [projection]="proj3857"></aol-view>
</aol-map>
