import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CooperativeStore } from '@app/overview/overview.models';
import { FindCooperativesSuccess } from '@app/overview/overview.actions';
import { Action, createReducer, on } from '@ngrx/store';

function sortByCooperativeName(a: CooperativeStore, b: CooperativeStore): number {
  return a.name.localeCompare(b.name);
}

export type State = EntityState<CooperativeStore>;

export const adapter: EntityAdapter<CooperativeStore> = createEntityAdapter<CooperativeStore>({
  selectId: cooperative => cooperative.id,
  sortComparer: sortByCooperativeName,
});

const initialState: State = adapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(FindCooperativesSuccess, (state, { cooperatives }) => adapter.addAll(cooperatives, state))
);

export function cooperativeReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
