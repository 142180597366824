import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIGURATION, Configuration } from '@app/overview/shared/farm-star/configuration/configuration';
import { CoreModel } from '@app/overview/shared/farm-star/core.model';
import { RegisteredTechniciansResponse, RegisteredUser } from '@app/overview/shared/farm-star/farmer.model';
import { toHttpParams } from '@app/shared/utils/http-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreApiModel } from './core-api.model';

@Injectable({
  providedIn: 'root',
})
export class CoreApiService {
  public headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  private readonly API = `${this.config.url}/${this.config.api}/fs-core/api`;

  constructor(@Inject(CONFIGURATION) private config: Configuration, private http: HttpClient) {}

  getFarms(params: CoreApiModel.FarmRequestParams): Observable<CoreModel.FarmBackend[]> {
    return this.http
      .get<CoreApiModel.FarmResponse>(`${this.API}/farms`, {
        headers: this.headers,
        // Filter active Farms in active Campaigns
        params: toHttpParams({ ...params, active: true, 'registeredFarmers.campaign.active': true }),
      })
      .pipe(map(response => response._embedded.farms));
  }

  getCampaigns(campaignIds?: string[]): Observable<CoreModel.CampaignBackend[]> {
    const params = campaignIds ? toHttpParams({ id: campaignIds }) : {};
    return this.http
      .get<CoreApiModel.CampaignResponse>(`${this.API}/campaigns`, {
        headers: this.headers,
        params,
      })
      .pipe(map(response => response._embedded.campaigns));
  }

  getCampaignsFromCooperative(cooperativeId: string): Observable<CoreModel.CampaignBackend[]> {
    return this.http
      .get<CoreApiModel.CampaignResponse>(`${this.API}/campaigns`, {
        headers: this.headers,
        params: { 'registeredCooperatives.cooperative.id': cooperativeId },
      })
      .pipe(map(response => response._embedded.campaigns));
  }

  findFarmCenters(farmIds: string[]): Observable<CoreApiModel.FarmCenters> {
    return this.http.post<CoreApiModel.FarmCenters>(
      `${this.API}/farms/findParcelCenter`,
      {},
      {
        headers: this.headers,
        params: toHttpParams({ farmIds, epsg: '3857' }),
      }
    );
  }

  getRegisteredTechnicians(params: CoreApiModel.RegisteredUserParams): Observable<RegisteredUser[]> {
    return this.http
      .get<RegisteredTechniciansResponse>(`${this.API}/registeredTechnicians`, {
        headers: this.headers,
        params: toHttpParams(params),
      })
      .pipe(map((response: RegisteredTechniciansResponse) => response._embedded.registeredTechnicians));
  }
}
